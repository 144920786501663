import React, { ReactElement, useMemo } from 'react';
import { FormikField } from 'components/common/form/field/FormikField';
import i18n from 'i18n';
import styles from '../l1ObjectForm.module.scss';
import { AdAccountOption } from 'components/AdAccountSelect/AdAccountOption';
import { AdAccountSingleValue } from 'components/AdAccountSelect/AdAccountSingleValue';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { L1ObjectManager, DefaultL1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { CustomField } from 'components/common/form/field/CustomField';

const defaultL1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager();
const bidStrategyFormatter = value => i18n.t<string>(`l1Object.labels.bidStrategy.${value.toLowerCase()}`);
export const FBCampaignForm: React.FC<{
  formType: string,
  fbAdAccountOptions: SelectOptions[],
  isCBO: boolean,
  canEditBidStrategy: boolean,
  currency: string,
  fbAdSets: FbAdSet[],
  fbAdSetBudgets: any[],
  objectiveOptions: SelectOptions[],
  onEditAdSetBudgetsBtn: () => void,
  getBudgetTips: () => ReactElement | string | undefined
}> = ({
  formType,
  fbAdAccountOptions,
  isCBO,
  canEditBidStrategy,
  currency,
  fbAdSets,
  fbAdSetBudgets,
  objectiveOptions,
  onEditAdSetBudgetsBtn,
  getBudgetTips
}) => {

  const bidStrategyOptions = useMemo(() => defaultL1ObjectManager.getL1ObjectBidStrategyOptions(), []);
  const objectiveFormatter = value => {
    if (Object.values(L1ObjectObjective).includes(value)) {
      return i18n.t<string>(`l1Object.labels.objective.${value.toLowerCase()}`);
    }
    return value;
  };
  const adAccountFormatter = value => {
    const option = fbAdAccountOptions.find(option => option.value.toString() === value.toString());
    return option ? `${option.label} (${option.value})` : '';
  };
  const renderAdSetBudget = () => {
    return fbAdSets.map(fbAdSet => {
      const adSetBudget = fbAdSetBudgets.find(fbAdSetBudget => fbAdSetBudget.adset_id === fbAdSet.id);
      return (
        <div key={fbAdSet.id}>
          {`${fbAdSet.name}: ${formatPriceWithCurrency(currency, adSetBudget ? +adSetBudget.lifetime_budget : 0)}`}
        </div>
      );
    });
  };
  return (
    <>
      <FormikField.InputGroup
        label={i18n.t<string>('l1Object.labels.budget')}
        name='budget'
        type='number'
        prefix={currency}
        hint={getBudgetTips()}
      />
      {
        fbAdSetBudgets &&
          <CustomField name='adsetBudgets' label=''>
            <div className={styles.adSetBudgetContainer}>
              <div className={styles.adSetBudget}>
                {renderAdSetBudget()}
              </div>
              <IconWithTooltip
                icon={faPencilAlt}
                onClick={onEditAdSetBudgetsBtn}
                tooltipProps={{
                  id: `adsetBudgetsEditTip`,
                  tooltip: i18n.t<string>('l1Object.hints.editFbAdsetBudgets')
                }}
              />
            </div>
          </CustomField>
      }
      {
        formType === 'create' ?
          <FormikField.Select
            label={i18n.t<string>('l1Object.labels.fb.accountId')}
            name='fb.account_id'
            simpleValue
            options={fbAdAccountOptions}
            optionComponent={AdAccountOption}
            singleValue={AdAccountSingleValue}
            className={styles.selectField}
            hint={fbAdAccountOptions.length > 0 ? undefined : (
              <div className={styles.adAccountEmptyError}>
                {i18n.t<string>('l1Object.errors.adAccountNotSet')}
              </div>
            )}
          /> :
          <FormikField.Label
            label={i18n.t<string>('l1Object.labels.fb.accountId')}
            name='fb.account_id'
            formatter={adAccountFormatter}
          />
      }
      {
        formType === 'create' ?
          <FormikField.Select
            label={i18n.t<string>('l1Object.labels.objectiveLabel')}
            name='fb.objective'
            simpleValue
            options={objectiveOptions}
            className={styles.selectField}
          /> :
          <FormikField.Label
            label={i18n.t<string>('l1Object.labels.objectiveLabel')}
            name='fb.objective'
            formatter={objectiveFormatter}
          />
      }
      {
        isCBO && (
          canEditBidStrategy ?
          <FormikField.Select
            label={i18n.t<string>('l1Object.labels.bidStrategyLabel')}
            name='fb.bid_strategy'
            simpleValue
            options={bidStrategyOptions}
            className={styles.selectField}
          /> :
          <FormikField.Label
            label={i18n.t<string>('l1Object.labels.bidStrategyLabel')}
            name='fb.bid_strategy'
            formatter={bidStrategyFormatter}
          />
        )
      }
    </>
  );
};
