import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { SegmentFormDTO } from 'core/segment/Segment';
import { SegmentManager, DefaultSegmentManager } from 'core/segment/SegmentManager';
import i18n from 'i18next';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { validateEmpty, validateMinMax } from 'utils/ValidateUtils';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';

export type SegmentFormState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
};

export interface SegmentFormModel {
  readonly segmentId?: string | number;
  readonly state: SegmentFormState;
  readonly event: UpdateEventListener<SegmentFormModel>;
  readonly title: string;
  readonly segment?: SegmentFormDTO;
  readonly advertisers: Array<SelectOptions>;
  readonly breadcrumbs: any[];
  init (): Promise<void>;
  validate (segment: SegmentFormDTO): any;
  submit (segment: SegmentFormDTO): void;
  onUnmount (eventHandler): void;
}

export type SegmentFormProps = {
  readonly model: SegmentFormModel;
};

abstract class DefaultSegmentFormModel implements SegmentFormModel {
  event: FireableUpdateEventListener<SegmentFormModel>;
  loading: boolean;
  segment?: SegmentFormDTO;
  manager: SegmentManager;
  redirectPath?: string;
  advertisers: Array<SelectOptions>;
  constructor (
    advertisers: Array<SelectOptions>,
    manager: SegmentManager = new DefaultSegmentManager()
  ) {
    this.advertisers = advertisers;
    this.event = new FireableUpdateEventListener<SegmentFormModel>();
    this.loading = true;
    this.manager = manager;
  }

  abstract get title ();

  abstract get breadcrumbs ();

  abstract initSegment (): Promise<void>;

  abstract submit (segment: SegmentFormDTO): void;

  async init () {
    this.updateState(true);
    try {
      await this.initSegment();
    } catch (e) {}
    this.updateState(false);
  }

  validate = (segment: SegmentFormDTO) => {
    return _.omitBy(
      {
        advertiserId: validateEmpty(_.get(segment, 'advertiserId')),
        name: validateEmpty(_.get(segment, 'name')),
        duration: validateMinMax(_.get(segment, 'duration'), 30, 365)
      }, (value) => value === undefined);
  }

  onUnmount = (eventHandler) => {
    this.event.remove(eventHandler);
    this.redirectPath = undefined;
  }

  get state (): SegmentFormState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath
    };
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}

export class CreateSegmentFormModel extends DefaultSegmentFormModel {

  async initSegment (): Promise<void> {
    this.segment = {
      name: '',
      duration: 30,
      advertiserId: SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER)
    };
  }

  async submit (newSegment: SegmentFormDTO) {
    this.updateState(true);
    try {
      const segmentId = await this.manager.createSegment(newSegment);
      this.updateState(false, `/audience-segments/${segmentId}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  get title () {
    return i18n.t<string>('segmentForm.labels.createTitle');
  }

  get breadcrumbs () {
    return [
      { path: '/audience-segments', breadcrumb: i18n.t<string>('appMenus.data.items.audienceSegments') },
      { path: '/audience-segments/new', breadcrumb: i18n.t<string>('goSegmentForm.titles.create') }
    ];
  }
}

export class EditSegmentFormModel extends DefaultSegmentFormModel {

  segmentId: string | number;

  constructor (
    segmentId: string | number,
    advertisers: Array<SelectOptions>,
    manager: SegmentManager = new DefaultSegmentManager()
  ) {
    super(advertisers, manager);
    this.segmentId = segmentId;
  }

  get title () {
    return i18n.t<string>('segmentForm.labels.editTitle');
  }

  get breadcrumbs () {
    return [
      { path: '/audience-segments', breadcrumb: i18n.t<string>('appMenus.data.items.audienceSegments') },
      {
        path: '/audience-segments/:segmentId',
        breadcrumb: DynamicBreadcrumb,
        props: { label: _.get(this.segment, 'name'), matchParam: 'segmentId' }
      },
      {
        path: '/audience-segments/:segmentId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: { prefix: i18n.t<string>('common.labels.edit'), label: _.get(this.segment, 'name'), matchParam: 'segmentId' }
      }
    ];
  }

  async initSegment (): Promise<void> {
    const segment = await this.manager.getSegment(this.segmentId);
    const advertiserOption = this.advertisers.find(advertiserOption => !!segment.advertiserId && advertiserOption.value.toString() === segment.advertiserId.toString());
    this.segment = {
      ...segment,
      advertiserName: advertiserOption ? advertiserOption.label : ''
    };
  }

  async submit (editedSegment: SegmentFormDTO) {
    this.updateState(true);
    try {
      await this.manager.updateSegment(editedSegment);
      this.updateState(false, `/audience-segments/${this.segmentId}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }
}
