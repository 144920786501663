import { AdType, OutdoorDeliverType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignBasicFormModel, RtbCampaignBasicFormModel, RtbCampaignBasicFormState } from './RtbCampaignBasicFormModel';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { DefaultProductGroupManager, ProductGroupManager } from 'core/limitation/ProductGroupManager';
import { OutdoorChannel } from 'core/limitation/ProductGroup';
import { defaultItemSetting } from 'components/CampaignLimitation/SelectItemComponent';
import { MachineCustomInputComponentModel } from 'components/CampaignLimitation';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { renderProductGroupTips } from 'containers/Limitations/LimitationSetting/ProductGroupTips';
import { renderSpacesOfAttribute } from './components/SpacesOfAttribute';
import { CustomOutdoorDevice } from 'components/CampaignLimitation/CustomOutdoorDevice/CustomOutdoorDevice';
import { OPERATE } from 'enum/Operate';
import { LimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';
import { Device, DeviceMap, SpaceAttribute, SpaceAttributeMap, SpaceAttributes, SpaceDetailMap } from 'components/CampaignLimitation/CustomOutdoorDevice/DeviceDataContext';
import i18n from 'i18n';
import _ from 'lodash';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';

export interface PICFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}

export type PICFormState = RtbCampaignBasicFormState & {
  readonly spaceAttributeMap: SpaceAttributeMap;
  readonly deviceMap: DeviceMap;
  readonly spaceDetailMap: SpaceDetailMap;
};

const adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager();
const rtbCmapignManager: RtbCampaignManager = new DefaultRtbCampaignManager();
const productGroupManager: ProductGroupManager = new DefaultProductGroupManager();
export abstract class AbstractPICFormModel
  extends DefaultRtbCampaignBasicFormModel
  implements PICFormModel {
  adFormat: string = 'Outdoor';
  spaceAttributeSettings: LimitationInventorySettings[] = [];
  spaceAttributes: SpaceAttributes = {};
  sidebarTitleKey: string = 'limitation.titles.outdoorDeviceProperty';
  spaceAttributeMap: SpaceAttributeMap = {};
  deviceMap: DeviceMap = {};
  spaceDetailMap: SpaceDetailMap = {};

  override get state (): PICFormState {
    return {
      dailyBudgetType: this.modelDailyBudgetType,
      spaceAttributeMap: this.spaceAttributeMap,
      deviceMap: this.deviceMap,
      spaceDetailMap: this.spaceDetailMap
    };
  }

  override async init () {
    try {
      const spaceAttributes = await adRequestSourceManager.getSpaceAttributes(
        this.adFormat
      );
      let spaceAttributeSettings: LimitationInventorySettings[] =
        productGroupManager.getSpaceAttributesInventorySettings(
          this.adFormat,
          OutdoorChannel.PIC,
          { [this.adFormat]: spaceAttributes }
        );

      for (const setting of spaceAttributeSettings) {
        if (setting.itemSetting) {
          setting.title && (this.spaceAttributes[setting.name] = {
            title: setting.title,
            options: spaceAttributes[setting.name]
          });
          this.spaceAttributeSettings.push({
            ...setting,
            ignoreAddonFeature: true,
            i18nPrefix: 'limitation.labels',
            supportOperates: [
              OPERATE.INCLUDE,
              OPERATE.EXCLUDE,
              OPERATE.PREFERRED,
              OPERATE.NONPREFERRED
            ],
            sidebarTitleKey: this.sidebarTitleKey,
            tipsConfig: {
              debounceTrigger: 500,
              renderTips: (attribute) => {
                return renderSpacesOfAttribute(
                  this.spaceAttributeMap,
                  setting.name,
                  attribute.value.toString(),
                  'Outdoor',
                  OutdoorChannel.PIC
                );
              }
            }
          });
        } else {
          this.spaceAttributeSettings.unshift(setting);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  fetchSpacesAndUpdateMap = async (attributeType: string, spaceAttribute: SpaceAttribute): Promise<void> => {
    const spaces = await adRequestSourceManager.getSpacesWithConditions({ [attributeType]: [spaceAttribute.value.toString()] }, 'Outdoor', OutdoorChannel.PIC);
    _.set(this.spaceAttributeMap, `${attributeType}.${spaceAttribute.value.toString()}`, spaces);
    for (const space of spaces) {
      const attributeTypeMap = _.get(this.deviceMap[space.value], attributeType, {
        title: this.spaceAttributes[attributeType].title,
        options: [] as SpaceAttribute[]
      });
      _.set(this.deviceMap, `${space.value}.${attributeType}`, {
        title: attributeTypeMap.title,
        options: _.unionBy(attributeTypeMap.options, [spaceAttribute], 'value')
      });
    }
  }

  fetchMaps = async () => {
    // 目前的 API 設計必須是一個一個 attribute 去打 space-with-attribute API，有點費時
    // TODO: performance issue, how to optimize?
    this.event.fireEvent(this);
    await Promise.all(_.keys(this.spaceAttributes).map(async (attributeType) => {
      await Promise.all(this.spaceAttributes[attributeType].options.map(async (spaceAttribute) => {
        if (!this.spaceAttributeMap[attributeType] || !this.spaceAttributeMap[attributeType][spaceAttribute.value]) {
          await this.fetchSpacesAndUpdateMap(attributeType, spaceAttribute);
        }
        if (spaceAttribute.options && spaceAttribute.options.length > 0) {
          await Promise.all(spaceAttribute.options.map(async (option) => {
            if (!this.spaceAttributeMap[attributeType] || !this.spaceAttributeMap[attributeType][option.value]) {
              await this.fetchSpacesAndUpdateMap(attributeType, option);
            }
          }));
        }
      }));
    }));
    if (_.isEmpty(this.spaceDetailMap)) {
      const spaceDevices = await rtbCmapignManager.getOutdoorSpacesDetail(_.keys(this.deviceMap));
      this.spaceDetailMap = _.keyBy(spaceDevices, 'spaceId');
    }
    this.event.fireEvent(this);
  }

  override get isOutdoorType (): boolean {
    return true;
  }

  override get limitatoinConfig () {
    return {
      defaultLimitationToShow: {
        name: 'customOutdoorDevice',
        operate: OPERATE.INCLUDE
      },
      supportAgeGenderLimitation: false,
      supportOtherLimitation: false,
      supportAudienceEstimation: false,
      requireIncludeLimitation: true
    };
  }

  abstract get campaignAdType (): AdType;

  override get outdoorDeliverTypeOptions (): SelectOptions[] {
    return createSelectOptionsFromEnum(
      OutdoorDeliverType,
      'campaign.labels.outdoorDeliverType.'
    );
  }

  override getRtbOptionsMap () {
    return {
      [L1ObjectObjective.AWARENESS]: {
        [RtbCampaignPlanType.RS]: [L2ObjectOptimizationGoal.IMPRESSIONS],
        [RtbCampaignPlanType.FCPM]: [L2ObjectOptimizationGoal.IMPRESSIONS]
      },
      [L1ObjectObjective.TRAFFIC]: {},
      [L1ObjectObjective.SALES]: {},
      [L1ObjectObjective.UNSPECIFIED]: {
        [RtbCampaignPlanType.FCPM]: [L2ObjectOptimizationGoal.IMPRESSIONS]
      }
    };
  }

  override getLimitationInventorySetting (): LimitationInventorySettings[] {
    return [
      ...this.spaceAttributeSettings,
      {
        name: 'customOutdoorDevice',
        title: 'limitation.labels.customOutdoorDevice',
        ignoreValidateOption: true,
        supportOperates: [
          OPERATE.INCLUDE,
          OPERATE.EXCLUDE,
          OPERATE.PREFERRED,
          OPERATE.NONPREFERRED
        ],
        singleSelect: false,
        itemSetting: {
          ...defaultItemSetting(),
          categorySelectable: false
        },
        searchPlaceholder: 'limitation.placeholders.searchOutdoorDevice',
        component: CustomOutdoorDevice,
        ignoreAddonFeature: true,
        sidebarTitleKey: this.sidebarTitleKey,
        cb: () =>
          adRequestSourceManager.getSpacesWithConditions(
            {},
            'Outdoor',
            OutdoorChannel.PIC
          ),
        customInputModelGetter: () => new MachineCustomInputComponentModel(
          this.spaceAttributes,
          this.state.spaceAttributeMap,
          this.state.deviceMap,
          this.state.spaceDetailMap,
          this.fetchMaps
        )
      },
      {
        name: 'systemRecommendOutdoorDevice',
        title: 'limitation.labels.systemRecommendOutdoorDevice',
        ignoreValidateOption: true,
        supportOperates: [
          OPERATE.INCLUDE,
          OPERATE.EXCLUDE,
          OPERATE.PREFERRED,
          OPERATE.NONPREFERRED
        ],
        singleSelect: false,
        itemSetting: {
          ...defaultItemSetting(),
          categorySelectable: false
        },
        searchPlaceholder:
          'limitation.placeholders.searchRecommendOutdoorDevice',
        component: SelectComponent,
        ignoreAddonFeature: true,
        sidebarTitleKey: this.sidebarTitleKey,
        needInitExtra: true,
        cb: async () => {
          const spacesGroups: SelectOptions[] = await adRequestSourceManager.getSpacesGroupsOfAdType(this.campaignAdType);
          _.forEach(spacesGroups, (spaceGroup) => {
            const extra = _.defaultTo(spaceGroup.extra, []);
            _.forEach(extra, (space) => {
              const spaceId = space.value.toString().replace('\\', '');
              const spaceAttribute: SpaceAttribute = {
                label: spaceGroup.label,
                value: spaceGroup.value
              };
              const device: Device = {
                label: space.label,
                value: spaceId
              };
              const devices = _.get(this.spaceAttributeMap.systemRecommendOutdoorDevice, spaceAttribute.value.toString(), []);
              _.set(this.spaceAttributeMap, `systemRecommendOutdoorDevice.${spaceAttribute.value.toString()}`, _.unionBy(devices, [device], 'value'));
              const systemRecommendOutdoorDevicesOfDevice = _.get(this.deviceMap[spaceId], 'systemRecommendOutdoorDevice', {
                title: i18n.t<string>('limitation.labels.systemRecommendOutdoorDevice'),
                options: []
              });
              _.set(this.deviceMap, `${spaceId}.systemRecommendOutdoorDevice`, {
                title: systemRecommendOutdoorDevicesOfDevice.title,
                options: _.unionBy(systemRecommendOutdoorDevicesOfDevice.options, [spaceAttribute], 'value')
              });

            });
          });
          return spacesGroups;
        },
        tipsConfig: {
          renderTips: (space: SelectOptions) => {
            return space.isGroup ? renderProductGroupTips(space) : undefined;
          }
        }
      },
      {
        name: 'adx',
        hideLimitation: true,
        ignoreValidateOption: true,
        ignoreAddonFeature: true
      }
    ];
  }
}
