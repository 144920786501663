import React from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { useProductGroupListModel } from './ProductGroupListModel';
import i18n from 'i18n';
import styles from './productGroupList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import { Modal } from 'components/common/Modal/Modal';
import _ from 'lodash';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Nav } from 'react-bootstrap';
import { ProductGroupType } from 'core/limitation/ProductGroup';
import { Link } from 'react-router-dom';
import { renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import BootstrapTable from 'react-bootstrap-table-next';

export type ProductGroupListProps = {};

export const ProductGroupList: React.FC<ProductGroupListProps> = () => {

  const {
    state,
    columns,
    loading,
    onSwitchType,
    onHandleSearch,
    deleteProductGroup,
    cancelDelete,
    closeCampaignsModal
  } = useProductGroupListModel();

  const renderCampaigns = (campaignsToShow: SelectOptions[]) => {
    const idFormatter = (id) => <Link className={styles.id} to={`/campaigns/${id}/redirectToDetail`}>{id}</Link>;

    const columns = [
      renderColumn(sortableColumn('value', 'campaignUseProductGroup.headers.id', true), idFormatter),
      renderColumn(sortableColumn('label', 'campaignUseProductGroup.headers.name', true))
    ];
    return (
      <div className={styles.tableContainer}>
        <BootstrapTable
          keyField='value'
          data={campaignsToShow}
          columns={columns}
          noDataIndication={i18n.t<string>('common.labels.noData')}
          defaultSorted={[{
            dataField: 'value',
            order: 'desc'
          }]}
        />
      </div>
    );
  };

  return (
    <div className={styles.productGroupList}>
      {loading && <LoadingIndicator />}
      {
        state.productGroupToDelete && (
          <Modal
            title={i18n.t<string>('productGroupList.labels.deleteModalTitle')}
            secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: cancelDelete }}
            primaryButton={{ title: i18n.t<string>('common.buttons.delete'), callback: _.partial(deleteProductGroup, state.productGroupToDelete) }}
            dismiss={cancelDelete}
          >
            {i18n.t<string>('productGroupList.labels.deleteModalContent')}
          </Modal>
        )
      }
      {
        state.campaignsToShow && (
          <Modal
            title={i18n.t<string>('productGroupList.labels.campaignModalTitle')}
            secondaryButton={{ title: i18n.t<string>('common.buttons.confirm'), callback: closeCampaignsModal }}
            dismiss={closeCampaignsModal}
          >
            {
              state.campaignsToShow.length === 0 ?
                i18n.t<string>('common.labels.noData') :
                renderCampaigns(state.campaignsToShow)
            }
          </Modal>
        )
      }
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {i18n.t<string>('productGroupList.labels.title')}
        </div>
      </div>
      <div className={styles.filterArea}>
        <Link
          to='/system/product-groups/new'
          className={`btn btn-tertiary btn-sm ${styles.createBtn}`}
        >
          {i18n.t<string>('productGroupList.labels.create')}
        </Link>
        <SearchBar
          model={{
            placeholder: i18n.t<string>('productGroupList.placeholders.searchbar'),
            search: onHandleSearch,
            defaultValue: state.searchString
          }}
        />
      </div>
      <div className={styles.contentArea}>
        <Nav
          activeKey={state.groupType}
          onSelect={onSwitchType}
        >
          <Nav.Item>
            <Nav.Link eventKey={ProductGroupType.ADSPACE}>{i18n.t<string>('productGroupList.labels.space')}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={ProductGroupType.DOMAIN}>{i18n.t<string>('productGroupList.labels.domain')}</Nav.Link>
          </Nav.Item>
        </Nav>
        <StickableBootstrapTable
          stickFirstColumn
          stickLastColumn
          keyField='id'
          data={state.filteredList}
          columns={columns}
          noDataIndication={i18n.t<string>('productGroupList.labels.noData')}
          defaultSorted={[{
            dataField: 'id',
            order: 'desc'
          }]}
        />
      </div>
    </div>
  );
};
