import { DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { SourceFormType } from 'core/tenmaxSegment/TenmaxSegment';
import { DefaultTenmaxSegmentManager } from 'core/tenmaxSegment/TenmaxSegmentManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from 'i18n';

const tenmaxSegmentManager = new DefaultTenmaxSegmentManager();
const adRequestSourceManager = new DefaultAdRequestSourceManager();

export type SourceFormModel = {
  title: string,
  loading: boolean,
  initSource?: SourceFormType,
  redirectPath: string | undefined,
  countryOptions: SelectOptions[],
  segmentLabelOptions: SelectOptions[],
  handleSubmit: (source: SourceFormType) => void
};

const useSegmentOptions = () => {
  const { loading, callAPIs } = useCallAPI();
  const [countryOptions, setCountryOptions] = useState<SelectOptions[]>([]);
  const [segmentLabelOptions, setSegmentLabelOptions] = useState<SelectOptions[]>([]);

  useEffect(() => {
    callAPIs([
      () => adRequestSourceManager.getAsiaMaxCountries(),
      () => adRequestSourceManager.getSegmentLabel()
    ], (countries, segmentLabels) => {
      setCountryOptions(countries);
      setSegmentLabelOptions(segmentLabels);
    });
  }, [callAPIs]);

  return {
    loading,
    countryOptions,
    segmentLabelOptions
  };
};

export const useCreateSourceFormModel = (): SourceFormModel => {
  const { loading, callAPIs } = useCallAPI();
  const { loading: loadingOptions, countryOptions, segmentLabelOptions } = useSegmentOptions();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();

  const handleSubmit = useCallback((source: SourceFormType) => {
    callAPIs([
      () => tenmaxSegmentManager.createSource(source)
    ], (id) => {
      setRedirectPath(`/tenmax-segments/sources/${id}`);
    });
  }, [callAPIs]);

  const initSource = useMemo(() => ({
    name: '',
    duration: 30,
    tags: [],
    countries: []
  }), []);

  return {
    title: i18n.t<string>('sourceForm.titles.create'),
    loading: loadingOptions || loading,
    initSource,
    redirectPath,
    countryOptions,
    segmentLabelOptions,
    handleSubmit
  };
};

export const useEditSourceFormModel = (): SourceFormModel => {

  const { loading, callAPIs } = useCallAPI();
  const { loading: loadingOptions, countryOptions, segmentLabelOptions } = useSegmentOptions();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [initSource, setInitSource] = useState<SourceFormType | undefined>();
  const { sourceId } = useParams<{ sourceId: string }>();

  useEffect(() => {
    callAPIs([
      () => tenmaxSegmentManager.getSource(+sourceId)
    ], source => {
      setInitSource(source);
    });
  }, [sourceId, callAPIs]);

  const handleSubmit = useCallback((source: SourceFormType) => {
    callAPIs([
      () => tenmaxSegmentManager.updateSource({
        ...source,
        id: +sourceId
      })
    ], () => {
      setRedirectPath(`/tenmax-segments/sources/${sourceId}`);
    });
  }, [sourceId, callAPIs]);

  return {
    title: i18n.t<string>('sourceForm.titles.edit'),
    loading: loadingOptions || loading,
    initSource,
    redirectPath,
    countryOptions,
    segmentLabelOptions,
    handleSubmit
  };
};
