import { AddonFeatureManager } from 'core';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { MessageCampaignBasic } from 'core/messageCampaign/MessageCampaign';
import { Order } from 'core/order/Order';
import {
  CreateMessageCampaignSetupFlowPageModel,
  EditMessageCampaignSetupFlowPageModel, MessageCampaignSetupFlowPageModel
} from './MessageCampaignSetupFlow/MessageCampaignSetupFlowPageModel';

export interface MessageCampaignsModel {
  readonly order: Order;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly channel: L1ObjectChannel;
  getCampaignCreateModel (localeMeta): MessageCampaignSetupFlowPageModel;
  getCampaignEditModel (campaignId: number, localeMeta): MessageCampaignSetupFlowPageModel;
  setOrder (order: Order): void;
}

export type MessageCampaignsProps = {
  readonly model: MessageCampaignsModel;
};

export class DefaultMessageCampaignsModel implements MessageCampaignsModel {

  addonFeatureManager: AddonFeatureManager;
  advertisers?: Array<SelectOptions>;
  campaignCreateModel?: MessageCampaignSetupFlowPageModel;
  campaignEditModel?: EditMessageCampaignSetupFlowPageModel;

  order: Order;
  channel: L1ObjectChannel;

  constructor (order: Order, addonFeatureManager: AddonFeatureManager, private l1Object: L1Object, private campaignList?: MessageCampaignBasic[]) {
    this.order = order;
    this.channel = l1Object.channel;
    this.addonFeatureManager = addonFeatureManager;
  }

  setOrder (order: Order) {
    this.order = order;
    this.campaignCreateModel && this.campaignCreateModel.setOrder(order);
    this.campaignEditModel && this.campaignEditModel.setOrder(order);
  }

  getCampaignCreateModel (localeMeta): MessageCampaignSetupFlowPageModel {
    if (this.campaignCreateModel) {
      return this.campaignCreateModel;
    }

    this.campaignCreateModel = new CreateMessageCampaignSetupFlowPageModel(
      this.order,
      this.addonFeatureManager,
      this.l1Object,
      localeMeta,
      this.campaignList
    );
    return this.campaignCreateModel;
  }

  getCampaignEditModel (campaignId: number, localeMeta): MessageCampaignSetupFlowPageModel {
    if (this.campaignEditModel && this.campaignEditModel.campaignId === campaignId) {
      return this.campaignEditModel;
    }
    this.campaignEditModel = new EditMessageCampaignSetupFlowPageModel(
      campaignId,
      this.order,
      this.addonFeatureManager,
      this.l1Object,
      localeMeta,
      this.campaignList ? this.campaignList.filter(campaign => campaign.id !== +campaignId) : []
    );
    return this.campaignEditModel;
  }

}
