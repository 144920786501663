import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';

import './Reports.scss';

import { PerformanceReportModel } from './PerformanceReport/PerformanceReportModel';
import { PMaxCore, RoleNames } from 'core';
import i18n from 'i18n';
import { addOnEnabled, hasFuncs, hasRoles } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { ReconciliationReportModel } from './ReconciliationReport/ReconciliationReportModel';
import { ReportContent } from './ReportContent';
import { PerformanceReportDimension } from './PerformanceReport/PerformanceReportDimension';
import { ReconciliationReportDimension } from './ReconciliationReport/ReconciliationReportDimension';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { CreditList } from 'containers/Credits/CreditList/CreditList';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import _ from 'lodash';
import { CreditDetail } from 'containers/Credits/CreditDetail/CreditDetail';
import { CampaignDashboard } from 'containers/CampaignDashboard/CampaignDashboard';
import { SalesChannelReconciliationReport } from './SalesChannelReconciliationReport';

const PerformanceReportView = withErrorBoundary(ERROR_CODE.REPORT_PERFORMANCE, ReportContent);
const ReconciliationView = withErrorBoundary(ERROR_CODE.REPORT_RECONCILIATION, ReportContent);

export class Reports extends React.Component<RouteComponentProps<{}> & { core: PMaxCore }> {

  performanceReportModel: PerformanceReportModel;
  reconciliationReportModel: ReconciliationReportModel;

  constructor (props) {
    super(props);
    const core = this.props.core;
    this.performanceReportModel = new PerformanceReportModel(
      core.authenticationManager.actor,
      core.accountManager.localeMeta,
      (newSearchPath, replace) => {
        const newPath = '/reports/performance' + newSearchPath;
        replace ? props.history.replace(newPath) : props.history.push(newPath);
      },
      core.addonFeatureManager
    );

    this.reconciliationReportModel = new ReconciliationReportModel(
      core.authenticationManager.actor,
      core.accountManager.localeMeta,
      (newSearchPath, replace) => {
        const newPath = '/reports/reconciliation' + newSearchPath;
        replace ? props.history.replace(newPath) : props.history.push(newPath);
      },
      core.addonFeatureManager
    );
  }

  renderPerformanceReport = (props) => {
    const actor = this.props.core.authenticationManager.actor;
    return (
      <PerformanceReportView
        key={actor ? actor.id : 0}
        lang={i18n.language}
        search={props.location.search}
        model={this.performanceReportModel}
        customDimensionComponent={PerformanceReportDimension}
      />
    );
  }

  renderAgencyReport = (props) => {
    const actor = this.props.core.authenticationManager.actor;
    return (
      <ReconciliationView
        key={actor ? actor.id : 0}
        lang={i18n.language}
        search={props.location.search}
        model={this.reconciliationReportModel}
        customDimensionComponent={ReconciliationReportDimension}
      />
    );
  }

  renderSalesChannelReconciliation = () => {
    return <SalesChannelReconciliationReport />;
  }

  redirectToRoot = () => {
    return <Redirect to='/' />;
  }

  renderCredits = () => {
    return (
      <PermissionChecker
        permissionAware={hasRoles(RoleNames.sysAdmin)}
        renderOtherwise={this.redirectToCreditDetail}
      >
        <CreditList />
      </PermissionChecker>
    );
  }

  redirectToCreditDetail = () => {
    const agencyId = _.get(this.props.core.authenticationManager.actor, 'agencyId');
    return agencyId ? <Redirect to={`/reports/credits/${agencyId}`} /> : `/reports/credits/${ROUTE_PATH.ERROR403}`;
  }

  renderCreditDetail = (props) => {
    const agencyId = props.match.params.agencyId;
    return (
      <CreditDetail agencyId={agencyId} />
    );
  }

  renderCampaignDashboard = () => {
    const agencyId = _.get(this.props.core.authenticationManager.actor, 'agencyId');
    return <CampaignDashboard agencyId={agencyId} />;
  }

  render () {
    const { sysAdmin, agencyAdmin, agencyManager, agencySales } = RoleNames;
    const creditPermission = hasRoles(
      RoleNames.sysAdmin
    );
    return (
      <div className='reports'>
        <Switch>
          <RouteWithPermission
            path='/reports/performance'
            permissionAware={hasFuncs(Permission.REPORT_ADS)}
            redirectPath={`/reports/performance/${ROUTE_PATH.ERROR403}`}
            render={this.renderPerformanceReport}
          />
          <RouteWithPermission
            path='/reports/campaign-dashboard'
            permissionAware={hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager, agencySales).and(addOnEnabled(ADDONFEATURE.REPORT.REPORT_CAMPAIGN_DASHBOARD)))}
            redirectPath={`/reports/campaign-dashboard/${ROUTE_PATH.ERROR403}`}
            render={this.renderCampaignDashboard}
          />
          <RouteWithPermission
            path='/reports/reconciliation'
            permissionAware={hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager, agencySales).and(addOnEnabled(ADDONFEATURE.COMPANY.AGENCY_PROVIDE_REPORT_TO_WORKFLOW)))}
            redirectPath={`/reports/reconciliation/${ROUTE_PATH.ERROR403}`}
            render={this.renderAgencyReport}
          />
          <RouteWithPermission
            path='/reports/sales-channel-reconciliation'
            permissionAware={hasRoles(sysAdmin)}
            redirectPath={`/reports/sales-channel-reconciliation/${ROUTE_PATH.ERROR403}`}
            render={this.renderSalesChannelReconciliation}
          />
          <RouteWithPermission
            path='/reports/credits/:agencyId'
            permissionAware={creditPermission}
            redirectPath={`/reports/credits/${ROUTE_PATH.ERROR403}`}
            render={this.renderCreditDetail}
          />
          <RouteWithPermission
            path='/reports/credits'
            permissionAware={creditPermission}
            redirectPath={`/reports/credits/${ROUTE_PATH.ERROR403}`}
            render={this.renderCredits}
          />
          <Route render={this.redirectToRoot} />
        </Switch>
      </div>
    );
  }
}
