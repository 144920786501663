import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import moment from 'moment';
import { ReportManager, DefaultReportManager } from 'core/report/ReportManager';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { AddonFeatureManager } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import i18n from 'i18n';

export type DownloadReportPanelState = {
  readonly startDate: string;
  readonly endDate: string;
  readonly loading: boolean;
  readonly selectFields: { [key: string]: boolean }
  readonly closingReportCustom: { [key: string]: boolean }
  readonly groupBy: string;
  readonly activeTab: string;
  readonly reportType: string;
  readonly closingReportType: string;
  readonly enableDownload: boolean;
};
export interface DownloadReportPanelModel {
  readonly state: DownloadReportPanelState;
  readonly event: UpdateEventListener<DownloadReportPanelModel>;
  readonly defaultStartDate: string;
  readonly defaultEndDate: string;
  readonly reportTypeOptions: SelectOptions[];
  readonly reportTypeCannotShowInCustom: string[];
  readonly addonFeatureManager: AddonFeatureManager;
  readonly supportGroupBy: GroupBy[];
  readonly errors: { [key: string]: string };
  cancel (): void;
  updateDateRange (startDate, endDate): void;
  downloadReport (): void;
  onQuickFieldSelect (type: string): void;
  onFieldSelect (event: any): void;
  onClosingReportCustomSelect (event: any): void;
  changeGroupBy (event: any): void;
  onSelectTab (tab: string | null): void;
  onSelectReportType (type: string): void;
  onSelectClosingReportType (type: string): void;
}

export type DownloadReportPanelProps = {
  readonly model: DownloadReportPanelModel;
};

export enum ReportDownloadPanelTab {
  NORMAL = 'normal',
  CLOSING = 'closing',
  ADVANCE = 'advance'
}

export enum Fields {
  IMPRES = 'IMPRESS',
  UNIQUE_USERS = 'UU',
  CLICKS = 'CLICK',
  CTR = 'CTR',
  VIEW = 'AD_VIEW',
  VIEW_RATE = 'VIEW_RATE',
  CONVS = 'CONVS',
  SPENT = 'SPENT',
  CPM = 'CPM',
  CPC = 'CPC',
  CPV = 'CPV',
  CPA = 'CPA',
  FIRST_QUARTILE = 'FIRST_QUARTILE',
  MID_POINT = 'MID_POINT',
  THIRD_QUARTILE = 'THIRD_QUARTILE',
  COMPLETE = 'COMPLETE',
  GENDER = 'GENDER',
  AGE = 'AGE',
  INTERESTS = 'INTEREST',
  INSIGHTS = 'INSIGHTS',
  COUNTRY = 'COUNTRY',
  DOMAIN = 'DOMAIN'
}

export const fieldsDependentOn = {
  [Fields.CLICKS]: [Fields.CTR, Fields.CPC],
  [Fields.IMPRES]: [Fields.CTR, Fields.CPM, Fields.VIEW_RATE],
  [Fields.VIEW]: [Fields.CPV, Fields.VIEW_RATE],
  [Fields.CONVS]: [Fields.CPA],
  [Fields.SPENT]: [Fields.CPM, Fields.CPC, Fields.CPA, Fields.CPV]
};

export enum GroupBy {
  L1_OBJECT = 'L1_OBJECT',
  CREATIVE = 'CREATIVE',
  CAMPAIGN = 'CAMPAIGN'
}

const initFields = {
  [Fields.IMPRES]: false,
  [Fields.UNIQUE_USERS]: false,
  [Fields.CLICKS]: false,
  [Fields.CTR]: false,
  [Fields.VIEW]: false,
  [Fields.VIEW_RATE]: false,
  [Fields.CONVS]: false,
  [Fields.SPENT]: false,
  [Fields.CPM]: false,
  [Fields.CPC]: false,
  [Fields.CPV]: false,
  [Fields.CPA]: false,
  [Fields.FIRST_QUARTILE]: false,
  [Fields.MID_POINT]: false,
  [Fields.THIRD_QUARTILE]: false,
  [Fields.COMPLETE]: false,
  [Fields.GENDER]: false,
  [Fields.AGE]: false,
  [Fields.INTERESTS]: false
};

const sortingFields = ['UU', 'IMPRESS', 'VIEWABLE', 'AD_VIEW', 'VIEW_RATE', 'VIEWABLE_VIEW_RATE', 'CPV', 'CLICK', 'CONVS', 'CTR', 'VIEWABLE_CTR', 'CPM', 'CPC', 'CPA', 'FIRST_QUARTILE', 'MID_POINT', 'THIRD_QUARTILE', 'COMPLETE', 'SPENT'];

export const defaultFormat = 'YYYY-MM-DD';
abstract class DefaultDownloadReportPanelModel implements DownloadReportPanelModel {
  event: FireableUpdateEventListener<DownloadReportPanelModel>;
  startDate: string;
  endDate: string;
  loading: boolean;
  reportType: string = 'cpm';
  closingReportType: string = 'closing';
  activeTab: string = ReportDownloadPanelTab.NORMAL;
  enableDownload: boolean = true;
  selectFields = { ...initFields };
  closingReportCustom = {
    [Fields.COUNTRY]: false,
    [Fields.DOMAIN]: false
  };

  groupBy: string = GroupBy.CREATIVE;

  constructor (
    public defaultStartDate: string,
    public defaultEndDate: string,
    public supportGroupBy: GroupBy[],
    public callback: () => void,
    public addonFeatureManager: AddonFeatureManager,
    protected reportManager: ReportManager = new DefaultReportManager()
  ) {
    this.event = new FireableUpdateEventListener<DownloadReportPanelModel>();
    this.startDate = moment(defaultStartDate).format(defaultFormat);
    this.endDate = moment(defaultEndDate).endOf('day').format(defaultFormat);
    this.loading = false;
  }

  get errors () {
    return Object.keys(this.selectFields).reduce<{ [key: string]: string }>((acc, field) => {
      const checked = this.selectFields[field];
      const checkedDependentFields = fieldsDependentOn[field] ?
        fieldsDependentOn[field].filter(dependentField => this.selectFields[dependentField]) :
        [];
      if (!checked && checkedDependentFields.length > 0) {
        const errorMessage = i18n.t<string>('downloadReportPanel.errors.fieldRequired', {
          field: i18n.t<string>(`downloadReportPanel.labels.${_.camelCase(field)}`),
          checkedDependentFields: checkedDependentFields.map(field => i18n.t<string>(`downloadReportPanel.labels.${_.camelCase(field)}`)).join(', ')
        });
        return { ...acc, [field]: errorMessage };
      }
      return acc;
    }, {});
  }

  get state (): DownloadReportPanelState {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      loading: this.loading,
      selectFields: this.selectFields,
      groupBy: this.groupBy,
      reportType: this.reportType,
      closingReportType: this.closingReportType,
      activeTab: this.activeTab,
      enableDownload: this.enableDownload,
      closingReportCustom: this.closingReportCustom
    };
  }

  get quickSelectFields () {
    return {
      cpm: [Fields.IMPRES, Fields.CLICKS, Fields.CTR, Fields.CPM, Fields.SPENT],
      cpc: [Fields.IMPRES, Fields.CLICKS, Fields.CTR, Fields.CPC, Fields.SPENT],
      cpa: [Fields.IMPRES, Fields.CLICKS, Fields.CONVS, Fields.CTR, Fields.CPC, Fields.CPA, Fields.SPENT],
      video: [Fields.IMPRES, Fields.VIEW, Fields.VIEW_RATE, Fields.CPV, Fields.CLICKS, Fields.CTR, Fields.FIRST_QUARTILE, Fields.MID_POINT, Fields.THIRD_QUARTILE, Fields.COMPLETE, Fields.SPENT],
      outdoor: [Fields.IMPRES, Fields.CPM, Fields.FIRST_QUARTILE, Fields.MID_POINT, Fields.THIRD_QUARTILE, Fields.COMPLETE, Fields.SPENT],
      closing: [Fields.IMPRES, Fields.CLICKS, Fields.CTR, Fields.CPM, Fields.CPC, Fields.SPENT, Fields.INSIGHTS, Fields.UNIQUE_USERS],
      reset: [],
      rawData: []
    };
  }

  async downloadReport () {
    this.updateState(true);
    try {
      let fields;
      let groupBy: string = GroupBy.CREATIVE;
      if (this.activeTab === ReportDownloadPanelTab.NORMAL) {
        fields = this.quickSelectFields[this.reportType];
      } else if (this.activeTab === ReportDownloadPanelTab.CLOSING) {
        fields = this.quickSelectFields[this.closingReportType];
        this.closingReportCustom[Fields.COUNTRY] && fields.push(Fields.COUNTRY);
        this.closingReportCustom[Fields.DOMAIN] && fields.push(Fields.DOMAIN);
      } else {
        fields = Object.keys(this.selectFields).filter(key => this.selectFields[key] === true).sort(function (a, b) {
          return sortingFields.indexOf(a) - sortingFields.indexOf(b);
        });
        groupBy = this.groupBy;
      }
      await this.callDownloadReportAPI(this.startDate, this.endDate, groupBy, fields);
      this.updateState(false);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  abstract get customReportTypeOptions (): Array<SelectOptions>;

  abstract get reportTypeCannotShowInCustom (): string[];

  abstract callDownloadReportAPI (startDate: string, endDate: string, groupBy: string, fields: Fields[]);

  cancel () {
    this.callback();
  }

  get reportTypeOptions (): Array<SelectOptions> {
    const isOutdoorEnable = this.addonFeatureManager.isAnyFeatureEnable(
      ADDONFEATURE.CREATIVES.EDIMAX,
      ADDONFEATURE.CREATIVES.PIC_LONG,
      ADDONFEATURE.CREATIVES.PIC_SHORT
    );
    return _.compact([
      {
        label: i18n.t<string>('downloadReportPanel.labels.cpmOption'),
        value: 'cpm'
      },
      {
        label: i18n.t<string>('downloadReportPanel.labels.cpcOption'),
        value: 'cpc'
      },
      this.addonFeatureManager.isFeatureEnable(
        ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST
      )
        ? {
          label: i18n.t<string>('downloadReportPanel.labels.cpaOption'),
          value: 'cpa'
        }
        : undefined,
      this.addonFeatureManager.isAnyFeatureEnable(
        ADDONFEATURE.CREATIVES.VIDEO,
        ADDONFEATURE.CREATIVES.THIRDPARTY,
        ADDONFEATURE.CREATIVES.COMBO
      ) || isOutdoorEnable
        ? {
          label: i18n.t<string>('downloadReportPanel.labels.videoOption'),
          value: 'video'
        }
        : undefined,
      isOutdoorEnable
        ? {
          label: i18n.t<string>('downloadReportPanel.labels.outdoorOption'),
          value: 'outdoor'
        }
        : undefined,
      ...this.customReportTypeOptions
    ]);
  }

  onQuickFieldSelect = (type: string) => {
    Object.keys(_.omit(this.selectFields, [Fields.GENDER, Fields.AGE, Fields.INTERESTS]))
      .forEach(field => {
        this.selectFields[field] = false;
        if (this.quickSelectFields[type].includes(field)) {
          this.selectFields[field] = true;
        }
      });
    this.checkEnableDownload();
    this.updateState(false);
  }

  onClosingReportCustomSelect = (event: any) => {
    this.closingReportCustom = { ...this.closingReportCustom, [event.target.id]: event.target.checked };
    this.updateState(false);
  }

  onFieldSelect = (event: any) => {
    this.selectFields = { ...this.selectFields, [event.target.id]: event.target.checked };
    this.checkEnableDownload();
    this.updateState(false);
  }

  checkEnableDownload = () => {
    if (this.activeTab === ReportDownloadPanelTab.ADVANCE) {
      this.enableDownload = Object.values(this.selectFields).reduce((acc, checked) => {
        return acc || checked;
      }, false) && _.isEmpty(this.errors);
    } else {
      this.enableDownload = true;
    }
  }

  onSelectTab = (tab: string | null) => {
    if (tab === null) {
      return;
    }
    this.activeTab = tab;
    this.checkEnableDownload();
    this.updateState(false);
  }

  onSelectReportType = (type: string) => {
    this.reportType = type;
    this.updateState(false);
  }

  onSelectClosingReportType = (type: string) => {
    this.closingReportType = type;
    this.updateState(false);
  }

  changeGroupBy = (event: any) => {
    this.groupBy = event.target.value;
    this.updateState(false);
  }

  updateDateRange (startDate: string, endDate: string) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.updateState();
  }

  updateState (loading = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class DownloadOrderReportPanelModel extends DefaultDownloadReportPanelModel {

  constructor (
    private order: {
      name: string,
      orderNumber: string,
      id: number
    },
    defaultStartDate: string,
    defaultEndDate: string,
    supportGroupBy: GroupBy[],
    callback: () => void,
    addonFeatureManager: AddonFeatureManager,
    reportManager: ReportManager = new DefaultReportManager()
  ) {
    super(
      defaultStartDate,
      defaultEndDate,
      supportGroupBy,
      callback,
      addonFeatureManager,
      reportManager
    );
  }

  get customReportTypeOptions (): Array<SelectOptions> {
    return [
      {
        label: i18n.t<string>('downloadReportPanel.labels.rawDataOption'),
        value: 'rawData'
      }
    ];
  }

  get reportTypeCannotShowInCustom (): string[] {
    return ['rawData'];
  }

  async callDownloadReportAPI (startDate: string, endDate: string, groupBy: string, fields: Fields[]) {
    if (this.reportType === 'rawData') {
      await this.reportManager.downloadOrderReportRawData(this.order.id, startDate, endDate);
    } else {
      const { name, orderNumber } = this.order;
      await this.reportManager.downloadOrderReport({
        name,
        orderNumber
      }, startDate, endDate, groupBy, fields);
    }
  }
}

export class DownloadRtbCampaignGroupReportPanelModel extends DefaultDownloadReportPanelModel {

  constructor (
    private l1Object: {
      name: string,
      l1ObjectId: number
    },
    defaultStartDate: string,
    defaultEndDate: string,
    supportGroupBy: GroupBy[],
    callback: () => void,
    addonFeatureManager: AddonFeatureManager,
    reportManager: ReportManager = new DefaultReportManager()
  ) {
    super(
      defaultStartDate,
      defaultEndDate,
      supportGroupBy,
      callback,
      addonFeatureManager,
      reportManager
    );
  }

  get customReportTypeOptions (): Array<SelectOptions> {
    return [];
  }

  get reportTypeCannotShowInCustom (): string[] {
    return [];
  }

  async callDownloadReportAPI (startDate: string, endDate: string, groupBy: string, fields: Fields[]) {
    await this.reportManager.downloadL1ObjectReport(this.l1Object, startDate, endDate, groupBy, fields);
  }
}
