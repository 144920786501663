const days = [1, 2, 3, 4, 5, 6, 7] as const;
const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] as const;
export type DaypartsDay = typeof days[number];
export type DaypartsHour = typeof hours[number];
export type Dayparts = {
  [Day in DaypartsDay]: DaypartsHour[];
} & { enabled: boolean };

export const isDayOfDaypart = (dayValue: number): dayValue is DaypartsDay => {
  return dayValue >= 1 && dayValue <= 7;
};

export const isHourOfDaypart = (hourValue: number): hourValue is DaypartsHour => {
  return hourValue >= 0 && hourValue <= 23;
};
