import { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import config from 'config';
import { SelectComponentModel, SelectComponentState } from '../SelectComponent';
import { APIProvider } from '@vis.gl/react-google-maps';
import { Device } from './DeviceDataContext';
import { LoadingIndicator } from 'components/common/LoadingIndicator';

// TODO: Recator lazy import component to another file when summary page also use this component
const MapContentComponent = lazy(() => import('./MapContentComponent'));

export type MapContainerProps = {
  model: SelectComponentModel;
  selectedDevices: Device[];
  handleRemoveDevice: (device: Device) => void;
};

export const MapContainer: React.FC<MapContainerProps> = ({
  model,
  selectedDevices,
  handleRemoveDevice
}) => {
  const apiKey = config.googleMapsApiKey;
  const [
    {
      optionsToShow: devicesToShow,
      searchString
    },
    setSelectComponentState
  ] = useState<SelectComponentState>(model.state);

  const cachedSelectComponentModelData = useRef<{
    eventHandler?: number;
    selectComponentModel: SelectComponentModel;
  }>();

  const onMapSearch = useCallback((searchString: string) => {
    model.onSearch(searchString);
  }, [model]);

  const onClickMapDevice = useCallback((selectedDevice: SelectOptions) => {
    model.onItemChange(selectedDevice, selectedDevice);
  }, [model]);

  const onRemoveMapDevice = useCallback((selectedDevice: SelectOptions) => {
    model.onItemRemove(selectedDevice);
    handleRemoveDevice(selectedDevice);
  }, [model, handleRemoveDevice]);

  useEffect(() => {
    const cachedData = cachedSelectComponentModelData.current;
    if (cachedData && cachedData.eventHandler) {
      cachedData.selectComponentModel.event.remove(cachedData.eventHandler);
    }

    const handler = model.event.add((model) => {
      setSelectComponentState(model.state);
    });

    cachedSelectComponentModelData.current = {
      eventHandler: handler,
      selectComponentModel: model
    };

    onMapSearch(searchString);

    return () => {
      model.event.remove(handler);
      cachedSelectComponentModelData.current = undefined;
    };
  }, [model, onMapSearch, searchString]);

  return (
    <APIProvider apiKey={apiKey}>
      <Suspense fallback={<LoadingIndicator />}>
        <MapContentComponent
          onClickMapDevice={onClickMapDevice}
          onRemoveMapDevice={onRemoveMapDevice}
          selectedDevices={selectedDevices}
          devicesToShow={devicesToShow}
          viewOnly={false}
        />
      </Suspense>
    </APIProvider>
  );
};
