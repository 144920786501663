export enum CreateRtbCampaignSetupFlowStep {
  CHOOSE_ADTYPE,
  SETUP_CAMPAIGN,
  SUMMARY
}

export enum CreateRtbCampaignWithCreativeSetupFlowStep {
  CHOOSE_ADTYPE,
  SETUP_CAMPAIGN,
  SETUP_CREATIVE,
  SUMMARY
}

export enum EditRtbCampaignSetupFlowStep {
  SETUP_CAMPAIGN,
  SUMMARY
}

export enum CreatePMPCampaignSetupFlowStep {
  CHOOSE_ADTYPE,
  SETUP_CAMPAIGN,
  SETUP_CREATIVE,
  SUMMARY
}
