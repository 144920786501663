export const resizeImage = (url, width, height) => {
  return new Promise((resolve, reject) => {
    const sourceImage = new Image();

    sourceImage.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const canvasContext = canvas.getContext('2d');
      canvasContext && canvasContext.drawImage(sourceImage, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
    sourceImage.onerror = reject;

    sourceImage.src = url;
  });
};

export const getDataUrlFromFile = (file: File, callback?): Promise<any> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => {
      callback && callback(reader.result);
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};

export const getDataUrlFromUrl = (url: string, callback?): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onload = () => {
          callback && callback(reader.result);
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
  });
};
