import { useCallback } from 'react';
import styles from './productGroupTips.module.scss';
import { defaultTo } from 'lodash';

type ProductGroupTipsProps = {
  productGroup: SelectOptions;
};

export const ProductGroupTips: React.FC<ProductGroupTipsProps> = ({
  productGroup
}) => {

  const renderTips = useCallback(() => {
    return defaultTo(productGroup.extra, []).map((group) => (
      <li key={group.value}>
        <div>{group.label}</div>
        <div>{`ID: ${group.value.toString().replace('\\', '')}`}</div>
      </li>
    ));
  }, [productGroup]);

  return (
    <div className={styles.productGroupTips}>
      <ul>{renderTips()}</ul>
    </div>
  );
};

export const renderProductGroupTips = (productGroup: SelectOptions) => {
  return <ProductGroupTips productGroup={productGroup} />;
};
