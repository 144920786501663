import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { useEffect, useState } from 'react';
import { generateI18nOfSelectOptions } from 'utils/I18nUtils';

const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();

export const useFetchFbPageOptions = (
  advertiserId: string | number,
  flowPageModel: CreativeSetupFlowPageModel,
  advertiserManager: AdvertiserManager = defaultAdvertiserManager
) => {

  const [loading, setLoading] = useState<boolean>(true);
  const [fbPageOptions, setFbPageOptions] = useState<(SelectOptions & {disabled?: boolean})[]>([]);

  const getDefaultFbPageOptions = () => {
    const defaultFbPageOption = fbPageOptions.find(option => !option.disabled);
    return defaultFbPageOption ? defaultFbPageOption.value : undefined;
  };

  useEffect(() => {
    const fetchFbPageOptions = async () => {
      let fbPageOptions = flowPageModel.getCache('fbPageOptions');
      if (fbPageOptions) {
        setFbPageOptions(fbPageOptions);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const fbPages = await advertiserManager.getAdvertiserFbPageList(advertiserId);
        fbPageOptions = fbPages.map(page => ({
          label: page.name,
          value: page.fbPageId,
          disabled: page.status === 0
        }));
        fbPageOptions.forEach(option => generateI18nOfSelectOptions(option, 'fbPages'));
        flowPageModel.addCache('fbPageOptions', fbPageOptions);
        setFbPageOptions(fbPageOptions);
      } catch (e) {}
      setLoading(false);
    };
    fetchFbPageOptions();
  }, [flowPageModel, advertiserId, advertiserManager]);

  return {
    loading,
    defaultFbPage: getDefaultFbPageOptions(),
    fbPageOptions
  };
};
