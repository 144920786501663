import cx from 'classnames/bind';
import LimitationSet from 'components/CampaignLimitation/LimitationSet/LimitationSet';
import { DefaultSelectComponentModel } from 'components/CampaignLimitation/SelectComponent';
import { Page } from 'components/Page/Page';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { ErrorHandler } from 'components/common/form/FormErrorHandler/FormErrorHandler';
import { CustomField, FormikCustomRenderProps } from 'components/common/form/field/CustomField';
import { FormikField } from 'components/common/form/field/FormikField';
import { DefaultInventoryModel, Inventory } from 'containers/Limitations/Inventory';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { RoleNames } from 'core';
import { notRoles } from 'core/permission/PermissionDSL';
import { Form, Formik } from 'formik';
import i18n from 'i18n';
import _ from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { MessageSegmentCountButton } from './MessageSegmentCountButton';
import { MessageSegmentFormModelData } from './MessageSegmentFormModel';
import styles from './messageSegmentForm.module.scss';
import { DEFAULT_INVENTORY } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';

const classNames = cx.bind(styles);

export const MessageSegmentForm: React.FC<{
  advertisers: SelectOptions[],
  useModel: () => MessageSegmentFormModelData
}> = ({
  advertisers,
  useModel
}) => {

  const {
    title,
    loading,
    formType,
    extraData,
    redirectPath,
    breadcrumbsRoutes,
    initMessageSegment,
    save,
    validate,
    setRedirectPath
  } = useModel();

  const mainDomRef = useRef(null);
  const [showInventory, setShowInventory] = useState(formType === 'create');
  const [currentInventory, setCurrentInventory] = useState('');

  const setUpAudienceBrief = useCallback((formikProps, limitationTypeObject) => {
    extraData.setLimitationTypeObject(extraData.limitationTypeObject);
    const values: any[] = Object.values(limitationTypeObject);
    let finalLogicValue;
    if (values.length === 0) {
      finalLogicValue = '';
    } else {
      let result = values.map(
        limitation => `${limitation.value.map(value => value.type).join(' OR ')}`
      ).join(' ) AND ( ');
      finalLogicValue = values.length > 1 ? `( ${result} )` : result;
    }
    formikProps.setFieldValue('audienceBrief', finalLogicValue);
  }, [extraData]);

  const defaultInventoryModel = useMemo(() => {
    const inventorySetting = _.find(extraData.inventorySettings, setting => setting.name === currentInventory);
    return new DefaultInventoryModel(
      inventorySetting ? currentInventory : DEFAULT_INVENTORY,
      extraData.inventorySettings.map(setting => ({
        name: setting.name,
        title: setting.title,
        cb: setting.cb
      })),
      {},
      'messageSegmentForm.inventory.titile'
    );
  }, [currentInventory, extraData.inventorySettings]);

  const addLimitationOfInventory = useCallback((inventory) => {
    setShowInventory(true);
    setCurrentInventory(inventory);
  }, []);

  const onEditBtnClick = useCallback(() => {
    setShowInventory(prev => !prev);
  }, []);

  const renderCountArea = (formikProps: FormikCustomRenderProps) => {
    const error = _.get(formikProps.errors, 'audienceBrief');
    const audienceBrief = _.get(formikProps, 'values.audienceBrief', '');
    return !error ? (
      <MessageSegmentCountButton
        audienceSummary={audienceBrief}
      />
    ) : '';
  };

  const advertiserFormatter = useCallback((value) => {
    const advertiser = advertisers.find(option => option.value.toString() === value.toString());
    return advertiser ? advertiser.label : '';
  }, [advertisers]);

  const onLimitationChange = useCallback((formikProps, name: string, options: SelectOptions[]) => {
    extraData.limitationTypeObject[name] = { type: name, value: options.map(option => ({
      label: option.label,
      value: option.value,
      // @ts-expect-error
      type: option.type
    })) };
    if (options.length === 0) {
      delete extraData.limitationTypeObject[name];
    }
    setUpAudienceBrief(formikProps, extraData.limitationTypeObject);
  }, [extraData.limitationTypeObject, setUpAudienceBrief]);

  const renderInventoryContent = useCallback((formikProps) => {
    return extraData.inventorySettings.map(inventorySetting => {
      const InventoryContent: typeof React.Component =
        inventorySetting.component;
      const {
        name,
        singleSelect,
        itemSetting,
        searchPlaceholder
      } = inventorySetting;
      const value = _.get(extraData.limitationTypeObject[name], 'value', []);
      const model = new DefaultSelectComponentModel(
        name,
        singleSelect,
        itemSetting,
        i18n.t<string>(searchPlaceholder),
        (options) => onLimitationChange(formikProps, name, options),
        [],
        value,
        undefined,
        undefined,
        'messageSegmentForm.inventory.contentPlaceholder',
        'messageSegmentForm.inventory.segmentCount'
      );
      return <InventoryContent key={name} model={model} />;
    });
  }, [extraData.inventorySettings, extraData.limitationTypeObject, onLimitationChange]);

  const renderFinalLogicValue = useCallback((formikProps) => {
    const audienceBrief = _.get(formikProps, 'values.audienceBrief', '');
    if (audienceBrief === '') {
      return <div/>;
    }
    return (
      <>
        {`${i18n.t<string>('messageSegmentForm.hints.selectHint')} : `}
        <span>{audienceBrief}</span>
      </>
    );
  }, []);

  const onDeleteLimitationSet = useCallback((formikProps, _1, type) => {
    delete extraData.limitationTypeObject[type];
    setUpAudienceBrief(formikProps, extraData.limitationTypeObject);
  }, [extraData.limitationTypeObject, setUpAudienceBrief]);

  const renderSelectedLimitation = useCallback((formikProps) => {
    const ignoreDefault = ({ name }) => name !== DEFAULT_INVENTORY;
    return extraData.inventorySettings.slice().filter(ignoreDefault).map(({ name, title }) => {
      const value: SelectOptions[] = _.get(extraData.limitationTypeObject[name], 'value', []);
      const onChange = options => {
        onLimitationChange(formikProps, name, options);
      };
      const onDelete = (_1, type) => onDeleteLimitationSet(formikProps, _1, type);
      return (
        <LimitationSet
          key={title ? title + value : value}
          value={value}
          title={title}
          onChange={onChange}
          deleteLimitation={onDelete}
          add={_.partial(addLimitationOfInventory, name)}
          type={name}
          disable={formType !== 'create'}
        />
      );
    });
  }, [extraData.limitationTypeObject, extraData.inventorySettings, formType, onLimitationChange, onDeleteLimitationSet, addLimitationOfInventory]);

  const renderSegmentLimitation = useCallback((formikProps) => {
    const error = _.get(formikProps.errors, 'audienceBrief');
    const formikTouched = _.get(formikProps.touched, 'audienceBrief');
    const touched = _.defaultTo(formikProps.submitCount, 0) > 0 || formikTouched;
    const hasError = error !== undefined && touched;
    const inventoryClassName = classNames('inventory', {
      active: showInventory,
      error: hasError
    });
    const selectedLimitationAreaClass = classNames('selectedLimitationArea', {
      short: showInventory
    });
    return (
      <>
        {formType === 'create' &&
          <div
            className={styles.editTitle}
            onClick={onEditBtnClick}
          >
            <span>
              {showInventory
                ? i18n.t<string>('limitation.labels.finishEditing')
                : i18n.t<string>('limitation.labels.edit')}
            </span>
          </div>
        }
        <div className={styles.segmentLimitation}>
          <div className={inventoryClassName}>
            <Inventory model={defaultInventoryModel} width={424}>
              {renderInventoryContent(formikProps)}
            </Inventory>
            {hasError && <span className='errorTip'>{error}</span>}
          </div>
          <div className={selectedLimitationAreaClass}>
            <div className={styles.selectedLimitation}>{renderSelectedLimitation(formikProps)}</div>
            <span className={styles.finalLogicValue}>{renderFinalLogicValue(formikProps)}</span>
          </div>
        </div>
      </>
    );
  }, [defaultInventoryModel, formType, showInventory, onEditBtnClick, renderFinalLogicValue, renderInventoryContent, renderSelectedLimitation]);

  if (extraData.inventorySettings.length === 0) {
    return <LoadingIndicator/>;
  }

  if (!initMessageSegment) {
    return <LoadingIndicator/>;
  }

  return (
    <Page
      title={title}
      minWidth={700}
      breadcrumbsRoutes={breadcrumbsRoutes}
    >
      <div className={styles.segmentFormContainer} ref={mainDomRef}>
        <NavigationPrompt when={!redirectPath}/>
        {loading && <LoadingIndicator />}
        {redirectPath && <Redirect to={redirectPath} />}
        <Formik
          initialValues={initMessageSegment}
          enableReinitialize
          onSubmit={save}
          validate={validate}
          validateOnBlur={false}
        >
          {(formikProps) => {
            const renderSegmentLimitationFunc = _.partial(renderSegmentLimitation, formikProps);
            return (
              <Form>
                <ErrorHandler
                  parentRef={mainDomRef}
                  isSubmitting={formikProps.isSubmitting}
                  submitCount={formikProps.submitCount}
                />
                <div className={styles.formContent}>
                  <div>
                    <PermissionChecker
                      permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport)}
                    >
                      {
                        formType === 'edit' ?
                        <FormikField.Label
                          label={i18n.t<string>('messageSegmentForm.fields.advertiser')}
                          name='advertiserId'
                          formatter={advertiserFormatter}
                        /> :
                        <FormikField.Select
                          label={i18n.t<string>('messageSegmentForm.fields.advertiser')}
                          name='advertiserId'
                          options={advertisers}
                          simpleValue
                        />
                      }
                    </PermissionChecker>
                    <FormikField.Input
                      label={i18n.t<string>('messageSegmentForm.fields.name')}
                      name='name'
                    />
                    <FormikField.TextArea
                      label={i18n.t<string>('messageSegmentForm.fields.description')}
                      name='description'
                    />
                    <CustomField
                      label={i18n.t<string>('messageSegmentForm.fields.select')}
                      name='audienceBrief'
                      render={renderSegmentLimitationFunc}
                      fieldContentWidth='auto'
                    />
                    <FormikField.Custom
                      label=''
                      render={renderCountArea}
                    />
                  </div>
                </div>
                <div className={styles.formButtons}>
                  <button
                    type='submit'
                    className='btn btn-primary btn-sm'
                  >
                    {i18n.t<string>('common.buttons.submit')}
                  </button>
                  <button
                    onClick={_.partial(setRedirectPath, '/message-segments')}
                    className='btn btn-secondary btn-sm'
                  >
                    {i18n.t<string>('common.buttons.cancel')}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Page>
  );
};
