import { AxiosInstance } from 'axios';
import { FinalReportResponseStatus } from 'core/finalReport/FinalReportStatus';
import { Order } from 'core/order/Order';
import { ReportCompareData, ReportCompareDimension, ReportData, ReportDimension, ReportGran, ReportType } from 'core/report/ReportData';
import fileDownload from 'js-file-download';
import _ from 'lodash';
import moment from 'moment';
import client from './RestClient';
import i18n from 'i18n';
import { L1ObjectChannel } from 'core/l1Object/L1Object';

export const defaultFormat = 'YYYY-MM-DD';

export interface ReportWebService {
  downloadOrderReport (order: {
    name: string,
    orderNumber: string
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void>;
  downloadL1ObjectReport (order: {
    name: string,
    l1ObjectId: number
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void>;
  downloadVideoReport (order: Order, startDate: string, endDate: string): Promise<void>;
  downloadOutdoorReport (order: Order, startDate: string, endDate: string): Promise<void>;
  downloadClosingReport (order: Order, reportType?: string): Promise<void>;
  downloadRebateReport (date: string, agencyId?: string | number): Promise<void>;
  getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string, useAppsflyerConversion?: boolean): Promise<ReportData>;
  getReportCompareData (compareDimension: ReportCompareDimension, filter: any, from: string, to: string): Promise<ReportCompareData>;
  uploadFinalReportData (orderId: string | number): Promise<FinalReportResponseStatus>;
  getFinalReportData (reportId: string | number): Promise<FinalReportResponseStatus>;
  downloadOrderReportRawData (orderId: string | number, startDate: string, endDate: string): Promise<void>;
  getFilterOptions (
    type: 'VIDEO' | 'PERFORMANCE',
    gran: 'daily' | 'hour',
    dimension: string,
    currentFilters: {
      [key: string]: string | number
    }
  ): Promise<SelectOptions[]>;
  downloadSalesChannelReconciliationReport (salesChannel: L1ObjectChannel, from: string, to: string): Promise<void>;
}

export class RestfulReportWebService implements ReportWebService {
  restClient: AxiosInstance;
  defualtFileDownload: any;

  constructor (restClient: AxiosInstance = client, defualtFileDownload: any = fileDownload) {
    this.restClient = restClient;
    this.defualtFileDownload = defualtFileDownload;
  }

  async downloadOrderReport (order: {
    name: string,
    orderNumber: string
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void> {
    const url = `/v2/report/export/order?orderNum=${order.orderNumber}&groupBy=${groupBy}&metrics=${selectFields.join(',')}&from=${startDate}&to=${endDate}`;
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${order.name}.xlsx`);
  }

  async downloadL1ObjectReport (l1Object: {
    name: string,
    l1ObjectId: number
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void> {
    const url = `/v2/report/export/l1-object?l1ObjectId=${l1Object.l1ObjectId}&groupBy=${groupBy}&metrics=${selectFields.join(',')}&from=${startDate}&to=${endDate}`;
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${l1Object.name}.xlsx`);
  }

  async downloadVideoReport (order: Order, startDate: string, endDate: string): Promise<void> {
    const response = await this.restClient.get(`report/video/export?orderNum=${order.orderNumber}&from=${startDate}&to=${endDate}`, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${order.projectName}.xlsx`);
  }

  async downloadOutdoorReport (order: Order, startDate: string, endDate: string): Promise<void> {
    const response = await this.restClient.get(`report/outdoor/export?orderNum=${order.orderNumber}&from=${startDate}&to=${endDate}`, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${order.projectName}.xlsx`);
  }

  async downloadClosingReport (order: Order, reportType?: string): Promise<void> {
    let url = `report/export/order/closing_report?orderNum=${order.orderNumber}`;
    if (reportType) {
      url = url + `&reportType=${reportType}`;
    }
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, `${order.projectName}.xlsx`);
  }

  async getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string, useAppsflyerConversion?: boolean) {
    const filterQueryParams = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');
    const reportType = type === ReportType.PERFORMANCE ? 'channel-performance' : type;
    let url = `report/${reportType}?dimension=${dimension}&gran=${gran}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&${filterQueryParams}`;
    if (!_.isNil(useAppsflyerConversion)) {
      url = `${url}&useAppsflyerConversion=${useAppsflyerConversion}`;
    }
    const response = await this.restClient.get(url);
    // message use fake data currently
    if (type === ReportType.MESSAGE) {
      return wrapSMSReportData(response.data);
    }
    const reportData = wrapReportData(response.data);
    if (dimension === ReportDimension.SALES_CHANNEL) {
      reportData.records.forEach(data => {
        data.dimensionName = i18n.t<string>(`l1Object.labels.channel_${data.dimensionName.toLowerCase()}`);
      });
    }
    return reportData;
  }

  async getReportCompareData (compareDimension: ReportCompareDimension, filter: any, from: string, to: string): Promise<ReportCompareData> {
    const filterQueryParams = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');
    const url = `report/compare-report?dimension=${ReportDimension.DAY}&gran=${ReportGran.DAY}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&compareDimension=${compareDimension}&${filterQueryParams}`;
    const response = await this.restClient.get(url);
    return wrapReportCompareData(response.data);
  }

  async downloadRebateReport (date: string, agencyId?: string | number): Promise<void> {
    const selectedDate = moment(date);
    let url = `report/export/month/reconciliation?month=${selectedDate.month() + 1}&year=${selectedDate.year()}`;
    if (agencyId) {
      url += `&agencyId=${agencyId}`;
    }
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, 'MonthReport.xlsx');
  }

  async uploadFinalReportData (orderId: string | number): Promise<FinalReportResponseStatus> {
    const response = await this.restClient.post('/v2/report/final-report', { orderId });
    return response.data;
  }

  async getFinalReportData (reportId: string | number): Promise<FinalReportResponseStatus> {
    const response = await this.restClient.get(`/v2/report/final-report?id=${reportId}`);
    return response.data;
  }

  async downloadOrderReportRawData (orderId: string | number, startDate: string, endDate: string): Promise<void> {
    let url = `/v2/report/export/order-report-raw-data?order_id=${orderId}&from=${startDate}&to=${endDate}`;
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, 'OrderReportRawData.csv');
  }

  async getFilterOptions (
    type: 'VIDEO' | 'PERFORMANCE',
    gran: 'daily' | 'hour',
    dimension: string,
    currentFilters: {
      [key: string]: string | number
    }
  ): Promise<SelectOptions[]> {
    const filterQueryParams = Object.keys(currentFilters).map(key => `${key}=${encodeURIComponent(currentFilters[key])}`).join('&');
    const response = await this.restClient.get(`/v2/report/options?reportType=${type}&gran=${gran}&dimension=${dimension}&${filterQueryParams}`);
    const records = response.data.records;
    if (dimension === ReportDimension.SALES_CHANNEL) {
      return records.map(record => ({
        ...record,
        label: i18n.t<string>(`l1Object.labels.channel_${record.label.toLowerCase()}`)
      }));
    }
    return records;
  }

  async downloadSalesChannelReconciliationReport (salesChannel: L1ObjectChannel, startDate: string, endDate: string): Promise<void> {
    let url = `/v2/report/export/sales-channel-reconciliation?channel=${salesChannel}&from=${startDate}&to=${endDate}`;
    const response = await this.restClient.get(url, { responseType: 'blob' });
    this.defualtFileDownload(response.data, 'sales_channel_reconciliation.xlsx');
  }
}

const wrapReportData = (json: any) => {
  return {
    allowMetrics: _.get(json, 'allowMetrics', []),
    dimension: _.get(json, 'dimension'),
    filter: _.get(json, 'filter', {}),
    from: _.get(json, 'from'),
    to: _.get(json, 'to'),
    records: _.get(json, 'records', []).map(record => wrapRecord(record)),
    currency: _.get(json, 'currency')
  };
};

const wrapSMSReportData = (json: any) => {

  const wrapSMSRecord = (record: any) => {
    return {
      dimensionID: _.get(record, 'dimensionID'),
      dimensionName: _.get(record, 'dimensionName'),
      agcProfit: _.get(record, 'agcProfit'),
      messageSend: _.get(record, 'impres'),
      messageArrived: _.get(record, 'viewable'),
      clicks: _.get(record, 'clicks'),
      spent: _.get(record, 'spent'),
      incentive: _.get(record, 'incentive'),
      mediaCost: _.get(record, 'mediaCost'),
      mediaSpent: _.get(record, 'mediaSpent'),
      skipCount: _.get(record, 'skipCount'),
      sysProfit: _.get(record, 'sysProfit'),
      tags: _.compact(_.get(record, 'tags', '').split(','))
    };
  };

  const allowMetrics = _.get(json, 'allowMetrics', []);
  if (allowMetrics.includes('impres')) {
    allowMetrics[allowMetrics.indexOf('impres')] = 'messageSend';
  }
  if (allowMetrics.includes('viewable')) {
    allowMetrics[allowMetrics.indexOf('viewable')] = 'messageArrived';
  }
  return {
    allowMetrics,
    dimension: _.get(json, 'dimension'),
    filter: _.get(json, 'filter', {}),
    from: _.get(json, 'from'),
    to: _.get(json, 'to'),
    records: _.get(json, 'records', []).map(record => wrapSMSRecord(record)),
    currency: _.get(json, 'currency')
  };
};

const wrapReportCompareData = (json: any) => {
  return {
    allowMetrics: _.get(json, 'allowMetrics', []),
    dimension: _.get(json, 'dimension'),
    filter: _.get(json, 'filter', {}),
    from: _.get(json, 'from'),
    to: _.get(json, 'to'),
    records: _.get(json, 'records', []).map(record => wrapCompareRecord(record)),
    currency: _.get(json, 'currency')
  };
};

const wrapRecord = (json: any) => {
  return {
    ...json,
    dimensionID: _.get(json, 'dimensionID'),
    dimensionName: _.get(json, 'dimensionName'),
    agcProfit: _.get(json, 'agcProfit'),
    clicks: _.get(json, 'clicks'),
    complete: _.get(json, 'complete'),
    conv_1: _.get(json, 'conv_1'),
    conv_7: _.get(json, 'conv_7'),
    convs: _.get(json, 'convs'),
    dataCost: _.get(json, 'dataCost'),
    firstQuartile: _.get(json, 'firstQuartile'),
    midpoint: _.get(json, 'midpoint'),
    thirdQuartile: _.get(json, 'thirdQuartile'),
    impres: _.get(json, 'impres'),
    incentive: _.get(json, 'incentive'),
    mediaCost: _.get(json, 'mediaCost'),
    mediaSpent: _.get(json, 'mediaSpent'),
    skipCount: _.get(json, 'skipCount'),
    spent: _.get(json, 'spent'),
    sysProfit: _.get(json, 'sysProfit'),
    underBidFloor: _.get(json, 'underBidFloor'),
    view: _.get(json, 'view'),
    viewable: _.get(json, 'viewable'),
    winTotal: _.get(json, 'winTotal'),
    tags: _.compact(_.get(json, 'tags', '').split(',')),
    parentId: _.get(json, 'parentId')
  };
};

const wrapCompareRecord = (json: any) => {
  const campareID = _.get(json, 'compareID');
  const compareName = _.get(json, 'compareName');
  return {
    ...wrapRecord(json),
    compareID: campareID ? campareID : compareName,
    compareName
  };
};
