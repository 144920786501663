import {
  RtbCampaignWebService,
  RestfulCampaignWebService
} from 'ws/RtbCampaignWebService';
import {
  RtbCampaign,
  VideoAdViewObjective,
  RtbCampaignBasic,
  RtbOptimize,
  RtbCampaignPlanType,
  RtbLegacyOptimize,
  AdType,
  VideoProgressTrackingCode,
  RtbCampaignListBasic,
  RTBCAMPAIGN_DEFAULT_AGE_MIN,
  RTBCAMPAIGN_DEFAULT_AGE_MAX,
  SpaceDevice
} from './RtbCampaign';
import _ from 'lodash';
import LocalDateTimeUtil from 'utils/LocalDateTimeUtil';
import moment from 'moment';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import i18n from 'i18n';
import { generateI18nOfSelectOptions } from 'utils/I18nUtils';
import { getLimitationContentLabel, toServerStructure, transformLimitationData } from 'utils/LimitationUtil';
import { ageMaxOptions, ageMinOptions, getPmax2RtbAgeGroupsByAgeRange, getPmax3RtbAgeGroupsByAgeRange } from 'core/limitation/l2ObjectTAOptions';
import { Limitation, LimitationData } from 'core/limitation/Limitation';
import { LegacyEstimateData } from './LegacyEstimateData';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { BidPriceFloor, OrderType } from 'core/order/Order';
import { Dayparts } from 'core/dayparts/Dayparts';

export type LegacyEstimateViewModelData = LegacyEstimateData & { involvedLimitatinoTypes: string[] };
export interface RtbCampaignManager {
  getCampaignsOfGroup (l1ObjectId: number | string): Promise<RtbCampaignListBasic[]>;
  getCampaign (campaignId): Promise<RtbCampaign>;
  getNoCidCampaign (campaignId): Promise<RtbCampaign>;
  createCampaign (campaign: RtbCampaign, l1ObjectId: number | string): Promise<void>;
  createPmpCampaign (campaign: RtbCampaign, l1ObjectId: number | string, pmpId: number, creative: Object): Promise<void>;
  createCampaignWithCreative (campaign: RtbCampaign, l1ObjectId: number | string, creative: Object): Promise<void>;
  updateCampaign (campaign: RtbCampaign, l1ObjectId: number | string): Promise<void>;
  splitCampaign (campaign: RtbCampaign, origCamaignId, l1ObjectId: number | string): Promise<void>;
  updateCampaignState (campaignData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  deleteCampaigns (campaignIds: Array<number>): Promise<void>;
  getCampaignOptions (from?: string, to?: string): Promise<Array<SelectOptions>>;
  getOrderNumber (campaignId: string | number): Promise<string>;
  getLegacyEstimateData (campaign: RtbCampaignBasic, limitations: LimitationData, campaignBidPrice: {
    type: string,
    autoBidCap: BidPriceFloor,
    bidFloor: BidPriceFloor
  }[]): Promise<LegacyEstimateViewModelData | undefined>;
  updateCampaignBidWeight (campaignId: string | number, bidWeight: number): Promise<void>;
  getMinBudgetOfCampaign (campaign: RtbCampaignBasic, budgetMinimumPerDay: number): number;
  getRetailOptions (): Promise<SelectOptions[]>;
  getRtbCampaignsUsingDailyBudget (l1ObjectId: number | string): Promise<RtbCampaignListBasic[]>;
  prepareCreateCampaignPayload (campaign: RtbCampaign);
  getLegacyOptimizeValue (pmax3OptimizationGoal: L2ObjectOptimizationGoal, priceModel: RtbCampaignPlanType): RtbLegacyOptimize;
  showFrequencyControl (objective: L1ObjectObjective, adType: AdType, optimize: L2ObjectOptimizationGoal): boolean;
  getAdTypeOptions (): Promise<SelectOptions[]>;
  getOutdoorSpacesDetail (spaceIds: string[]): Promise<SpaceDevice[]>;
  getLimitationSummaryData (limitation: LimitationData, orderType: OrderType, isRetailMediaCampaign: boolean, agencySegmentName: string, hiddenLimitations?: string[]): any;
  checkOptimizeSameAsPriceModel (priceModel: RtbCampaignPlanType, optimize: L2ObjectOptimizationGoal): boolean;
  isOutdoorAdType (adType: AdType): boolean;
}

export class DefaultRtbCampaignManager implements RtbCampaignManager {
  webService: RtbCampaignWebService;

  constructor (
    webService: RtbCampaignWebService = new RestfulCampaignWebService()
  ) {
    this.webService = webService;
  }

  async getCampaign (campaignId: number): Promise<RtbCampaign> {
    return this.webService.getCampaign(campaignId);
  }

  async getNoCidCampaign (campaignId: number): Promise<RtbCampaign> {
    const campaign = await this.getCampaign(campaignId);
    _.unset(campaign, 'basic.id');
    _.set(campaign, 'basic.name', campaign.basic.name + '_' + LocalDateTimeUtil.getYMDHMS());
    return campaign;
  }

  async getCampaignsOfGroup (l1ObjectId: number | string): Promise<RtbCampaignListBasic[]> {
    return this.webService.getCampaignsOfGroup(l1ObjectId);
  }

  async createCampaign (campaign: RtbCampaign, l1ObjectId: number | string): Promise<void> {
    const payload = this.prepareCreateCampaignPayload(campaign);
    _.set(payload, 'campaign.l1ObjectId', l1ObjectId);
    await this.webService.createCampaign(payload, l1ObjectId);
  }

  async createPmpCampaign (campaign: RtbCampaign, l1ObjectId: number | string, pmpId: number, creative: Object): Promise<void> {
    const campaignPayload = this.prepareCreateCampaignPayload(campaign);
    _.set(campaignPayload, 'campaign.l1ObjectId', l1ObjectId);

    const dealIdTA = _.defaultTo(campaign.limitations.other, []).find(limitation => limitation.type === 'dealId');
    const spaceTA = campaign.limitations.include.find(limitation => limitation.type === 'adspace');
    const payload = {
      ...campaignPayload,
      campaign: _.omit({
        ...campaignPayload.campaign,
        dealIds: dealIdTA ? (dealIdTA.value as SelectOptions[]).map(limit => limit.value) : [],
        pmpId,
        ageMin: RTBCAMPAIGN_DEFAULT_AGE_MIN,
        ageMax: RTBCAMPAIGN_DEFAULT_AGE_MAX
      }),
      limitations: spaceTA
        ? transformLimitationData(false, 'inc', spaceTA)
        : [],
      creatives: [creative]
    };
    await this.webService.createCampaignWithCreative(payload, l1ObjectId);
  }

  async createCampaignWithCreative (campaign: RtbCampaign, l1ObjectId: number | string, creative: Object): Promise<void> {
    const payload = this.prepareCreateCampaignPayload(campaign);
    _.set(payload, 'campaign.l1ObjectId', l1ObjectId);
    payload.creatives = [creative];
    await this.webService.createCampaignWithCreative(payload, l1ObjectId);
  }

  async updateCampaign (campaign: RtbCampaign, l1ObjectId: number | string): Promise<void> {
    const payload = this.wrapCampaignForServer(_.cloneDeep(campaign));
    _.set(payload, 'campaign.l1ObjectId', l1ObjectId);
    await this.webService.updateCampaign(payload, l1ObjectId);
  }

  async splitCampaign (campaign: RtbCampaign, origCamaignId, l1ObjectId: number | string): Promise<void> {
    const payload = this.prepareCreateCampaignPayload(campaign);
    _.set(payload, 'campaign.l1ObjectId', l1ObjectId);
    await this.webService.splitCampaign(payload, origCamaignId, l1ObjectId);
  }

  async updateCampaignState (campaignData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.webService.updateCampaignState(campaignData, state);
  }

  async deleteCampaigns (campaignIds: Array<number>): Promise<void> {
    return this.webService.deleteCampaigns(campaignIds);
  }

  async getCampaignOptions (from?: string, to?: string): Promise<Array<SelectOptions>> {
    return this.webService.getCampaignOptions(from, to);
  }

  async getOrderNumber (campaignId: string | number): Promise<string> {
    return this.webService.getOrderNumber(campaignId);
  }

  isArray = (value: (SelectOptions & { isGroup?: boolean })[] | string | number): value is (SelectOptions & { isGroup?: boolean })[] => {
    return Array.isArray(value);
  }

  async getLegacyEstimateData (campaign: RtbCampaignBasic, limitations: LimitationData, campaignBidPrice: {
    type: string,
    autoBidCap: BidPriceFloor,
    bidFloor: BidPriceFloor
  }[]): Promise<LegacyEstimateViewModelData | undefined> {
    const optimize: L2ObjectOptimizationGoal = campaign.optimize;
    const priceModel: RtbCampaignPlanType = campaign.priceModel;
    const legacyOptimize: RtbLegacyOptimize = this.getLegacyOptimizeValue(optimize, priceModel);
    let bidPrice = campaign.bidPrice;
    const systemDefaultBidPrice = campaignBidPrice.find(setting => setting.type === campaign.adType);
    if (!bidPrice && systemDefaultBidPrice) {
      bidPrice = systemDefaultBidPrice.autoBidCap[_.camelCase(legacyOptimize)];
    }
    bidPrice = _.defaultTo(bidPrice, 0);
    const orderPrice = campaign.orderPrice;
    const includeLimitations = _.defaultTo(limitations.include, []);
    const excludeLimitations = _.defaultTo(limitations.exclude, []);
    const otherLimitations = _.defaultTo(limitations.other, []);
    const [instlLimitation] = _.remove(otherLimitations, (limitation: Limitation) => limitation.type === 'instl');
    const involvedLimitatinoTypes: string[] =
      _.uniq((includeLimitations
        .concat(excludeLimitations)
        .concat(otherLimitations))
        .filter(limitation => this.isArray(limitation.value) && _.defaultTo(limitation.value, []).length > 0)
        .flatMap(limitation => limitation.type)
      );

    !instlLimitation && involvedLimitatinoTypes.push('instl');
    const isLimitationSettingInvalid =
      involvedLimitatinoTypes.reduce((acc: boolean, type: string) => {
        const hasInvalidLimitaiton = ['segment', 'tenmaxAudience', 'unicornlanguage'].includes(type);
        return acc || hasInvalidLimitaiton;
      }, false);

    if (
      // campaign.basic.priceModel === RtbCampaignPlanType.RB ||
      isLimitationSettingInvalid
    ) {
      return;
    }

    const includeLimitation = _.defaultTo(limitations.include, []);
    const preferredLimitation = _.defaultTo(limitations.preferred, []);
    const includeAgeMin = includeLimitation.find(limitation => limitation.type === 'age_min');
    const ageMinLimitation = includeAgeMin || preferredLimitation.find(limitation => limitation.type === 'age_min');
    const ageMin = _.get(ageMinLimitation, 'value');
    const includeAgeMax = includeLimitation.find(limitation => limitation.type === 'age_max');
    const ageMaxLimitation = includeAgeMax || preferredLimitation.find(limitation => limitation.type === 'age_max');
    const ageMax = _.get(ageMaxLimitation, 'value');

    const ageOptValue = includeAgeMin ? 'include' : 'preferred';

    const limitationUseAliasName = [AdType.PIC_LONG, AdType.PIC_SHORT].includes(campaign.adType);
    const limitation = ageOptValue === 'include' ? toServerStructure(limitations, limitationUseAliasName) : toServerStructure({
      ...limitations,
      preferred: [
        ..._.filter(limitations.preferred, (limitation: Limitation) => !['age_min', 'age_max'].includes(limitation.type)),
        {
          op: 'Preferred',
          type: 'age',
          value: getPmax2RtbAgeGroupsByAgeRange(ageMin, ageMax)
        }
      ]
    }, limitationUseAliasName);
    const dealIdLimitation = _.defaultTo(otherLimitations.find(limitation => limitation.type === 'dealId'), { value: [] });
    if (this.isArray(dealIdLimitation.value) && dealIdLimitation.value.length > 0) {
      limitation.push({
        op: 'inc',
        limits: [...dealIdLimitation.value],
        isGroup: false,
        type: 'dealIds'
      });
    }
    const autoBidCap = _.get(campaignBidPrice.find(bidPriceData => bidPriceData.type === campaign.adType), `autoBidCap`, {});
    const autoBidCapPrice = _.defaultTo(autoBidCap[legacyOptimize.toLowerCase()], 100);
    const dayPart: Omit<Dayparts, 'enabled'> | undefined = _.isEmpty(campaign.dayPart)
      ? undefined
      : _.omit(campaign.dayPart, ['enabled']);

    const apiPayload = {
      dayPart,
      orderId: campaign.orderId,
      campaignId: campaign.id,
      bidPrice: bidPrice
       ? parseFloat(bidPrice.toString()) : orderPrice
       ? Math.min(parseFloat(orderPrice.toString()), autoBidCapPrice) : parseFloat(_.defaultTo(orderPrice, 0).toString()),
      optimize: legacyOptimize,
      limitation
    };
    const legacyEstimateData = await this.webService.getLegacyEstimateData(apiPayload);
    return { ...legacyEstimateData, involvedLimitatinoTypes };
  }

  async updateCampaignBidWeight (campaignId: string | number, bidWeight: number): Promise<void> {
    return this.webService.updateCampaignBidWeight(campaignId, bidWeight);
  }

  async getRetailOptions (): Promise<SelectOptions[]> {
    const retailOptions = await this.webService.getRetailOptions();
    return retailOptions.map(
      option => {
        const result = {
          value: option.value,
          label: option.label
        };
        generateI18nOfSelectOptions(({
          label: result.label,
          value: result.value
        }), 'retailers');
        return result;
      }
    );
  }

  async getRtbCampaignsUsingDailyBudget (l1ObjectId: string | number): Promise<RtbCampaignListBasic[]> {
    return this.webService.getRtbCampaignsUsingDailyBudget(l1ObjectId);
  }

  async getAdTypeOptions (): Promise<SelectOptions[]> {
    return this.webService.getAdTypeOptions();
  }

  async getOutdoorSpacesDetail (spaceIds: string[]): Promise<SpaceDevice[]> {
    return this.webService.getOutdoorSpacesDetail(spaceIds);
  }

  convertVideoAdEventToEvent (campaign: RtbCampaign) {
    const videoAdEvent = _.get(campaign, 'basic.videoAdViewObjective.videoAdEvent');
    const offset = _.get(campaign, 'basic.videoAdViewObjective.offset');

    const videoAdMetricEvent = _.get(campaign, 'basic.videoAdViewObjective.videoAdMetricEvent');
    _.unset(campaign, 'basic.videoAdViewObjective');
    switch (videoAdEvent) {
      case VideoAdViewObjective.METRIC:
        return _.set(campaign, 'basic.videoAdViewObjective.event', videoAdMetricEvent);
      case VideoAdViewObjective.PROGRESS:
        _.set(campaign, 'basic.videoAdViewObjective.offset', offset);
        return _.set(campaign, 'basic.videoAdViewObjective.event', videoAdEvent);
      default:
        return campaign;
    }
  }

  getMinBudgetOfCampaign (campaign: {
    startDate: string;
    endDate: string;
  }, budgetMinimumPerDay: number) {
    const startDate = moment(campaign.startDate);
    const endDate = moment(campaign.endDate);
    const scheduleDateCount = endDate.diff(startDate, 'days') + 1;
    return budgetMinimumPerDay * scheduleDateCount;
  }

  getNativeOptimizeValue (pmax3OptimizationGoal: L2ObjectOptimizationGoal) {
    const nativeOptimizeMap = {
      [L2ObjectOptimizationGoal.REACH]: RtbOptimize.REACH,
      [L2ObjectOptimizationGoal.VIDEO_VIEWS]: RtbOptimize.VIDEO_VIEWS,
      [L2ObjectOptimizationGoal.IMPRESSIONS]: RtbOptimize.IMPRESSIONS,
      [L2ObjectOptimizationGoal.CLICKS]: RtbOptimize.CLICKS,
      [L2ObjectOptimizationGoal.SALES]: RtbOptimize.SALES
    };
    return nativeOptimizeMap[pmax3OptimizationGoal];
  }

  getLegacyOptimizeValue (pmax3OptimizationGoal: L2ObjectOptimizationGoal, priceModel: RtbCampaignPlanType) {
    const legacyOptimizeMap = {
      [L2ObjectOptimizationGoal.REACH]: RtbLegacyOptimize.CPM,
      [L2ObjectOptimizationGoal.VIDEO_VIEWS]: (priceModel === RtbCampaignPlanType.FVCPM)
       ? RtbLegacyOptimize.VCPM
       : RtbLegacyOptimize.CPV,
      [L2ObjectOptimizationGoal.IMPRESSIONS]: RtbLegacyOptimize.CPM,
      [L2ObjectOptimizationGoal.CLICKS]: RtbLegacyOptimize.CPC,
      [L2ObjectOptimizationGoal.SALES]: RtbLegacyOptimize.CPC
    };
    return legacyOptimizeMap[pmax3OptimizationGoal];
  }

  wrapCampaignForServer = (campaign: RtbCampaign) => {
    let videoProgressTrackingCode: (VideoProgressTrackingCode & { event: 'progress'})[] | undefined = undefined;
    if (campaign.basic.videoProgressTrackingCode &&
      campaign.basic.videoProgressTrackingCode.code &&
      campaign.basic.videoProgressTrackingCode.offset
    ) {
      videoProgressTrackingCode = [{
        ...campaign.basic.videoProgressTrackingCode,
        event: 'progress'
      }];
    }
    const ageOptValue = campaign.limitations.include && campaign.limitations.include.find(limitation => limitation.type === 'age_min') ? 'include' : 'preferred';
    const ageMinLimitation = ageOptValue === 'include'
     ? campaign.limitations.include.find(limitation => limitation.type === 'age_min') : campaign.limitations.preferred
     ? campaign.limitations.preferred.find(limitation => limitation.type === 'age_min') : undefined;
    const ageMin = ageMinLimitation ? ageMinLimitation.value : undefined;
    const ageMaxLimitation = ageOptValue === 'include'
     ? campaign.limitations.include.find(limitation => limitation.type === 'age_max') : campaign.limitations.preferred
     ? campaign.limitations.preferred.find(limitation => limitation.type === 'age_max') : undefined;
    const ageMax = ageMaxLimitation ? ageMaxLimitation.value : undefined;
    const limitationUseAliasName = [AdType.PIC_LONG, AdType.PIC_SHORT].includes(campaign.basic.adType);
    let result: any = {
      campaign: {
        ..._.omit(campaign.basic, [
          'id',
          'enableMonitor',
          'tags',
          'orderPriceEnable'
        ]),
        tags: campaign.basic.tags.join(','),
        budget: campaign.basic.budget,
        campaignId: campaign.basic.id,
        videoProgressTrackingCode: videoProgressTrackingCode,
        dealIds: this.getDealIds(campaign.limitations),
        isMonitorEnable: campaign.basic.enableMonitor,
        ageMin: ageMin,
        ageMax: ageMax,
        optimize: this.getNativeOptimizeValue(campaign.basic.optimize),
        videoAdViewObjective:
          campaign.basic.adType &&
          this.isOutdoorAdType(campaign.basic.adType) &&
          campaign.basic.optimize === L2ObjectOptimizationGoal.VIDEO_VIEWS
            ? {
              event: VideoAdViewObjective.PROGRESS,
              offset: 1
            }
            : campaign.basic.videoAdViewObjective
      },
      limitations:
        ageOptValue === 'include'
          ? toServerStructure(
            campaign.limitations,
            limitationUseAliasName
          )
          : toServerStructure(
            {
              ...campaign.limitations,
              preferred: [
                ..._.filter(
                  campaign.limitations.preferred,
                  (limitation: Limitation) =>
                    !['age_min', 'age_max'].includes(limitation.type)
                ),
                {
                  op: 'Preferred',
                  type: 'age',
                  value: getPmax2RtbAgeGroupsByAgeRange(ageMin, ageMax)
                }
              ]
            },
            limitationUseAliasName
          )
    };
    return result;
  }

  prepareCreateCampaignPayload (campaign: RtbCampaign) {
    const prepareCreate = _.flow([_.cloneDeep, this.convertVideoAdEventToEvent, this.wrapCampaignForServer]);
    return prepareCreate(campaign);
  }

  getDealIds (limitations) {
    const dealIdLimitation = limitations.other ? limitations.other.find(limitation => limitation.type === 'dealId') : undefined;
    if (!dealIdLimitation || dealIdLimitation.value.length === 0) {
      return undefined;
    }

    return dealIdLimitation.value.map(selectOption => selectOption.value);
  }

  showFrequencyControl (objective: L1ObjectObjective, adType: AdType, optimize: L2ObjectOptimizationGoal) {
    if (this.isOutdoorAdType(adType)) {
      return false;
    }

    return (
      (objective === L1ObjectObjective.AWARENESS || objective === L1ObjectObjective.UNSPECIFIED) &&
      optimize === L2ObjectOptimizationGoal.REACH
    );
  }

  getAgeLimitationSummary = (limitations, orderType) => {
    const ageMinLimitation = limitations.find(limitation => limitation.type === 'age_min');
    const ageMaxLimitation = limitations.find(limitation => limitation.type === 'age_max');
    if (ageMinLimitation && ageMaxLimitation) {
      const ageMinOption = ageMinOptions.find(option => option.value === _.get(ageMinLimitation, 'value'));
      const ageMaxOption = ageMaxOptions.find(option => option.value === _.get(ageMaxLimitation, 'value'));
      const ageGroupsHintGetter = orderType === OrderType.TENMAX ? getPmax2RtbAgeGroupsByAgeRange : getPmax3RtbAgeGroupsByAgeRange;
      const ageGroupsHint = ageGroupsHintGetter(_.get(ageMinOption, 'value', 0), _.get(ageMaxOption, 'value', 0))
                              .map(group => group.label)
                              .join(',');
      return {
        label: i18n.t<string>('limitation.labels.age'),
        value: `${_.get(ageMinOption, 'label', 0)} ~ ${_.get(ageMaxOption, 'label', 0)}`,
        hint: i18n.t<string>('limitation.hints.ageGroups', { groups: ageGroupsHint })
      };
    }
    return undefined;
  }

  getLimitationContent = (
    orderType: OrderType,
    isRetailMediaCampaign: boolean,
    agencySegmentName: string,
    limitationData: Limitation[]
  ) => {
    if (!limitationData) {
      return [];
    }

    const normalLimitationSummary = _.compact(limitationData.map((data) => {
      const ignoreType = [
        'unicornlanguage',
        'age',
        'age_min',
        'age_max'
      ];
      if (ignoreType.includes(data.type)) {
        return undefined;
      }

      if (!this.isArray(data.value)) {
        return undefined;
      }

      if (_.defaultTo(data.value, []).length === 0) {
        return undefined;
      }

      if (data.type === 'agencySegment') {
        return {
          label: i18n.t<string>('limitation.labels.agencySegment', { name: agencySegmentName }),
          value: data.value.map((value) => getLimitationContentLabel(data.type, value)).join(', ')
        };
      }

      return {
        label: data.type === 'adspace' && isRetailMediaCampaign ? i18n.t<string>('limitation.labels.placement') : i18n.t<string>(`limitation.labels.${data.type}`),
        value: data.value.map((value) => getLimitationContentLabel(data.type, value)).join(', ')
      };
    }));

    const ageLimitationSummary = this.getAgeLimitationSummary(limitationData, orderType);
    if (ageLimitationSummary) {
      return [ageLimitationSummary, ...normalLimitationSummary];
    }
    return normalLimitationSummary;
  }

  getLimitationSummaryData (limitationData: LimitationData, orderType: OrderType, isRetailMediaCampaign: boolean, agencySegmentName: string, hiddenLimitations: string[] = []) {
    const getLimitationContent = _.partial(this.getLimitationContent, orderType, isRetailMediaCampaign, agencySegmentName);
    const getLimitationToShow = (limitations?: Limitation[]) => limitations ? limitations.filter(limitation => !hiddenLimitations.includes(limitation.type)) : [];
    return _.omitBy({
      include: getLimitationContent(getLimitationToShow(limitationData.include)),
      preferred: getLimitationContent(getLimitationToShow(limitationData.preferred)),
      nonPreferred: getLimitationContent(getLimitationToShow(limitationData.nonPreferred)),
      exclude: getLimitationContent(getLimitationToShow(limitationData.exclude)),
      other: limitationData.other ? getLimitationContent(getLimitationToShow(limitationData.other)) : undefined
    }, _.isEmpty);
  }

  checkOptimizeSameAsPriceModel (priceModel: RtbCampaignPlanType, optimize: L2ObjectOptimizationGoal): boolean {
    return (priceModel === RtbCampaignPlanType.FCPC && (optimize === L2ObjectOptimizationGoal.CLICKS || optimize === L2ObjectOptimizationGoal.SALES)) ||
      (priceModel === RtbCampaignPlanType.FCPM && (optimize === L2ObjectOptimizationGoal.IMPRESSIONS || optimize === L2ObjectOptimizationGoal.REACH)) ||
      (priceModel === RtbCampaignPlanType.FCPV && optimize === L2ObjectOptimizationGoal.VIDEO_VIEWS) ||
      (priceModel === RtbCampaignPlanType.FVCPM && optimize === L2ObjectOptimizationGoal.VIDEO_VIEWS);
  }

  isOutdoorAdType (adType) {
    return [AdType.EDIMAX, AdType.PIC_SHORT, AdType.PIC_LONG, AdType.PMP_PIC_LONG, AdType.PMP_PIC_SHORT].includes(adType);
  }
}
