import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { ProductGroupType, ProductGroupListDTO } from 'core/limitation/ProductGroup';
import { DefaultProductGroupManager } from 'core/limitation/ProductGroupManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useEffect, useState } from 'react';
import formatters from './listFormatters';
import styles from './productGroupList.module.scss';
import { useCoreContext } from 'contexts/coreContext';
import { PMaxCore } from 'core';
import { compact, isNil } from 'lodash';

export enum ProductGroupListColumns {
  ID = 'id',
  DESC = 'description',
  AGENCIES = 'agencys',
  EDITBTNS = 'editBtns'
}

export type ProductGroupListState = {
  readonly searchString: string;
  readonly filteredList: ProductGroupListDTO[];
  readonly productGroupList: ProductGroupListDTO[];
  readonly productGroupToDelete?: number;
  readonly groupType: ProductGroupType;
  readonly campaignsToShow?: SelectOptions[];
};

const defaultProductGroupManager = new DefaultProductGroupManager();

export const useProductGroupListModel = (
  productGroupManager = defaultProductGroupManager
) => {

  const [state, setState] = useState<ProductGroupListState>({
    searchString: '',
    productGroupList: [],
    filteredList: [],
    productGroupToDelete: undefined,
    groupType: ProductGroupType.ADSPACE,
    campaignsToShow: undefined
  });
  const { loading, callAPIs, callAPIsBySequence } = useCallAPI();

  const core = useCoreContext() as PMaxCore;
  const isAdmin = isNil(core.accountManager.localeMeta?.agencyId);

  useEffect(() => {
    const filteredList = state.productGroupList.filter(productGroup => {
      return productGroup.groupName.toLowerCase().includes(state.searchString.toLowerCase()) && productGroup.groupType === state.groupType;
    });
    setState(prevState => ({ ...prevState, filteredList }));
  }, [state.searchString, state.groupType, state.productGroupList]);

  useEffect(() => {
    callAPIs([productGroupManager.getProductGroup.bind(productGroupManager)], productGroups => {
      setState(prevState => ({
        ...prevState,
        productGroupList: productGroups,
        filteredList: productGroups
      }));
    });
  }, [productGroupManager, callAPIs]);

  const deleteProductGroup = async (productGroupId: number) => {
    callAPIsBySequence([
      () => productGroupManager.deleteProductGroupById(productGroupId),
      () => productGroupManager.getProductGroup()
    ], (_, productGroups) => {
      setState(prevState => ({ ...prevState, productGroupToDelete: undefined, productGroupList: productGroups }));
    });
  };

  const onDeleteBtnClick = (productGroupToDelete: number) => setState({ ...state, productGroupToDelete });

  const cancelDelete = () => setState({ ...state, productGroupToDelete: undefined });

  const onHandleSearch = (searchString: string) => setState({ ...state, searchString });

  const onSwitchType = (groupType) => {
    if (groupType === null) {
      return;
    }
    setState({ ...state, groupType });
  };

  const showCampaigns = async (productGroupId: number) => {
    callAPIs([() => productGroupManager.getCampaignByGroupId(productGroupId)], campaignsToShow => {
      setState(prevState => ({ ...prevState, campaignsToShow }));
    });
  };

  const closeCampaignsModal = () => setState({ ...state, campaignsToShow: undefined });

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `productGroupList.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const columns = compact([
    renderColumn(columnDefinition(ProductGroupListColumns.ID), formatters.nameFormatter),
    renderColumn(columnDefinition(ProductGroupListColumns.DESC)),
    isAdmin ? renderColumn(columnDefinition(ProductGroupListColumns.AGENCIES), formatters.agenciesFormatter) : undefined,
    renderColumn({
      ...columnDefinition(ProductGroupListColumns.EDITBTNS),
      text: '',
      sort: false,
      formatExtraData: {
        onDeleteBtnClick,
        showCampaigns
      }
    }, formatters.floatingEditBtnsFormatter)
  ]);

  return {
    state,
    columns,
    loading,
    onSwitchType,
    onHandleSearch,
    deleteProductGroup,
    cancelDelete,
    closeCampaignsModal
  };
};
