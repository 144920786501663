import { VideoAdViewObjective, CampaignState, RtbCampaignPlanType, RtbOptimize, RtbCampaign, RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import { validateEmpty, validateMinimum, validateInteger, validateMinMax } from 'utils/ValidateUtils';
import i18n from 'i18n';
import _ from 'lodash';
import { formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { Order } from 'core/order/Order';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import moment from 'moment';
import { L1Object, L1ObjectObjective } from 'core/l1Object/L1Object';
import { renderOverBudgetWording } from '../../../L2Objects/FormHintRenderFunctions';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';

type ValidateFormResult = ValidationResult<RtbCampaignBasic & { dayRange: string }>;

export interface RtbCampaignBasicFormValidator {
  validate (l1Object: L1Object, campaignBasic: Partial<RtbCampaignBasic>, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta): ValidateFormResult;
}

export class DefaultRtbCampaignBasicFormValidator implements RtbCampaignBasicFormValidator {

  bidFloorGetter: (optimize: RtbOptimize, localeMeta?: LocaleMeta) => number;
  addonFeatureManager: AddonFeatureManager;
  validateStrategies: Record<RtbCampaignPlanType, (l1Object: L1Object, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta) => ValidateFormResult>;
  constructor (
    protected action: string,
    protected order: Order,
    protected defaultCampaign: RtbCampaign,
    protected campaignBasic: RtbCampaignBasic,
    bidFloorGetter: (optimize: RtbOptimize | RtbCampaignPlanType, localeMeta?: LocaleMeta) => number,
    addonFeatureManager: AddonFeatureManager
  ) {
    this.bidFloorGetter = bidFloorGetter;
    this.addonFeatureManager = addonFeatureManager;
    this.validateStrategies = {
      [RtbCampaignPlanType.FCPC]: this.validateNormalCampaign.bind(this),
      [RtbCampaignPlanType.FCPM]: this.validateNormalCampaign.bind(this),
      [RtbCampaignPlanType.FCPV]: this.validateCPVCampaign.bind(this),
      [RtbCampaignPlanType.FVCPM]: this.validateNormalCampaign.bind(this),
      [RtbCampaignPlanType.DCPM]: this.validateDCPMCampaign.bind(this),
      [RtbCampaignPlanType.RS]: this.validateRSCampaign.bind(this)
    };
  }

  validate (l1Object: L1Object, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta) {
    const errors = this.validateStrategies[campaignBasic.priceModel](l1Object, campaignBasic, order, isDailySchedule, otherCampaignCost, localeMeta);
    return _.omitBy(errors, _.isEmpty);
  }

  validateBasic (l1Object: L1Object, campaignBasic: RtbCampaignBasic): ValidateFormResult {
    return {
      name: this.validateName(campaignBasic),
      dayRange: this.validateDateRange(campaignBasic),
      videoProgressTrackingCode: this.validateVideoProgressTrackingCode(campaignBasic),
      conversionTracking: this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST)
        && l1Object.objective === L1ObjectObjective.SALES
        ? validateEmpty(campaignBasic.conversionTracking) : undefined
    };
  }

  validateDCPMCampaign (l1Object: L1Object, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta): ValidateFormResult {
    // TODO: verify this
    return {
      ...this.validateBasic(l1Object, campaignBasic),
      budget: this.validateBudget(l1Object, campaignBasic, order, otherCampaignCost),
      bidPrice: this.validateBidPrice(campaignBasic, order.currency, localeMeta),
      dailyTargetBudget: isDailySchedule ? this.validateDailyTargetBudget(campaignBasic) : undefined,
      frequency: l1Object.objective === L1ObjectObjective.AWARENESS && campaignBasic.optimize === L2ObjectOptimizationGoal.REACH.toString() && campaignBasic.frequency ?
        this.validateFrequency(campaignBasic.frequency) : undefined
    };
  }

  validateRSCampaign (l1Object: L1Object, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta): ValidateFormResult {
    return {
      ...this.validateBasic(l1Object, campaignBasic),
      budget: this.validateBudget(l1Object, campaignBasic, order, otherCampaignCost),
      bidPrice: this.validateBidPrice(campaignBasic, order.currency, localeMeta),
      dailyTargetBudget: isDailySchedule ? this.validateDailyTargetBudget(campaignBasic) : undefined
    };
  }

  validateNormalCampaign (l1Object: L1Object, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta): ValidateFormResult {
    return {
      ...this.validateBasic(l1Object, campaignBasic),
      orderPrice: this.validateOrderPrice(campaignBasic, order.currency),
      budget: this.validateBudget(l1Object, campaignBasic, order, otherCampaignCost),
      bidPrice: this.validateBidPrice(campaignBasic, order.currency, localeMeta),
      dailyTargetBudget: isDailySchedule ? this.validateDailyTargetBudget(campaignBasic) : undefined
    };
  }

  validateCPVCampaign (l1Object: L1Object, campaignBasic: RtbCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta): ValidateFormResult {
    return {
      ...this.validateNormalCampaign(l1Object, campaignBasic, order, isDailySchedule, otherCampaignCost, localeMeta),
      videoAdViewObjective: this.validateVideoAdViewObjective(campaignBasic)
    };
  }

  validateName (campaignBasic) {
    const name = campaignBasic.name;
    let error = validateEmpty(name);
    if (error) {
      return error;
    }
    if (name.length > 255) {
      return i18n.t<string>('campaign.descriptions.lengthError');
    }
  }

  validateDailyTargetBudget (campaignBasic) {
    return validateMinMax(
      campaignBasic.dailyTargetBudget,
      Math.min(1, campaignBasic.budget),
      campaignBasic.budget
    );
  }

  validateDateRange (campaignBasic) {
    let start = new Date(campaignBasic.startDate);
    let end = new Date(campaignBasic.endDate);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 92) {
      return i18n.t<string>('campaign.descriptions.moreThanDays', { days: 92 });
    }
    const now = moment();
    const defaultCampaignStartDate = this.campaignBasic.startDate;
    if (moment(campaignBasic.startDate).isBefore(this.order.startDate)) {
      return i18n.t<string>('campaign.descriptions.startDayBeforeOrder');
    }
    const shouldCheckStartDay = !campaignBasic.id || moment(defaultCampaignStartDate).isAfter(now);
    if (shouldCheckStartDay && moment(campaignBasic.startDate).add(1, 'hours').isBefore(now)) {
      return i18n.t<string>('campaign.descriptions.createBeforeNow');
    }
    const defaultCampaignEndDate = this.campaignBasic.endDate;
    const shouldCheckEndDay = campaignBasic.id && defaultCampaignEndDate !== campaignBasic.endDate;
    if (shouldCheckEndDay && moment(campaignBasic.endDate).isBefore(now)) {
      return i18n.t<string>('campaign.descriptions.endDayBeforeNow');
    }
  }

  validateOrderPrice (campaignBasic, currency: string) {
    if (!campaignBasic.orderPriceEnable) {
      return;
    }
    let orderPriceMinimum = this.bidFloorGetter(campaignBasic.priceModel);
    orderPriceMinimum = orderPriceMinimum ? orderPriceMinimum : 0;
    return validateMinimum(
      campaignBasic.orderPrice,
      orderPriceMinimum,
      'campaign.descriptions.priceMinimum',
      currency
    );
  }

  validateVideoAdViewObjective (campaignBasic) {
    const videoAdViewObjective = _.get(campaignBasic, 'videoAdViewObjective');
    return _.omitBy(
      {
        offset:
          videoAdViewObjective &&
          videoAdViewObjective.videoAdEvent ===
            VideoAdViewObjective.PROGRESS
            ? validateEmpty(videoAdViewObjective.offset)
            : undefined,
        videoAdMetricEvent:
          videoAdViewObjective &&
          videoAdViewObjective.videoAdEvent ===
            VideoAdViewObjective.METRIC
            ? validateEmpty(videoAdViewObjective.videoAdMetricEvent)
            : undefined
      }, _.isUndefined);
  }

  validateBudget (l1Object: L1Object, campaignBasic, order: Order, otherCampaignCost: number) {
    const budget = campaignBasic.budget;
    const rtbCampaignManager = new DefaultRtbCampaignManager();
    const minBudgetOfCampaign = rtbCampaignManager.getMinBudgetOfCampaign(campaignBasic, order.campaignConstraint.budgetMinimum);
    if (budget < 0) {
      return validateMinimum(
        budget,
        0,
        'campaign.descriptions.smallerThanBudgetZero',
        order.currency
      );
    }

    if (this.action === 'create') {
      return this.validateBudgetOfCreateAction(l1Object, campaignBasic, order, otherCampaignCost, minBudgetOfCampaign);
    }
    return this.validateBudgetOfEditAction(l1Object, campaignBasic, order, otherCampaignCost, minBudgetOfCampaign);
  }

  validateBudgetOfCreateAction (
    l1Object: L1Object,
    campaignBasic: RtbCampaignBasic,
    order: Order,
    otherCampaignCost: number,
    minBudgetOfCampaign: number
  ) {
    const budget = campaignBasic.budget;
    const l1ObjectAutoOptimise = _.get(l1Object, 'autoOptimise', false);
    const l1ObjectBudget = +_.defaultTo(_.get(l1Object, 'budget'), 0);
    if (l1ObjectAutoOptimise) {
      const l1ObjectMinBudget = otherCampaignCost + Math.max(minBudgetOfCampaign, budget);
      if (l1ObjectMinBudget > l1ObjectBudget) {
        return i18n.t<string>('campaign.descriptions.minL1ObjectBudget', { budget: formatPriceWithCurrency(order.currency, l1ObjectMinBudget) });
      }
      return;
    }
    const totalBudget = l1Object.budgetBalance;
    const remainBudget = totalBudget - budget;
    if (remainBudget < 0) {
      return renderOverBudgetWording(order.currency, totalBudget);
    }

    return this.validateBudgetBasic(campaignBasic, l1Object, order, minBudgetOfCampaign);
  }

  validateBudgetOfEditAction (
    l1Object: L1Object,
    campaignBasic: RtbCampaignBasic,
    order: Order,
    otherCampaignCost: number,
    minBudgetOfCampaign: number
  ) {
    const budget = campaignBasic.budget;
    const l1ObjectAutoOptimise = _.get(l1Object, 'autoOptimise', false);
    const l1ObjectBudget = +_.defaultTo(_.get(l1Object, 'budget'), 0);
    if (l1ObjectAutoOptimise) {
      if (budget === 0) { // NOT ALLOCATED CAMPAIGN
        const l1ObjectMinBudget = otherCampaignCost + minBudgetOfCampaign;
        if (l1ObjectMinBudget > l1ObjectBudget) {
          return i18n.t<string>('campaign.descriptions.minL1ObjectBudget', { budget: formatPriceWithCurrency(order.currency, l1ObjectMinBudget) });
        }
      }
      return;
    }
    if (
      !this.defaultCampaign.basic.isDraft &&
      this.defaultCampaign.basic.budget === campaignBasic.budget
    ) {
      return undefined;
    }
    const totalBudget = l1Object.budgetBalance;
    const remainBudget = totalBudget - budget + this.defaultCampaign.basic.budget;
    if (remainBudget < 0) {
      return renderOverBudgetWording(order.currency, totalBudget);
    }
    return this.validateBudgetBasic(campaignBasic, l1Object, order, minBudgetOfCampaign);
  }

  validateBudgetBasic (
    campaignBasic: RtbCampaignBasic,
    l1Object: L1Object,
    order: Order,
    minBudgetOfCampaign: number
  ) {
    const l1ObjectAutoOptimise = _.get(l1Object, 'autoOptimise', false);
    const budget = campaignBasic.budget;
    const spents = _.get(campaignBasic, 'spents', 0);
    const expectedSpent = _.get(campaignBasic, 'expectedSpent', 0);
    const campaignBudgetMinimum = order.campaignConstraint.campaignBudgetMinimum;
    const finalMinBudgetOfCampaign = l1ObjectAutoOptimise ? minBudgetOfCampaign : campaignBudgetMinimum;
    const minBudget = Math.max(spents, expectedSpent, finalMinBudgetOfCampaign);

    if (campaignBasic.state === CampaignState.DEACTIVATE) {
      return validateMinimum(
        budget,
        getPriceValue(order.currency, spents),
        'campaign.descriptions.smallerThanBudgetMinimum',
        order.currency
      );
    }

    let error = validateEmpty(budget);
    if (error) {
      return error;
    }

    let hintI18n = 'campaign.descriptions.smallerThanBudgetMinimum';
    let i18nParams: object = { min: formatPriceWithCurrency(order.currency, minBudget) };
    switch (minBudget) {
      case spents:
        hintI18n = 'campaign.descriptions.smallerThanSpents';
        break;
      case expectedSpent:
        hintI18n = 'campaign.descriptions.smallerThanExpectSpents';
        break;
      case finalMinBudgetOfCampaign:
        hintI18n = l1ObjectAutoOptimise ? 'campaign.descriptions.smallerThanDailyMnBudgetSum' : 'campaign.descriptions.smallerThanCampaignBudgetMinimum';
        i18nParams = {
          ...i18nParams,
          dmin: formatPriceWithCurrency(order.currency, order.campaignConstraint.budgetMinimum)
        };
        break;
      default:
        break;
    }

    const minValue = getPriceValue(order.currency, minBudget);
    if (budget < minValue) {
      return i18n.t<string>(hintI18n, { ...i18nParams });
    }
  }

  validateBidPrice (campaignBasic, currency: string, localeMeta) {
    if (_.get(campaignBasic, 'bidStrategy') === BidStrategy.LOWEST_COST_WITHOUT_CAP) {
      return;
    }
    const bidPrice = campaignBasic.bidPrice;
    let error = validateEmpty(bidPrice);
    if (error) {
      return error;
    }
    const optimize = campaignBasic.optimize;
    let bidFloor = this.bidFloorGetter(optimize, localeMeta);
    const valueNumber = typeof bidPrice === 'string' ? parseFloat(bidPrice) : bidPrice;
    if (valueNumber < bidFloor) {
      let min = currency + bidFloor;
      return i18n.t<string>('campaign.descriptions.priceMinimum', { min });
    }
    const orderPriceMaximum = campaignBasic.orderPrice ? +campaignBasic.orderPrice : undefined;
    const priceModel = campaignBasic.priceModel;
    const rtbCampaignManager = new DefaultRtbCampaignManager();
    const optimizeSameAsPriceModel = rtbCampaignManager.checkOptimizeSameAsPriceModel(priceModel, optimize);
    if (optimizeSameAsPriceModel && orderPriceMaximum && orderPriceMaximum > -1 && valueNumber > orderPriceMaximum) {
      let max = currency + orderPriceMaximum;
      return i18n.t<string>('formValidate.labels.maximumError', { max });
    }
  }

  validateVideoProgressTrackingCode (campaignBasic) {
    const videoProgressTrackingCode = _.get(campaignBasic.videoProgressTrackingCode, 'code', '');
    const videoProgressTrackingCodeOffset = _.get(campaignBasic.videoProgressTrackingCode, 'offset');
    if (videoProgressTrackingCode.length > 0) {
      return _.omitBy({ offset: validateInteger(videoProgressTrackingCodeOffset) }, _.isUndefined);
    }
  }

  validateFrequency = (frequency) => {
    const minIntervalDays = 1;
    const maxIntervalDays = 7;
    const minMaxFrequency = 1;
    const maxMaxFrequency = 20;
    const maxFrequency = frequency.maxFrequency;
    const intervalDays = frequency.intervalDays;
    const emptyMaxFrequency = validateEmpty(maxFrequency) && i18n.t<string>('adSetSetupFlow.mainStep.errors.emptyMaxFrequency');
    const emptyIntervalDays = validateEmpty(intervalDays) && i18n.t<string>('adSetSetupFlow.mainStep.errors.emptyIntervalDays');
    const intervalDaysOutofRange = validateMinMax(intervalDays, minIntervalDays, maxIntervalDays) &&
      i18n.t<string>('adSetSetupFlow.mainStep.errors.intervalDaysOutofRange', { min: minIntervalDays, max: maxIntervalDays });
    const maxFrequencyOutofRange = validateMinMax(maxFrequency, minMaxFrequency, maxMaxFrequency) &&
      i18n.t<string>('adSetSetupFlow.mainStep.errors.maxFrequencyOutofRange', { min: minMaxFrequency, max: maxMaxFrequency });
    return _.omitBy({
      maxFrequency: emptyMaxFrequency || maxFrequencyOutofRange,
      intervalDays: emptyIntervalDays || intervalDaysOutofRange
    }, _.isUndefined);
  }
}
