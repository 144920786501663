import { AdType } from 'core/rtbCampaign/RtbCampaign';

export const defaultCampaignBidPrice: {
  type: string;
  autoBidCap: {[key: string]: string};
  bidFloor: {[key: string]: string};
}[] = [{
  type: AdType.ONE_FOR_ALL_DISPLAY,
  autoBidCap: {
    cpc: '12',
    cpm: '20',
    vCpm: '60'
  },
  bidFloor: {
    cpc: '4',
    cpm: '4',
    vCpm: '12'
  }
},{
  type: AdType.DISPLAY,
  autoBidCap: {
    cpc: '12',
    cpm: '20',
    vCpm: '60'
  },
  bidFloor: {
    cpc: '4',
    cpm: '4',
    vCpm: '12'
  }
}, {
  type: AdType.VIDEO,
  autoBidCap: {
    cpc: '18',
    cpv: '1',
    cpm: '800',
    vCpm: '800'
  },
  bidFloor: {
    cpc: '6',
    cpv: '0.2',
    cpm: '160',
    vCpm: '480'
  }
}, {
  type: AdType.COMBO,
  autoBidCap: {
    cpc: '18',
    cpv: '1',
    cpm: '800',
    vCpm: '800'
  },
  bidFloor: {
    cpc: '6',
    cpv: '0.2',
    cpm: '160',
    vCpm: '480'
  }
}, {
  type: AdType.THIRD_PARTY_BOTTOM,
  autoBidCap: {
    cpc: '18',
    cpv: '0.5',
    cpm: '180',
    vCpm: '180'
  },
  bidFloor: {
    cpc: '6',
    cpv: '0.15',
    cpm: '60',
    vCpm: '60'
  }
}, {
  type: AdType.THIRD_PARTY_RECTANGLE,
  autoBidCap: {
    cpc: '18',
    cpv: '0.25',
    cpm: '90',
    vCpm: '90'
  },
  bidFloor: {
    cpc: '6',
    cpv: '0.08',
    cpm: '30',
    vCpm: '30'
  }
}, {
  type: AdType.RETAIL,
  autoBidCap: {
    cpc: '100',
    cpv: '100',
    cpm: '100',
    vCpm: '100'
  },
  bidFloor: {
    cpc: '0',
    cpv: '0',
    cpm: '0',
    vCpm: '0'
  }
}, {
  type: AdType.CTV,
  autoBidCap: {
    cpc: '18',
    cpv: '2.7',
    cpm: '2500',
    vCpm: '2500'
  },
  bidFloor: {
    cpc: '6',
    cpv: '0.8',
    cpm: '750',
    vCpm: '750'
  }
}, {
  type: AdType.EDIMAX,
  autoBidCap: {
    cpc: '2.5',
    cpv: '2.5',
    cpm: '2500',
    vCpm: '2500'
  },
  bidFloor: {
    cpc: '1.6',
    cpv: '1.6',
    cpm: '1600',
    vCpm: '1600'
  }
}, {
  type: AdType.PIC_SHORT,
  autoBidCap: {
    cpc: '0.25',
    cpv: '0.25',
    cpm: '250',
    vCpm: '250'
  },
  bidFloor: {
    cpc: '0.25',
    cpv: '0.25',
    cpm: '250',
    vCpm: '250'
  }
}, {
  type: AdType.PIC_LONG,
  autoBidCap: {
    cpc: '0.4',
    cpv: '0.4',
    cpm: '400',
    vCpm: '400'
  },
  bidFloor: {
    cpc: '0.4',
    cpv: '0.4',
    cpm: '400',
    vCpm: '400'
  }
}, {
  type: AdType.MESSAGE,
  autoBidCap: {
    cpc: '300',
    cpv: '3',
    cpm: '3',
    vCpm: '3'
  },
  bidFloor: {
    cpc: '300',
    cpv: '2',
    cpm: '2',
    vCpm: '2'
  }
}];
