import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { LimitationManager, DefaultLimitationManager } from 'core/limitation/LimitationManager';
import { DeliverType, RtbCampaignPlanType, CreativeDeliverType, AdType, VideoAdViewObjective, CampaignState, RTBCAMPAIGN_DEFAULT_AGE_MIN, RTBCAMPAIGN_DEFAULT_AGE_MAX, RtbCampaign, OutdoorDeliverType, TrackEvent, RtbCampaignListBasic } from 'core/rtbCampaign/RtbCampaign';
import { FinalReportGender, Order, OrderType } from 'core/order/Order';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { ConversionManager, DefaultConversionManager } from 'core/conversion/ConversionManager';
import { RtbCampaignBasicFormModel, RtbCampaignBasicFormModelConstructorParams } from './RtbCampaignForm/RtbCampaignBasicFormModel';
import { DefaultEditLimitationModel, EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { OPERATE } from 'enum/Operate';
import moment from 'moment';
import i18n from 'i18next';
import _, { defaultTo, get } from 'lodash';
import { getPriceValue } from 'helper/CurrencyHelper';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { L1Object, L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { CreateOneForAllDisplayFormModel, EditOneForAllDisplayFormModel, OneForAllDisplayFormModel } from './RtbCampaignForm/OneForAllDisplayFormModel';
import { UnknowAdTypeFormModel, CreateUnknowAdTypeFormModel, EditUnknowAdTypeFormModel } from './RtbCampaignForm/UnknowAdTypeFormModel';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { DefaultRtbCampaignSummaryStepModel, RtbCampaignDraftSummaryStepModel, RtbCampaignSummaryStepModel } from './FlowSteps/RtbCampaignSummaryStepModel';
import { Retail } from 'core/product/Product';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { CreatePilotTVFormModel, EditPilotTVFormModel, PilotTVFormModel } from './RtbCampaignForm/PilotTVFormModel';
import { toServerStructure } from 'utils/LimitationUtil';
import { genderOptions, getPmax2RtbAgeGroupsByAgeRange, getPmax3RtbAgeGroupsByAgeRange } from 'core/limitation/l2ObjectTAOptions';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import { SavedTargeting } from 'core/limitation/Limitation';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { DraftManager, RtbCampaignDraftManager } from 'core/draft/DraftManager';
import { toast } from 'react-toastify';
import { CreateDisplayFormModel, DisplayFormModel, EditDisplayFormModel } from './RtbCampaignForm/DisplayFormModel';
import { CreateVideoFormModel, EditVideoFormModel, VideoFormModel } from './RtbCampaignForm/VideoFormModel';
import { ComboFormModel, CreateComboFormModel, EditComboFormModel } from './RtbCampaignForm/ComboFormModel';
import { CreateThirdPartyBottomFormModel, EditThirdPartyBottomFormModel, ThirdPartyBottomFormModel } from './RtbCampaignForm/ThirdPartyBottomFormModel';
import { CreateThirdPartyRectangleFormModel, EditThirdPartyRectangleFormModel, ThirdPartyRectangleFormModel } from './RtbCampaignForm/ThirdPartyRectangleFormModel';
import { CreateRetailFormModel, EditRetailFormModel, RetailFormModel } from './RtbCampaignForm/RetailFormModel';
import { CtvFormModel, CreateCtvFormModel, EditCtvFormModel } from './RtbCampaignForm/CTVFormModel';
import { CreateEdiMaxFormModel, EdiMaxFormModel, EditEdiMaxFormModel } from './RtbCampaignForm/EdiMaxFormModel';
import { CreatePICShortFormModel, EditPICShortFormModel } from './RtbCampaignForm/PICShortFormModel';
import { CreatePICLongFormModel, EditPICLongFormModel } from './RtbCampaignForm/PICLongFormModel';
import { rtbInventories } from 'containers/Limitations/LimitationSetting/rtbLimitationInventory';
import { CreateThirdPartyFormModel, EditThirdPartyFormModel, ThirdPartyFormModel } from './RtbCampaignForm/ThirdPartyFormModel';
import { PICFormModel } from './RtbCampaignForm/PICFormModel';
import { DEFAULT_INVENTORY, DefaultLimitationInventorySettings, LimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';
import { PmpFormModel } from './RtbCampaignForm/PmpFormModel';
import { Pmp, PmpStatus } from 'core/pmp/Pmp';
import { DefaultPmpManager, PmpManager } from 'core/pmp/PmpManager';
import { CreatePmpPICShortFormModel, EditPmpPICShortFormModel } from './RtbCampaignForm/PmpPicShortModel';
import { CreatePmpPICLongFormModel, EditPmpPICLongFormModel } from './RtbCampaignForm/PmpPicLongModel';
import { CreateCreativeInL2ObjectSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { AdLogoType } from 'core/adLogo/AdLogo';
import { CreativeSummaryStepModel } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/CreativeSummaryStepModel';

export interface RtbCampaignSetupFlowPageModel {
  readonly type: string;
  readonly objectType: string;
  readonly order: Order;
  readonly conversionOptions?: Array<SelectOptions>;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly state: RtbCampaignSetupFlowPageState;
  readonly event: UpdateEventListener<RtbCampaignSetupFlowPageModel>;
  readonly limitationModel?: EditLimitationModel;
  readonly campaignBasicFormModel?: RtbCampaignBasicFormModel;
  readonly limitationPreSet: any;
  readonly campaignId: number | string | null;
  readonly needSetupTracking: boolean;
  readonly l1Object: L1Object;
  readonly localeMeta?: LocaleMeta;
  readonly estimatedAudience?: EstimatedAudience;
  readonly audienceLowestThreshold: number;
  readonly savedTAList: SavedTargeting[];
  readonly appliedSavedTAInfo?: SelectOptions;
  readonly isPmp: boolean;
  readonly pmpListOfOrder: Pmp[];
  init (): void;
  setCampaign (campaign: any, rerender?: boolean): void;
  getRtbCampaignBasicFormModel (): RtbCampaignBasicFormModel | undefined;
  getLimitationModel (): EditLimitationModel;
  getTitle (): string;
  onAdTypeChange (adType): void;
  onUnmount (handler): void;
  setOrder (order: Order): void;
  setRedirectPath (redirectPath?: string): void;
  setFinishedRedirectPath (redirectPath?: string): void;
  cancel (): void;
  getRtbCampaignSummaryStepModel (
    subStepIndex: number,
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void,
    goSubStep: (subStepIndex: number) => void,
    creativeSummaryStepModel?: CreativeSummaryStepModel
  ): RtbCampaignSummaryStepModel;
  getLimitationsSummaryData (limitations): any;
  setShowTAManagement (showTAManagement: boolean): void;
  importLimitation (
    appliedSavedTAInfo: SelectOptions,
    limitationValue: any
  ): void;
  onDeleteSaveTA (deletedTAId: number);
  onSaveDraft?: (campaign: RtbCampaign) => void;
  getCreativeSetupFlowModel ():
    | CreateCreativeInL2ObjectSetupFlowPageModel
    | undefined;
}

export type RtbCampaignSetupFlowPageProps = {
  readonly model: RtbCampaignSetupFlowPageModel;
};

export type RtbCampaignSetupFlowPageState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly campaign: any;
  readonly finished: boolean;
  readonly showTAManagement: boolean;
};

export abstract class DefaultRtbCampaignSetupFlowPageModel implements RtbCampaignSetupFlowPageModel {
  objectType: string;
  event: FireableUpdateEventListener<RtbCampaignSetupFlowPageModel>;
  loading: boolean;
  redirectPath?: string;
  addonFeatureManager: AddonFeatureManager;
  campaign?: RtbCampaign;
  defaultCampaign: any;
  conversionOptions: Array<SelectOptions>;
  limitationPreSet: any;
  order: Order;
  campaignOneForAllDisplayFormModel?: OneForAllDisplayFormModel;
  campaignPilotTVFormModel?: PilotTVFormModel;
  campaignDisplayFormModel?: DisplayFormModel;
  campaignVideoFormModel?: VideoFormModel;
  campaignEdiMaxFormModel?: EdiMaxFormModel;
  campaignPICShortFormModel?: PICFormModel;
  campaignPICLongFormModel?: PICFormModel;
  campaignComboFormModel?: ComboFormModel;
  campaignCtvFormModel?: CtvFormModel;
  campaignUnknowAdTypeFormModel?: UnknowAdTypeFormModel;
  campaignThirdPartyFormModel?: ThirdPartyFormModel;
  campaignThirdPartyBottomFormModel?: ThirdPartyBottomFormModel;
  campaignThirdPartyRectangleFormModel?: ThirdPartyRectangleFormModel;
  campaignRetailFormModel?: RetailFormModel;
  campaignPmpPICShortFormModel?: PmpFormModel;
  campaignPmpPICLongFormModel?: PmpFormModel;
  campaignBasicFormModel?: RtbCampaignBasicFormModel;
  campaignSummaryModel?: RtbCampaignSummaryStepModel;
  limitationModel?: EditLimitationModel;
  rbLimitationModel: any;
  normalLimitationModel?: EditLimitationModel;
  finished: boolean;
  goSegments: SelectOptions[] | undefined = [];
  retailers?: Retail[];
  savedTAList: SavedTargeting[] = [];
  showTAManagement: boolean = false;
  appliedSavedTAInfo?: SelectOptions;
  pmpListOfOrder: Pmp[] = [];

  constructor (
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    public l1Object: L1Object,
    public localeMeta?: LocaleMeta,
    protected otherCampaignOfL1Object?: RtbCampaignListBasic[],
    protected manager: RtbCampaignManager= new DefaultRtbCampaignManager(),
    protected conversionManager: ConversionManager = new DefaultConversionManager(),
    protected limitationManager: LimitationManager = new DefaultLimitationManager(),
    protected adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    protected rdpManager: RdpManager = new DefaultRdpManager(),
    protected pmpManager: PmpManager = new DefaultPmpManager()
  ) {
    this.objectType = 'campaign';
    this.order = { ...order };
    this.l1Object = { ...l1Object };
    this.addonFeatureManager = addonFeatureManager;
    this.manager = manager;
    this.conversionManager = conversionManager;
    this.limitationManager = limitationManager;
    this.event = new FireableUpdateEventListener<RtbCampaignSetupFlowPageModel>();
    this.loading = true;
    this.conversionOptions = [];
    this.finished = false;
  }

  abstract get type ();

  async init () {
    this.updateState(true);
    try {
      await this.initSavedTAList();
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      if (this.l1Object?.channel === L1ObjectChannel.RETAIL_MEDIA) {
        Object.keys(this.limitationPreSet).forEach(key => {
          const limitationPreset = this.limitationPreSet[key];
          _.remove(limitationPreset, (preset: any) => preset.type === 'adx');
        });
      }
      this.campaign = await this.getCampaign();
      this.initBidStrategy();
      this.defaultCampaign = _.cloneDeep(this.campaign);
      await this.initConvertsionOptions();
      await this.initGoSegments();
      this.createCampaignFormModels();
      this.setUpCampaignBasicFormModel(this.campaign!.basic.adType);
      if (this.isCampaignInvalid()) {
        this.to404();
        return;
      }
    } catch (e) {
      const errorEvent = new ErrorEvent('render-error', new Error('Failed to init campaign setup flow'));
      window.dispatchEvent(errorEvent);
    }
    this.updateState(false);
  }

  abstract getCampaign ();

  abstract getTitle ();

  async initSavedTAList () {
    try {
      this.savedTAList = await this.limitationManager.getSavedTargetings(
        this.order.advertiserId,
        _.get(this.l1Object, 'channel', L1ObjectChannel.RTB),
        this.order.orderType
      );
    } catch (e) {}
  }

  setShowTAManagement = (showTAManagement: boolean): void => {
    this.showTAManagement = showTAManagement;
    this.updateState(false);
  }

  refreshSavedTAList = async () => {
    this.updateState(true);
    try {
      this.savedTAList = await this.limitationManager.getSavedTargetings(
        this.order.advertiserId,
        _.get(this.l1Object, 'channel', L1ObjectChannel.RTB),
        this.order.orderType
      );
    } catch (e) {}
    this.updateState(false);
  }

  get needSetupTracking () {
    if (!this.campaignBasicFormModel) {
      return false;
    }

    const addonEnable = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST) ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.THIRDPARTY_TRACKING) ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.RETARGETING_TRACKER);

    return addonEnable && !this.campaignBasicFormModel.isOutdoorType && this.order.orderType !== OrderType.GOJEK;
  }

  get estimatedAudience () {
    return this.limitationModel ? this.limitationModel.estimateData : undefined;
  }

  get audienceLowestThreshold () {
    return 5000000;
  }

  get isPmp () {
    return this.campaignBasicFormModel ? this.campaignBasicFormModel.isPmpType : false;
  }

  getCreativeSetupFlowModel = (): CreateCreativeInL2ObjectSetupFlowPageModel | undefined => {
    return undefined;
  }

  to404 () {
    const redirectPath =
      `/orders/${this.order.orderNumber}/campaign-groups/${this.l1Object.l1ObjectId}/campaigns/${this.campaignId}/edit/error404`;
    this.setRedirectPath(redirectPath);
  }

  initBidStrategy () {
    if (!this.campaign) {
      return;
    }
    if (this.l1Object.autoOptimise) {
      _.set(this.campaign, 'basic.bidStrategy', this.l1Object.rtb.bid_strategy);
    } else {
      _.set(this.campaign, 'basic.bidStrategy',
        _.isNumber(_.get(this.campaign, 'basic.bidPrice')) ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP
      );
    }
  }

  getRtbCampaignSummaryStepModel (
    subStepIndex: number,
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void,
    goSubStep: (subStepIndex: number) => void,
    creativeSummaryStepModel?: CreativeSummaryStepModel
  ) {
    if (
      this.campaignSummaryModel &&
      this.campaignSummaryModel.subStepIndex === subStepIndex &&
      this.campaignSummaryModel.creativeSummaryStepModel ===
        creativeSummaryStepModel
    ) {
      return this.campaignSummaryModel;
    }
    this.campaignSummaryModel = new DefaultRtbCampaignSummaryStepModel(this, subStepIndex, goLast, goStep, goSubStep, creativeSummaryStepModel);
    return this.campaignSummaryModel;
  }

  async initConvertsionOptions () {
    this.conversionOptions = await this.conversionManager.getConversionOptions(this.order.advertiserId);
  }

  async initGoSegments () {
    const goSegments = await this.adRequestSourceManager.getGoSegments(this.order.advertiserId, this.l1Object.channel);
    if (this.order.orderType === OrderType.GOJEK) {
      this.goSegments = goSegments;
    } else {
      this.goSegments = undefined;
    }
  }

  getLimitationsSummaryData = (limitations): any => {
    const supportAgeGender = this.campaignBasicFormModel?.limitatoinConfig.supportAgeGenderLimitation;
    const limitationSummaryData = this.manager.getLimitationSummaryData(
      limitations,
      this.order.orderType,
      this.l1Object?.channel === L1ObjectChannel.RETAIL_MEDIA,
      _.get(
        this.localeMeta,
        'property.addonProps.agencySegmentLimitationName',
        ''
      ),
      this.limitationModel
        ? this.limitationModel.limitationSetting
            .filter(setting => setting.hideLimitation)
            .map(setting => setting.name)
            .concat(supportAgeGender ? [] : ['age_min', 'age_max'])
        : []
    );
    return _.omitBy({
      include: {
        title: i18n.t<string>('campaignSummary.titles.inc'),
        titlePrefixColor: SummaryTitleColor.GREEN,
        content: limitationSummaryData.include
      },
      preferred: {
        title: i18n.t<string>('campaignSummary.titles.preferred'),
        titlePrefixColor: SummaryTitleColor.BLUE,
        content: limitationSummaryData.preferred
      },
      nonPreferred: {
        title: i18n.t<string>('campaignSummary.titles.nonPreferred'),
        titlePrefixColor: SummaryTitleColor.YELLOW,
        content: limitationSummaryData.nonPreferred
      },
      exclude: {
        title: i18n.t<string>('campaignSummary.titles.exc'),
        titlePrefixColor: SummaryTitleColor.RED,
        content: limitationSummaryData.exclude
      },
      other: limitationSummaryData.other ? {
        title: i18n.t<string>('campaignSummary.titles.other'),
        content: limitationSummaryData.other
      } : undefined
    }, _.isEmpty);
  }

  importLimitation = (appliedSavedTAInfo: SelectOptions, limitationValue: any) => {
    if (!this.limitationModel) {
      return;
    }
    this.appliedSavedTAInfo = appliedSavedTAInfo;
    this.limitationModel.updateLimitationValue(limitationValue);
  }

  onDeleteSaveTA = (deletedTAId: number) => {
    if (this.appliedSavedTAInfo && this.appliedSavedTAInfo.value === deletedTAId) {
      this.appliedSavedTAInfo = undefined;
    }
    this.refreshSavedTAList();
  }

  setCampaign = (campaign: any, rerender: boolean | undefined = true) => {
    this.campaign = campaign;
    rerender && this.updateState(false);
  }

  setOrder = (order: Order) => {
    this.order = { ...order };
  }

  abstract get campaignId ();

  abstract createCampaignFormModels ();

  get state (): RtbCampaignSetupFlowPageState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      campaign: this.campaign,
      finished: this.finished,
      showTAManagement: this.showTAManagement
    };
  }

  getCampaignFormModelParams (): RtbCampaignBasicFormModelConstructorParams {
    return [
      this.defaultCampaign,
      this.campaign!.basic,
      this.order,
      this.l1Object,
      this.addonFeatureManager,
      this.onPriceModelChange,
      this.otherCampaignOfL1Object
    ];
  }

  async onAdTypeChange (adType: AdType) {
    await this.setUpCampaignBasicFormModel(adType, true);
    const defaultCampaign = _.cloneDeep(this.defaultCampaign);
    const objective = _.get(this.l1Object, 'objective');
    if (this.campaignBasicFormModel) {
      const optimize = this.campaignBasicFormModel.getDefaultOptimizeType(this.campaignBasicFormModel.defaultPriceModel);
      _.set(defaultCampaign, 'basic.priceModel', this.campaignBasicFormModel.defaultPriceModel);
      _.set(defaultCampaign, 'basic.optimize', optimize);
      _.set(defaultCampaign, 'basic.videoAdViewObjective', this.campaignBasicFormModel.getVideoAdViewObjectiveOptions(this.campaignBasicFormModel.defaultPriceModel) ? {
        videoAdEvent: VideoAdViewObjective.DEFAULT
      } : undefined);
      if (this.manager.showFrequencyControl(objective, this.campaignBasicFormModel.campaignAdType, _.defaultTo(optimize, L2ObjectOptimizationGoal.UNSPECIFIED))) {
        _.set(defaultCampaign, 'basic.frequency', {
          maxFrequency: 5,
          intervalDays: 1
        });
      } else {
        _.set(defaultCampaign, 'basic.frequency', undefined);
      }
      _.set(defaultCampaign, 'basic.outdoorDeliverType', this.campaignBasicFormModel.isOutdoorType ? OutdoorDeliverType.OPTIMIZE : undefined);
      const optimizeSameAsPriceModel = optimize ? this.manager.checkOptimizeSameAsPriceModel(this.campaignBasicFormModel.defaultPriceModel, optimize) : false;
      const onlyBidCap = (!this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE) && optimizeSameAsPriceModel) || this.campaignBasicFormModel.isOutdoorType;
      _.set(defaultCampaign, 'basic.bidStrategy', onlyBidCap ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP);
      const limitationModel = this.getLimitationModel();
      limitationModel.cleanTaOptionsCache(rtbInventories.adspace.name);
      const defaultLimitationToShow = this.campaignBasicFormModel.limitatoinConfig.defaultLimitationToShow;
      defaultLimitationToShow && limitationModel.showInventory(defaultLimitationToShow.name, defaultLimitationToShow.operate);
    }
    if (this.l1Object.autoOptimise) {
      _.set(defaultCampaign, 'basic.bidStrategy', this.l1Object.rtb.bid_strategy);
    }
    const newCampaign = {
      ...defaultCampaign,
      basic: {
        ...defaultCampaign.basic,
        adType: adType
      }
    };
    this.setCampaign(newCampaign, true);
    const creativeCreateFlowModel = this.getCreativeSetupFlowModel();
    if (creativeCreateFlowModel) {
      await creativeCreateFlowModel.init();
    }
  }

  setRedirectPath (redirectPath?: string) {
    this.redirectPath = redirectPath;
    this.updateState(false);
  }

  setFinishedRedirectPath (redirectPath?: string) {
    this.redirectPath = redirectPath;
    this.finished = true;
    this.updateState(false);
  }

  getRtbCampaignBasicFormModel (): RtbCampaignBasicFormModel | undefined {
    return this.campaignBasicFormModel;
  }

  async setUpCampaignBasicFormModel (adType: AdType, needShowLoading: boolean = false) {
    switch (adType) {
      case AdType.VIDEO:
        this.campaignBasicFormModel = this.campaignVideoFormModel;
        break;
      case AdType.DISPLAY:
        this.campaignBasicFormModel = this.campaignDisplayFormModel;
        break;
      case AdType.THIRD_PARTY:
        this.campaignBasicFormModel = this.campaignThirdPartyFormModel;
        break;
      case AdType.THIRD_PARTY_BOTTOM:
        this.campaignBasicFormModel = this.campaignThirdPartyBottomFormModel;
        break;
      case AdType.THIRD_PARTY_RECTANGLE:
        this.campaignBasicFormModel = this.campaignThirdPartyRectangleFormModel;
        break;
      case AdType.EDIMAX:
        this.campaignBasicFormModel = this.campaignEdiMaxFormModel;
        break;
      case AdType.PIC_SHORT:
        this.campaignBasicFormModel = this.campaignPICShortFormModel;
        break;
      case AdType.PIC_LONG:
        this.campaignBasicFormModel = this.campaignPICLongFormModel;
        break;
      case AdType.PMP_PIC_SHORT:
        this.campaignBasicFormModel = this.campaignPmpPICShortFormModel;
        break;
      case AdType.PMP_PIC_LONG:
        this.campaignBasicFormModel = this.campaignPmpPICLongFormModel;
        break;
      case AdType.COMBO:
        this.campaignBasicFormModel = this.campaignComboFormModel;
        break;
      case AdType.ONE_FOR_ALL_DISPLAY:
        this.campaignBasicFormModel = this.campaignOneForAllDisplayFormModel;
        break;
      case AdType.PILOT_TV:
        this.campaignBasicFormModel = this.campaignPilotTVFormModel;
        break;
      case AdType.RETAIL:
        this.campaignBasicFormModel = this.campaignRetailFormModel;
        break;
      case AdType.CTV:
        this.campaignBasicFormModel = this.campaignCtvFormModel;
        break;
      default:
        this.campaignBasicFormModel = this.campaignUnknowAdTypeFormModel;
        break;
    }
    needShowLoading && this.updateState(true);
    this.campaignBasicFormModel && await this.campaignBasicFormModel.init();
    needShowLoading && this.updateState(false);
  }

  getLimitationModel (): EditLimitationModel {
    const defaultCanNotNull = this.l1Object?.channel === L1ObjectChannel.RETAIL_MEDIA ? {} : {
      include: ['adx']
    };
    const limitationPreSet = this.limitationPreSet ? this.limitationPreSet : {};
    Object.keys(defaultCanNotNull).forEach(opreate => {
      _.remove(defaultCanNotNull[opreate],
        value => !limitationPreSet[opreate] ||
          limitationPreSet[opreate].find(element => element.type === value) === undefined
      );
    });

    const limitationsCanNotNull = _.omitBy(defaultCanNotNull, _.isEmpty);
    const requiredOperateOfTaTypes = {};
    Object.keys(limitationsCanNotNull).forEach(operate => {
      const limitationCanNotNullOfOperate = limitationsCanNotNull[operate];
      limitationCanNotNullOfOperate && limitationCanNotNullOfOperate.forEach(type => {
        let requiredOperateOfTaType = defaultTo(requiredOperateOfTaTypes[type], []);
        requiredOperateOfTaType.push(operate);
        requiredOperateOfTaTypes[type] = requiredOperateOfTaType;
      });
    });
    this.limitationModel = this.getNormalLimitationModel(requiredOperateOfTaTypes);
    return this.limitationModel!;
  }

  isCampaignNeedOtherLimitation () {
    if (!this.campaignBasicFormModel) {
      return true;
    }
    return this.campaignBasicFormModel.limitatoinConfig.supportOtherLimitation;
  }

  processGojekLimitation (limitationSetting: LimitationInventorySettings[]) {
    const gojekLimitationSetting = _.filter(limitationSetting, (inventory) => [
      DEFAULT_INVENTORY,
      'geography',
      'os',
      'device',
      'segment',
      'adx',
      'spaceType',
      'adFormat'
    ].includes(inventory.name))
    .map(inventory => {
      if (inventory.name === 'geography') {
        inventory = {
          ...inventory,
          cb: async () => {
            const countries = await this.adRequestSourceManager.getCountries();
            return _.filter(countries, country => country.value === 'IDN');
          }
        } as DefaultLimitationInventorySettings;
      }
      return inventory;
    });
    return gojekLimitationSetting;
  }

  getNormalLimitationModel (requiredOperateOfTaTypes) {
    if (!this.campaignBasicFormModel) {
      return;
    }

    let limitationSetting = this.campaignBasicFormModel.getLimitationInventorySetting(requiredOperateOfTaTypes, this.campaign!.basic, this.goSegments, this.localeMeta);
    if (this.order.orderType === OrderType.GOJEK) {
      limitationSetting = this.processGojekLimitation(limitationSetting);
    }

    if (!this.normalLimitationModel) {
      this.normalLimitationModel = new DefaultEditLimitationModel(
        limitationSetting,
        this.campaign!.limitations,
        {
          need: [OPERATE.INCLUDE, OPERATE.PREFERRED, OPERATE.NONPREFERRED],
          notNeed: [OPERATE.EXCLUDE],
          other: this.isCampaignNeedOtherLimitation() ? [] : undefined
        },
        this.addonFeatureManager.addonFeature,
        _.partial(this.setShowTAManagement, true),
        {
          channel: _.get(this.l1Object, 'channel', L1ObjectChannel.RTB),
          audienceLowestThreshold: this.audienceLowestThreshold,
          channelTargetingGetter: this.getRtbTargeting,
          campaignDetailDataGetter: () => {
            if (!this.campaignBasicFormModel) {
              return;
            }
            return {
              campaign: this.campaignBasicFormModel.formikValue,
              order: this.order
            };
          }
        },
        this.campaignBasicFormModel.limitatoinConfig.supportAudienceEstimation,
        this.campaignBasicFormModel.limitatoinConfig.requireIncludeLimitation
      );
    }
    this.normalLimitationModel.setLimitationSetting(limitationSetting);
    this.normalLimitationModel.setOperationSet({
      need: [OPERATE.INCLUDE, OPERATE.PREFERRED, OPERATE.NONPREFERRED],
      notNeed: [OPERATE.EXCLUDE],
      other: this.isCampaignNeedOtherLimitation() ? [] : undefined
    });
    this.normalLimitationModel.setSupportAudienceEstimation(this.campaignBasicFormModel.limitatoinConfig.supportAudienceEstimation);
    return this.normalLimitationModel;
  }

  getRtbTargeting = limitationValue => {
    const includeLimitation = defaultTo(limitationValue.include, []);
    const preferredLimitation = defaultTo(limitationValue.preferred, []);
    const includeAgeMin = includeLimitation.find(limitation => limitation.type === 'age_min');
    const ageMinLimitation = includeAgeMin || preferredLimitation.find(limitation => limitation.type === 'age_min');
    const ageMin = get(ageMinLimitation, 'value', 18);
    const includeAgeMax = includeLimitation.find(limitation => limitation.type === 'age_max');
    const ageMaxLimitation = includeAgeMax || preferredLimitation.find(limitation => limitation.type === 'age_max');
    const ageMax = get(ageMaxLimitation, 'value', 61);
    const ageOptValue = includeAgeMin ? 'include' : 'preferred';
    const ages = this.order.orderType === OrderType.TENMAX
      ? getPmax2RtbAgeGroupsByAgeRange(ageMin, ageMax)
      : getPmax3RtbAgeGroupsByAgeRange(ageMin, ageMax);
    const otherLimitations = toServerStructure(limitationValue)
      .filter(limitation => (
          limitation.op === 'inc' && ['gender', 'segment'].includes(limitation.type)
        ) || (
          limitation.op === 'Preferred' && limitation.type === 'gender'
        ) || (limitation.op === 'exc' && limitation.type === 'segment'));
    otherLimitations.push({
      op: ageOptValue === 'include' ? 'inc' : 'Preferred',
      limits: ages,
      isGroup: false,
      type: 'age'
    });
    return otherLimitations;
  }

  onPriceModelChange = (priceModel, currentCampaignBasic) => {
    if (!this.campaignBasicFormModel) {
      return;
    }
    const limitationModel = this.getLimitationModel();
    const optimize = this.campaignBasicFormModel.getDefaultOptimizeType(priceModel);
    const needFrequency = optimize === L2ObjectOptimizationGoal.REACH && this.l1Object.objective === L1ObjectObjective.AWARENESS;
    const optimizeSameAsPriceModel = optimize ? this.manager.checkOptimizeSameAsPriceModel(priceModel, optimize) : false;
    const onlyBidCap =
      (!this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE) && optimizeSameAsPriceModel) ||
      this.campaignBasicFormModel.isOutdoorType;
    const needVideoAdViewObjective = this.campaignBasicFormModel.getVideoAdViewObjectiveOptions(priceModel) !== undefined;
    this.setCampaign({
      basic: {
        ...currentCampaignBasic,
        priceModel,
        optimize,
        orderPrice: undefined,
        bidPrice: undefined,
        bidStrategy: this.l1Object.autoOptimise ?
          this.l1Object.rtb.bid_strategy :
          onlyBidCap ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP,
        frequency: needFrequency ? {
          maxFrequency: 5,
          intervalDays: 1
        } : undefined,
        videoAdViewObjective: needVideoAdViewObjective ?
          currentCampaignBasic.videoAdViewObjective ?
            currentCampaignBasic.videoAdViewObjective : {
              videoAdEvent: VideoAdViewObjective.DEFAULT
            } : undefined
      },
      limitations: limitationModel.limitationValue
    });
  }

  cancel = () => {
    const redirectPath =
      `/orders/${this.order.orderNumber}/campaign-groups/${this.l1Object.l1ObjectId}`;
    this.setRedirectPath(redirectPath);
  }

  isCampaignInvalid () {
    if (this.campaign!.basic.isDraft) {
      return false;
    }
    return (this.campaign!.basic.goGanGroupId !== _.get(this.l1Object, 'rtb.group_id')) || this.campaign!.basic.state === CampaignState.DELETE;
  }

  onUnmount (handler) {
    this.event.remove(handler);
    this.redirectPath = undefined;
    this.campaign = undefined;
    this.rbLimitationModel = undefined;
    this.normalLimitationModel = undefined;
    this.campaignSummaryModel = undefined;
    this.finished = false;
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

abstract class CreateFlowPageModel extends DefaultRtbCampaignSetupFlowPageModel {

  createCampaignFormModels () {
    const params = this.getCampaignFormModelParams();
    this.campaignOneForAllDisplayFormModel = new CreateOneForAllDisplayFormModel(...params);
    this.campaignPilotTVFormModel = new CreatePilotTVFormModel(...params);
    this.campaignDisplayFormModel = new CreateDisplayFormModel(...params);
    this.campaignVideoFormModel = new CreateVideoFormModel(...params);
    this.campaignThirdPartyFormModel = new CreateThirdPartyFormModel(...params);
    this.campaignThirdPartyBottomFormModel = new CreateThirdPartyBottomFormModel(...params);
    this.campaignThirdPartyRectangleFormModel = new CreateThirdPartyRectangleFormModel(...params);
    this.campaignEdiMaxFormModel = new CreateEdiMaxFormModel(...params);
    this.campaignPICShortFormModel = new CreatePICShortFormModel(...params);
    this.campaignPICLongFormModel = new CreatePICLongFormModel(...params);
    this.campaignComboFormModel = new CreateComboFormModel(...params);
    this.campaignRetailFormModel = new CreateRetailFormModel(...params);
    this.campaignUnknowAdTypeFormModel = new CreateUnknowAdTypeFormModel(...params);
    this.campaignCtvFormModel = new CreateCtvFormModel(...params);
    this.campaignPmpPICShortFormModel = new CreatePmpPICShortFormModel(this.pmpListOfOrder, ...params);
    this.campaignPmpPICLongFormModel = new CreatePmpPICLongFormModel(this.pmpListOfOrder, ...params);
  }
}

abstract class CreateFlowFromExistOnePageModel extends CreateFlowPageModel {

  constructor (
    protected modalCampaignId: number,
    ...args: ConstructorParameters<typeof DefaultRtbCampaignSetupFlowPageModel>
  ) {
    super(...args);
    this.modalCampaignId = modalCampaignId;
  }
}

abstract class EditFlowPageModel extends DefaultRtbCampaignSetupFlowPageModel {

  constructor (
    protected modalCampaignId: number,
    ...args: ConstructorParameters<typeof DefaultRtbCampaignSetupFlowPageModel>
  ) {
    super(...args);
    this.modalCampaignId = modalCampaignId;
  }

  createCampaignFormModels () {
    const params = this.getCampaignFormModelParams();
    this.campaignOneForAllDisplayFormModel = new EditOneForAllDisplayFormModel(...params);
    this.campaignPilotTVFormModel = new EditPilotTVFormModel(...params);
    this.campaignDisplayFormModel = new EditDisplayFormModel(...params);
    this.campaignVideoFormModel = new EditVideoFormModel(...params);
    this.campaignThirdPartyFormModel = new EditThirdPartyFormModel(...params);
    this.campaignThirdPartyBottomFormModel = new EditThirdPartyBottomFormModel(...params);
    this.campaignThirdPartyRectangleFormModel = new EditThirdPartyRectangleFormModel(...params);
    this.campaignEdiMaxFormModel = new EditEdiMaxFormModel(...params);
    this.campaignPICShortFormModel = new EditPICShortFormModel(...params);
    this.campaignPICLongFormModel = new EditPICLongFormModel(...params);
    this.campaignComboFormModel = new EditComboFormModel(...params);
    this.campaignRetailFormModel = new EditRetailFormModel(...params);
    this.campaignUnknowAdTypeFormModel = new EditUnknowAdTypeFormModel(...params);
    this.campaignCtvFormModel = new EditCtvFormModel(...params);
    this.campaignPmpPICShortFormModel = new EditPmpPICShortFormModel(this.pmpListOfOrder, ...params);
    this.campaignPmpPICLongFormModel = new EditPmpPICLongFormModel(this.pmpListOfOrder, ...params);
  }

  override isCampaignInvalid () {
    if (this.isPmp) {
      return true;
    }
    return super.isCampaignInvalid();
  }
}

export class CreateRtbCampaignSetupFlowPageModel extends CreateFlowPageModel {

  creativeCreateFlowModel?: CreateCreativeInL2ObjectSetupFlowPageModel;
  creativeCreateEventHandler?: number;

  get type () {
    return 'create';
  }

  get campaignId () {
    return null;
  }

  async init () {
    this.updateState(true);
    try {
      const startDate = this.getCampaignStartDay();
      const endDate = this.getCampaignEndDay();
      this.pmpListOfOrder = (await this.pmpManager.getPmpsOfOrder(this.order.id, [PmpStatus.ACTIVE]))
        .filter(pmp => moment(pmp.startTime).isBefore(endDate) && moment(pmp.endTime).isAfter(startDate));
      await super.init();
    } catch (e) {
      console.error(e);
    }
    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.createCampaignTitle');
  }

  async getCampaign () {
    const orderType: OrderType = this.order.orderType;
    const orderAgeMin: number = (typeof this.order.ageMin === 'string')
     ? defaultTo(_.parseInt(this.order.ageMin), RTBCAMPAIGN_DEFAULT_AGE_MIN)
     : defaultTo(this.order.ageMin, RTBCAMPAIGN_DEFAULT_AGE_MIN);
    const orderAgeMax: number = (typeof this.order.ageMax === 'string')
     ? defaultTo(_.parseInt(this.order.ageMax), RTBCAMPAIGN_DEFAULT_AGE_MAX)
     : defaultTo(this.order.ageMax, RTBCAMPAIGN_DEFAULT_AGE_MAX);
    const orderGender: FinalReportGender = defaultTo(this.order.gender, FinalReportGender.ALL);
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const ageLimitationPresets = [{
      type: 'age_min',
      value: orderAgeMin
    }, {
      type: 'age_max',
      value: orderAgeMax
    }];
    const genderLimitationPresets = orderGender !== FinalReportGender.ALL ? [{
      type: 'gender',
      value: [genderOptions.find(option => option.value === orderGender)]
    }] : [];
    const preferredLimitationPresets = defaultTo(this.limitationPreSet.preferred, []);
    const includeLimitationPresets = defaultTo(this.limitationPreSet.include, []);
    let limitationPreSet = orderType === OrderType.TENMAX ? {
      ...this.limitationPreSet,
      preferred: [
        ...preferredLimitationPresets,
        ...ageLimitationPresets,
        ...genderLimitationPresets
      ]
    } : {
      ...this.limitationPreSet,
      include: [
        ...includeLimitationPresets,
        ...ageLimitationPresets,
        ...genderLimitationPresets
      ]
    };
    const startDate = this.getCampaignStartDay();
    const endDate = this.getCampaignEndDay();
    const scheduleDateCount = endDate.diff(startDate, 'days') + 1;
    const minBudgetOfCampaign = this.order.campaignConstraint.budgetMinimum * scheduleDateCount;
    const parentBidStrategy = _.get(this.l1Object, 'rtb.bid_strategy');
    const defaultRetailer = get(this.retailers, '0');
    const autoOptimise = get(this.l1Object, 'autoOptimise', false);
    const campaign = {
      basic: {
        advertiserId: this.order.advertiserId,
        budget: autoOptimise ?
          minBudgetOfCampaign :
          this.l1Object.budgetBalance,
        checkpoints: [],
        creativeDeliverType: CreativeDeliverType.OPTIMIZE,
        dailyTargetBudget: null,
        tags: [],
        name: this.l1Object.name,
        orderId: this.order.id,
        startDate: startDate.format(dateFormat),
        endDate: endDate.format(dateFormat),
        priceModel: RtbCampaignPlanType.RS,
        optimize: L2ObjectOptimizationGoal.CLICKS,
        enableMonitor: true,
        deliverType: DeliverType.STANDARD,
        convTrackEvent: TrackEvent.CLICK,
        expectedSpent: 0,
        orderPriceEnable: true,
        bidPrice: parentBidStrategy && parentBidStrategy === BidStrategy.LOWEST_COST_WITH_BID_CAP ? 0 : undefined,
        retailType: this.l1Object.channel === L1ObjectChannel.RETAIL_MEDIA && defaultRetailer ? defaultRetailer.id : undefined,
        goGanGroupId: _.get(this.l1Object, 'rtb.group_id')
      },
      limitations: _.cloneDeep(limitationPreSet)
    };

    return campaign;
  }

  getCampaignStartDay () {
    const now = moment()
      .startOf('hour');
    const orderStartDate = moment(this.order.startDate);
    return now.isBefore(orderStartDate) ? orderStartDate : now;
  }

  getCampaignEndDay () {
    return moment.min(
      moment(this.order.endDate)
        .add(1, 'days')
        .subtract(1, 'seconds'),
      this.getCampaignStartDay().add(91, 'days').endOf('day')
    );
  }

  getCreativeSetupFlowModel = () => {
    if (!this.campaignBasicFormModel?.canCreateWithCreative || !this.campaign) {
      return undefined;
    }
    if (
      this.creativeCreateFlowModel &&
      this.creativeCreateFlowModel.campaign.basic.adType === this.campaign.basic.adType
    ) {
      return this.creativeCreateFlowModel;
    }
    this.creativeCreateFlowModel = new CreateCreativeInL2ObjectSetupFlowPageModel(
      false,
      [],
      this.addonFeatureManager,
      {
        type: AdLogoType.DEFAULT
      },
      this.campaign,
      this.order
    );
    this.creativeCreateEventHandler = this.creativeCreateFlowModel.event.add(() => {});
    return this.creativeCreateFlowModel;
  }

  onUnmount (handler) {
    super.onUnmount(handler);
    if (this.creativeCreateFlowModel) {
      this.creativeCreateFlowModel.onUnmount(this.creativeCreateEventHandler);
      this.creativeCreateFlowModel = undefined;
    }
  }
}

export class EditRtbCampaignSetupFlowPageModel extends EditFlowPageModel {

  get type () {
    return 'edit';
  }

  get campaignId () {
    return this.modalCampaignId;
  }

  async getCampaign () {
    return this.manager.getCampaign(this.modalCampaignId);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.editCampaignTitle');
  }
}

export class CopyRtbCampaignSetupFlowPageModel extends CreateFlowFromExistOnePageModel {

  get type () {
    return 'copy';
  }

  get campaignId () {
    return this.modalCampaignId;
  }

  async getCampaign () {
    const campaign = await this.manager.getNoCidCampaign(this.campaignId);
    const oldCampaign = _.cloneDeep(campaign);
    campaign.basic.orderPriceEnable = true;
    campaign.basic.spents = 0;
    campaign.basic.expectedSpent = 0;
    const oldCampaignIsStart = moment().isAfter(oldCampaign.basic.startDate);
    const oldCampaignIsEnd = moment().isAfter(oldCampaign.basic.endDate);
    if (oldCampaignIsStart) {
      campaign.basic.startDate = moment().startOf('hours').format('YYYY-MM-DD HH:mm:ss');
    }
    if (oldCampaignIsEnd) {
      campaign.basic.endDate = moment(this.order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    if (this.l1Object.autoOptimise) {
      campaign.basic.budget = this.manager.getMinBudgetOfCampaign(campaign.basic, this.order.campaignConstraint.budgetMinimum);
    }
    return campaign;
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.copyCampaignTitle');
  }
}

export class SplitRtbCampaignSetupFlowPageModel extends CreateFlowFromExistOnePageModel {

  get type () {
    return 'split';
  }

  get campaignId (): number | null {
    return this.modalCampaignId;
  }

  async getCampaign () {
    const campaign = await this.manager.getNoCidCampaign(this.modalCampaignId);
    const oldCampaign = _.cloneDeep(campaign);

    campaign.basic.orderPriceEnable = true;
    campaign.basic.expectedSpent = 0;
    campaign.basic.spents = 0;
    campaign.basic.state = CampaignState.ACTIVATE;

    const isOldCampaignActive = oldCampaign.basic.state !== CampaignState.DEACTIVATE;
    const isAutoOptimise = this.l1Object.autoOptimise;
    let oldCampaignMinBudget = isOldCampaignActive
      ? Math.max(
          isAutoOptimise
            ? this.manager.getMinBudgetOfCampaign(
                oldCampaign.basic,
                this.order.campaignConstraint.budgetMinimum
              )
            : this.order.campaignConstraint.campaignBudgetMinimum,
          oldCampaign.basic.expectedSpent
        )
      : _.defaultTo(oldCampaign.basic.spents, 0);
    const oldCampaignSpent = isOldCampaignActive ? oldCampaign.basic.expectedSpent : _.defaultTo(oldCampaign.basic.spents, 0);
    const newCampaignBudget = oldCampaignSpent > oldCampaign.basic.budget
      ? 0
      : getPriceValue(this.order.currency, oldCampaign.basic.budget - oldCampaignMinBudget);
    this.l1Object.budgetBalance = newCampaignBudget;
    const oldCampaignIsStart = moment().isAfter(oldCampaign.basic.startDate);
    const oldCampaignIsEnd = moment().isAfter(oldCampaign.basic.endDate);
    if (oldCampaignIsStart) {
      campaign.basic.startDate = moment().startOf('hours').format('YYYY-MM-DD HH:mm:ss');
    }
    if (oldCampaignIsEnd) {
      campaign.basic.endDate = moment(this.order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    campaign.basic.budget = this.l1Object.autoOptimise
      ? this.manager.getMinBudgetOfCampaign(campaign.basic, this.order.campaignConstraint.budgetMinimum)
      : newCampaignBudget;

    return campaign;
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.splitCampaignTitle');
  }
}

export class EditRtbCampaignDraftSetupFlowPageModel extends CreateRtbCampaignSetupFlowPageModel {

  constructor (
    private draftId: string,
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    l1Object: L1Object,
    localeMeta?: LocaleMeta,
    otherCampaignOfL1Object?: RtbCampaignListBasic[],
    private draftManager: DraftManager = new RtbCampaignDraftManager()
  ) {
    super(
      order,
      addonFeatureManager,
      l1Object,
      localeMeta,
      otherCampaignOfL1Object
    );
    this.objectType = 'draft';
  }

  get type () {
    return 'edit';
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.editCampaignDraftTitle');
  }

  async getCampaign () {
    return this.draftManager.getDraft(this.draftId);
  }

  getRtbCampaignSummaryStepModel (
    subStepIndex: number,
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void,
    goSubStep: (subStepIndex: number) => void

  ) {
    if (this.campaignSummaryModel) {
      return this.campaignSummaryModel;
    }
    this.campaignSummaryModel = new RtbCampaignDraftSummaryStepModel(
      this.draftId,
      this,
      subStepIndex,
      goLast,
      goStep,
      goSubStep,
      this.draftManager
    );
    return this.campaignSummaryModel;
  }

  onSaveDraft = async (campaign: RtbCampaign) => {
    this.updateState(true);
    try {
      await this.draftManager.updateDraft(this.draftId, this.manager.prepareCreateCampaignPayload(campaign));
      toast.success(i18n.t<string>('campaignSetupFlow.messages.updateDraftSuccess'));
    } catch (e) {
      toast.error(i18n.t<string>('campaignSetupFlow.messages.updateDraftFailed'));
    }
    this.updateState(false);
  }
}
