import _ from 'lodash';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';

export enum ItemType {
  auto,
  withCategory,
  autoByGroupSetting
}

export type ItemSetting = {
  type: ItemType;
  categorySelectable: boolean;
  i18nPrefix?: string;
  readonly: boolean;
  hideExtra?: boolean;
};

export function defaultItemSetting () {
  return {
    type: ItemType.auto,
    categorySelectable: true,
    readonly: false
  };
}

export interface SelectItemComponentModel {
  readonly event: UpdateEventListener<SelectItemComponentModel>;
  readonly state: SelectItemComponentState;
  readonly data: SelectOptions;
  readonly singleSelection: boolean;
  readonly selectedOptions?: SelectOptions[];
  readonly disabled?: boolean;
  readonly itemSetting: ItemSetting;
  readonly itemHeight: number;
  readonly onSelect: (option: SelectOptions) => void;
  readonly isOptionDisabled: (option: string | number) => boolean;
  readonly isSelected: (option: SelectOptions) => boolean;
  readonly setItemHeight: (height: number) => void;
  readonly toggleOpen: () => void;
  readonly setOpen: (setOpen: boolean) => void;
  readonly updateProps: (disabled, selectedOptions, onSelect) => void;
  setData: (itemData) => void;
}

export type SelectItemComponentProps = {
  readonly model: SelectItemComponentModel;
};

export type SelectItemComponentState = {
  readonly isOpen: boolean;
};

export class DefaultSelectItemComponentModel implements SelectItemComponentModel {
  event: FireableUpdateEventListener<SelectItemComponentModel> =
    new FireableUpdateEventListener<SelectItemComponentModel>();
  data: SelectOptions;
  singleSelection: boolean;
  selectedOptions?: SelectOptions[];
  selectedSubOptions: SelectOptions[];
  disabled?: boolean;
  itemSetting: ItemSetting;
  categorySelected: boolean;
  itemHeight: number = 20;
  onSelect: (SelectOptions) => void;
  isOpen: boolean;

  constructor (
    data: SelectOptions,
    singleSelection: boolean,
    itemSetting: ItemSetting,
    onSelect: (SelectOptions) => void,
    private listRef: { current: null | any },
    disabled?: boolean,
    selectedOptions?: SelectOptions[]
  ) {
    this.data = data;
    this.singleSelection = singleSelection;
    this.selectedOptions = selectedOptions;
    this.itemSetting = itemSetting;
    this.disabled = disabled;
    this.isOpen = false;
    this.onSelect = onSelect;
    this.categorySelected = _.findIndex(this.selectedOptions, { value: this.data.value }) >= 0;
    this.selectedSubOptions = this.data.options ? _.intersectionWith(this.selectedOptions, this.data.options, (selectedOption, itemOption) => {
      return itemOption.value === selectedOption.value;
    }) : [];
  }

  get state () {
    return {
      isOpen: this.isOpen
    };
  }

  setData (data) {
    this.data = data;
  }

  updateProps (disabled, selectedOptions, onSelect) {
    this.disabled = disabled;
    this.selectedOptions = selectedOptions;
    this.onSelect = onSelect;
    this.categorySelected = _.findIndex(this.selectedOptions, { value: this.data.value }) >= 0;
    this.selectedSubOptions = this.data.options ? _.intersectionWith(this.selectedOptions, this.data.options, (selectedOption, itemOption) => {
      return itemOption.value === selectedOption.value;
    }) : [];
  }

  toggleOpen = () => {
    this.isOpen = !this.isOpen;
    this.updateState();
  }

  setOpen = (isOpen) => {
    this.isOpen = isOpen;
    this.updateState();
  }

  setItemHeight (height: number) {
    if (this.itemHeight === height) {
      return;
    }
    this.itemHeight = height;
    this.listRef.current && this.listRef.current.resetAfterIndex(0);
  }

  isOptionDisabled (value) {
    if (this.disabled) {
      return true;
    }

    if (!this.singleSelection) {
      return false;
    }

    if (this.selectedSubOptions.length === 0) {
      return false;
    }

    return _.findIndex(this.selectedSubOptions, { value }) < 0;
  }

  isSelected (option: SelectOptions) {
    if (!this.selectedOptions) {
      return false;
    }

    if (this.categorySelected) {
      return true;
    }

    if (this.data.value === option.value) {
      const allOptionSelected = (
        !!this.data.options
        && this.data.options.length > 0
        && this.selectedSubOptions.length === this.data.options.length
      );
      return allOptionSelected;
    }

    return _.findIndex(
        this.selectedOptions,
        { value: option.value }
      ) >= 0;
  }

  updateState () {
    this.event.fireEvent(this);
  }
}
