import { toast } from 'react-toastify';
import { AbstractAuditLogModel } from './AuditLogModel';
import _ from 'lodash';
import { renderColumn } from 'components/TableColumn/TableColumn';
import formatters from './AuditLogFormatters';
import { RtbCampaignListBasic } from 'core/rtbCampaign/RtbCampaign';
import { AUDITLOG_SRC, AuditLogRecord } from 'core/auditLog/AuditLog';
import i18n from 'i18n';
import { AuditLogManager, DefaultAuditLogManager } from 'core/auditLog/AuditLogManager';
import { ColumnDescription, ExpandRowProps } from 'react-bootstrap-table-next';

export enum AUDITLOG_COLUMN {
  DATE = 'ctime',
  SOURCE_INFO = 'srcID',
  EMAIL = 'email',
  EVENT = 'funcType',
  DETAIL = 'msg'
}

export type AuditLogListRow = {
  id: number,
  [AUDITLOG_COLUMN.DETAIL]: { [key: string]: {
    adminOnly?: boolean,
    org: string,
    to: string
  } }[],
  [AUDITLOG_COLUMN.DATE]: string;
  [AUDITLOG_COLUMN.SOURCE_INFO]: {
    id: number;
    type: AUDITLOG_SRC;
    name: string
  },
  [AUDITLOG_COLUMN.EMAIL]: string;
  [AUDITLOG_COLUMN.EVENT]: string;
};

export class CampaignAuditLogModel extends AbstractAuditLogModel<AuditLogListRow> {

  nonExpandRowKey: number[] = [];
  campaignTarget?: number | string;

  constructor (
    private isAdmin: boolean,
    public campaignList: RtbCampaignListBasic[],
    auditLogManager: AuditLogManager = new DefaultAuditLogManager()
  ) {
    super(auditLogManager);
  }

  getSearchString () {
    let searchString = super.getSearchString();
    const campaignTarget = this.campaignTarget !== 0 && this.campaignTarget !== undefined ?
      this.campaignTarget :
      this.campaignList.map(campaign => campaign.id).join(',');

    if (!_.isEmpty(searchString)) {
      return `${searchString}&campaignIds=${campaignTarget}`;
    }
    return `campaignIds=${campaignTarget}`;
  }

  getIgnoreFields () {
    return [];
  }

  getAuditLogDetail = (messages) => {
    return messages && Array.isArray(messages) ?
      messages.filter(msg => {
        const values: any = _.defaultTo(Object.values(msg), []);
        if (values.length === 0) return true;
        const content = values[0];
        return content.adminOnly ? content.adminOnly && this.isAdmin : true;
      }) :
      messages;
  }

  getAuditLogDataOfRecord = (record: AuditLogRecord) => {
    if (!record.msg || record.msg.length === 0) {
      this.nonExpandRowKey.push(record.id);
    }
    let srcName = '';
    if (record.srcType === AUDITLOG_SRC.CAMPAIGN) {
      const srcCampaign = this.campaignList.find(campaign => campaign.id === record.srcID);
      srcName = _.get(srcCampaign, 'name', '');
    }
    return {
      id: record.id,
      [AUDITLOG_COLUMN.DETAIL]: this.getAuditLogDetail(record.msg),
      [AUDITLOG_COLUMN.DATE]: record.ctime,
      [AUDITLOG_COLUMN.SOURCE_INFO]: {
        id: record.srcID,
        type: record.srcType,
        name: srcName
      },
      [AUDITLOG_COLUMN.EMAIL]: record.email,
      [AUDITLOG_COLUMN.EVENT]: record.funcType
    };
  }

  async initAuditLog (page: number = 1) {
    try {
      const pageable = { page: page, sizePerPage: 10, sort: this.sortField === AUDITLOG_COLUMN.EMAIL ? 'userId' : this.sortField , direction: this.sortOrder };
      const auditLogFromServer = await this.auditLogManager.getCampaignAuditLog(pageable, this.getSearchString());
      this.pagination = auditLogFromServer.pagination;
      this.nonExpandRowKey = [];
      this.auditLogData = auditLogFromServer.records.map(this.getAuditLogDataOfRecord);
      this.updateFilteredAuditLogData();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.auditLogData = [];
    }
  }

  async getAuditLogFields () {
    try {
      this.auditLogFields = await this.auditLogManager.getCampaignAuditLogFields(this.campaignTarget ? [this.campaignTarget] : this.campaignList.map(campaign => campaign.id!));
      this.auditLogFields.sort();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
  }

  updateFilteredAuditLogData () {
    this.filteredAuditLogData = this.auditLogData ? this.auditLogData : [];
    const campaigntarget = this.campaignTarget;
    if (campaigntarget) {
      this.filteredAuditLogData = this.filteredAuditLogData.filter(data => {
        return data.srcID.id === campaigntarget && data.srcID.type === AUDITLOG_SRC.CAMPAIGN;
      });
    }

    return this.filteredAuditLogData;
  }

  getColumnFormatter (columnName) {
    switch (columnName) {
      case AUDITLOG_COLUMN.SOURCE_INFO:
        return formatters.srcInfoFormatter;
      case AUDITLOG_COLUMN.EVENT:
        return formatters.i18nFormatter;
      default:
        return;
    }
  }

  getSubFilters (): {
    [key: string]: {
      options: SelectOptions[],
      value?: string | number,
      onSelect: (campaignTarget: string | number) => void;
    }} {
    return {
      campaignFilter: {
        options: this.getCampaignOptions(),
        value: this.campaignTarget,
        onSelect: this.setCampaignTarget
      }
    };
  }

  setCampaignTarget = (campaignTarget) => {
    this.campaignTarget = campaignTarget;
    this.init(1, true);
  }

  getCampaignOptions (): SelectOptions[] {
    let options = this.campaignList.map(campaign => ({
      label: `${campaign.id}:${campaign.name}`,
      value: campaign.id ? campaign.id : 0
    }));
    options.unshift({
      label: i18n.t<string>('common.placeholder.all'),
      value: 0
    });
    return options;
  }

  getColumns (): ColumnDescription<AuditLogListRow>[] {
    const definitions = Object.values(AUDITLOG_COLUMN).map(columnToRender => {
      if (columnToRender === AUDITLOG_COLUMN.DETAIL) {
        return undefined;
      }
      return renderColumn(this.getAuditLogColumnDefinition(columnToRender), this.getColumnFormatter(columnToRender));
    });
    return _.compact(definitions);
  }

  getExpandRenderer (): ExpandRowProps<AuditLogListRow, number> {
    return {
      nonExpandable: this.nonExpandRowKey,
      renderer: formatters.detailExpandRenderer
    };
  }
}
