import _ from 'lodash';
import client from './RestClient';
import { Agency, AgencyDetail, AgencyWithPagination } from 'core/agency/Agency';
import { CompanyMember, Account } from 'core';
import { AdLogo, AdLogoType } from 'core/adLogo/AdLogo';
import moment from 'moment';
import { CloudStorageManager, DefaultCloudStorageManager } from 'core/cloudStorage/CloudStorageManager';
import { defaultCampaignBidPrice } from 'containers/Agencies/AgencyForm/defaultCampaignBidPrice';
import { AxiosInstance } from 'axios';
import { Pagination } from 'core/pagination/Pagination';
import { Pageable } from './Pageable';

export interface AgencyWebService {
  getAgencies (addonFeature?: string): Promise<Array<Agency>>;
  getAgency (agencyId: number): Promise<Agency>;
  getAgenciesWithPagination (pageable: Pageable, searchString: string): Promise<AgencyWithPagination>;
  getCreditLog (agencyId: number): Promise<any>;
  getBill (agencyId: number): Promise<any>;
  updateAgency (request: AgencyDetail): Promise<number>;
  updateAgencySelf (agencyId: number, request: {
    agcPercent: number,
    adLogo: AdLogo,
    logoData: {
      url?: string;
      file?: any;
    }
  }): Promise<number>;
  updateAgencyMargin (agencyId: number, agcPercent: number): Promise<void>;
  createAgency (request: AgencyDetail): Promise<number>;
  getAgencyMember (agencyId: number): Promise<Array<CompanyMember>>;
  getAgenciesOptions (addonFeature?: string, extraFields?: string[], allAddonEnable?: boolean): Promise<Array<SelectOptions>>;
  getOemOptions (): Promise<Array<SelectOptions>>;
  updateRebate (agencyId: number, rebateData: {
    id: string | number;
    effectDate: string;
    rate: string | number;
  }): Promise<void>;
  payBill (billId: number): Promise<void>;
  getAdLogo (agencyId: number): Promise<AdLogo>;
  getAgencyName (agencyId: number): Promise<string | null>;
}

function wrapAgencyDetail (json: any): AgencyDetail {
  let addonProps = _.get(json, 'property.addonProps', _.get(json, 'addonProps', {}));
  if (!addonProps.campaignBidPrice || addonProps.campaignBidPrice.length === 0 || Array.isArray(addonProps.campaignBidPrice[0].autoBidCap)) {
    addonProps = {
      ...addonProps,
      campaignBidPrice: _.cloneDeep(defaultCampaignBidPrice)
    };
  } else {
    const bidPriceSettings = addonProps.campaignBidPrice.map(bidPriceSetting => {
      if (bidPriceSetting.type === 'OUTDOOR') {
        return {
          ...bidPriceSetting,
          type: 'EDIMAX'
        };
      }
      return bidPriceSetting;
    });

    const mergedCampaignBidPrice = defaultCampaignBidPrice.map((item) => {
      const found = bidPriceSettings.find((addonItem) => addonItem.type === item.type);
      if (found) {
        return {
          ...item,
          autoBidCap: {
            ...item.autoBidCap,
            ...found.autoBidCap
          },
          bidFloor: {
            ...item.bidFloor,
            ...found.bidFloor
          }
        };
      }
      return _.cloneDeep(item);
    });

    addonProps = {
      ...addonProps,
      campaignBidPrice: mergedCampaignBidPrice
    };
  }
  return {
    ...json,
    agencyId: _.get(json, 'id', _.get(json, 'agency_id')),
    companyName: _.get(json, 'companyName', _.get(json, 'com_name')),
    comComment: _.get(json, 'comComment', ''),
    limitPublisherCountry: _.get(json, 'limitPublisherCountry', []),
    limitAdx: _.get(json, 'limitAdx', []),
    defaultCountry: _.get(json, 'defaultCountry', []),
    defaultSpace: _.get(json, 'defaultSpace', []),
    segmentLabel: _.get(json, 'segmentLabel', []),
    segmentCountry: _.get(json, 'segmentCountry', []),
    addonProps,
    targetBudgetMinimum: _.get(json, 'property.targetBudgetMinimum', 0),
    campaignBudgetMinimum: _.get(json, 'property.campaignBudgetMinimum', 0),
    adLogo: wrapAdLogo(_.get(json, 'adLogo')),
    logoData: wrapLogoData(_.get(json, 'logoUrl', ''))
  };
}

function wrapAdLogo (json: any) {
  return {
    type: _.get(json, 'type', AdLogoType.NULL),
    link: _.get(json, 'link'),
    image: _.get(json, 'imgUrl') ? {
      url: _.get(json, 'imgUrl')
    } : undefined
  };
}

function wrapLogoData (logoUrl: string) {
  return {
    url: logoUrl
  };
}

function wrapCompanyMember (json: any): CompanyMember {
  return {
    id: _.get(json, 'id'),
    account: wrapAccount(_.get(json, 'account')),
    roleName: _.get(json, 'roleName')
  };
}

function wrapAccount (json: any): Account {
  return {
    language: _.get(json, 'language'),
    activated: _.get(json, 'activated'),
    email: _.get(json, 'email'),
    name: _.get(json, 'name'),
    id: _.get(json, 'accountId'),
    isAdmin: _.get(json, 'isAdmin'),
    isGoJekAccountManager: _.get(json, 'isGoJekAccountManager', false),
    status: _.get(json, 'status'),
    actors: []
  };
}

function wrapAgency (json: any): Agency {
  return {
    id: _.get(json, 'id', _.get(json, 'agency_id')),
    name: _.get(json, 'companyName', _.get(json, 'com_name', '')),
    currency: _.get(json, 'currency'),
    timeZone: _.get(json, 'timeZone'),
    isOEMAgency: _.get(json, 'oem', _.get(json, 'is_oem', 0)) === 1,
    loginTitle: _.get(json, 'loginTitle', _.get(json, 'login_title')),
    loginDomain: _.get(json, 'loginDomain', _.get(json, 'login_domain')),
    addOnFeatures: _.get(
      json,
      'addonFeatures',
      _.get(json, 'addon_features', {})
    ),
    detail: wrapAgencyDetail(_.get(json, 'agencyDetail', {})),
    rebate: wrapRebate(_.get(json, 'rebate', {}))
  };
}

function wrapRebate (json) {
  const effectDate = _.get(json, 'effectDate');
  return {
    currentRate: _.get(json, 'currentRate', 0),
    futureRate: _.get(json, 'futureRate'),
    effectDate: effectDate ? moment(effectDate).format('YYYY-MM-DD') : effectDate
  };
}

function wrapAgencyOptions (json, extraFields?: string[]) {
  let extra;
  if (extraFields) {
    extra = extraFields.reduce((acc, field) => {
      acc[field] = _.get(json, field);
      return acc;
    }, {});
  }
  return {
    value: _.get(json, 'agency_id'),
    label: _.get(json, 'com_name'),
    extra
  };
}

function wrapPagination (json: any): Pagination {
  return {
    page: _.get(json, 'page', 1),
    size: _.get(json, 'size', 10),
    totalCount: _.get(json, 'totalCount', 0)
  };
}

export class RestfulAgencyWebService implements AgencyWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client, private cloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager()) {
    this.restClient = restClient;
  }

  async getAgencies (addonFeature?: string): Promise<Array<Agency>> {
    if (addonFeature) {
      const response = await this.restClient.get(`/agencies?addonFeature=${addonFeature}`);
      return _.defaultTo(response.data, []).map(json => wrapAgencyDetail(json));
    }
    const response = await this.restClient.get('/v2/agencies');
    return _.defaultTo(response.data, []).flatMap((json: any) =>
      wrapAgency(json)
    );
  }

  async getAgenciesWithPagination (pageable: Pageable, searchString: string): Promise<AgencyWithPagination> {
    let { page, sizePerPage, sort, direction } = pageable;
    const response = await this.restClient.get(`/v2/agencies-pagination?page=${page}&size=${sizePerPage}&sort=${sort},${direction}&search=${searchString}`);
    return {
      agencies: _.defaultTo(response.data.records, []).flatMap((json: any) => wrapAgency(json)),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async getAgency (agencyId: number): Promise<Agency> {
    const response = await this.restClient.get(`/v2/agencies/${agencyId}`);
    return wrapAgency(response.data);
  }

  async updateAgency (request: AgencyDetail): Promise<number> {
    const body = await this.prepareBody(request, request.agencyId);
    const response = await this.restClient.put('/v2/agencies', body);
    return _.get(response.data, ['id'], 0);
  }

  async updateAgencySelf (agencyId: number, request: {
    agcPercent: number,
    adLogo: AdLogo,
    logoData: {
      url?: string;
      file?: any;
    }
  }): Promise<number> {
    const finalRequest = {
      agcPercent: request.agcPercent
    };
    const adLogoData = this.getAdLogoData(request.adLogo);
    adLogoData && _.set(finalRequest, 'adLogo', adLogoData);
    if (request.logoData.file) {
      const url = await this.cloudStorageManager.uploadFileToCloud(request.logoData.file);
      _.set(finalRequest, 'logoUrl', url);
    } else {
      _.set(finalRequest, 'logoUrl', request.logoData.url);
    }
    const response = await this.restClient.put(`/v2/agencies/${agencyId}`, finalRequest);
    return _.get(response.data, ['id'], 0);
  }

  async updateAgencyMargin (
    agencyId: number,
    agcPercent: number
  ): Promise<void> {
    await this.restClient.put(
      `/v2/agencies/${agencyId}/margin?value=${agcPercent}`
    );
  }

  async createAgency (request: AgencyDetail): Promise<number> {
    const body = await this.prepareBody(request, null);
    const response = await this.restClient.post('/v2/agencies', body);
    return _.get(response.data, ['id'], 0);
  }

  async updateRebate (agencyId: number, rebateData: {
    id: string | number;
    effectDate: string;
    rate: string | number;
  }): Promise<void> {
    return this.restClient.post(`/agency/${agencyId}/rebateRate`, rebateData);
  }

  getAdLogoData (adLogoData) {
    if (adLogoData.type === AdLogoType.CUSTOM) {
      return {
        type: adLogoData.type,
        link: adLogoData.link,
        imgUrl: _.get(adLogoData, 'image.url'),
        imgBase64: _.get(adLogoData, 'image.imgBase64')
      };
    }

    return {
      type: adLogoData.type
    };
  }

  async prepareBody (request: AgencyDetail, agencyId) {
    const { oem, interstitial } = request;
    const realRequest = _.omit(request, ['agencyId', 'id', 'adLogo', 'logoData', 'addonProps', 'currentRate', 'futureRate', 'effectDate', 'currentRebateID', 'futureRebateID', 'targetBudgetMinimum', 'campaignBudgetMinimum']);
    const adLogoData = this.getAdLogoData(request.adLogo);
    const finalRequest = {
      ...realRequest,
      interstitial: ~~interstitial,
      oem: ~~oem,
      id: agencyId,
      property: {
        addonProps: request.addonProps,
        targetBudgetMinimum: request.targetBudgetMinimum,
        campaignBudgetMinimum: request.campaignBudgetMinimum
      }
    };
    adLogoData && _.set(finalRequest, 'adLogo', adLogoData);
    if (request.logoData) {
      if (request.logoData.file) {
        const url = await this.cloudStorageManager.uploadFileToCloud(request.logoData.file);
        _.set(finalRequest, 'logoUrl', url);
      } else {
        _.set(finalRequest, 'logoUrl', request.logoData.url);
      }
    }
    return finalRequest;
  }

  async getAgencyMember (agencyId: number): Promise<Array<CompanyMember>> {
    const response = await this.restClient.get(
      `/v2/agencies/${agencyId}/members`
    );
    return _.defaultTo(response.data.records, []).flatMap((json: any) =>
      wrapCompanyMember(json)
    );
  }

  async getAgenciesOptions (addonFeature?: string, extraFields?: string[], allAddonEnable: boolean = true): Promise<Array<SelectOptions>> {
    if (addonFeature) {
      const response = await this.restClient.get(`/v2/agencies?addonFeature=${addonFeature}&allAddonEnable=${allAddonEnable}`);
      return _.get(response, 'data', []).map(json => wrapAgencyOptions(json, extraFields));
    }
    const response = await this.restClient.get('/v2/agencies/options');
    return _.get(response, 'data.records', []);
  }

  async getOemOptions (): Promise<Array<SelectOptions>> {
    const response = await this.restClient.get('/v2/agencies/oem/options');
    return _.get(response, 'data.records', []);
  }

  async getCreditLog (agencyId: number): Promise<any> {
    const response = await this.restClient.get(`/credit?agencyId=${agencyId}`);
    return _.get(response, 'data', []);
  }

  async getBill (agencyId: number): Promise<any> {
    const response = await this.restClient.get(`/agencyBill?agencyId=${agencyId}`);
    return _.get(response, 'data', []);
  }

  async payBill (billId: number): Promise<void> {
    return this.restClient.post(`/agencyBill/${billId}/pay`);
  }

  async getAdLogo (agencyId: number): Promise<AdLogo> {
    const response = await this.restClient.get(`/v2/agencies/${agencyId}/adLogo`);
    const data = response.data;
    if (!data) {
      return {
        type: AdLogoType.DEFAULT
      };
    }
    return {
      type: data.withLogo ?
        data.link ?
          AdLogoType.CUSTOM :
          AdLogoType.DEFAULT :
        AdLogoType.NULL,
      link: data.link,
      image: {
        url: data.imgUrl
      }
    };
  }

  async getAgencyName (agencyId: number): Promise<string | null> {
    const response = await this.restClient.get(`/v2/agencies/${agencyId}/name`);
    return response.data.result;
  }
}
