import { FormikField } from 'components/common/form/field/FormikField';
import React from 'react';
import i18n from 'i18n';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { FinalReportTargetType, GojekFinalReportProjectType } from 'core/order/Order';
import moment from 'moment';
import styles from './orderForm.module.scss';

const finalReportTargetTypeOptions: SelectOptions[] = [{
  label: i18n.t<string>('order.finalReport.targetType.impression'),
  value: FinalReportTargetType.IMPRESSION
}];

const finalReportProjectTypeOptions: SelectOptions[] = createSelectOptionsFromEnum(GojekFinalReportProjectType, 'finalReport.enums.');

export const GojekFinalReportSection: React.FC<{
  orderEndDate: string,
  finalReportProjectType?: GojekFinalReportProjectType,
  finalReportProjectName?: string,
  isFinalReportProjectNameExist: (projectName: string) => boolean

}> = ({
  orderEndDate,
  finalReportProjectType,
  finalReportProjectName,
  isFinalReportProjectNameExist
}) => {

  const renderProjectNameHint = (projectName?: string) => {
    if (!projectName || projectName === '') {
      return;
    }

    return isFinalReportProjectNameExist(projectName) ? (
      <span className={styles.warning}>
        {i18n.t<string>('orderForm.hints.finalReport.projectName')}
      </span>
    ) : '';
  };

  const renderNotSupportFinalReportHint = () => (
    <div>
      Final Reports are not supported by legacy ad orders. Please create a new Ad Order.
    </div>
  );

  return (
    finalReportProjectType ?
      <>
        <FormikField.Input
          label={i18n.t<string>('orderForm.labels.finalReport.advertiserName')}
          name='finalReportAdvertiserName'
        />
        <FormikField.Select
          label={i18n.t<string>('orderForm.labels.finalReport.projectType')}
          name='finalReportProjectType'
          simpleValue
          options={finalReportProjectTypeOptions}
        />
        {finalReportProjectType !== GojekFinalReportProjectType.OTHERS &&
          <FormikField.Input
            label={i18n.t<string>('orderForm.labels.finalReport.projectName')}
            name='finalReportProjectName'
            hint={renderProjectNameHint(finalReportProjectName)}
          />
        }
        <FormikField.Select
          label={i18n.t<string>('orderForm.labels.finalReport.targetType')}
          name='finalReportTargetType'
          simpleValue
          options={finalReportTargetTypeOptions}
        />
        <FormikField.Input
          label={i18n.t<string>('orderForm.labels.finalReport.targetValue')}
          name='finalReportTargetValue'
          type='number'
        />
        <FormikField.Tags
          label={i18n.t<string>('orderForm.labels.finalReport.receivers')}
          name='finalReportReceivers'
          placeholder={i18n.t<string>('orderForm.placeholders.finalReport.receivers')}
        />
        <FormikField.DatePicker
          formGroupClassName='finalReportSendOutDate'
          label={i18n.t<string>('orderForm.labels.finalReport.sendOutDate')}
          name='finalReportSendOutDate'
          showTimePicker={false}
          minDate={moment(orderEndDate).startOf('day').add(2, 'day').format('YYYY-MM-DD')}
        />
      </> :
      <FormikField.Custom
        label=''
        render={renderNotSupportFinalReportHint}
      />
  );
};
