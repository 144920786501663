import { Modal } from 'components/common/Modal/Modal';
import React, { useMemo, useState } from 'react';
import i18n from 'i18n';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'components/common/Select/Select';
import styles from './savedTAManagement.module.scss';
import { SavedTargeting } from 'core/limitation/Limitation';
import { DefaultLimitationManager, LimitationManager } from 'core/limitation/LimitationManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import SummaryDetail from 'components/SummaryDetail/SummaryDetail';
import { wrapLimitationsFromServer } from 'utils/LimitationUtil';
import { toast } from 'react-toastify';

const defaultLimitationManager: LimitationManager = new DefaultLimitationManager();

export const SavedTAManagement: React.FC<{
  onImport: (appliedSavedTAInfo: SelectOptions, newLimitationValue: any) => void;
  onDelete: (deletedTAId: number) => void;
  onClose: () => void;
  getSummaryData (targeting): any;
  savedTAList: SavedTargeting[];
  limitationManager?: LimitationManager;
}> = ({
  onImport,
  onDelete,
  onClose,
  getSummaryData,
  savedTAList,
  limitationManager = defaultLimitationManager
}) => {

  const { loading, callAPIs } = useCallAPI();
  const [selectedTA, setSelectedTA] = useState<number | null>(null);
  const [showOutdatedHint, setShowOutdateHint] = useState(false);
  const [dialogData, setDialogData] = useState<{
    title: string,
    message: string,
    primaryBtnData: {
      title: string,
      callback: () => void
    }
  }>();

  const limitationValue = useMemo(() => {
    const savedTA = savedTAList.find(ta => ta.id === selectedTA);
    if (savedTA) {
      setShowOutdateHint(savedTA.version < savedTA.accessMinVersion);
      return wrapLimitationsFromServer(savedTA.targeting);
    }
    return undefined;
  }, [savedTAList, selectedTA]);

  const closeDialog = () => setDialogData(undefined);

  const onImportBtnClicked = () => {
    const savedTA = savedTAList.find(ta => ta.id === selectedTA);
    if (!savedTA) {
      return;
    }
    setDialogData({
      title: i18n.t<string>('savedTAManagement.titles.import'),
      message: i18n.t<string>('savedTAManagement.messages.import'),
      primaryBtnData: {
        title: i18n.t<string>('common.buttons.confirm'),
        callback: () => {
          closeDialog();
          onImport({
            label: savedTA.name,
            value: savedTA.id
          }, limitationValue);
          onClose();
        }
      }
    });
  };

  const onDeleteBtnClicked = () => {
    if (!selectedTA) {
      return;
    }
    setDialogData({
      title: i18n.t<string>('savedTAManagement.titles.delete'),
      message: i18n.t<string>('savedTAManagement.messages.delete'),
      primaryBtnData: {
        title: i18n.t<string>('common.buttons.delete'),
        callback: () => {
          callAPIs([() => limitationManager.deleteSavedTargeting(selectedTA)], () => {
            toast.success(i18n.t<string>('savedTAManagement.messages.deleteSuccess'));
            closeDialog();
            setSelectedTA(null);
            onDelete(selectedTA);
          }, () => {
            toast.error(i18n.t<string>('savedTAManagement.messages.deleteFailed'));
            closeDialog();
          });
        }
      }
    });
  };

  const taOptions: SelectOptions[] = useMemo(() => {
    return savedTAList.map(savedTA => ({
      label: savedTA.name,
      value: savedTA.id
    }));
  }, [savedTAList]);

  const renderSummaryDetails = (data) => {
    return Object.keys(data).map((key) =>
      data[key] ?
      <SummaryDetail
        key={key}
        title={data[key].title}
        data={data[key].content}
        prefixColor={data[key].titlePrefixColor}
      /> : <div key={key}/>
    );
  };

  return (
    <Modal
      fullScreen={true}
      animation={false}
      title={i18n.t<string>('savedTAManagement.labels.title')}
      primaryButton={{
        title: i18n.t<string>('savedTAManagement.buttons.import'),
        callback: onImportBtnClicked,
        disabled: !selectedTA || showOutdatedHint
      }}
      secondaryButton={{
        title: i18n.t<string>('common.buttons.cancel'),
        callback: onClose
      }}
      dangerButton={{
        title: i18n.t<string>('savedTAManagement.buttons.delete'),
        callback: onDeleteBtnClicked,
        disabled: !selectedTA
      }}
      dismiss={onClose}
    >
      <div className={styles.savedTAManagement}>
        {loading && <LoadingIndicator/>}
        {dialogData &&
          <Modal
            title={dialogData.title}
            dismiss={closeDialog}
            primaryButton={{ ...dialogData.primaryBtnData }}
            secondaryButton={{
              title: i18n.t<string>('common.buttons.cancel'),
              callback: closeDialog
            }}
          >
            {dialogData.message}
          </Modal>
        }
        <Form.Group as={Row}>
          <Col sm={3}>
            <Form.Label column>
              {i18n.t<string>('savedTAManagement.labels.taOptions')}
            </Form.Label>
          </Col>
          <Col sm={9}>
            <Select
              options={taOptions}
              name='taOptions'
              simpleValue
              value={selectedTA}
              onChange={setSelectedTA}
              placeholder={i18n.t<string>('savedTAManagement.placeholders.taOptions')}
            />
          </Col>
        </Form.Group>
        {
          limitationValue &&
            <div className={styles.taDetail}>
              {showOutdatedHint &&
                <div className={styles.outDateHint}>
                  {i18n.t<string>('savedTAManagement.messages.outdate')}
                </div>
              }
              {renderSummaryDetails(getSummaryData(limitationValue))}
            </div>
        }
      </div>
    </Modal>
  );
};
