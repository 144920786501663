import React from 'react';
import { AlertDialog } from 'components/AlertDialog';
import {
  ProgressBar,
  Breadcrumb,
  Alert,
  Badge,
  Button,
  Form,
  Col,
  Row,
  Tab,
  Tabs,
  Pagination,
  Nav
} from 'react-bootstrap';
import './StylePage.scss';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Link } from 'react-router-dom';
import { SearchBar } from 'components/common/SearchBar';
import Toggle from 'components/common/Toggle/Toggle';
import Tags from 'components/common/Tags/Tags';

type StylePageState = {
  readonly loading: boolean;
  readonly modalShown: boolean;
  readonly toggleValue: boolean;
  readonly largeToggleValue: boolean;
  readonly tagsValue: SelectOptions[];
};

type StylePageProps = {};

export class StylePage extends React.Component<StylePageProps, StylePageState> {
  constructor (props: any) {
    super(props);
    this.state = {
      loading: false,
      modalShown: false,
      toggleValue: false,
      largeToggleValue: false,
      tagsValue: [
        {
          label: 'TenMax',
          value: 'TenMaxxxxx'
        },
        {
          label: 'Cacafly',
          value: 'Cacaflyxxxx'
        },
        {
          label: 'AdHub',
          value: 'AdHubxxxx'
        }
      ]
      // tagsValue: SelectOptionsUtils.createSelectOptions([
      //   'TenMax',
      //   'Cacafly',
      //   'AdHub'
      // ])
    };
    this.handleClickToggle = this.handleClickToggle.bind(this);
    this.handleChangeTags = this.handleChangeTags.bind(this);
  }

  showModal = () => {
    this.setState({
      modalShown: true
    });
  }

  showSpinner = () => {
    this.setState({
      loading: true
    });
    window.setTimeout(() => {
      this.hideSpinner();
    }, 4000);
  }

  hideModal = () => {
    this.setState({
      modalShown: false
    });
  }

  hideSpinner = () => {
    this.setState({
      loading: false
    });
  }

  handleClickToggle = () => {
    const { toggleValue } = this.state;
    this.setState({
      toggleValue: !toggleValue
    });
  }

  handleClickLargeToggle = () => {
    const { largeToggleValue } = this.state;
    this.setState({
      largeToggleValue: !largeToggleValue
    });
  }

  handleChangeTags = tagsValue => {
    this.setState({
      tagsValue
    });
  }

  render () {
    return (
      <div className='style-page'>
        <Breadcrumb>
          <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
          <Breadcrumb.Item href='/styles'>Styles</Breadcrumb.Item>
          <Breadcrumb.Item active>All</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs defaultActiveKey='font' id='style-examples'>
          <Tab eventKey='color' title='Colors'>
            <div className='demo-panel'>
              <h4>Theme Colors</h4>
              <span className='color-palette main theme1'>01</span>
              <span className='color-palette secondary theme2'>02</span>
              <span className='color-palette secondary theme3'>03</span>
              <span className='color-palette secondary theme4'>04</span>
              <span className='color-palette secondary theme5'>05</span>
              <h4>Primary Colors</h4>
              <span className='color-palette main primary1'>01</span>
              <span className='color-palette secondary primary2'>02</span>
              <span className='color-palette secondary primary3'>03</span>
              <span className='color-palette secondary primary4'>04</span>
              <span className='color-palette secondary primary5'>05</span>
              <h4>Font Colors</h4>
              <span className='color-palette main black'>Black</span>
              <span className='color-palette main dark'>Dark</span>
              <span className='color-palette main light'>Light</span>
              <span className='color-palette main super-light'>
                Super light
              </span>
              <h4>Status Colors</h4>
              <span className='color-palette secondary danger' />
              <span className='color-palette secondary light-danger' />
              <span className='color-palette secondary' />
              <span className='color-palette secondary success' />
              <span className='color-palette secondary light-success' />
              <span className='color-palette secondary' />
              <span className='color-palette secondary warning' />
              <span className='color-palette secondary light-warning' />
              <span className='color-palette secondary' />
              <span className='color-palette secondary info' />
              <span className='color-palette secondary light-info' />
              <div className='note'>
                Note: all these colors are defined as variables in colors.scss
              </div>
            </div>
          </Tab>
          <Tab eventKey='font' title='Fonts'>
            <div className='demo-panel'>
              <h4>Fonts</h4>
              <div className='font-sample body-font'>
                body-font: Noto Sans TC, font-size: 14px, line-height: 22px
              </div>
              <div className='font-sample headline-font'>
                headline-font: Noto Sans TC, font-size: 20px, line-height: 24px
              </div>
              <div className='font-sample subheading-font'>
                subheading-font: Noto Sans TC, font-size: 17px, line-height:
                20px
              </div>
              <div className='font-sample button-font'>
                button-font: Noto Sans TC, font-size: 14px, line-height: 14px
              </div>
              <div className='font-sample caption-font'>
                caption-font: Noto Sans TC, font-size: 12px, line-height: 14px
              </div>
              <div className='font-sample text'>
                text: Noto Sans TC, font-size: 16px, weight: normal,
                letter-spacing: 0.74px
              </div>
              <h4>Fonts with color</h4>
              <div className='font-sample body-link'>
                body-link: @include body-font, weight: 300, color: $theme1
              </div>
              <div className='font-sample body-link-hover'>
                body-link-hover: @include body-font, weight: 300, color: $theme2
              </div>
              <div className='font-sample body-black'>
                body-black: @include body-font, color: $black
              </div>
              <div className='font-sample body-black-medium'>
                body-black-medium: @include body-font, weight: medium, color:
                $black
              </div>
              <div className='font-sample body-dark'>
                body-dark: @include body-font, color: $dark
              </div>
              <div className='font-sample body-dark-medium'>
                body-dark-medium: @include body-font, weight: medium, color:
                $dark
              </div>
              <div className='font-sample body-super-light'>
                body-super-light: @include body-font, color: $super-light
              </div>
              <div className='font-sample headline-black'>
                headline-black: @include headline-font, weight: medium, color:
                $black
              </div>
              <div className='font-sample subheading-dark'>
                subheading-dark: @include subheading-font, weight: medium,
                color: $dark
              </div>
              <div className='font-sample subheading-success'>
                subheading-success: @include subheading-font, weight: medium,
                color: $success
              </div>
              <div className='font-sample subheading-danger'>
                subheading-danger: @include subheading-font, weight: medium,
                color: $danger
              </div>
              <div className='font-sample subheading-warning'>
                subheading-warning: @include subheading-font, weight: medium,
                color: $warning
              </div>
              <div className='font-sample subheading-info'>
                subheading-info: @include subheading-font, weight: medium,
                color: $info
              </div>
              <div className='font-sample button-dark'>
                button-dark: @include button-font, weight: medium, color: $dark
              </div>
              <div className='font-sample button-white'>
                button-white: @include button-font, weight: medium, color:
                $white
              </div>
              <div className='note'>
                Note: all these fonts are defined as mixins in fonts.scss
              </div>
            </div>
          </Tab>
          <Tab eventKey='basic' title='Basic'>
            <div className='demo-panel'>
              <h4>Nav Tabs</h4>
              <Nav activeKey='agency'>
                <Nav.Item>
                  <Nav.Link eventKey='agency'>Agencies</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='advertiser'>Advertisers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link disabled>Disabled</Nav.Link>
                </Nav.Item>
              </Nav>
              <h4>Badges</h4>
              <Badge variant='primary'>Primary</Badge>
              <Badge variant='secondary'>Secondary</Badge>
              <Badge variant='success'>Success</Badge>
              <Badge variant='danger'>Danger</Badge>
              <Badge variant='warning'>Warning</Badge>
              <Badge variant='info'>Info</Badge>
              <Badge variant='light'>Light</Badge>
              <Badge variant='dark'>Dark</Badge>
              <div className='note'>
                Note: The margin (margin-right) between badge is 10px by default
              </div>

              <h4>Buttons</h4>
              <Button variant='primary' size='sm'>
                Primary
              </Button>
              <Button variant='secondary' size='sm'>
                Sdecondary
              </Button>
              <Button variant='danger' size='sm'>
                Danger
              </Button>
              <div className='note'>
                Note: The margin between buttons is not set by default
              </div>

              <h4>Buttons Large</h4>
              <Button variant='primary' size='lg'>
                Primary
              </Button>
              <Button variant='secondary' size='lg'>
                Secondary
              </Button>
              <div className='note'>
                Note: The margin between buttons is not set by default
              </div>

              <h4>ProgressBar</h4>
              <ProgressBar now={40} />
              <ProgressBar variant='info' now={50} />
              <span className='progress-label'>50.00 % / 100.00 %</span>
              <ProgressBar variant='warning' now={60} />
              <ProgressBar variant='danger' now={70} />
              <ProgressBar variant='success' now={100} />
              <div className='note'>
                Note: The width of the progress bar is not set by default
              </div>

              <h4>Alerts</h4>
              <Alert variant='success'>This is a sunncess alert</Alert>
              <Alert variant='danger'>This is a danger alert</Alert>
              <Alert variant='warning'>This is a warning alert</Alert>
              <Alert variant='info'>This is a info alert</Alert>
              <div className='note'>
                Note: The width is set to 600px as maximum
              </div>
            </div>
          </Tab>
          <Tab eventKey='inputs' title='Inputs'>
            <div className='demo-panel'>
              <h4>Form Input Status</h4>
              <Form className='basic-input'>
                <Row>
                  <Col>
                    <Form.Label column>Disabled</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control type='text' disabled placeholder='disabled' />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label column>Placeholder</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control type='email' placeholder='placeholder' />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label column>Hover</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control type='number' placeholder='placeholder' />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label column>Focus</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control type='text' autoFocus defaultValue='Key in' />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label column>Normal</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control type='date' />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label column>Error</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control type='text' isInvalid />
                    <span className='note'>Error explanation</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label column>Success</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control type='text' isValid />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label column>Text area</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control as='textarea' />
                  </Col>
                </Row>
              </Form>
              <div className='note'>
                Note: the form width is not set up by default, the margin
                between row is 20px
              </div>
              <h4>Checkboxes / Radio Buttons</h4>
              <Form className='basic-input'>
                <Row>
                  <Col>
                    <Form.Check label='Normal' id='1' />
                    <Form.Check label='Hover' id='2' />
                    <Form.Check label='Checked' id='3' defaultChecked />
                    <Form.Check label='Checked' id='4' disabled />
                  </Col>
                  <Col>
                    <Form.Check type='radio' label='Normal' id='5' />
                    <Form.Check type='radio' label='Hover' id='6' />
                    <Form.Check
                      type='radio'
                      label='Checked'
                      id='7'
                      defaultChecked
                    />
                    <Form.Check type='radio' label='Checked' id='8' disabled />
                  </Col>
                </Row>
              </Form>
              <div className='note'>
                Note: must provide id for the customized checkbox and radio
                button or the pseudo element will not show
              </div>
              <h4>Switch button</h4>
              <div>
                <Toggle
                  onChange={this.handleClickToggle}
                  checked={this.state.toggleValue}
                  className='toggle'
                />
                <Toggle checked disabled className='toggle' />
                <Toggle checked={false} disabled className='toggle' />
                <Toggle
                  onChange={this.handleClickLargeToggle}
                  checked={this.state.largeToggleValue}
                  large
                  className='toggle'
                />
                <Toggle checked disabled large className='toggle' />
                <Toggle checked={false} disabled large className='toggle' />
              </div>
              <h4>Tags</h4>
              <Tags
                value={this.state.tagsValue}
                onChange={this.handleChangeTags}
              />
              <h4>Date range picker</h4>

            </div>
          </Tab>
          <Tab eventKey='customized' title='Customized'>
            <div className='demo-panel'>
              <SearchBar
                model={{
                  placeholder: 'a placeholder',
                  search: (keyword: string) => {}
                }}
              />
            </div>
          </Tab>
          <Tab eventKey='modal' title='Modal'>
            <div className='demo-panel'>
              <Button size='sm' onClick={this.showModal}>
                Show Modal
              </Button>
              <Button size='sm' onClick={this.showSpinner}>
                Show Spinner
              </Button>
              {this.state.modalShown && (
                <AlertDialog
                  message='A modal dialog'
                  dismiss={this.hideModal}
                />
              )}
              {this.state.loading && <LoadingIndicator />}
              <div className='note'>
                Note: the spinner will show for 4 seconds
              </div>
            </div>
          </Tab>
          <Tab eventKey='table' title='Tables'>
            <div className='demo-panel'>
              <Link to='/accounts' className='btn btn-primary btn-sm'>
                See Table
              </Link>
              <Pagination>
                <Pagination.First />
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Ellipsis />

                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item disabled>{14}</Pagination.Item>

                <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next />
                <Pagination.Last />
              </Pagination>
              <div className='note'>
                Note: all styles are overwritten in bootstrap.scss
              </div>
            </div>
          </Tab>
          <Tab eventKey='disabled' title='Disabled' disabled />
        </Tabs>
      </div>
    );
  }
}
