import React from 'react';
import styles from './creativeSummaryStep.module.scss';
import { CreativeSummaryStepProps, CreativeSummaryStepState } from './CreativeSummaryStepModel';
import i18n from 'i18next';
import { CreativeSetupFlowDataContext } from '../CreativeSetupFlowDataContext';
import { Button } from 'react-bootstrap';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Modal } from 'components/common/Modal/Modal';
import { ImagePreview, ImagePreviewProps } from 'components/common/Image/ImagePreview';
import { FlowMediaSummarySection, FlowSummarySection, FlowSummarySectionTitle, FlowSummarySectionTitleProps } from 'components/FlowSummarySection/FlowSummarySection';

export class CreativeSummaryStep extends React.Component<CreativeSummaryStepProps, CreativeSummaryStepState> {
  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.initSummaryData();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.setBasicSummaryData(prevProps.model.state.basicSummaryData);
      if (!this.props.model.state.basicSummaryData) {
        this.props.model.initSummaryData();
      }
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  renderImageSummary = (data: (FlowSummarySectionTitleProps & { summaryData: ImagePreviewProps })) => {
    return (
      <div>
        <FlowSummarySectionTitle title={data.title} goStep={data.goStep}/>
        <ImagePreview
          size={{ width: 150, height: 150 }}
          {...data.summaryData}
        />
      </div>
    );
  }

  renderSubmitAlertModal (modalData) {
    return (
      <Modal
        title={modalData.title}
        primaryButton={{
          title: i18n.t<string>('common.buttons.submit'),
          callback: modalData.submit
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: modalData.cancel
        }}
        dismiss={modalData.cancel}
        animation={false}
      >
        {modalData.message}
      </Modal>
    );
  }

  render () {
    return (
      <CreativeSetupFlowDataContext.Consumer>
        {(creativeContext) => {
          const model = this.props.model;
          model.initContextData(creativeContext);
          const {
            loading,
            basicSummaryData,
            mediaSummaryData,
            submitAlertModalData
          } = model.state;

          if (!basicSummaryData) {
            return <LoadingIndicator />;
          }
          const adLogoFileData = model.getAdLogoPreviewData();
          const advertiserSummaryData = model.getAdvertiserSummaryData(creativeContext.advertisers, creativeContext.creative.basic.advertiserId);
          const limitationSummaryData = model.getLimitationsSummaryData();
          const adLogoSummaryData = creativeContext.enableAdLogo ? model.getAdLogoSummaryData() : undefined;
          return (
            <div className={styles.container}>
              {submitAlertModalData && this.renderSubmitAlertModal(submitAlertModalData)}
              {loading && <LoadingIndicator />}
              <div className={styles.title}>
                {i18n.t<string>('campaignSummary.titles.checkYourSettings')}
              </div>
              <div className={styles.content}>
                <FlowSummarySection {...advertiserSummaryData}/>
                <div className={styles.sepLine} />
                <FlowSummarySection {...basicSummaryData}/>
                {
                  mediaSummaryData && (
                    <>
                      <div className={styles.sepLine} />
                      <FlowMediaSummarySection {...mediaSummaryData}/>
                    </>
                  )
                }
                {
                  limitationSummaryData && (
                    <>
                      <div className={styles.sepLine} />
                      <FlowSummarySection {...limitationSummaryData}/>
                    </>
                  )
                }
                {adLogoSummaryData &&
                  <>
                    <div className={styles.sepLine} />
                    <FlowSummarySection {...adLogoSummaryData} />
                    {
                      adLogoFileData &&
                      <>
                        <div className={styles.sepLine} />
                        {this.renderImageSummary(adLogoFileData)}
                      </>
                    }
                  </>
                }
              </div>
              <div className={styles.buttonArea}>
                <Button variant='primary' size='sm' onClick={model.submit}>
                  {i18n.t<string>('common.buttons.submit')}
                </Button>
                <Button variant='secondary' size='sm' onClick={model.goLast}>
                  {i18n.t<string>('campaign.buttons.back')}
                </Button>
              </div>
            </div>
          );
        }}
      </CreativeSetupFlowDataContext.Consumer>
    );
  }
}
