import { AudienceCountPayload, MessageSegmentCount, MessageSegmentWebService, RestfulMessageSegmentWebService } from 'ws/MessageSegmentWebService';
import { MessageSegmentFormData, MessageSegmentListRecord } from './MessageSegment';

export interface MessageSegmentManager {
  getMessageSegments (agencyId?: number | string): Promise<MessageSegmentListRecord[]>;
  createMessageSegment (segment: MessageSegmentFormData): Promise<void>;
  getPICCoreSegments (): Promise<SelectOptions[]>;
  getMessageSegment (segmentId: number | string): Promise<MessageSegmentFormData>;
  updateMessageSegment (segmentId: number | string, segment: MessageSegmentFormData): Promise<void>;
  getSegmentCount (audienceSummary: AudienceCountPayload): Promise<MessageSegmentCount>;
  getSegmentCountBySQL (brief: string): Promise<MessageSegmentCount>;
}

export class DefaultMessageSegmentManager implements MessageSegmentManager {

  webService: MessageSegmentWebService;

  constructor (
    webService: MessageSegmentWebService = new RestfulMessageSegmentWebService()
  ) {
    this.webService = webService;
  }

  // only system admin need specify agencyId
  async getMessageSegments (agencyId?: number | string): Promise<MessageSegmentListRecord[]> {
    return this.webService.getMessageSegments(agencyId);
  }

  async createMessageSegment (segment: MessageSegmentFormData): Promise<void> {
    return this.webService.createMessageSegment(segment);
  }

  async getPICCoreSegments (): Promise<SelectOptions[]> {
    return this.webService.getPICCoreSegments();
  }

  async getMessageSegment (segmentId: string | number): Promise<MessageSegmentFormData> {
    return this.webService.getMessageSegment(segmentId);
  }

  async updateMessageSegment (segmentId: string | number, segment: MessageSegmentFormData): Promise<void> {
    return this.webService.updateMessageSegment(segmentId, segment);
  }

  async getSegmentCount (audienceBrief: AudienceCountPayload): Promise<MessageSegmentCount> {
    return this.webService.getSegmentCount(audienceBrief);
  }

  async getSegmentCountBySQL (brief: string): Promise<MessageSegmentCount> {
    return this.webService.getSegmentCountBySQL(brief);
  }
}
