import { DefaultSelectComponentModel, SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { Page } from 'components/Page/Page';
import { useCallback, useMemo, useState } from 'react';
import i18n from 'i18n';
import { useFormikContext } from 'formik';
import { ProductGroupType, ProductGroupFormValue, AUTO_UPDATE_VALUE, ALL_SELECT_VALUE } from 'core/limitation/ProductGroup';
import { DomainCustomInputComponentModel, SpaceCustomInputComponentModel } from 'components/CampaignLimitation';
import { ItemType } from 'components/CampaignLimitation/SelectItemComponent';
import styles from './productGroupForm.module.scss';
import LimitationSet from 'components/CampaignLimitation/LimitationSet/LimitationSet';
import { capitalize, defaultTo, get, remove, uniqBy } from 'lodash';
import Tips from 'components/common/Tips/Tips';
import { readFromClipboard, writeToClipboard } from 'helper/ClipboardHelper';
import { textToSelectOptions } from 'utils/SelectOptionsUtils';
import classNames from 'classnames';

const cx = classNames.bind(styles);

export const SelectGroupSection: React.FC<{ options: SelectOptions[] }> = ({
  options
}) => {

  const { values, errors, touched: formikTouched, submitCount, setFieldValue } = useFormikContext<ProductGroupFormValue>();
  const [showInventory, setShowInventory] = useState<boolean>(true);
  const error = get(errors, 'groupValues');
  const touched = defaultTo(submitCount, 0) > 0 || get(formikTouched, 'groupValues');
  const hasError = error !== undefined && touched;
  const groupType = values.groupType;

  const customInputModelGetter = useCallback(() => {
    return groupType === ProductGroupType.ADSPACE ?
      new SpaceCustomInputComponentModel(
        i18n.t<string>('limitation.addSpace'),
        i18n.t<string>('limitation.addSpaceTip'),
        i18n.t<string>('limitation.placeholders.addSpace'),
        'row-reverse'
      ) :
      new DomainCustomInputComponentModel(
        i18n.t<string>('productGroupForm.addDomain'),
        i18n.t<string>('limitation.addDomainTip'),
        i18n.t<string>('productGroupForm.placeholders.addDomain'),
        'row-reverse'
      );
  }, [groupType]);

  const onGroupsChange = useCallback((newGroups: SelectOptions[]) => {
    const allCustoms = remove(newGroups, group => group.extra === 'isCustom');
    setFieldValue('customGroupValues', allCustoms);

    if (newGroups.length === 0) {
      setFieldValue('groupValues', []);
      return;
    }

    const newItem = newGroups[newGroups.length - 1];
    if (newItem.value === AUTO_UPDATE_VALUE) {
      setFieldValue('groupValues', [newItem]);
    } else if (newItem.value === ALL_SELECT_VALUE) {
      setFieldValue('groupValues', options.slice(2));
    } else {
      remove(newGroups, group => group.value === AUTO_UPDATE_VALUE);
      setFieldValue('groupValues', newGroups);
    }
  }, [options, setFieldValue]);

  const groupValues = useMemo(
    () => [...values.groupValues, ...values.customGroupValues],
    [values.groupValues, values.customGroupValues]
  );

  const selectComponentModel = useMemo(() => {
    return new DefaultSelectComponentModel(
      'groupValues',
      false,
      {
        type: ItemType.auto,
        categorySelectable: true,
        readonly: false
      },
      i18n.t<string>('productGroup.placeholders.search'),
      newGroups => onGroupsChange(newGroups),
      options,
      groupValues,
      customInputModelGetter
    );
  }, [options, groupValues, customInputModelGetter, onGroupsChange]);

  const onClearGroups = useCallback(() => {
    setFieldValue('groupValues', []);
    setFieldValue('customGroupValues', []);
  }, [setFieldValue]);

  const handleCopyToClipboard = useCallback(() => {
    const groupValues = defaultTo(values.groupValues, []);
    remove(groupValues, option => [ALL_SELECT_VALUE, AUTO_UPDATE_VALUE].includes(option.value.toString()));
    const groupValuesText = groupValues.reduce((acc, value) => acc.concat(value.value.toString(), ',', value.label, '\n'), '');

    const customValues = defaultTo(values.customGroupValues, []);
    const customValuesText = customValues.reduce((acc, value) => acc.concat(value.value.toString(), ',', value.label, '\n'), '');

    writeToClipboard(`${groupValuesText}${customValuesText}`);
  }, [values.groupValues, values.customGroupValues]);

  const handlePasteFromClipboard = useCallback(async (e) => {
    e.preventDefault();
    const groupValues = defaultTo(values.groupValues, []);
    const customGroupValues = defaultTo(values.customGroupValues, []);
    const pasteText = await readFromClipboard();
    const groupValuesToAdd = textToSelectOptions(pasteText);
    const customValuesToAdd = remove(groupValuesToAdd, (value => options.find(option => option.value === value.value) === undefined));
    customValuesToAdd.forEach(option => ({ ...option, extra: 'isCustom' }));

    setFieldValue('groupValues', uniqBy([...groupValues, ...groupValuesToAdd], 'value'));
    setFieldValue('customGroupValues', uniqBy([...customGroupValues, ...customValuesToAdd], 'value'));
    return false;
  }, [options, values.groupValues, values.customGroupValues, setFieldValue]);

  const onCollapseButtonClick = useCallback(() => {
    setShowInventory(prev => !prev);
  }, []);

  const collapseButtonTitle = useMemo(() => {
    return showInventory
      ? i18n.t<string>('productGroupForm.buttons.finishEdit')
      : i18n.t<string>(`productGroupForm.buttons.edit${capitalize(groupType)}`);
  }, [showInventory, groupType]);

  return (
    <Page.Section
      title={i18n.t<string>(`productGroupForm.title.select${capitalize(values.groupType)}Section`)}
      titleHint={groupType === ProductGroupType.ADSPACE ? i18n.t<string>('productGroupForm.hints.conditionSpace') : undefined}
      inlineTitle
      withShadow={false}
      className={cx({ [styles.sectionOnlyTitle]: !showInventory && groupValues.length === 0 })}
    >
      <div className={styles.collapseButton} onClick={onCollapseButtonClick}>
        {collapseButtonTitle}
      </div>
      <div style={{ display: 'flex' }}>
        {showInventory && (
          <div className={styles.options}>
            <SelectComponent model={selectComponentModel} />
          </div>
        )}
        {(showInventory || groupValues.length > 0) && (
          <div className={styles.selectedFilters} onPaste={handlePasteFromClipboard}>
            <LimitationSet
              value={groupValues}
              type='groupValues'
              needShow
              dropdownHidden
              showPlus={false}
              showClearButton
              onChange={onGroupsChange}
              deleteLimitation={onClearGroups}
              errorTip={hasError ? <Tips>{error}</Tips> : undefined}
              disable={!showInventory}
            />
            <span className={styles.copyHintLabel} onClick={handleCopyToClipboard}>{i18n.t<string>('common.labels.copyToClipBoard')}</span>
          </div>
        )}
      </div>
    </Page.Section>
  );
};
