import React from 'react';
import { GroupOptionSelection } from 'components/common/GroupOptionSelection/GroupOptionSelection';
import { DefaultGroupOptionSelectionModel } from 'components/common/GroupOptionSelection/GroupOptionSelectionModel';
import Cookies from 'universal-cookie';
import { SessionStorageHelper } from 'helper/StorageHelper';
import i18n from 'i18next';
import _ from 'lodash';

const cookies = new Cookies();

export class RecentGroupDropdown extends React.Component<any, any> {

  localStorageKey: string;

  constructor (props) {
    super(props);
    this.localStorageKey = `${props.storageKey}ListOf${cookies.get('actor')}`;
    const recentOptionsString = localStorage.getItem(this.localStorageKey);
    this.state = {
      recentOptions: recentOptionsString === null ? [] : recentOptionsString.split(',')
    };
  }

  updateRecentOptions (value: number | undefined) {
    if (value) {
      let recentOptions;
      let recentOptionsString = localStorage.getItem(this.localStorageKey);
      if (recentOptionsString === null) {
        recentOptions = [value.toString()];
      } else {
        recentOptions = recentOptionsString.split(',');
        _.remove(recentOptions, recentOption => recentOption === value.toString());
        recentOptions.length === 5 && recentOptions.pop();
        recentOptions.unshift(value.toString());
      }
      localStorage.setItem(this.localStorageKey, recentOptions.join(','));
      this.setState({
        recentOptions
      });
    }
  }

  onOptionSelected = (value?: number) => {
    if (value) {
      SessionStorageHelper.setItem(this.props.storageKey, value);
    } else {
      SessionStorageHelper.removeItem(this.props.storageKey);
    }
    this.updateRecentOptions(value);
    this.props.callback(value);
  }

  getGroupOptions (): {[groupTitle: string]: Array<SelectOptions>} {
    const selectOptions = this.props.options ? this.props.options : [];
    const groupOptions = {};
    const recentUseGroup: Array<SelectOptions> = [];
    this.state.recentOptions.forEach(recentOption => {
      const findOption: SelectOptions | undefined = selectOptions.find(option => option.value.toString() === recentOption);
      findOption && recentUseGroup.push(findOption);
    });
    groupOptions[i18n.t<string>('recentGroupDropdown.labels.useRecently')] = recentUseGroup;
    groupOptions[i18n.t<string>(`recentGroupDropdown.labels.${_.camelCase('other-' + this.props.storageKey)}`)] = _.xorWith(selectOptions, recentUseGroup, (optionA, optionB) => optionA.value === optionB.value);
    return groupOptions;
  }

  render () {
    return (
      <GroupOptionSelection
        model={
          new DefaultGroupOptionSelectionModel(
            this.props.value,
            this.getGroupOptions(),
            this.onOptionSelected,
            this.props.storageKey,
            this.props.showShowAllBtn,
            this.props.useCustomStyle
          )
        }
      />
    );
  }
}
