import { getInitDaypart } from 'components/Dayparts/Dayparts';
import { Pmp, PmpFormType, PmpsWithPagination } from './Pmp';
import moment from 'moment';
import { Pageable } from 'ws/Pageable';
import { PmpWebService, RestfulPmpWebService } from 'ws/PmpWebService';
import { isEmpty, omit } from 'lodash';
import { Dayparts } from 'core/dayparts/Dayparts';

export interface PmpManager {
  getPmp: (pmpId: number) => Promise<Pmp>;
  getPmpList: (pageable: Pageable, search: string, status: number[]) => Promise<PmpsWithPagination>;
  createPmp: (pmp: PmpFormType) => Promise<void>;
  updatePmp: (pmp: Pmp) => Promise<void>;
  updatePmpState (pmpIds: number[], state: 'activate' | 'deactivate'): Promise<void>;
  getPmpsWithin (startTime: string, endTime: string): Promise<Pmp[]>;
  getPmpsOfOrder: (orderId: number, status: number[]) => Promise<Pmp[]>;
  getDaypartDescriptions (startTime: string, endTime: string, daypart?: {[day: string]: (string | number)[]}): string[];
}

export class DefaultPmpManager implements PmpManager {

  webService: PmpWebService;

  constructor (webService: PmpWebService = new RestfulPmpWebService()) {
    this.webService = webService;
  }

  getPmp (pmpId: number) {
    return this.webService.getPmp(pmpId);
  }

  getPmpList (pageable: Pageable, search: string = '', status: number[] = []) {
    return this.webService.getPmps(pageable, search, status);
  }

  async createPmp (pmp: PmpFormType) {
    return this.webService.createPmp(pmp);
  }

  updatePmp (pmp: Pmp) {
    return this.webService.updatePmp(pmp);
  }

  updatePmpState (pmpIds: number[], state: 'activate' | 'deactivate') {
    return this.webService.updatePmpState(pmpIds, state);
  }

  getPmpsWithin (startTime: string, endTime: string) {
    return this.webService.getPmpsWithin(startTime, endTime);
  }

  async getPmpsOfOrder (orderId: number, status: number[] = []) {
    const pmpList = await this.webService.getPmpsOfOrder(orderId, status);
    return pmpList.filter(pmp => !pmp.campaignId);
  }

  getDaypartDescriptions (startTime: string, endTime: string, daypart?: Omit<Dayparts, 'enabled'>): string[] {
    let targetDaypart = daypart;
    if (isEmpty(daypart) || targetDaypart === undefined) {
      targetDaypart = omit(getInitDaypart(), 'enabled');
    }
    const start = moment(startTime);
    const startString = start.format('YYYY-MM-DD');
    const startDate = moment(startTime).startOf('day');
    const end = moment(endTime);
    const endString = end.format('YYYY-MM-DD');
    const endDate = moment(endTime).startOf('day');
    let descriptions: string[] = [];
    const padStart = (num: number) => num.toString().padStart(2, '0');
    while (startDate.isSameOrBefore(endDate)) {
      const isStartDay = startDate.format('YYYY-MM-DD') === startString;
      const isEndDay = startDate.format('YYYY-MM-DD') === endString;
      const weekday = startDate.weekday();
      const key = weekday === 0 ? 7 : weekday;
      let hours = targetDaypart[key] || targetDaypart[key.toString()];
      if (isStartDay) {
        hours = hours.filter(hour => +hour >= +start.format('HH'));
      }
      if (isEndDay) {
        hours = hours.filter(hour => +hour <= +end.format('HH'));
      }
      const descriptionsOfDate = hours.map(hour => {
        return `${startDate.year()}-${padStart(startDate.month() + 1)}-${padStart(startDate.date())} ${padStart(+hour)}`;
      });
      descriptions = descriptions.concat(descriptionsOfDate);
      startDate.add(1, 'day');
    }
    return descriptions;
  }
}
