import { LIMITATION_TYPE, LimitationInventorySettings } from './limitationSettingsType';
import { LocaleMeta } from 'core';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { getDefaultLimitationInventorySettings } from './defaultLimitationInventorySettings';
import { findIndex, get, slice } from 'lodash';
import { defaultItemSetting, ItemType } from 'components/CampaignLimitation/SelectItemComponent';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { SearchKeywordsComponent } from 'components/CampaignLimitation/SearchKeywordsComponent/SearchKeywordsComponent';

export const getRetailLimitationInventorySettings = (
  requiredOperateOfTaTypes: {[type: string]: string[]},
  advertiserId: number,
  limitationType: LIMITATION_TYPE,
  goSegments?: SelectOptions[],
  localeMeta?: LocaleMeta,
  retailType?: string,
  adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager()
): LimitationInventorySettings[] => {
  let defaultSetting: any[] = getDefaultLimitationInventorySettings(requiredOperateOfTaTypes, advertiserId, limitationType, goSegments, localeMeta).filter(setting => setting.name !== 'adx' && setting.name !== 'adspace');
  let segmentIndex: number = findIndex(defaultSetting, inventory => inventory.name === 'segment');
  defaultSetting = [
    ...slice(defaultSetting, 0, segmentIndex + 1),
    {
      name: 'product_segment',
      ignoreValidateOption: true,
      ignoreAddonFeature: true,
      title: 'limitation.labels.productSegment',
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        type: ItemType.autoByGroupSetting,
        categorySelectable: false
      },
      searchPlaceholder: 'limitation.placeholders.searchProductSegments',
      cb: retailType ?
        adRequestSourceManager.getProductSegments.bind(adRequestSourceManager, advertiserId, retailType) :
        () => [],
      supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
      component: SelectComponent
    },
    ...slice(defaultSetting, segmentIndex + 1),
    {
      name: 'searchKeywords',
      ignoreAddonFeature: true,
      ignoreValidateOption: true,
      title: 'limitation.labels.searchKeyword',
      singleSelect: false,
      itemSetting: defaultItemSetting(),
      searchPlaceholder: '',
      component: SearchKeywordsComponent,
      supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
      extra: {
        retailType
      }
    },
    {
      name: 'adspace',
      ignoreAddonFeature: true,
      title: 'limitation.labels.placement',
      cb: async () => {
        const spaces = await adRequestSourceManager.getSSPSpaces();
        return spaces.filter(space => get(space, 'isGroup', false));
      },
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        categorySelectable: false
      },
      needInitExtra: true,
      searchPlaceholder: 'limitation.placeholders.searchPlacement',
      component: SelectComponent,
      supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred']
    }
  ];
  return defaultSetting;
};
