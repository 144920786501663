import {
  ConversionWebService,
  RestfulConversionWebService
} from 'ws/ConversionWebService';

import { ConversionFormDTO, ConversionListDTO } from 'core/conversion/Conversion';

export interface ConversionManager {
  getConversionOptions (advertiserId: number): Promise<Array<SelectOptions>>;
  getConversions (agencyId?: number): Promise<Array<ConversionListDTO>>;
  createConversion (conversion: ConversionFormDTO): Promise<void>;
  updateConversion (conversion: ConversionFormDTO): Promise<void>;
  getConversionCode (conversionId: number): Promise<string>;
}

export class DefaultConversionManager implements ConversionManager {
  webService: ConversionWebService;

  constructor (
    webService: ConversionWebService = new RestfulConversionWebService()
  ) {
    this.webService = webService;
  }

  async getConversionOptions (advertiserId: number): Promise<Array<SelectOptions>> {
    const conversionOptions = await this.webService.getConversionOptions(advertiserId);
    return conversionOptions;
  }

  async getConversions (agencyId?: number): Promise<Array<ConversionListDTO>> {
    const conversions = await this.webService.getConversions(agencyId);
    return conversions;
  }

  async createConversion (conversion: ConversionFormDTO): Promise<void> {
    return this.webService.createConversion(conversion);
  }

  async updateConversion (conversion: ConversionFormDTO): Promise<void> {
    return this.webService.updateConversion(conversion);
  }

  async getConversionCode (conversionId: number): Promise<string> {
    return this.webService.getConversionCode(conversionId);
  }
}
