import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { FilterField, FILTER_FIELDS, PRODUCT_SEGMENT_OPERATOR } from 'core/product/ProductSegment';
import { FilterFieldOperatorsConfig, OperatorInitValuesMapConfig } from 'core/product/ProductSegmentConfig';
import { FormikField } from 'components/common/form/field/FormikField';
import i18n from 'i18n';
import styles from './productSegmentForm.module.scss';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CustomField } from 'components/common/form/field/CustomField';

export const ProductSegmentFilterField: FC<{
  ruleIndex: number,
  combination: 'inclusion' | 'exclusion',
  filters: FilterField[],
  filterField: FilterField,
  fieldIndex: number,
  productSetOptions: SelectOptions[],
  filterFields: FILTER_FIELDS[],
  setValues: (updateFunc: (prev: any) => any) => void
}> = ({
  ruleIndex,
  combination,
  filters,
  filterField,
  fieldIndex,
  productSetOptions,
  filterFields,
  setValues
}) => {

  const [currentFilterFields, setCurrentFilterFields] = useState(filters.map(filter => filter.field));
  useEffect(() => {
    const newFilterFields = filters.map(filter => filter.field);
    const array1Sorted = currentFilterFields.slice().sort();
    const array2Sorted = newFilterFields.slice().sort();
    if (array1Sorted.length !== array2Sorted.length || array1Sorted.some((value, index) => {
      return value !== array2Sorted[index];
    })) {
      setCurrentFilterFields(newFilterFields);
    }
  }, [currentFilterFields, filters]);

  const filterFieldOperators: PRODUCT_SEGMENT_OPERATOR[] = _.get(FilterFieldOperatorsConfig, `${filterField.field}`, []);
  const filterFieldOperatorOptions = useMemo(() => createSelectOptions(filterFieldOperators, 'productSegmentForm.labels.operator.', _.snakeCase), [filterFieldOperators]);
  const validFilterFieldOptions = useMemo(() => {
    const filterFieldsOptions: SelectOptions[] = createSelectOptions(filterFields, 'productSegmentForm.labels.filterField.', _.snakeCase);
    const shouldIncludeOption: SelectOptions[] = filterField.field ? filterFieldsOptions.filter(option => filterField.field === option.value) : [];
    const validFilterFieldOptions: SelectOptions[] = filterFieldsOptions.filter(option => _.findIndex(currentFilterFields, field => field === option.value) === -1);
    return [
      ...shouldIncludeOption,
      ...validFilterFieldOptions
    ];
  }, [filterFields, currentFilterFields, filterField.field]);

  const handleRemoveFilterField = () => {
    const newFilters = _.filter(filters, (_1, index) => fieldIndex !== index);
    setValues(prev => {
      const newValue = _.cloneDeep(prev);
      _.set(newValue, `${combination}.rules[${ruleIndex}].filterSet.filters`, newFilters);
      _.set(newValue, `${combination}.rules[${ruleIndex}].selectedFilterField`, undefined);
      return newValue;
    });
  };

  const onChangeFilterField = useCallback((selectedFilterField: FILTER_FIELDS) => {
    const operator: PRODUCT_SEGMENT_OPERATOR | undefined = _.get(FilterFieldOperatorsConfig, `${selectedFilterField}[0]`);
    if (!operator) {
      return;
    }
    const newFilterField: FilterField = {
      field: selectedFilterField,
      operator,
      value: _.get(OperatorInitValuesMapConfig, `${operator}`, [''])
    };
    setValues(prev => {
      const newValue = _.cloneDeep(prev);
      _.set(newValue, `${combination}.rules[${ruleIndex}].filterSet.filters[${fieldIndex}]`, newFilterField);
      _.set(newValue, `${combination}.rules[${ruleIndex}].selectedFilterField`, undefined);
      return newValue;
    });
  }, [combination, ruleIndex, fieldIndex, setValues]);

  const onChangeOperator = useCallback((selectedOperator: PRODUCT_SEGMENT_OPERATOR) => {
    const field = filterField.field;
    const newFilterField: FilterField = {
      field,
      operator: selectedOperator,
      value: _.get(OperatorInitValuesMapConfig, `${selectedOperator}`, [''])
    };
    setValues(prev => {
      const newValue = _.cloneDeep(prev);
      _.set(newValue, `${combination}.rules[${ruleIndex}].filterSet.filters[${fieldIndex}]`, newFilterField);
      _.set(newValue, `${combination}.rules[${ruleIndex}].selectedFilterField`, undefined);
      return newValue;
    });
  }, [combination, fieldIndex, filterField.field, ruleIndex, setValues]);

  const valueFormikField = () => {
    switch (filterField.field) {
      case FILTER_FIELDS.PRODUCT_SET_ID:
        return (
          <FormikField.Select
            name={`${combination}.rules[${ruleIndex}].filterSet.filters[${fieldIndex}].value[0]`}
            simpleValue
            options={productSetOptions}
            componentWidthFitParent={true}
            fieldContentWidth={280}
            inputColSm={3}
          />
        );
      case FILTER_FIELDS.SALE_PRICE:
      case FILTER_FIELDS.TOTAL_PRICE:
        return (
          <Fragment>
            {_.map(filterField.value, (_1, valueId) => {
              return (
                <FormikField.InputGroup
                  key={`fieldValue-${valueId}`}
                  name={`${combination}.rules[${ruleIndex}].filterSet.filters[${fieldIndex}].value[${valueId}]`}
                  fieldContentWidth={280 / filterField.value.length}
                  inputColSm={3}
                  postfix={i18n.t<string>('productSegmentForm.labels.postText.price')}
                />
              );
            })}
          </Fragment>
        );
      default:
        return (
          <Fragment>
            {_.map(filterField.value, (_1, valueId) => {
              return (
                <FormikField.Input
                  key={`fieldValue-${valueId}`}
                  name={`${combination}.rules[${ruleIndex}].filterSet.filters[${fieldIndex}].value[${valueId}]`}
                  fieldContentWidth={280 / filterField.value.length}
                  inputColSm={3}
                />
              );
            })}
          </Fragment>
        );
    }
  };

  return (
    <Fragment key={`${filterField.field}-${fieldIndex}`}>
      <CustomField
        name={`selectedFilterField-${filterField.field}-${fieldIndex}`}
        label={i18n.t<string>(`productSegmentForm.labels.common.${PRODUCT_SEGMENT_OPERATOR.AND}`)}
        labelColSm={2}
        formGroupClassName={styles.filterFieldFormGroup}
      >
        <div className={styles.filterFieldRow}>
          <FormikField.Select
            name={`${combination}.rules[${ruleIndex}].filterSet.filters[${fieldIndex}].field`}
            simpleValue
            options={validFilterFieldOptions}
            componentWidthFitParent={true}
            fieldContentWidth={180}
            inputColSm={3}
            onChange={onChangeFilterField}
          />
          <FormikField.Select
            name={`${combination}.rules[${ruleIndex}].filterSet.filters[${fieldIndex}].operator`}
            simpleValue
            options={filterFieldOperatorOptions}
            componentWidthFitParent={true}
            fieldContentWidth={100}
            inputColSm={3}
            onChange={onChangeOperator}
          />
          {valueFormikField()}
          <div className={styles.fieldCloseBtn}>
            <button
              type='button'
              className='btn'
              onClick={handleRemoveFilterField}
            >
              <FontAwesomeIcon
                icon={faTimes}
                size='lg'
              />
            </button>
          </div>
        </div>
      </CustomField>
    </Fragment>
  );
};
