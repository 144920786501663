import { SpaceDevice } from 'core/rtbCampaign/RtbCampaign';
import React from 'react';

export type Device = SelectOptions;
export type SpaceAttribute = SelectOptions;

/**
 * @description 從 SSP 拉到的 space attribute
 * @example
 * {
 *  "administrativeArea": {
 *    title: "行政區",
 *    options: [
 *      {
 *        label: "台北市",
 *        value: "台北市"
 *      }
 *    ]
 *  }
 * }
 */
export type SpaceAttributes = {
  [attributeType: string]: {
    title: string;
    options: SpaceAttribute[];
  }
};

/**
 * @description 所有的 space attribute 與對應的機台
 * @example
 * {
 *  "administrativeArea": {
 *    "台北市": [
 *      {
 *        label: "[統一資訊][連網裝置][Desktop][其他][其他][新文華門市_253657][Always on]"
 *        value: "8aace3aa038741f6",
 *      }
 *    ]
 *  }
 * }
 */
export type SpaceAttributeMap = {
  [attributeType: string]: {
    [spaceAttribute: string]: Device[];
  }
};

/**
 * @description 所有的機台與對應的 space attribute
 * @example
 * {
 *  "8aace3aa038741f6": {
 *   "administrativeArea": {
 *     title: "行政區",
 *     options: [
 *      {
 *          label: "台北市",
 *          value: "台北市"
 *      }
 *     ]
 *   }
 *  }
 * }
 */
export type DeviceMap = {
  [spaceId: string]: {
    [attributeType: string]: {
      title: string;
      options: SpaceAttribute[];
    }
  }
};

/**
 * @description 所有的機台對應的資訊，包括 經緯度、地址等
 * @example
 * {
 *  "8aace3aa038741f6": {
 *    latitude: 25.033,
 *    longitude: 121.565,
 *    address: "台北市大安區忠孝東路四段"
 *    spaceId: "8aace3aa038741f6",
 *    storeNum: "253657"
 *  }
 * }
 */
export type SpaceDetailMap = {
  [spaceId: string]: SpaceDevice;
};

export type MapFilter = {
  device?: Device[];
};

export type DeviceDataContextType = {
  filter: MapFilter;
  deviceMap: DeviceMap;
  spaceDetailMap: SpaceDetailMap;
};

export const DeviceDataContext = React.createContext<DeviceDataContextType>({
  filter: {},
  deviceMap: {},
  spaceDetailMap: {}
});
