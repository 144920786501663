import React, { useCallback, useMemo, useState } from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { connect, FormikContextType } from 'formik';
import i18next from 'i18n';
import { AdRequestSourceSpace } from 'core/adRequestSource/AdRequestSource';
import { selectOptionsAddI18n } from 'utils/I18nUtils';
import styles from './agencyForm.module.scss';
import { FormikField } from 'components/common/form/field/FormikField';
import { DefaultAgencyManager } from 'core';
import { get, isNil, partial } from 'lodash';
import moment from 'moment';
import { CurrencyRate } from 'core/currencyRate/CurrencyRate';

type AgencyBasicFormProps = {
  currencyRates: CurrencyRate[];
  timeZoneOptions: SelectOptions[];
  languageOptions: SelectOptions[];
  currencyOptions: SelectOptions[];
  priorityOptions: SelectOptions[];
  segmentCountryOptions: SelectOptions[];
  segmentLabelOptions: SelectOptions[];
  publisherCountryOptions: SelectOptions[];
  adxOptions: SelectOptions[];
  countryOptions: SelectOptions[];
  spaceOptions: AdRequestSourceSpace[];
  isOptionsLoading: boolean;
  changePublisherIds: Function;
  isNew: boolean;
};

const agencyManager = new DefaultAgencyManager();

const AgencyBasicForm: React.FC<AgencyBasicFormProps & { formik: FormikContextType<any> }> = ({
  timeZoneOptions,
  priorityOptions,
  languageOptions,
  currencyOptions,
  countryOptions,
  isOptionsLoading,
  adxOptions,
  segmentCountryOptions,
  segmentLabelOptions,
  isNew,
  formik
}) => {

  const [editRebateRate, setEditRebateRate] = useState(false);

  const setFieldValue = formik.setFieldValue;

  const labelLower = (options: SelectOptions) => {
    return {
      label: options.label.toLowerCase(),
      value: options.value,
      options: options.options
    };
  };
  const goganOpOptions = useMemo(() => [
    {
      value: 'inc',
      label: i18next.t<string>('limitation.inc')
    },
    {
      value: 'exc',
      label: i18next.t<string>('limitation.exc')
    }
  ], []);
  const timeZoneDom = useMemo(() =>
    timeZoneOptions
      .map(labelLower)
      .map(options => selectOptionsAddI18n(options, ['common.timeZone.'])),
    [timeZoneOptions]
  );
  const languageDom = useMemo(() =>
    languageOptions
      .map(labelLower)
      .map(options => selectOptionsAddI18n(options, ['common.language.'])),
    [languageOptions]
  );
  const currencyDom = useMemo(() =>
    currencyOptions
      .map(labelLower)
      .map(options => selectOptionsAddI18n(options, ['common.currency.'])),
    [currencyOptions]
  );

  const onCurrencyChange = useCallback((newCurrency) => {
    setFieldValue('targetBudgetMinimum', agencyManager.getRTBDefaultMinBudgetPerDay(newCurrency));
    setFieldValue('campaignBudgetMinimum', agencyManager.getRTBDefaultMinBudget(newCurrency));
  }, [setFieldValue]);

  const onSelfBuyChange = useCallback((newSelfBuy) => {
    if (!newSelfBuy) {
      setFieldValue('noLimitSelfBuyStatus', false);
    }
  }, [setFieldValue]);

  const percentFormatter = useCallback(value => (`${value}%`), []);
  const effectDateFormatter = useCallback(value => {
    return value ? value : i18next.t<string>('common.labels.noData');
  }, []);

  const renderFutureRateRate = () => {
    const initFutureRate = formik.initialValues.futureRate;
    const initEffectDate = formik.initialValues.effectDate;
    const onEditClick = () => {
      !formik.values.effectDate &&
        formik.setFieldValue('effectDate', moment().startOf('day').add(1, 'day').format('YYYY-MM-DD'));
      !formik.values.futureRate && formik.setFieldValue('futureRate', 0);
      setEditRebateRate(true);
    };
    const onCancelClick = () => {
      formik.setValues(values => ({
        ...values,
        futureRate: initFutureRate,
        effectDate: initEffectDate
      }));
      setEditRebateRate(false);
    };
    const futureRateLabelFormatter = value => {
      return isNil(value) || value === '' ? i18next.t<string>('common.labels.noData') : `${value}%`;
    };
    return (
        editRebateRate ?
          <div className={styles.row}>
            <FormikField.InputGroup
              label={i18next.t<string>('agency.futureRate')}
              name='futureRate'
              type='number'
              postfix='%'
              fieldContentWidth='120px'
              postComponent={
                <Button variant='secondary' size='sm' onClick={onCancelClick}>
                {i18next.t<string>('common.buttons.cancel')}
              </Button>
              }
            />
          </div> :
          <div className={styles.row}>
            <FormikField.Label
              label={i18next.t<string>('agency.futureRate')}
              name='futureRate'
              fieldContentWidth='120px'
              formatter={futureRateLabelFormatter}
              postComponent={
                <Button variant='secondary' size='sm' onClick={onEditClick}>
                  {i18next.t<string>('common.buttons.edit')}
                </Button>
              }
            />
          </div>
    );
  };

  const onSegmentTagsChange = (fieldName, tags) => {
    const newValues = tags.map(tag => {
      return typeof tag === 'string' ? {
        value: tag,
        label: tag
      } : tag;
    });
    setFieldValue(fieldName, newValues);
  };

  const hasAdLogo = get(formik, 'values.logoData.file') || get(formik, 'values.logoData.url');
  return (
    <div className={styles.agencyFrom}>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.information')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Input
            label={i18next.t<string>('agency.companyName')}
            name='companyName'
          />
          <FormikField.Select
            label={i18next.t<string>('agency.language')}
            simpleValue
            name='language'
            options={languageDom}
          />
          <FormikField.Select
            label={i18next.t<string>('agency.currency')}
            simpleValue
            disabled={!isNew}
            name='currency'
            options={currencyDom}
            onChange={onCurrencyChange}
          />
          <FormikField.Select
            label={i18next.t<string>('agency.timeZone')}
            simpleValue
            disabled={!isNew}
            name='timeZone'
            options={timeZoneDom}
          />
          <FormikField.InputGroup
            label={i18next.t<string>('agency.taxPercent')}
            name='taxPercent'
            type='number'
            postfix='%'
          />
          <FormikField.Select
            label={i18next.t<string>('agency.priority')}
            simpleValue
            name='priority'
            options={priorityOptions}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.selfBuy')}
            disabled={!isNew}
            name='selfBuy'
            onChange={onSelfBuyChange}
          />
          <FormikField.Tags
            label={i18next.t<string>('agency.limitPublisherStr')}
            name='limitPublisher'
            placeholder={i18next.t<string>('agencyForm.placeholders.tags')}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.noLimitSelfBuyStatus')}
            disabled={!isNew || !formik.values.selfBuy}
            name='noLimitSelfBuyStatus'
          />
          <FormikField.FileInput
            type='image'
            label={i18next.t<string>('agency.logo')}
            name='logoData'
            validTypes={['image/jpeg', 'image/jpg', 'image/png']}
            hints={[i18next.t<string>('creativeSetupFlow.labels.typeHint')]}
            className={hasAdLogo ? styles.autoHeight : styles.reportLogoPlaceholder}
          />
          <FormikField.TextArea
            label={i18next.t<string>('agency.comComment')}
            name='comComment'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.form.titles.generalDeliverySetting')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <Form.Group as={Row} controlId='limitAdx'>
            <Col sm={3}>
              <Form.Label column>{i18next.t<string>('agency.limitAdxStr')}</Form.Label>
            </Col>
            <Col sm={2}>
              <FormikField.Select
                withFieldLayout={false}
                simpleValue
                name='limitAdxOp'
                options={goganOpOptions}
                className={styles.autoWithSelect}
              />
            </Col>
            <Col sm={7}>
              <FormikField.Select
                withFieldLayout={false}
                isMulti
                isLoading={isOptionsLoading}
                name='limitAdx'
                options={adxOptions}
                className={styles.autoWithSelect}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='defaultCountry'>
            <Col sm={3}>
              <Form.Label column>{i18next.t<string>('agency.defaultCountryStr')}</Form.Label>
            </Col>
            <Col sm={2}>
              <FormikField.Select
                withFieldLayout={false}
                simpleValue
                name='defaultCountryOp'
                options={goganOpOptions}
                className={styles.autoWithSelect}
              />
            </Col>
            <Col sm={7}>
              <FormikField.Select
                withFieldLayout={false}
                isMulti
                isLoading={isOptionsLoading}
                name='defaultCountry'
                options={countryOptions}
                className={styles.autoWithSelect}
              />
            </Col>
          </Form.Group>
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.form.titles.rtbDeliverySetting')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.InputGroup
            label={i18next.t<string>('agency.gogan.targetBudgetMinimum')}
            name='targetBudgetMinimum'
            type='number'
            min={1}
            prefix={formik.values.currency ? formik.values.currency : currencyOptions[0].value}
          />
          <FormikField.InputGroup
            label={i18next.t<string>('agency.campaignBudgetMinimum')}
            name='campaignBudgetMinimum'
            type='number'
            min={1}
            prefix={formik.values.currency ? formik.values.currency : currencyOptions[0].value}
          />
          <FormikField.Tags
            label={i18next.t('agency.segmentCountryStr')}
            name='segmentCountry'
            suggestions={segmentCountryOptions}
            onChange={partial(onSegmentTagsChange, 'segmentCountry')}
          />
          <FormikField.Tags
            label={i18next.t('agency.segmentLabelStr')}
            name='segmentLabel'
            suggestions={segmentLabelOptions}
            onChange={partial(onSegmentTagsChange, 'segmentLabel')}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.interstitial')}
            name='interstitial'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.marginSetting')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.InputGroup
            label={i18next.t<string>('agency.sysPercentIn')}
            name='sysPercentIn'
            type='number'
            min={0}
            postfix='%'
          />
          <FormikField.InputGroup
            label={i18next.t<string>('agency.agcPercent')}
            name='agcPercent'
            type='number'
            min={0}
            postfix='%'
          />
          <FormikField.InputGroup
            label={i18next.t<string>('agency.addonProps.maxOrderProfit')}
            name='addonProps.maxOrderProfit'
            type='number'
            min={0}
            postfix='%'
          />
        </div>
      </fieldset>
      {!isNew &&
        <fieldset>
          <legend>
            <span>{i18next.t<string>('agency.rebateRate')}</span>
          </legend>
          <div className={styles.fieldContent}>
            <FormikField.Label
              label={i18next.t<string>('agency.currentRate')}
              name='currentRate'
              formatter={percentFormatter}
            />
            {renderFutureRateRate()}
            {editRebateRate ?
              <FormikField.DatePicker
                name='effectDate'
                label={i18next.t<string>('agency.effectDate')}
                showTimePicker={false}
                minDate={moment().startOf('day').add(1, 'day').format('YYYY-MM-DD')}
                fieldContentWidth='120px'
              /> :
              <FormikField.Label
                name='effectDate'
                label={i18next.t<string>('agency.effectDate')}
                formatter={effectDateFormatter}
              />
            }
          </div>
        </fieldset>
      }
    </div>
  );
};

export default connect<AgencyBasicFormProps>(AgencyBasicForm);
