import { DefaultAsyncSourceSpanModel } from 'components/AsyncSourceSpan/AsyncSourceSpanModel';
import { AbstractCustomInputComponentModel } from 'components/CampaignLimitation';
import { ItemType } from 'components/CampaignLimitation/SelectItemComponent';
import { Limitation } from 'core/limitation/Limitation';
import { OPERATE, OPERATES } from 'enum/Operate';
import { ElementType } from 'react';

export const DEFAULT_INVENTORY = 'default';

export enum LIMITATION_TYPE {
  CAMPAIGN = 'campaign',
  CREATIVE = 'creative'
}

export const operateTitles = {
  [OPERATE.INCLUDE]: {
    0: 'limitation.labels.incNoValue',
    1: 'limitation.labels.incHaveValue'
  },
  [OPERATE.EXCLUDE]: {
    0: 'limitation.labels.excNoValue',
    1: 'limitation.labels.excHaveValue'
  },
  [OPERATE.PREFERRED]: {
    0: 'limitation.labels.preferNoValue',
    1: 'limitation.labels.preferHaveValue'
  },
  [OPERATE.NONPREFERRED]: {
    0: 'limitation.labels.nonpreferNoValue',
    1: 'limitation.labels.nonpreferHaveValue'
  }
} as const;

export type DefaultLimitationInventorySettings = {
  name: string;
  addonFeature?: string;
  hideLimitation?: false;
  hideInMenu?: false;
  ignoreValidateOption?: boolean;
  ignoreAddonFeature?: boolean;
  showWithLimitation?: string[];
  disable?: boolean;
  disableReason?: string;
  title?: string;
  singleSelect: boolean;
  itemSetting: {
    type: ItemType;
    categorySelectable: boolean;
    readonly: boolean;
    i18nPrefix?: string;
    hideExtra?: boolean;
  };
  i18nPrefix?: string;
  requiredOperate?: string[];
  searchPlaceholder: string;
  supportOperates?: OPERATES[];
  extra?: any;
  sidebarTitleKey?: string;
  needInitExtra?: boolean;
  component: ElementType;
  cb?:
    | (() => Promise<SelectOptions[]>)
    | (() => SelectOptions[])
    | (() => SelectOptions[] | undefined);
  preCommentPropsGetter?: (
    limitation: SelectOptions,
    needShow: boolean
  ) => { model: DefaultAsyncSourceSpanModel } | undefined;
  postCommentPropsGetter?: (
    limitation: SelectOptions,
    needShow: boolean
  ) => { model: DefaultAsyncSourceSpanModel } | undefined;
  customInputModelGetter?: () => AbstractCustomInputComponentModel;
  tipsConfig?: {
    debounceTrigger?: number;
    renderTips: (limitation: SelectOptions) => JSX.Element | undefined;
  };
  validator?: (
    operate: string,
    value: SelectOptions[],
    limitationValue: { [key: string]: Limitation[] }
  ) => string | undefined;
};

export type HiddenLimitationInventorySettings = OverrideAndNeverOthers<
  DefaultLimitationInventorySettings,
  {
    name: string;
    hideLimitation: true;
    ignoreValidateOption: true;
    ignoreAddonFeature: true;
  }
>;

export type HiddenMenuLimitationInventorySettings = Override<
  DefaultLimitationInventorySettings,
  {
    name: string;
    hideInMenu: true;
  }
>;

export type EmptyLimitationInventorySettings = OverrideAndNeverOthers<
  DefaultLimitationInventorySettings,
  {
    name: 'default';
  }
>;

export type LimitationInventorySettings =
  | DefaultLimitationInventorySettings
  | HiddenMenuLimitationInventorySettings
  | HiddenLimitationInventorySettings
  | EmptyLimitationInventorySettings;
