import ToggleButtons from 'components/common/ToggleButtons/ToggleButtons';
import { useCallback, useMemo, useState } from 'react';
import i18n from 'i18n';
import { startCase, find } from 'lodash';
import styles from './thirdPartyCreativePreview.module.scss';

enum PREVIEWTYPE {
  MOBILE = 'mobile',
  DESKTOP = 'desktop'
}

const ppstudioIframeSandboxSetting = [
  'allow-scripts', 'allow-popups', 'allow-modals', 'allow-orientation-lock',
  'allow-pointer-lock', 'allow-popups-to-escape-sandbox', 'allow-presentation',
  'allow-top-navigation', 'allow-top-navigation-by-user-activation'
];

const iframeSandboxSetting = ppstudioIframeSandboxSetting.concat('allow-same-origin');

const previewOptions = [
  { label: i18n.t<string>(`ppsCreativeDetail.labels.toggle${startCase(PREVIEWTYPE.MOBILE)}`), value: PREVIEWTYPE.MOBILE },
  { label: i18n.t<string>(`ppsCreativeDetail.labels.toggle${startCase(PREVIEWTYPE.DESKTOP)}`), value: PREVIEWTYPE.DESKTOP }
];

export const ThirdPartyCreativePreview = ({
  defaultDeviceType = PREVIEWTYPE.MOBILE,
  showPreviewOptions = true,
  iframeSrc,
  iframeSrcDoc
}: {
  defaultDeviceType?: PREVIEWTYPE,
  showPreviewOptions?: boolean,
  iframeSrc?: string,
  iframeSrcDoc?: string
}) => {

  const [previewType, setPreviewType] = useState<PREVIEWTYPE>(defaultDeviceType);

  const updatePreviewType = useCallback((previewType) => {
    const previewTypeKey = find(Object.keys(PREVIEWTYPE), key => PREVIEWTYPE[key] === previewType);
    if (previewTypeKey) {
      setPreviewType(PREVIEWTYPE[previewTypeKey]);
    } else {
      setPreviewType(PREVIEWTYPE.MOBILE);
    }
  }, []);

  const wrappedIframeSrcDoc = useMemo(() => {
    if (!iframeSrcDoc) {
      return undefined;
    }
    if (iframeSrcDoc.includes('ppstudio')) {
      return iframeSrcDoc.replace('ins class="ppstudio"', 'ins class="ppstudio preview"');
    }
    return `<div/><iframe srcDoc="${iframeSrcDoc.replaceAll('"', '&quot;')}" frameBorder="0" sandbox="${iframeSandboxSetting.join(' ')}"></iframe>`;
  }, [iframeSrcDoc]);

  const targetIframeSetting = useMemo(() => {
    if (iframeSrcDoc && iframeSrcDoc.includes('ppstudio')) {
      return ppstudioIframeSandboxSetting;
    }
    return iframeSandboxSetting;
  }, [iframeSrcDoc]);

  const renderContainer = useCallback(() => {

    switch (previewType) {
      case PREVIEWTYPE.DESKTOP:
        return (
          <div className={styles.desktopItem}>
            <div className={styles.desktopScreen}>
              <iframe
                src={iframeSrc}
                srcDoc={wrappedIframeSrcDoc}
                title={'layout_preview'}
                frameBorder='0'
                sandbox={targetIframeSetting.join(' ')}
              />
            </div>
            <div className={styles.desktopHandler}/>
            <div className={styles.desktopBottom}/>
          </div>
        );
      case PREVIEWTYPE.MOBILE:
      default:
        return (
          <div className={styles.mobileItem}>
            <ul className={styles.mobileVolume}>
              <li/>
              <li/>
            </ul>
            <div className={styles.mobileScreen}>
              <iframe
                src={iframeSrc}
                srcDoc={wrappedIframeSrcDoc}
                title={'layout_preview'}
                frameBorder='0'
                sandbox={targetIframeSetting.join(' ')}
              />
            </div>
            <ul className={styles.mobileSleep}>
              <li/>
            </ul>
          </div>
        );
    }
  }, [iframeSrc, wrappedIframeSrcDoc, targetIframeSetting, previewType]);

  return (
    <div className={styles.thirdPartyCreativePreview}>
      {showPreviewOptions &&
        <ToggleButtons
          className={styles.toggleButtons}
          name={'previewType'}
          value={previewType}
          options={previewOptions}
          onChange={updatePreviewType}
        />}
      <div className={styles.previewContainer}>
        {renderContainer()}
      </div>
    </div>
  );
};
