import axios, { AxiosInstance } from 'axios';
import client from './RestClient';
import { CloudResourceWebService } from './CloudResourceWebService';
import config from 'config';

export interface GCPCloudStorageWebService extends CloudResourceWebService {
  getGCSToken (): Promise<string>;
  uploadFileToCloud (token: string, file: File, bucket: string, filePath: string): Promise<void>;
}

export class RestfulGCPCloudStorageWebService implements GCPCloudStorageWebService {

  constructor (private restClient: AxiosInstance = axios, private pmaxRestClient: AxiosInstance = client) {
  }

  async getLimitationPreSet (type: string): Promise<Object> {
    return {};
  }

  async getOrderExternalTypes (): Promise<SelectOptions[]> {
    try {
      const resp = await fetch(`https://storage.googleapis.com/${config.cloudStorageConfig.bucket}/crmOrderType.json`);
      const data = await resp.json();
      return data;
    } catch {
      return [];
    }
  }

  async getGCSToken (): Promise<string> {
    const resp = await this.pmaxRestClient.get('/v2/creatives/blob-sas-token');
    return resp.data.sasToken;
  }

  async uploadFileToCloud (token: string, file: File, bucket: string, filePath: string) {
    await this.restClient.post(
      `https://storage.googleapis.com/upload/storage/v1/b/${bucket}/o?uploadType=media&name=${filePath}`,
      file,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': file.type
        }
      }
    );
  }
}
