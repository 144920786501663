import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import i18n from 'i18n';
import { Button } from 'react-bootstrap';
import { useCallback } from 'react';
import { defaultTo } from 'lodash';
import { Pmp } from 'core/pmp/Pmp';
import { DeliverType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { PmpListColumns, usePmpListModel } from 'containers/PmpOrder/PmpListModel';
import { PmpCampaign, PmpFormModel } from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignForm/PmpFormModel';

export const SelectPmpStep: React.FC<{
  pmpFormModel: PmpFormModel;
  goNext: (pmp: Pmp, campaign: PmpCampaign) => void;
  goLast: () => void;
}> = ({
  pmpFormModel,
  goNext,
  goLast
}) => {

  const {
    pmp,
    pmpListOfOrder,
    pmpSpaceOptions
  } = pmpFormModel;

  const {
    loading,
    columns,
    filterdPmps,
    selectedPmps,
    onHandleSelect
  } = usePmpListModel(
    defaultTo(pmpSpaceOptions, []),
    [],
    true,
    pmp ? [pmp.id] : [],
    pmpListOfOrder,
    false,
    false,
    false
  );

  const completeAndCheck = useCallback(() => {
    const pmp = filterdPmps.find(pmp => pmp.id === selectedPmps[0]);
    if (!pmp) return;

    const bidPrice = 0.01;
    const campaignBasic = {
      name: pmp.name,
      budget: pmp.budget,
      startDate: pmp.startTime,
      endDate: pmp.endTime,
      priceModel: RtbCampaignPlanType.FCPM,
      optimize: L2ObjectOptimizationGoal.IMPRESSIONS,
      orderPrice: bidPrice,
      bidPrice: bidPrice,
      deliverType: DeliverType.ACCELERATED,
      bidStrategy: BidStrategy.LOWEST_COST_WITH_BID_CAP,
      dayPart: pmp.dayPart
    };
    goNext(pmp, {
      basic: campaignBasic,
      limitations: {
        other: [{
          op: 'inc',
          type: 'dealId',
          value: [{ label: pmp.dealId, value: pmp.dealId }]
        }],
        include: [{
          op: 'inc',
          type: 'adspace',
          value: pmp.spaces.map(space => {
            const spaceOption = pmpSpaceOptions.find(option => option.value === space);
            return { label: spaceOption ? spaceOption.label : space, value: space };
          })
        }]
      }
    });
  }, [filterdPmps, selectedPmps, pmpSpaceOptions, goNext]);

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      {loading && <LoadingIndicator/>}
      <StickableBootstrapTable
        customPadding={false}
        keyField={'id'}
        data={filterdPmps}
        columns={columns}
        noDataIndication={i18n.t<string>('selectPmpStep.placeholders.noData')}
        defaultSorted={[{
          dataField: PmpListColumns.NAME,
          order: 'desc'
        }]}
        selectedRows={selectedPmps}
        onSelect={onHandleSelect}
        hidePagination={true}
      />
      <div style={{ padding: '30px' }}>
        <Button disabled={selectedPmps.length === 0} style={{ marginRight: '20px' }} size='sm' onClick={completeAndCheck}>{i18n.t<string>('common.buttons.completeAndCheck')}</Button>
        <Button variant='secondary' size='sm' onClick={goLast}>{i18n.t<string>('common.buttons.back')}</Button>
      </div>
    </div>
  );
};
