import { AccountListModel, DefaultAccountListModel, OEMAccountListModel } from 'components/AccountList';
import { AccountManager, Account } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

export type AccountsHomeState = {

  readonly loading: boolean;
  readonly error: Error | null;
  readonly selectAgency?: number;
};

export interface AccountsHomeModel {

  refresh (): Promise<void>;
  getAccountListModel (): AccountListModel;
  onAgencyChange (agencyId): void;

  readonly agencyOptions?: SelectOptions[];
  readonly state: AccountsHomeState;
  readonly event: UpdateEventListener<AccountsHomeModel>;
  readonly rootPath: string;
}

export type AccountsHomeProps = {

  readonly model: AccountsHomeModel;
};

export class DefaultAccountsHomeModel implements AccountsHomeModel {
  loading: boolean;
  error: Error | null;
  event: FireableUpdateEventListener<AccountsHomeModel>;

  manager: AccountManager;
  accounts: Array<Account> = [];
  rootPath: string = '/accounts';

  constructor (manager: AccountManager, protected isSysAdmin: boolean) {
    this.manager = manager;
    this.error = null;
    this.loading = false;
    this.event = new FireableUpdateEventListener<AccountsHomeModel>();
  }

  get state (): AccountsHomeState {
    return {
      error: this.error,
      loading: this.loading
    };
  }

  getAccountListModel (): AccountListModel {
    return new DefaultAccountListModel(this.accounts, this.isSysAdmin);
  }

  async refresh (): Promise<void> {
    this.notify(true);
    try {
      this.accounts = await this.manager.getAccounts();
      this.notify(false);
    } catch (error) {
      this.notify(false, error as Error);
    }
  }

  onAgencyChange (_) {}

  notify (loading: boolean, error: Error | null = null) {
    this.error = error;
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class OEMAccountsHomeModel extends DefaultAccountsHomeModel {

  rootPath: string = '/oem-accounts';
  selectAgency?: number;
  filteredAccounts: Array<Account> = [];

  constructor (
    public agencyOptions: SelectOptions[],
    manager: AccountManager,
    isSysAdmin: boolean
  ) {
    super(manager, isSysAdmin);
  }

  getAccountListModel (): AccountListModel {
    return new OEMAccountListModel(this.filteredAccounts);
  }

  get state (): AccountsHomeState {
    return {
      error: this.error,
      loading: this.loading,
      selectAgency: this.selectAgency
    };
  }

  async refresh (): Promise<void> {
    this.notify(true);
    try {

      this.accounts = await this.manager.getOemAccounts();
      this.updateFilteredAccounts();
      this.notify(false);
    } catch (error) {
      this.notify(false, error as Error);
    }
  }

  onAgencyChange = (agencyId) => {
    this.selectAgency = agencyId;
    this.updateFilteredAccounts();
    this.notify(false);
  }

  updateFilteredAccounts = () => {
    this.filteredAccounts = this.accounts.filter(account => !this.selectAgency || account.agencyId === this.selectAgency);
  }
}
