import { usePmpListModel } from './PmpListModel';
import { useCallback } from 'react';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18n';
import { Link } from 'react-router-dom';
import { Page } from 'components/Page/Page';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { PmpDurationError } from './PmpDurationValidator';
import { Pmp, PmpStatus } from 'core/pmp/Pmp';
import { Modal } from 'components/common/Modal/Modal';

export type PmpListProps = {
  spaceOptions: SelectOptions[];
  agencyOptions: SelectOptions[];
};

export const PmpList: React.FunctionComponent<PmpListProps> = ({
  spaceOptions,
  agencyOptions
}) => {

  const {
    loading,
    columns,
    pageable,
    filterdPmps,
    selectedPmps,
    searchString,
    batchOperations,
    pmpDurationError,
    cancelActivate,
    onHandleSearch,
    onHandleSelect,
    handleTableChange,
    onHandleSelectPage
  } = usePmpListModel(spaceOptions, agencyOptions, false);

  const renderRowBtns = useCallback((pmp: Pmp) => {
    const editable = !pmp.campaignId && pmp.status !== PmpStatus.EXPIRED;
    return [(
      <IconWithTooltip
        key={`edit-btn-${pmp.id}`}
        disabled={!editable}
        icon={faPencilAlt}
        tooltipProps={{
          id: `edit-pmp-${pmp.id}`,
          link: `/pmp/${pmp.id}/edit`,
          tooltip: editable ?
          i18n.t<string>('pmpSetupFlowPage.titles.edit') :
          i18n.t<string>('pmpList.hints.cannotEdit')
        }}
      />
    )];
  }, []);

  const renderListOperations = useCallback(() => [
    <Link
      key='new-btn'
      to={'/pmp/new'}
      className={'btn btn-tertiary btn-sm'}
    >
      {i18n.t<string>('pmpList.buttons.create')}
    </Link>
  ]
  , []);

  const renderActivateError = useCallback((errorData: PmpDurationError & Pmp) => {
    return (
      <Modal
        title={i18n.t<string>('pmpActivateModal.titles.sponsorshipConflict')}
        primaryButton={{
          title: i18n.t<string>('common.buttons.ok'),
          callback: cancelActivate
        }}
        dismiss={cancelActivate}
        animation={false}
      >
        <div>
          <div>{i18n.t<string>('pmpActivateModal.hints.conflictWith')}</div>
          <ul style={{ paddingInlineStart: '20px' }}>
            {errorData.duration && <li>{i18n.t<string>('pmpActivateModal.hints.durationConflict')}</li>}
            <li>{i18n.t<string>('pmpActivateModal.hints.spaceConflict')}</li>
          </ul>
          <div>{errorData.campaignId ? i18n.t<string>('pmpActivateModal.hints.boundPmpSuggest') : i18n.t<string>('pmpActivateModal.hints.unboundPmpSuggest')}</div>
        </div>
      </Modal>
    );
  }, [cancelActivate]);

  return (
    <Page title={i18n.t<string>('pmpList.title')}>
      {loading && <LoadingIndicator/>}
      {pmpDurationError && renderActivateError(pmpDurationError)}
      <StickableBootstrapTable
        remote
        customPadding={false}
        keyField={'id'}
        data={filterdPmps}
        columns={columns}
        noDataIndication={i18n.t<string>('pmpList.placeholders.noData')}
        pagination={{
          page: pageable.page,
          sizePerPage: pageable.sizePerPage,
          totalSize: pageable.totalCount
        }}
        selectedRows={selectedPmps}
        onSelect={onHandleSelect}
        onSelectPage={onHandleSelectPage}
        defaultSearchString={searchString}
        searchbarPlaceholder={i18n.t<string>('pmpList.placeholders.searchbar')}
        batchOperaionConfig={batchOperations}
        onHandleSearch={onHandleSearch}
        renderListOperations={renderListOperations}
        renderRowBtns={renderRowBtns}
        onTableChange={handleTableChange}
      />
    </Page>
  );
};
