import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { FormikProps } from 'formik';
import { validateEmpty, validateUrl } from 'utils/ValidateUtils';
import _ from 'lodash';
import { CreativeFormBasicData, FormContentModel } from './FormContent/FormContentModel';
import { CreativeManager } from 'core/creative/CreativeManager';
import i18n from 'i18next';
import { CreativeType } from 'core/creative/Creative';
import { UrlMacroInstruction } from './FormContent/UrlMacroInstruction';
import styles from './creativeBasicForm.module.scss';
import { FormConfig } from 'components/common/form/FormConfig';
import { renderEndTime, renderStartTime } from './CreativeDayRangeRenderer';

export interface CreativeBasicFormModel {
  readonly type: string;
  readonly initCreative: CreativeFormBasicData;
  readonly state: CreativeBasicFormState;
  readonly event: UpdateEventListener<CreativeBasicFormModel>;
  readonly tenmaxCategories: Array<SelectOptions>;
  readonly enabledCreativeTypes: string[];
  readonly needLandingUrl: boolean;
  setFormikProps (props: FormikProps<any>): void;
  validate (): any;
  getCreativeBasicValue (): any;
  getContentModelByCreativeType (): FormContentModel | undefined;
  onCreativeTypeChange (newType): void;
  onUnmount (handler?: number): void;
  setLimitationHook (hook: (operate: string, limitationType: string, label: string, value: string) => void): void;
  setShowStartTime (showStartTime: boolean): void;
  setShowEndTime (showEndTime: boolean): void;
  setHintModalData (data: {
    component: any,
    props: any
  }): void;
  triggerUrlMacroHint (): void;
  getInitFormConfig (): FormConfig;
}

export type CreativeBasicFormProps = {
  readonly model: CreativeBasicFormModel;
};

export type CreativeBasicFormState = {
  readonly loading: boolean;
  readonly needLandingUrl: boolean;
  readonly showStartTime: boolean;
  readonly showEndTime: boolean;
  readonly hintModalData?: any;
  readonly basicFields?: any[];
};

export class DefaultCreativeBasicFormModel implements CreativeBasicFormModel {
  type: string;
  event: FireableUpdateEventListener<CreativeBasicFormModel>;
  loading: boolean;
  initCreative: CreativeFormBasicData;
  contentModel?: FormContentModel;
  tenmaxCategories: Array<SelectOptions>;
  creativeManager: CreativeManager;
  enabledCreativeTypes: string[];
  showStartTime: boolean;
  showEndTime: boolean;
  hintModalData?: any;
  urlMacroHintModalData?: any;
  basicFields: any = [];
  formikProps?: FormikProps<CreativeFormBasicData>;
  currentCreativeType: CreativeType;

  constructor (
    type: string,
    creative: CreativeFormBasicData,
    tenmaxCategories: Array<SelectOptions>,
    creativeManager: CreativeManager,
    enabledCreativeTypes: CreativeType[],
    private onTypeChange: (creativeType) => void,
    private formContentModelGetter: (creativeType: CreativeType) => FormContentModel,
    private limitationHook: (operate: string, limitationType: string, label: string, value: string) => void,
    private forPmp: boolean = false
  ) {
    this.type = type;
    this.event = new FireableUpdateEventListener<CreativeBasicFormModel>();
    this.loading = false;
    this.initCreative = creative;
    this.showStartTime = !!creative.enableStartTime;
    this.showEndTime = !!creative.enableEndTime;
    this.contentModel = this.formContentModelGetter(creative.creativeType);
    this.contentModel.setLimitationHook(this.limitationHook);
    this.tenmaxCategories = tenmaxCategories;
    this.creativeManager = creativeManager;
    this.enabledCreativeTypes = enabledCreativeTypes.map(key => CreativeType[key]);
    this.currentCreativeType = creative.creativeType;
    this.updateBasicFields();
  }

  get needLandingUrl () {
    return this.contentModel ? this.contentModel.needBannerUrl : true;
  }

  get state (): CreativeBasicFormState {
    return {
      loading: this.loading,
      needLandingUrl: this.needLandingUrl,
      showStartTime: this.showStartTime,
      showEndTime: this.showEndTime,
      hintModalData: this.hintModalData,
      basicFields: this.basicFields
    };
  }

  updateBasicFields () {
    const creativeType = this.currentCreativeType;
    const enableSelectCreativeTypeModelTypes = ['create', 'createAndBind', 'fbCreateAndBindRTBCampaign', 'fbCreateAndBindAdSet'];
    this.basicFields = new FormConfig.FieldsBuilder()
      .addFormikSelect({
        label: i18n.t<string>('creativeSetupFlow.labels.creativeType'),
        name: 'creativeType',
        simpleValue: true,
        options: this.enabledCreativeTypes.map(type => {
          return {
            label: i18n.t<string>(`creativeType.${_.camelCase(type)}`),
            value: CreativeType[type]
          };
        }),
        onChange: this.onCreativeTypeChange,
        validate: validateEmpty
      }, !enableSelectCreativeTypeModelTypes.includes(this.type))
      .addFormikLabel({
        label: i18n.t<string>('creativeSetupFlow.labels.creativeType'),
        name: 'creativeType',
        formatter: value => i18n.t<string>(`creativeType.${_.camelCase(CreativeType[value])}`)
      }, enableSelectCreativeTypeModelTypes.includes(this.type))
      .addFormikSelect({
        label: i18n.t<string>('creativeSetupFlow.labels.tenmaxCategory'),
        name: 'tenmaxCategory',
        simpleValue: true,
        options: this.tenmaxCategories.map(category => {
          return {
            value: category.value,
            label: i18n.t<string>(`tenmaxCategory.labels.${category.label}`)
          };
        }),
        validate: validateEmpty
      })
      .addFormikInput({
        label: i18n.t<string>('creativeSetupFlow.labels.name'),
        name: 'name',
        validate: validateEmpty
      })
      .addFormikUrlInput({
        label: i18n.t<string>('creativeSetupFlow.labels.bannerUrl'),
        name: 'bannerUrl',
        className: styles.bannerUrl,
        validate: this.validateBannerUrl
      }, !this.needLandingUrl)
      .addFormikCustom({
        label: i18n.t<string>('creativeSetupFlow.labels.enableStartTime'),
        name: 'enableStartTime',
        render: _.partial(renderStartTime, this.showStartTime, this.setShowStartTime.bind(this))
      }, creativeType === CreativeType.ONE_FOR_ALL ||
        creativeType === CreativeType.ONE_FOR_ALL_DISPLAY ||
        creativeType === CreativeType.ONE_FOR_ALL_VIDEO ||
        this.forPmp
      )
      .addFormikCustom({
        label: i18n.t<string>('creativeSetupFlow.labels.enableEndTime'),
        name: 'enableEndTime',
        render: _.partial(renderEndTime, this.showEndTime, this.setShowEndTime.bind(this))
      }, creativeType === CreativeType.ONE_FOR_ALL ||
        creativeType === CreativeType.ONE_FOR_ALL_DISPLAY ||
        creativeType === CreativeType.ONE_FOR_ALL_VIDEO ||
        this.forPmp
      )
      .build();
  }

  getInitFormConfig = () => {
    return new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(this.basicFields)
          .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
          .build()
      ).build();
  }

  getContentModelByCreativeType = () => {
    return this.contentModel;
  }

  setShowStartTime = (showStartTime: boolean): void => {
    this.showStartTime = showStartTime;
    this.updateBasicFields();
    this.updateState(false);
  }

  setShowEndTime = (showEndTime: boolean): void => {
    this.showEndTime = showEndTime;
    this.updateBasicFields();
    this.updateState(false);
  }

  setLimitationHook (hook: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.limitationHook = hook;
    this.contentModel && this.contentModel.setLimitationHook(this.limitationHook);
  }

  setFormikProps (props: FormikProps<any>) {
    this.formikProps = props;
  }

  getCreativeBasicValue () {
    if (!this.formikProps) {
      return {};
    }
    return this.formikProps.values;
  }

  validateBannerUrl = (url) => {
    if (this.loading) {
      return i18n.t<string>('creativeSetupFlow.labels.analyzingHint');
    }

    return validateUrl(url);
  }

  validate = async () => {
    if (!this.formikProps) {
      return {};
    }

    const errors = await this.formikProps.validateForm();
    return errors;
  }

  onCreativeTypeChange = (creativeType) => {
    this.contentModel = this.formContentModelGetter(creativeType);
    this.contentModel.setLimitationHook(this.limitationHook);
    this.showStartTime = false;
    this.showEndTime = false;
    if (this.formikProps && this.contentModel) {
      const resetValue = {
        ...this.formikProps.values,
        creativeType,
        typeProperties: this.contentModel.getInitTypeProperties(),
        medias: {},
        enableNativeBanner: creativeType === CreativeType.NATIVE ? true : undefined,
        enableStartTime: undefined,
        enableEndTime: undefined
      };
      const enableAdLogo = this.contentModel.isAdLogoSupported(resetValue);
      if (enableAdLogo) {
        resetValue.adLogo = this.contentModel.defaultAdLogo;
      }
      this.formikProps.resetForm({ values: resetValue, errors: {} });
    }
    this.urlMacroHintModalData = undefined;
    this.hintModalData = undefined;
    this.currentCreativeType = creativeType;
    this.updateBasicFields();
    this.onTypeChange(creativeType);
  }

  setHintModalData = (data?: any) => {
    this.hintModalData = data;
    if (data) {
      this.urlMacroHintModalData = undefined;
    }
    this.updateState(false);
  }

  triggerUrlMacroHint = () => {
    if (this.urlMacroHintModalData) {
      this.urlMacroHintModalData = undefined;
    } else {
      this.urlMacroHintModalData = {
        component: UrlMacroInstruction,
        props: {
          onClose: _.partial(this.setHintModalData, undefined)
        }
      };
    }
    this.hintModalData = this.urlMacroHintModalData;
    this.updateState(false);
  }

  onUnmount (handler?: number) {
    handler && this.event.remove(handler);
    if (this.formikProps) {
      this.initCreative = this.formikProps?.values;
    }
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
