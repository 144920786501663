import React from 'react';
import Select from 'components/common/Select/Select';
import styles from './dayparts.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import classNames from 'classnames/bind';
import Tips from 'components/common/Tips/Tips';
import { DaypartsDay, DaypartsHour, Dayparts as DaypartsType, isDayOfDaypart, isHourOfDaypart } from 'core/dayparts/Dayparts';
import { FormikContextType } from 'formik';
const cx = classNames.bind(styles);

export const getDayPartSummary = (dayPart: DaypartsType) => {
  let dayPartValue = _.omitBy(_.omit(dayPart, 'enabled'), _.isEmpty);
  return Object.keys(dayPartValue).map(day => {
    return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t<string>('daypart.labels.hourUnit')}: ${dayPartValue[day].join(', ')}`;
  }).join('\r\n');
};

export function getDayOfWeekLabelByValue (value: number): string {
  switch (value) {
    case 1:
      return i18n.t<string>('daypart.labels.monday');
    case 2:
      return i18n.t<string>('daypart.labels.tuesday');
    case 3:
      return i18n.t<string>('daypart.labels.wednesday');
    case 4:
      return i18n.t<string>('daypart.labels.thursday');
    case 5:
      return i18n.t<string>('daypart.labels.friday');
    case 6:
      return i18n.t<string>('daypart.labels.saturday');
    case 7:
      return i18n.t<string>('daypart.labels.sunday');
    default:
      return '';
  }
}

export function getInitDaypart (): DaypartsType {
  return {
    1 : Array.from({ length: 24 }, (_, index) => index) as DaypartsHour[],
    2 : Array.from({ length: 24 }, (_, index) => index) as DaypartsHour[],
    3 : Array.from({ length: 24 }, (_, index) => index) as DaypartsHour[],
    4 : Array.from({ length: 24 }, (_, index) => index) as DaypartsHour[],
    5 : Array.from({ length: 24 }, (_, index) => index) as DaypartsHour[],
    6 : Array.from({ length: 24 }, (_, index) => index) as DaypartsHour[],
    7 : Array.from({ length: 24 }, (_, index) => index) as DaypartsHour[],
    enabled: true
  };
}

export type DaypartsProps = {
  name: string;
} & FormikContextType<unknown & {[key: string]: DaypartsType}>;

export type DaypartsState = {
  dayPart: DaypartsType;
};

type DaypartQuickOption = {
  label: string;
  value: number;
  sets: { days: DaypartsDay[]; hours: DaypartsHour[] }[];
};

export class Dayparts extends React.Component<DaypartsProps, DaypartsState> {

  state: {
    dayPart: DaypartsType
  } = {
    dayPart: {
      ...getInitDaypart()
    }
  };

  componentDidMount () {
    let dayPart = this.props.values[this.props.name];
    if (dayPart && Object.keys(dayPart).length > 1) {
      this.setState({ dayPart: dayPart });
    } else {
      this.props.setFieldValue(this.props.name, this.state.dayPart);
    }
  }

  shouldComponentUpdate (nextProps: DaypartsProps, nextState: DaypartsState) {
    if (
      nextState.dayPart === this.state.dayPart &&
      this.props.errors[this.props.name] === nextProps.errors[this.props.name] &&
      this.props.submitCount === nextProps.submitCount &&
      this.props.touched[this.props.name] === nextProps.touched[this.props.name]
    ) {
      return false;
    }

    return true;
  }

  onCellClick = (dayValue: DaypartsDay, hourValue: DaypartsHour) => {
    let hours = _.defaultTo(this.state.dayPart[dayValue], []);
    let indexOfValue = hours.indexOf(hourValue);
    if (indexOfValue === -1) {
      hours = this.addHour(hours, hourValue);
    } else {
      hours.splice(indexOfValue, 1);
    }

    let newDaypart: DaypartsType = {
      ...this.state.dayPart,
      [dayValue]: hours
    };

    this.setState({ dayPart: newDaypart }, () => {
      this.props.setFieldValue(this.props.name, newDaypart);
    });
  }

  addHour = (hourArr: DaypartsHour[] | undefined, hour: DaypartsHour): DaypartsHour[] => {
    const hours = hourArr ? hourArr.concat(hour) : [hour];
    return _.uniq(hours).sort((a, b) => {
      return a - b;
    });
  }

  onHourColumnClick = (hourValue: DaypartsHour) => {
    let dayPart = this.state.dayPart;
    let hasHourNotSelected = false;
    for (let dayValue = 1; dayValue < 8; ++dayValue) {
      if (dayPart[dayValue].indexOf(hourValue) === -1) {
        hasHourNotSelected = true;
        break;
      }
    }

    let newDaypart: DaypartsType;
    if (hasHourNotSelected) {
      newDaypart = {
        1: this.addHour(dayPart[1], hourValue),
        2: this.addHour(dayPart[2], hourValue),
        3: this.addHour(dayPart[3], hourValue),
        4: this.addHour(dayPart[4], hourValue),
        5: this.addHour(dayPart[5], hourValue),
        6: this.addHour(dayPart[6], hourValue),
        7: this.addHour(dayPart[7], hourValue),
        enabled: true
      };
    } else {
      newDaypart = {
        1: _.without(dayPart[1], hourValue),
        2: _.without(dayPart[2], hourValue),
        3: _.without(dayPart[3], hourValue),
        4: _.without(dayPart[4], hourValue),
        5: _.without(dayPart[5], hourValue),
        6: _.without(dayPart[6], hourValue),
        7: _.without(dayPart[7], hourValue),
        enabled: true
      };
    }

    this.setState({ dayPart: newDaypart }, () => {
      this.props.setFieldValue(this.props.name, newDaypart);
    });
  }

  onDayOfWeekClick = (dayValue: DaypartsDay) => {
    let hours = this.state.dayPart[dayValue];
    let newHours: DaypartsHour[] = hours && hours.length === 24
      ? []
      : Array.from({ length: 24 }, (_, index) => index) as DaypartsHour[];
    let newDaypart: DaypartsType = {
      ...this.state.dayPart,
      [dayValue]: newHours
    };

    this.setState({ dayPart: newDaypart }, () => {
      this.props.setFieldValue(this.props.name, newDaypart);
    });
  }

  onDayPartOptionsChanged = (event: DaypartQuickOption) => {
    let newDaypart: DaypartsType = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      enabled: true
    };

    event.sets.forEach(set => {
      set.days.forEach((day: DaypartsDay) => {
        newDaypart[day] = Array.from(set.hours);
      });
    });

    this.setState({ dayPart: newDaypart }, () => {
      this.props.setFieldValue(this.props.name, newDaypart);
    });
  }

  findDaypartOptionByDaypart = (daypart: DaypartsType) => {
    if (_.isEmpty(daypart)) {
      return _.find(this.daypartOptions, option => option.value === 1);
    }

    const hoursDaysMap = Object.keys(daypart).filter(key => key !== 'enabled').reduce((acc, day) => {
      const hourKey = daypart[+day].join(',');
      if (hourKey !== '') {
        const daysWithSameHours = [..._.defaultTo(acc[hourKey], []), +day];
        acc[hourKey] = daysWithSameHours;
      }
      return acc;
    }, {});

    if (_.isEmpty(hoursDaysMap)) {
      return;
    }

    return _.find(this.daypartOptions, option => {
      return Object.keys(hoursDaysMap).every(hours => {
        const days = hoursDaysMap[hours];
        return option.sets.some(set => {
          return set.hours.join(',') === hours && set.days.join(',') === days.join(',');
        });
      });
    });
  }

  renderDayOfWeek = () => {
    return Array.from({ length: 8 }, (_, index: number) => {
      const clickFunc = () => isDayOfDaypart(index) ? this.onDayOfWeekClick(index) : {};
      return (
        <div key={`dayOfWeek-${index}`} className={styles.dayOfWeek} onClick={clickFunc}>
          {getDayOfWeekLabelByValue(index)}
        </div>
      );
    });
  }

  renderContent = () => {
    return Array.from({ length: 8 }, (_, rowIndex: number) => {
      return (
        <div key={`cell-row${rowIndex}`} className={styles.row}>
          {
            rowIndex === 0 ?
              this.renderHeaders() :
              Array.from({ length: 24 }, (_, columnIndex: number) => {
                if (!isDayOfDaypart(rowIndex) || !isHourOfDaypart(columnIndex)) {
                  throw new Error('Invalid day or hour value');
                }
                let className;
                let hours = this.state.dayPart[rowIndex];
                hours && hours.indexOf(columnIndex) >= 0 ? className = [styles.selected, styles.cell].join(' ') : className = styles.cell;

                const clickFunc = () => this.onCellClick(rowIndex, columnIndex);
                return (
                  <div key={`cell-${rowIndex}${columnIndex}`} className={className} onClick={clickFunc}/>
                );
              })
          }
        </div>
      );
    });
  }

  renderHeaders = () => {
    return Array.from({ length: 24 }, (_, index: number) => {
      if (!isHourOfDaypart(index)) {
        throw new Error('Invalid hour value');
      }
      const onClick = () => this.onHourColumnClick(index);
      return (
        <div key={`header-${index}`} className={[styles.cell, styles.header].join(' ')} onClick={onClick}>
          {index}
        </div>
      );
    });
  }

  get daypartOptions (): DaypartQuickOption[] {
    return [
      // { label : "選一個時段", value : "0", sets:[ {days:[0,1,2,3,4,5,6], hours:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]}]},
      { label: i18n.t<string>('daypart.labels.allDay'), value: 1, sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t<string>('daypart.labels.morning'), value: 4, sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [6, 7, 8, 9, 10, 11] }] },
      { label: i18n.t<string>('daypart.labels.afternoon'), value: 2, sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [12, 13, 14, 15, 16, 17] }] },
      { label: i18n.t<string>('daypart.labels.evening'), value: 3, sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t<string>('daypart.labels.weekday'), value: 5, sets: [{ days: [1, 2, 3, 4, 5], hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t<string>('daypart.labels.weekend'), value: 6, sets: [{ days: [6, 7], hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t<string>('daypart.labels.conversionOptimize'), value: 7, sets: [{ days: [6, 7], hours: [0, 8, 9, 10, 19, 20, 21, 22, 23] }, { days: [1, 2, 3, 4, 5], hours: [0, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 23] }] },
      { label: i18n.t<string>('daypart.labels.midnightDisabled'), value: 8, sets: [{ days: [1, 2, 3, 4, 5, 6, 7], hours: [0, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] }] },
      { label: i18n.t<string>('daypart.labels.mobileOptimize'), value: 9, sets: [{ days: [6, 7], hours: [0, 1, 6, 7, 8, 9, 10, 11, 13, 15, 16, 17, 19, 23] }, { days: [1, 2, 3, 4, 5], hours: [0, 1, 6, 7, 8, 9, 11, 12, 13, 14, 16, 17, 19, 20, 23] }] },
      { label: i18n.t<string>('daypart.labels.pcOptimize'), value: 10, sets: [{ days: [6, 7], hours: [0, 1, 8, 9, 10, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23] }, { days: [1, 2, 3, 4, 5], hours: [0, 1, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 23] }] }
    ];
  }

  render () {
    const touched = _.defaultTo(this.props.submitCount, 0) > 0 || this.props.touched[this.props.name];
    const error = this.props.errors[this.props.name];
    const hasError = error !== undefined && touched;
    const daypartOptionClass = cx(styles.daypartOptions, { error: hasError });
    const daypartClass = cx(styles.dayPart, { error: hasError });
    return (
      <div>
        <Select
          className={daypartOptionClass}
          options={this.daypartOptions}
          name='dayPart'
          value={this.findDaypartOptionByDaypart(this.state.dayPart)}
          placeholder={i18n.t<string>('daypart.labels.optionsPlaceholder')}
          onChange={this.onDayPartOptionsChanged}
        />
        <div className={daypartClass}>
          <div className={styles.dayOfWeekColumn}>
            {this.renderDayOfWeek()}
          </div>
          <div>
            <div className={styles.hint}>
              <label>{i18n.t<string>('daypart.labels.am')}</label>
              <label>{i18n.t<string>('daypart.labels.pm')}</label>
            </div>
            {this.renderContent()}
          </div>
        </div>
        {hasError && <Tips>{error}</Tips>}
      </div>
    );
  }
}
