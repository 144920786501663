import { getDayOfWeekLabelByValue } from 'components/Dayparts/Dayparts';
import { Dayparts } from 'core/dayparts/Dayparts';
import { Pmp, PmpFormType } from 'core/pmp/Pmp';
import { DefaultPmpManager } from 'core/pmp/PmpManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { defaultTo, get, isEmpty, omit, uniq } from 'lodash';
import moment from 'moment';
import { useCallback, useRef } from 'react';

const pmpManager = new DefaultPmpManager();
export type PmpDurationError = {
  spaces: string[];
  duration?: string[];
  dayPart?: string[];
} | undefined;

export const usePmpDurationValidator = (): {
  loading: boolean;
  fetchPmpWithinDuration: (startTime: string, endTime: string) => Promise<Pmp[] | undefined>;
  validatePmpDuration: (pmp: Pmp | PmpFormType) => PmpDurationError | undefined;
  clearCacheWithin: (startTime: string, endTime: string) => void;
} => {

  const {
    loading,
    callAPIs
  } = useCallAPI();

  const pmpWithinDurationMap = useRef<{ [key: string]: Pmp[] }>({}); // key: startTime-endTime

  const getMapKeyPattern = useCallback((startTime: string, endTime: string) => `${startTime}-${endTime}`, []);

  const fetchPmpWithinDuration = useCallback(async (startTime: string, endTime: string): Promise<Pmp[] | undefined> => {
    if (pmpWithinDurationMap.current[getMapKeyPattern(startTime, endTime)]) {
      return;
    }
    return new Promise((resolve) => {
      callAPIs([() => pmpManager.getPmpsWithin(startTime, endTime)], (pmps: Pmp[]) => {
        pmpWithinDurationMap.current = {
          ...pmpWithinDurationMap.current,
          [getMapKeyPattern(startTime, endTime)]: pmps
        };
        resolve(pmps);
      });
    });
  }, [callAPIs, getMapKeyPattern]);

  const getConflictDaypartDescriptions = useCallback((conflictData: {[date: string]: number[]}) => {
    const conflictDates = Object.keys(conflictData).sort();
    return conflictDates.map(date => {
      const hours = conflictData[date].sort((a, b) => a - b);
      const continuousHours = hours.reduce((acc, hour) => {
        const lastContinuousHour = acc[acc.length - 1];
        if (lastContinuousHour && lastContinuousHour[lastContinuousHour.length - 1] + 1 === hour) {
          lastContinuousHour.push(hour);
        } else {
          acc.push([hour]);
        }
        return acc;
      }, [] as number[][]);

      const weekday = moment(date).weekday();
      const dateString = `${moment(date).format('MM/DD')}(${getDayOfWeekLabelByValue(weekday === 0 ? 7 : weekday)[1]})`;
      const hourString = continuousHours.map(
        hours => hours.length > 1 ?
          `${hours[0]}-${hours[hours.length - 1]}` :
          `${hours[0]}`
      ).join(', ');
      return `${dateString} ${hourString}`;
    });
  }, []);

  const getConflictDurationDescriptions = useCallback((pmp: Pmp | PmpFormType, conflictData: {[date: string]: number[]}) => {
    const conflictDates = Object.keys(conflictData).sort();
    const continuousDates = conflictDates.reduce((acc, date) => {
      const lastContinuousDate = acc[acc.length - 1];
      if (lastContinuousDate && moment(lastContinuousDate[lastContinuousDate.length - 1]).add(1, 'day').isSame(date)) {
        lastContinuousDate.push(date);
      } else {
        acc.push([date]);
      }
      return acc;
    }, [] as string[][]);
    return continuousDates.map(dates => {
      let startTime = `${dates[0]} 00:00:00`;
      let endTime = `${dates[dates.length - 1]} 23:59:59`;
      if (moment(startTime).isSame(pmp.startTime, 'day')) {
        startTime = pmp.startTime;
      }
      if (moment(endTime).isSame(pmp.endTime, 'day')) {
        endTime = pmp.endTime;
      }
      return `${startTime} ~ ${endTime}`;
    });
  }, []);

  const validatePmpDuration = useCallback((pmp: Pmp | PmpFormType): PmpDurationError | undefined => {
    if (!pmp.spaces) {
      return;
    }

    const id = get(pmp, 'id');
    const pmpWithinDuration = defaultTo(pmpWithinDurationMap.current[getMapKeyPattern(pmp.startTime, pmp.endTime)], []);
    const pmpToCompare = pmpWithinDuration.filter(otherPmp => {
      const useSameSpace = otherPmp.spaces.some(space => pmp.spaces.includes(space));
      const sameId = id && id === otherPmp.id;
      return useSameSpace && !sameId;
    });

    if (pmpToCompare.length === 0) {
      return;
    }

    const getPureDaypart = (dayparts?: Dayparts): Omit<Dayparts, 'enabled'> => {
      return omit(dayparts, 'enabled');
    };

    let spaces: string[] = [];
    let conflictDurationDescriptions: string[] = [];
    const durationDes = pmpManager.getDaypartDescriptions(pmp.startTime, pmp.endTime, getPureDaypart(pmp.dayPart));
    pmpToCompare.forEach(otherPmp => {
      const otherPmpDurationDes = pmpManager.getDaypartDescriptions(otherPmp.startTime, otherPmp.endTime, getPureDaypart(otherPmp.dayPart));
      const conflictDurationDes = durationDes.filter(des => otherPmpDurationDes.includes(des));
      if (conflictDurationDes.length > 0) {
        spaces = uniq(spaces.concat(otherPmp.spaces.filter(space => pmp.spaces.includes(space))));
        conflictDurationDescriptions = uniq(conflictDurationDescriptions.concat(conflictDurationDes));
      }
    });

    if (spaces.length === 0) {
      return;
    }

    const conflictData = conflictDurationDescriptions.reduce((acc, description) => {
      const date = description.split(' ')[0];
      const hour = description.split(' ')[1];
      acc[date] = uniq(defaultTo(acc[date], []).concat(+hour));
      return acc;
    }, {});

    const daypartEnabled = !isEmpty(pmp.dayPart);
    return daypartEnabled ? {
      spaces,
      dayPart: getConflictDaypartDescriptions(conflictData)
    } : {
      spaces,
      duration: getConflictDurationDescriptions(pmp, conflictData)
    };
  }, [getMapKeyPattern, getConflictDurationDescriptions, getConflictDaypartDescriptions]);

  const clearCacheWithin = useCallback((startTime: string, endTime: string) => {
    Object.keys(pmpWithinDurationMap.current).forEach(key => {
      const start = key.slice(0, 19);
      const end = key.slice(20);
      if (moment(start).isSameOrBefore(endTime) && moment(end).isSameOrAfter(startTime)) {
        delete pmpWithinDurationMap.current[key];
      }
    });
  }, []);

  return {
    loading,
    fetchPmpWithinDuration,
    validatePmpDuration,
    clearCacheWithin
  };
};
