import _, { Dictionary } from 'lodash';
import moment from 'moment';
import {
  RtbCampaignPlanType,
  CreativeDeliverType,
  DailyBudgetPlan,
  DeliverType,
  CampaignState,
  AdType,
  RtbOptimize,
  RtbCampaignBasic,
  RtbCampaignListBasic
} from 'core/rtbCampaign/RtbCampaign';
import * as SelectOptionsUtils from 'utils/SelectOptionsUtils';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { Currency, AddonFeatureManager, LocaleMeta } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { getDecimalPlaceCount, getPriceValue } from 'helper/CurrencyHelper';
import { Order } from 'core/order/Order';
import { DefaultRtbCampaignBasicFormValidator, RtbCampaignBasicFormValidator } from './RtbCampaignBasicFormValidator';
import { L1Object, L1ObjectObjective } from 'core/l1Object/L1Object';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import i18n from 'i18n';
import { LIMITATION_TYPE, LimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';
import { getDefaultLimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/defaultLimitationInventorySettings';
import { OPERATES } from 'enum/Operate';

export interface RtbCampaignBasicFormModel {
  readonly actionType: string;
  readonly l1Object: L1Object;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly canUseDailyBudget: boolean;
  readonly priceModelOptions: SelectOptions[];
  readonly optimizeOptions: SelectOptions[];
  readonly creativeDeliverTypeOptions: SelectOptions[];
  readonly outdoorDeliverTypeOptions: SelectOptions[] | undefined;
  readonly campaignBasic: RtbCampaignBasic;
  readonly campaignDeliverTypeOptions: SelectOptions[];
  readonly order: Order;
  readonly orderName: string;
  readonly agencyProfit?: string;
  readonly currency: string;
  readonly budgetMinimum: number;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
  readonly canEditEnableMonitor: boolean;
  readonly minDate: string;
  readonly maxDate: string;
  readonly defaultPriceModel: RtbCampaignPlanType;
  readonly campaignAdType: AdType;
  readonly showOptimizeSection: boolean;
  readonly showFrequencyCap: boolean;
  readonly availablePriceModel: RtbCampaignPlanType[];
  readonly retailerOptions?: SelectOptions[];
  readonly formikValue?: any;
  readonly optimizeAddonEnable: boolean;
  readonly isOutdoorType: boolean;
  readonly isPmpType: boolean;
  readonly canEditVideoViewObjective: boolean;
  readonly limitatoinConfig: {
    defaultLimitationToShow?: {
      name: string;
      operate: OPERATES
    };
    supportAgeGenderLimitation: boolean;
    supportOtherLimitation: boolean;
    supportAudienceEstimation: boolean;
    requireIncludeLimitation?: boolean;
  };
  readonly canCreateWithCreative: boolean;
  init (): Promise<void>;
  getVideoAdViewObjectiveOptions: (priceModel: RtbCampaignPlanType) => SelectOptions[] | undefined;
  canEditOptimize: (priceModel: RtbCampaignPlanType) => boolean;
  bidCapNeedAlignOrderPrice (priceModel: RtbCampaignPlanType, optimize: L2ObjectOptimizationGoal): boolean;
  canEditBidStrategy (priceModel: RtbCampaignPlanType, optimize: L2ObjectOptimizationGoal): boolean;
  showFrequencyControl (optimizationGoal: L2ObjectOptimizationGoal): boolean;
  getBidPriceFloorData (): any;
  getCurrentPriceModel (): RtbCampaignPlanType;
  setCurrentPriceModel (planType: RtbCampaignPlanType);
  getBidFloor: (optimize: RtbOptimize) => number;
  getDefaultOptimizeType: (planType: RtbCampaignPlanType) => L2ObjectOptimizationGoal | undefined;
  getRemainBudget: (campaignBudget: number) => number;
  changeDailyBudgetOptions: (dailyBudgetType: DailyBudgetPlan) => void;
  getDailyBudgetState: (
    totalBudget: number,
    dailyBudget: number | undefined,
    totalDay: number
  ) => DAILY_BUDGET_STATE;
  getCampaignTotalDay: (startDate: string, endDate: string) => number;
  state: RtbCampaignBasicFormState;
  readonly event: UpdateEventListener<RtbCampaignBasicFormModel>;
  onPriceModelChangeCallback: (type: RtbCampaignPlanType, campaignBasic: RtbCampaignBasic) => void;
  validate: (campaignBasic: any, order: Order, localeMeta?: LocaleMeta) => any;
  onUnmount: (handler?: number) => void;
  setFormikValue: (value: any) => void;
  getLimitationInventorySetting (
    requiredOperateOfTaTypes: {[key: string]: string[]},
    campaignBasic: RtbCampaignBasic,
    segments?: SelectOptions[] | undefined,
    localeMeta?: LocaleMeta | undefined
  ): LimitationInventorySettings[];
}

export enum DAILY_BUDGET_STATE {
  DEFAULT,
  UNDER_BUDGET,
  OVER_BUDGET,
  MEET_BUDGET
}

export type RtbCampaignBasicFormProps = {
  readonly model: RtbCampaignBasicFormModel;
};

export type RtbCampaignBasicFormState = {
  dailyBudgetType: DailyBudgetPlan;
};

export type RtbCampaignBasicFormModelConstructorParams = [
  any,
  RtbCampaignBasic,
  Order,
  L1Object,
  AddonFeatureManager,
  (type: RtbCampaignPlanType, campaignBasic: RtbCampaignBasic) => void,
  RtbCampaignListBasic[]?
];
export abstract class DefaultRtbCampaignBasicFormModel
  implements RtbCampaignBasicFormModel {
  modelDailyBudgetType: DailyBudgetPlan;
  event: FireableUpdateEventListener<RtbCampaignBasicFormModel>;
  currentPriceModel: RtbCampaignPlanType;
  validator: RtbCampaignBasicFormValidator;
  rtbCampaignManager = new DefaultRtbCampaignManager();
  retailerOptions?: SelectOptions[] = undefined;
  formikValue?: any;
  optimizeAddonEnable: boolean;

  constructor (
    public actionType: string,
    private defaultCampaign: any,
    public campaignBasic: RtbCampaignBasic,
    public order: Order,
    public l1Object: L1Object,
    public addonFeatureManager: AddonFeatureManager,
    public onPriceModelChangeCallback: (type: RtbCampaignPlanType, campaignBasic: RtbCampaignBasic) => void,
    private otherCampaignOfL1Object?: RtbCampaignListBasic[]
  ) {
    this.modelDailyBudgetType = !campaignBasic.dailyTargetBudget || campaignBasic.dailyTargetBudget < 1 ? DailyBudgetPlan.SCHEDULE : DailyBudgetPlan.DAILY;
    this.event = new FireableUpdateEventListener<RtbCampaignBasicFormModel>();
    this.currentPriceModel = this.campaignBasic.priceModel;
    this.canEditOptimize = this.canEditOptimize.bind(this);
    this.validator = new DefaultRtbCampaignBasicFormValidator(
      actionType,
      this.order,
      this.defaultCampaign,
      this.campaignBasic,
      this.getBidFloor.bind(this),
      this.addonFeatureManager
    );
    this.optimizeAddonEnable = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE);
  }

  get isOutdoorType (): boolean {
    return false;
  }

  get isPmpType (): boolean {
    return false;
  }

  get outdoorDeliverTypeOptions (): SelectOptions[] | undefined {
    return undefined;
  }

  get campaignAdType (): AdType {
    return AdType.UNKNOW;
  }

  get showOptimizeSection (): boolean {
    return true;
  }

  get showFrequencyCap (): boolean {
    return true;
  }

  get limitatoinConfig () {
    return {
      supportAgeGenderLimitation: true,
      supportOtherLimitation: true,
      supportAudienceEstimation: true
    };
  }

  get canCreateWithCreative (): boolean {
    return false;
  }

  async init () {}

  getVideoAdViewObjectiveOptions = (priceModel: RtbCampaignPlanType): SelectOptions[] | undefined => {
    return undefined;
  }

  getBidPriceFloorData () {
    const data = this.order.campaignBidPrice.find(data => data.type === this.campaignAdType);
    return data ? data.bidFloor : {};
  }

  bidCapNeedAlignOrderPrice (priceModel: RtbCampaignPlanType, optimize: L2ObjectOptimizationGoal): boolean {
    const optimizeSameAsPriceModel = this.rtbCampaignManager.checkOptimizeSameAsPriceModel(priceModel, optimize);
    return !this.optimizeAddonEnable && optimizeSameAsPriceModel;
  }

  canEditBidStrategy (priceModel: RtbCampaignPlanType, optimize: L2ObjectOptimizationGoal): boolean {
    if (this.isOutdoorType) {
      return false;
    }
    if (this.l1Object.autoOptimise) {
      return false;
    }
    if (this.bidCapNeedAlignOrderPrice(priceModel, optimize)) {
      return false;
    }
    return true;
  }

  setFormikValue (value: any) {
    this.formikValue = value;
  }

  showFrequencyControl = (optimize: L2ObjectOptimizationGoal): boolean => {
    if (!this.l1Object) {
      return false;
    }
    return this.rtbCampaignManager.showFrequencyControl(this.l1Object.objective, this.campaignAdType, optimize);
  }

  getRtbOptionsMap (): { [objective: string]: Dictionary<L2ObjectOptimizationGoal[] | undefined> } {
    const enableOptimizedPrice = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE);
    return {
      [L1ObjectObjective.AWARENESS]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.DCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      },
      [L1ObjectObjective.TRAFFIC]: _.omitBy({
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.CLICKS
        ],
        [RtbCampaignPlanType.FCPM]: enableOptimizedPrice ? [
          L2ObjectOptimizationGoal.CLICKS
        ] : undefined,
        [RtbCampaignPlanType.FCPC]: [
          L2ObjectOptimizationGoal.CLICKS
        ],
        [RtbCampaignPlanType.DCPM]: [
          L2ObjectOptimizationGoal.CLICKS
        ]
      }, _.isUndefined),
      [L1ObjectObjective.SALES]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.SALES
        ],
        [RtbCampaignPlanType.FCPC]: [
          L2ObjectOptimizationGoal.SALES
        ]
      },
      [L1ObjectObjective.UNSPECIFIED]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPC]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.DCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        rb: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      }
    };
  }

  getLimitationInventorySetting (
    requiredOperateOfTaTypes: {[key: string]: string[]},
    campaignBasic: RtbCampaignBasic,
    segments?: SelectOptions[] | undefined,
    localeMeta?: LocaleMeta | undefined
  ): LimitationInventorySettings[] {
    const inventorySettings = getDefaultLimitationInventorySettings(
      requiredOperateOfTaTypes,
      campaignBasic.advertiserId,
      LIMITATION_TYPE.CAMPAIGN,
      segments,
      localeMeta,
      this.campaignAdType,
      !this.isOutdoorType
    );
    return inventorySettings;
  }

  getPriceModels () {
    const objective = this.l1Object.objective;
    const optionsMap = this.getRtbOptionsMap();
    return Object.keys(optionsMap[objective]);
  }

  getOptimizes (priceModel: RtbCampaignPlanType) {
    const objective = this.l1Object.objective;
    const optionsMap = this.getRtbOptionsMap();
    const optimizes = optionsMap[objective][priceModel];
    return optimizes ? optimizes : [];
  }

  getCurrentPriceModel (): RtbCampaignPlanType {
    return this.currentPriceModel;
  }

  setCurrentPriceModel (planType: RtbCampaignPlanType) {
    this.currentPriceModel = planType;
  }

  getAddonByPlanTypeOrOptimize (planType) {
    switch (planType) {
      case RtbCampaignPlanType.FCPC:
      case L2ObjectOptimizationGoal.CLICKS:
        return ADDONFEATURE.CAMPAIGN.FIXED_CPC;
      case RtbCampaignPlanType.FCPM:
      case L2ObjectOptimizationGoal.IMPRESSIONS:
        return ADDONFEATURE.CAMPAIGN.FIXED_CPM;
      case RtbCampaignPlanType.FCPV:
      case L2ObjectOptimizationGoal.VIDEO_VIEWS:
        return ADDONFEATURE.CAMPAIGN.FIXED_CPV;
      case RtbCampaignPlanType.FVCPM:
      // case L2ObjectOptimizationGoal.VIDEO_VIEWS:
        return ADDONFEATURE.CAMPAIGN.FIXED_VCPM;
      case RtbCampaignPlanType.DCPM:
        return ADDONFEATURE.CAMPAIGN.DYNAMIC_CPM;
      case RtbCampaignPlanType.RS:
        return ADDONFEATURE.CAMPAIGN.REVENUE_SHARING;
      default:
        return;
    }
  }

  get availablePriceModel () {
    const availablePriceModel: any[] = [];
    this.getPriceModels().forEach(priceModel => {
      const relatedAddon = this.getAddonByPlanTypeOrOptimize(priceModel);
      const addonEnable = relatedAddon ? this.addonFeatureManager.isFeatureEnable(relatedAddon) : true;
      if (addonEnable) {
        availablePriceModel.push(priceModel);
      }
    });
    return availablePriceModel;
  }

  get defaultPriceModel (): RtbCampaignPlanType {
    return this.availablePriceModel.includes(RtbCampaignPlanType.RS) ?
      RtbCampaignPlanType.RS :
      this.availablePriceModel[0];
  }

  get canEditVideoViewObjective (): boolean {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.VIDEO_AD_VIEW_OBJECTIVE);
  }

  get priceModelOptions (): SelectOptions[] {
    const options = SelectOptionsUtils.createSelectOptionsFromEnum(
      RtbCampaignPlanType, 'campaign.labels.'
    );
    return SelectOptionsUtils.filter(options, this.availablePriceModel);
  }

  get optimizeOptions (): SelectOptions[] {
    const validOptimizes = this.getOptimizes(this.currentPriceModel);
    return validOptimizes.map(goal => ({
      label: i18n.t<string>(`optimizationGoal.${goal.toLowerCase()}`),
      value: goal
    }));
  }

  get campaignDeliverTypeOptions (): SelectOptions[] {
    return SelectOptionsUtils.createSelectOptionsFromEnum(
      DeliverType, 'campaign.labels.'
    );
  }

  get creativeDeliverTypeOptions (): SelectOptions[] {
    return SelectOptionsUtils.createSelectOptionsFromEnum(
      CreativeDeliverType, 'campaign.labels.creativeDeliverType.'
    );
  }

  getRemainBudget (campaignBudget: number): number {
    const budgetBalance = _.get(this.l1Object, 'budgetBalance', 0);
    const totalBudget = this.defaultCampaign.basic.isDraft || this.defaultCampaign.basic.id === undefined ?
        budgetBalance :
        budgetBalance + this.defaultCampaign.basic.budget;
    return _.round(Number(totalBudget) - campaignBudget, 2);
  }

  get budgetMinimum (): number {
    const budgetMinimum = _.get(
      this.order,
      'campaignConstraint.budgetMinimum',
      100
    );
    const spents = this.campaignBasic.spents ? this.campaignBasic.spents : 0;
    return this.campaignBasic.state === CampaignState.DEACTIVATE ? getPriceValue(this.order.currency, spents) : Number(budgetMinimum);
  }

  getDailyBudgetState (
    totalBudget: number,
    dailyBudget: number | undefined,
    totalDay: number
  ): DAILY_BUDGET_STATE {
    if (!dailyBudget || dailyBudget < 1 || dailyBudget > totalBudget) {
      return DAILY_BUDGET_STATE.DEFAULT;
    }
    const lastDay = totalBudget / dailyBudget;
    if (lastDay === totalDay) {
      return DAILY_BUDGET_STATE.MEET_BUDGET;
    }
    if (lastDay < totalDay) {
      return DAILY_BUDGET_STATE.OVER_BUDGET;
    }
    return DAILY_BUDGET_STATE.UNDER_BUDGET;
  }

  get canUseDailyBudget (): boolean {
    return !!this.l1Object &&
      !this.l1Object.autoOptimise &&
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.BUDGET_DOMINATE);
  }

  canEditOptimize (priceModel: RtbCampaignPlanType): boolean {
    if (
      this.l1Object &&
      this.l1Object.autoOptimise &&
      this.otherCampaignOfL1Object &&
      this.otherCampaignOfL1Object.length > 0
    ) {
      return false;
    }
    return priceModel === RtbCampaignPlanType.RS ||
      priceModel === RtbCampaignPlanType.DCPM ||
      priceModel === RtbCampaignPlanType.FCPM ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE);
  }

  get canEditEnableMonitor (): boolean {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.MONITOR.MONITOR_CAMPAIGN_BEHIND_PROGRESS);
  }

  get orderName (): string {
    return `${_.get(this.order, 'projectName')} (${_.get(
      this.order,
      'orderNumber'
    )})`;
  }

  get agencyProfit (): string | undefined {
    const agencyProfit = _.get(this.order, 'orderMargin', 0);
    return _.round(Number(agencyProfit) * 100, 2) + ' %';
  }

  get minDate (): string {
    const orderStartDate = moment(_.get(this.order, 'startDate'));
    const thisHour = moment().startOf('hour').format('YYYY-MM-DD_HH:mm:ss');
    if (this.campaignBasic && this.campaignBasic.startDate) {
      return moment(this.campaignBasic.startDate).isBefore() ? moment(this.campaignBasic.startDate).format('YYYY-MM-DD_HH:mm:ss') : thisHour;
    }
    return moment().isAfter(orderStartDate) ? thisHour : orderStartDate.format('YYYY-MM-DD_HH:mm:ss');
  }

  get maxDate (): string {
    return moment(_.get(this.order, 'endDate')).endOf('day').format('YYYY-MM-DD_HH:mm:ss');
  }

  getDefaultOptimizeType (priceModel: RtbCampaignPlanType) {
    const validOptimizes = this.getOptimizes(priceModel);
    let l1ObjectDefaultOptimizationGoal = validOptimizes[0];
    if (
      this.l1Object &&
      this.l1Object.autoOptimise &&
      this.otherCampaignOfL1Object &&
      this.otherCampaignOfL1Object.length > 0
    ) {
      l1ObjectDefaultOptimizationGoal = this.otherCampaignOfL1Object[0].optimize;
    }
    return l1ObjectDefaultOptimizationGoal;
  }

  getCampaignTotalDay (startDate: string, endDate: string): number {
    const format = 'YYYY-MM-DD';
    return moment(endDate, format)
      .add(1, 'days')
      .diff(moment(startDate, format), 'days');
  }

  getBidFloor = (optimize: RtbOptimize | RtbCampaignPlanType): number => {
    const bidFloorData = this.getBidPriceFloorData();
    let optimizeKey = '';
    switch (optimize) {
      case RtbOptimize.OFFSITE_CONVERSIONS:
      case RtbOptimize.LINK_CLICKS:
      case RtbOptimize.CLICKS:
      case RtbOptimize.SALES:
      case RtbCampaignPlanType.FCPC:
        optimizeKey = 'cpc';
        break;
      case RtbOptimize.REACH:
      case RtbOptimize.IMPRESSIONS:
      case RtbCampaignPlanType.FCPM:
      case RtbCampaignPlanType.FVCPM:
        optimizeKey = 'cpm';
        break;
      case RtbOptimize.VIDEO_VIEWS:
      case RtbCampaignPlanType.FCPV:
        optimizeKey = 'cpv';
        break;
      default:
        optimizeKey = '';
    }
    return _.get(bidFloorData, optimizeKey, 0);
  }

  get currency (): string {
    const currency = _.get(this.order, 'currency', Currency.NTD);
    return currency;
  }

  abstract get canEditBudgetPlan (): boolean;
  abstract get canEditPriceModel (): boolean;

  changeDailyBudgetOptions (dailyBudgetType: DailyBudgetPlan): void {
    this.updateState(dailyBudgetType);
  }

  validate (campaignBasic: RtbCampaignBasic, order: Order, localeMeta?: LocaleMeta) {
    const isDailySchedule = this.state.dailyBudgetType.toString() === DailyBudgetPlan.DAILY.toString();
    const decimalPlaceCount = getDecimalPlaceCount(order.currency);
    const otherCampaignCost = this.otherCampaignOfL1Object ? this.otherCampaignOfL1Object.reduce((acc, campaign) => {
      return acc + Math.max(_.ceil(campaign.expectedSpent, decimalPlaceCount), this.rtbCampaignManager.getMinBudgetOfCampaign(campaign, order.campaignConstraint.budgetMinimum));
    }, 0) : 0;
    return this.validator.validate(this.l1Object, campaignBasic, order, isDailySchedule, otherCampaignCost, localeMeta);
  }

  onUnmount (handler?: number) {
    handler && this.event.remove(handler);
    this.modelDailyBudgetType = DailyBudgetPlan.SCHEDULE;
  }

  updateState (dailyBudgetType: DailyBudgetPlan): void {
    this.modelDailyBudgetType = dailyBudgetType;
    this.event.fireEvent(this);
  }

  get state (): RtbCampaignBasicFormState {
    return {
      dailyBudgetType: this.modelDailyBudgetType
    };
  }
}
