import { useCallback, useEffect, useMemo, useState } from 'react';
import { Actor, AddonFeatureManager, Agency, AgencyManager, AuthenticationManager, DefaultAgencyManager } from 'core';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { useRouteMatch } from 'react-router';
import moment from 'moment';
import i18n from 'i18n';
import _ from 'lodash';
import { numberWithCommas } from 'utils/StringUtil';
import { DefaultReportManager, ReportManager } from 'core/report/ReportManager';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';
import { clientWithoutErrorHandler } from 'ws/RestClient';
import { RestfulOrderWebService } from 'ws/OrderWebService';
import { FinalReportCsvStatusItem, FinalReportStatus, FinalReportTableStatusItem } from 'core/finalReport/FinalReportStatus';
import { GojekFinalReportProjectType, Order, State, OrderType } from 'core/order/Order';
import { ReportType } from 'core/report/ReportData';
import { useCallAPI } from 'hooks/useCallAPI';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { AdvertiserRole } from 'core/companyMember/CompanyRole';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { getDayOfWeekLabelByValue } from 'components/Dayparts/Dayparts';
import { Permission } from 'core/auth/Permission';
import { toast } from 'react-toastify';
import { ageMaxOptions, ageMinOptions, genderOptions } from 'core/limitation/l2ObjectTAOptions';
import { getPercentValue } from 'helper/MetricsHelper';
import { DefaultStoredValueManager, StoredValueManager } from 'core/storedValue/StoredValueManager';
import { isSystemAdmin } from 'helper/ActorHelper';

export type OrderDetailModelData = {
  l1ObjectList: any[],
  canSettle: boolean,
  settleTips: string,
  showDownloadFinalReport: boolean,
  fetchingFinalReport: boolean,
  finalReportStatus?: string,
  loading: boolean
  orderExternalTypes: SelectOptions[],
  order?: Order,
  viewData: any,
  showDetail: boolean,
  showDownloadRtbReportModal: boolean,
  showApproveButton: boolean,
  showRejectButton: boolean,
  canEditOrder: boolean,
  showSettleButton: boolean,
  showUnsettleButton: boolean;
  showGoVideoReportButton: boolean;
  remainingStoredValueError: string | undefined;
  toggleShowDetail: () => void,
  setShowDownloadRtbReportModal: (show: boolean) => void,
  settleOrder: () => Promise<void>;
  unsettleOrder: () => Promise<void>;
  rejectOrder: () => Promise<void>;
  approveOrder: () => Promise<void>;
  getReportLink: (type: ReportType) => string;
  refreshOrderDetail: () => Promise<void>,
  openFinalReport: () => Promise<void>
};

const defaultL1ObjectManager = new DefaultL1ObjectManager();
const defaultReportManager = new DefaultReportManager();
const defaultOrderManager = new DefaultOrderManager();
const orderManagerWithoutError = new DefaultOrderManager(new RestfulOrderWebService(clientWithoutErrorHandler));
const defaultStoredValueManager = new DefaultStoredValueManager();
const defaultAgencyManager = new DefaultAgencyManager();

export const useOrderDetailModel = (
  orderNumber: string,
  localMetaDataUpdater: Function,
  authenticationManager: AuthenticationManager,
  addonFeatureManager: AddonFeatureManager,
  l1ObjectManager: L1ObjectManager = defaultL1ObjectManager,
  reportManager: ReportManager = defaultReportManager,
  orderManager: OrderManager = defaultOrderManager,
  storedValueManager: StoredValueManager = defaultStoredValueManager,
  agencyManager: AgencyManager = defaultAgencyManager
): OrderDetailModelData => {

  const match = useRouteMatch();
  const [showDownloadRtbReportModal, setShowDownloadRtbReportModal] = useState(false);
  const [showDetail, setShowDetail] = useState(true);
  const [orderExternalTypes, setOrderExternalTypes] = useState<SelectOptions[]>([]);
  const [viewData, setViewData] = useState<any>({});
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const { loading, callAPIs, callAPIsBySequence } = useCallAPI();
  const [l1ObjectList, setL1ObjectList] = useState<any[]>([]);
  const [fetchingFinalReport, setFetchingFinalReport] = useState(false);
  const [finalReportStatus, setFinalReportStatus] = useState<FinalReportStatus | undefined>();
  const [remainingStoredValueError, setRemainingStoredValueError] = useState<string | undefined>(undefined);
  const [agencyAddonFeatures, setAgencyAddonFeatures] = useState<string[]>([]);

  const fetchFinalReportStatus = useCallback(async () => {
    const finalReportStatus = await getFinalReportStatus(order);
    setFinalReportStatus(finalReportStatus);
  }, [order]);

  useEffect(() => {
    if (match.isExact) {
      fetchFinalReportStatus();
    }
  }, [fetchFinalReportStatus, match.isExact]);

  const genOrderViewData = useCallback((order: Order, orderExternalTypes: SelectOptions[]) => {
    const orderViewData = getOrderViewData(
      order,
      orderExternalTypes,
      addonFeatureManager,
      authenticationManager
    );
    setViewData(orderViewData);
  }, [addonFeatureManager, authenticationManager]);

  const fetchOrderData = useCallback(async (matchIsExact) => {
    callAPIs([
      orderManager.getOrderExternalTypes.bind(orderManager),
      orderManager.getOrder.bind(orderManager, orderNumber)
    ], (orderExternalTypes, newOrder) => {
      callAPIs([
        (!matchIsExact) ? () => [] : l1ObjectManager.getL1Objects.bind(l1ObjectManager, newOrder.id)
      ], (l1ObjectList) => {
        setL1ObjectList(l1ObjectList);
      });
      setOrderExternalTypes(orderExternalTypes);
      setOrder(newOrder);
      genOrderViewData(newOrder, orderExternalTypes);
    });
  }, [orderNumber, orderManager, callAPIs, l1ObjectManager, genOrderViewData]);

  useEffect(() => {
    (!match.isExact && order === undefined) && fetchOrderData(false);
  }, [order, fetchOrderData, match.isExact]);

  useEffect(() => {
    match.isExact && fetchOrderData(true);
  }, [fetchOrderData, match.isExact]);

  const fetchAgencyAddonFeatureData = useCallback((order: Order) => {
    callAPIs([
      agencyManager.fetchAgency.bind(agencyManager, order.agencyId)
    ], (agency: Agency) => {
      const addonFeatures = addonFeatureManager.convertAgencyAddonFeatures(agency.detail.addonFeatures);
      setAgencyAddonFeatures(addonFeatures);
    });
  }, [agencyManager, addonFeatureManager, callAPIs]);

  useEffect(() => {
    !_.isNil(order) && fetchAgencyAddonFeatureData(order);
  }, [order, fetchAgencyAddonFeatureData]);

  const settleOrder = useCallback(async () => {
    if (!order) {
      return;
    }

    callAPIsBySequence([
      orderManager.settleOrder.bind(orderManager, order.id),
      orderManager.getOrder.bind(orderManager, order.orderNumber),
      l1ObjectManager.getL1Objects.bind(l1ObjectManager, order.id)
    ], (updatedOrder, l1ObjectList) => {
      setOrder(updatedOrder);
      genOrderViewData(updatedOrder, orderExternalTypes);
      setL1ObjectList(l1ObjectList);
      toast.success(i18n.t<string>('orderDetail.labels.settleSuccess'));
    });
  }, [callAPIsBySequence, orderManager, order, l1ObjectManager, orderExternalTypes, genOrderViewData]);

  const unsettleOrder = useCallback(async () => {
    if (!order) {
      return;
    }

    callAPIsBySequence([
      orderManager.unsettleOrder.bind(orderManager, order.id),
      orderManager.getOrder.bind(orderManager, order.orderNumber)
    ], updatedOrder => {
      setOrder(updatedOrder);
      genOrderViewData(updatedOrder, orderExternalTypes);
      toast.success(i18n.t<string>('orderDetail.labels.unsettleSuccess'));
    });
  }, [order, callAPIsBySequence, orderManager, genOrderViewData, orderExternalTypes]);

  const approveOrder = useCallback(async () => {
    if (!order) {
      return;
    }
    callAPIsBySequence([
      orderManager.approveOrder.bind(orderManager, order.id),
      orderManager.getOrder.bind(orderManager, order.orderNumber),
      async () => {
        await localMetaDataUpdater(authenticationManager.actor);
      }
    ], updatedOrder => {
      setOrder(updatedOrder);
      genOrderViewData(updatedOrder, orderExternalTypes);
      toast.success(i18n.t<string>('orderDetail.labels.approveSuccess'));
    });
  }, [callAPIsBySequence, orderManager, order, orderExternalTypes, genOrderViewData, localMetaDataUpdater, authenticationManager.actor]);

  const rejectOrder = useCallback(async () => {
    if (!order) {
      return;
    }
    callAPIsBySequence([
      orderManager.rejectOrder.bind(orderManager, order.id),
      orderManager.getOrder.bind(orderManager, order.orderNumber)
    ], updatedOrder => {
      setOrder(updatedOrder);
      genOrderViewData(updatedOrder, orderExternalTypes);
      toast.success(i18n.t<string>('orderDetail.labels.rejectSuccess'));
    });
  }, [callAPIsBySequence, orderManager, order, orderExternalTypes, genOrderViewData]);

  const checkRemainingStoredValue = useCallback(async order => {
    const error = await validateBudgetWithRemainingStoredValue(order, addonFeatureManager, storedValueManager);
    error && setRemainingStoredValueError(error);
  }, [addonFeatureManager, storedValueManager]);

  useEffect(() => {
    checkRemainingStoredValue(order);
  }, [order, checkRemainingStoredValue]);

  const toggleShowDetail = useCallback(() => {
    setShowDetail(value => !value);
  }, []);

  const hasAliveOrNotStartCampaign = false;

  const showDownloadFinalReport = useMemo(() => {
    const hasAddonFeature = agencyAddonFeatures.includes(ADDONFEATURE.REPORT.REPORT_RESTRICT_FINALREPORT.toString());
    if (_.isEmpty(agencyAddonFeatures)) {
      return false;
    }
    if (hasAddonFeature && !isSystemAdmin(authenticationManager.actor)) {
      return false;
    }
    if (!order ||
      (order.orderType === OrderType.GOJEK && !order.finalReportProjectName) ||
      (order.orderType === OrderType.TENMAX && !order.projectName)
    ) {
      return false;
    }
    const canDownloadFinalReportDate = moment(order.startDate).startOf('day');
    if (moment().isAfter(canDownloadFinalReportDate)) {
      return true;
    }
    return false;
  }, [order, agencyAddonFeatures, authenticationManager.actor]);

  return {
    order,
    viewData: _.omitBy({
      ...viewData,
      finalReport: getFinalReportData(order, finalReportStatus)
    }, _.isUndefined),
    loading,
    showDetail,
    orderExternalTypes,
    showDownloadRtbReportModal,
    showGoVideoReportButton: showGoVideoReportButton(order, addonFeatureManager),
    showApproveButton: showApproveButton(order),
    showRejectButton: showRejectButton(order),
    canEditOrder: canEditOrder(authenticationManager.actor, order),
    showSettleButton: showSettleButton(order, addonFeatureManager),
    showUnsettleButton: showUnsettleButton(order, addonFeatureManager),
    l1ObjectList,
    canSettle: getCanSettle(order, hasAliveOrNotStartCampaign),
    settleTips: getSettleTips(order, hasAliveOrNotStartCampaign),
    showDownloadFinalReport: showDownloadFinalReport,
    fetchingFinalReport,
    remainingStoredValueError,
    settleOrder,
    unsettleOrder,
    rejectOrder,
    approveOrder,
    getReportLink: _.partial(getReportLink, order),
    toggleShowDetail,
    refreshOrderDetail: () => fetchOrderData(true),
    setShowDownloadRtbReportModal,
    openFinalReport: async () => {
      if (!order) {
        return;
      }
      const newTab = window.open(`/final-report?preparing`, '_blank');
      if (newTab) {
        newTab.opener = null;
      }
      setFetchingFinalReport(true);
      try {
        const reportFileId = await reportManager.uploadFinalReportData(order.id);
        const reportId = _.get(reportFileId, 'status', '') === 'INITIAL' ? _.get(reportFileId, 'payload', '') : '';
        if (newTab) {
          newTab.location.href = `/final-report?id=${reportId}`;
        }
      } catch (e) {}
      setFetchingFinalReport(false);
    }
  };
};

function getFinalReportStatusOfGoApps (order: Order, finalReportStatus: FinalReportStatus) {
  const audienceCsvStatus: FinalReportCsvStatusItem[] = _.get(finalReportStatus, 'csv.audience', []);
  const audienceTableStatus: FinalReportTableStatusItem[] = _.get(finalReportStatus, 'table.audience', []);
  const outletCsvStatus: FinalReportCsvStatusItem[] = _.get(finalReportStatus, 'csv.outlet', []);
  const outletTableStatus: FinalReportTableStatusItem = _.get(finalReportStatus, 'table.outlet', {
    lastLoadDateTime: '',
    count: 0
  });

  const reasons: string[] = [];
  const statusCollectorMap = {
    NOT_READY: () => {
      if (audienceCsvStatus.length === 0) {
        reasons.push(i18n.t<string>('orderDetail.labels.finalReport.audienceCsvNotReady'));
      } else if (audienceTableStatus.length === 0) {
        reasons.push(i18n.t<string>('orderDetail.labels.finalReport.audienceTableNotReady'));
      }
    },
    PARTIAL: () => {
      if (outletCsvStatus.length === 0) {
        reasons.push(i18n.t<string>('orderDetail.labels.finalReport.outletCsvNotReady'));
      } else if (!outletTableStatus) {
        reasons.push(i18n.t<string>('orderDetail.labels.finalReport.outletTableNotReady'));
      }
      if (_.get(finalReportStatus.transaction, 'status') !== 'READY') {
        reasons.push(i18n.t<string>('orderDetail.labels.finalReport.onelinkCampaignNameNotTracked', { name: order.finalReportProjectName }));
      }
    }
  };

  const statusCollectFunc = _.defaultTo(statusCollectorMap[finalReportStatus.status], _.noop);
  statusCollectFunc();
  return reasons;
}

function getFinalReportStatusDetail (order: Order, finalReportStatus: FinalReportStatus, finalReportProjectType?: GojekFinalReportProjectType): string[] {
  let reasons: string[] = [];
  if (finalReportProjectType !== GojekFinalReportProjectType.OTHERS) {
    const appReasons = getFinalReportStatusOfGoApps(order, finalReportStatus);
    reasons = reasons.concat(appReasons);
  }
  if (moment().isBefore(moment(order.startDate).startOf('day')) && finalReportStatus.status === 'NOT_READY') {
    let today = moment().format('YYYY-MM-DD');
    let diffStart = moment(order.startDate, 'YYYY-MM-DD').diff(moment(today), 'days');
    reasons.push(i18n.t<string>('orderDetail.labels.finalReport.notStart', { days: diffStart }));
  } else if (moment().isBefore(moment(order.endDate).endOf('day')) && ['READY', 'PARTIAL'].includes(finalReportStatus.status)) {
    reasons.push(i18n.t<string>('orderDetail.labels.finalReport.notEnd'));
  }
  return reasons;
}

const getFinalReportStatus = async (order) => {
  const defaultFinalReportStatus = {
    status: 'NOT_READY',
    csv: {
      status: 'NOT_READY',
      audience: [],
      outlet: []
    },
    table: {
      status: 'NOT_READY',
      audience: []
    },
    transaction: {
      status: 'NOT_READY',
      totalTransactions: 0,
      totalSoldItems: 0
    }
  };
  if (order && order.finalReportProjectType) {
    if (moment().isBefore(moment(order.startDate).startOf('day'))) {
      return defaultFinalReportStatus;
    } else {
      try {
        const status = await orderManagerWithoutError.getFinalReportStatus(order.id);
        return status;
      } catch (e) {
        return defaultFinalReportStatus;
      }
    }
  }
};

const getOrderStateDes = (state) => {
  if (State.CHANGE_PENDING === state || State.NOT_APPROVE === state) {
    return i18n.t<string>('orderDetail.labels.waitingForReview');
  }
  if (State.REJECT === state) {
    return i18n.t<string>('orderDetail.labels.reject');
  }
  if (State.SETTLE === state) {
    return i18n.t<string>('orderDetail.labels.settle');
  }
  if (State.SETTLED === state) {
    return i18n.t<string>('orderDetail.labels.settled');
  }
  return i18n.t<string>('orderDetail.labels.approve');
};

const getRemainDayDesc = (startDate, endDate) => {
  const now = moment();
  const today = now.startOf('day');
  if (moment(startDate).isAfter(today)) {
    return i18n.t<string>('orderDetail.labels.notstart');
  }

  if (moment(endDate).add(1, 'day').startOf('day').isBefore(now)) {
    return i18n.t<string>('orderDetail.labels.finished');
  }

  if (moment(endDate).startOf('day').isSame(today)) {
    return i18n.t<string>('orderDetail.labels.today');
  }

  return i18n.t<string>('orderDetail.labels.days', { days: moment(endDate).diff(today, 'days') + 1 });
};

const getDayPartValue = (dayPart) => {
  let dayPartValue = _.omitBy(_.omit(dayPart, 'enabled'), _.isEmpty);
  return Object.keys(dayPartValue).map(day => {
    return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t<string>('daypart.labels.hourUnit')}: ${dayPartValue[day].join(', ')}`;
  }).join('\r\n');
};

const isOutdoorOrder = (order) => {
  return order.orderPrice && order.creativeDuration && order.dayPart;
};

const getOrderViewData = (
  order: Order,
  orderExternalTypes: SelectOptions[],
  addonFeatureManager: AddonFeatureManager,
  authenticationManager: AuthenticationManager
) => {
  let totalBudget: any = formatPriceWithCurrency(order.currency, order.budget);
  let remainBudget: any = formatPriceWithCurrency(order.currency, order.budgetBalance);
  const orderMargin = _.round(order.orderMargin * 100, 2);
  let agencyProfit: any = `${orderMargin} %`;
  let modifyReason: any = undefined;
  if (order.state === State.CHANGE_PENDING) {
    if (order.changeBudget) {
      totalBudget = {
        origin: i18n.t<string>('orderDetail.labels.originBudget', { budget: formatPriceWithCurrency(order.currency, order.budget) }),
        change: i18n.t<string>('orderDetail.labels.changeBudget', { budget: formatPriceWithCurrency(order.currency, order.changeBudget) })
      };
      remainBudget = {
        origin: i18n.t<string>('orderDetail.labels.originBudget', { budget: formatPriceWithCurrency(order.currency, order.budgetBalance) }),
        change: i18n.t<string>('orderDetail.labels.changeBudget', { budget: formatPriceWithCurrency(order.currency, order.budgetBalance + order.changeBudget - order.budget) })
      };
    }
    if (order.changeOrderMargin) {
      agencyProfit = {
        origin: i18n.t<string>('orderDetail.labels.originProfit', { profit: orderMargin }),
        change: i18n.t<string>('orderDetail.labels.changeProfit', { profit: _.round(order.changeOrderMargin * 100, 2) })
      };
    }
    modifyReason = order.modifyReason;
  }
  const currentActorType = _.get(authenticationManager.actor, 'actorType');
  const canCheckAgencyProfit =
    addonFeatureManager.isFeatureEnable(
      ADDONFEATURE.CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING
    ) &&
    ![
      AdvertiserRole.ROLE_ADV_ADMIN.toString(),
      AdvertiserRole.ROLE_ADV_REPORT.toString(),
      AdvertiserRole.ROLE_ADV_SALES.toString()
    ].includes(`ROLE_${currentActorType}`);
  const canCheckPICMargin = isSystemAdmin(authenticationManager.actor);
  const orderExternalType = orderExternalTypes.find(typeOption => typeOption.value === order.externalType);
  const performance = order.externalType && ['Video', 'pDOOH_7-11'].includes(order.externalType) ? {
    impres: numberWithCommas(order.impres),
    adView: numberWithCommas(order.adView),
    viewRate: getPercentValue(order.adView, order.impres)
  } : {
    impres: numberWithCommas(order.impres),
    clicks: numberWithCommas(order.clicks),
    ctr: getPercentValue(order.clicks, order.impres)
  };
  const picMargin = canCheckPICMargin && order.picMargin ? `${_.round(order.picMargin * 100, 2)} %` : undefined;
  const orderViewData: any = {
    id: order.id,
    name: order.projectName,
    startDate: order.startDate,
    endDate: order.endDate,
    basic: _.omitBy({
      orderNumber: order.orderNumber,
      orderType: i18n.t<string>(`orderType.enums.${order.orderType.toLowerCase()}`),
      externalId: _.defaultTo(order.externalId, i18n.t<string>('common.labels.noData')),
      externalType: _.get(orderExternalType, 'label', i18n.t<string>('common.labels.noData')),
      dateRange: `${order.startDate} ~ ${order.endDate}`,
      remainDays: getRemainDayDesc(order.startDate, order.endDate),
      totalBudget: totalBudget,
      alreadySpent: formatPriceWithCurrency(order.currency, order.spent),
      remainBudget: remainBudget,
      progress: getPercentValue(order.spent, order.budget),
      agencyProfit: canCheckAgencyProfit ? agencyProfit : undefined,
      picMargin,
      status: getOrderStateDes(order.state),
      modifyReason,
      creator: order.creator,
      creatorEmail: order.creatorEmail,
      monitor: order.monitor ? i18n.t<string>('common.labels.open') : i18n.t<string>('common.labels.close')
    }, _.isUndefined),
    performance
  };
  if (isOutdoorOrder(order)) {
    orderViewData.outdoor = {
      orderPrice: formatPriceWithCurrency(order.currency, order.orderPrice),
      creativeDuration: numberWithCommas(order.creativeDuration) + i18n.t<string>('common.units.seconds'),
      dayPart: getDayPartValue(order.dayPart)
    };
  }
  return orderViewData;
};

const getFinalReportData = (order, finalReportStatus) => {
  const orderType = _.get(order, 'orderType');
  return orderType === OrderType.GOJEK ? getGojekFinalReportData(order, finalReportStatus) : getTenmaxFinalReportData(order);
};

const getGojekFinalReportData = (order, finalReportStatus) => {
  if (!order || !order.finalReportProjectType) {
    return undefined;
  }
  return _.omitBy({
    finalReportAdvertiserName: order.finalReportAdvertiserName,
    finalReportProjectType: order.finalReportProjectType ? i18n.t<string>(`finalReport.enums.${order.finalReportProjectType.toLowerCase()}`) : '',
    finalReportProjectName: order.finalReportProjectType === GojekFinalReportProjectType.OTHERS ? undefined : order.finalReportProjectName,
    finalReportTargetType: i18n.t<string>(`order.finalReport.targetType.${order.finalReportTargetType}`),
    finalReportTargetValue: numberWithCommas(order.finalReportTargetValue),
    finalReportReceivers: order.finalReportReceivers ? order.finalReportReceivers.join(', ') : '',
    finalReportSendOutDate: moment(order.finalReportSendOutDate).format('YYYY-MM-DD'),
    finalReportStatus: finalReportStatus ? {
      value: i18n.t<string>(`orderDetail.labels.finalReport.${_.camelCase(finalReportStatus.status)}`),
      extraInfo: getFinalReportStatusDetail(order, finalReportStatus, order.finalReportProjectType)
    } : i18n.t<string>('orderDetail.placeholders.finalReportStatus')
  }, _.isUndefined);
};

const getTenmaxFinalReportData = (order) => {
  if (!order || !order.finalReportProjectType) {
    return undefined;
  }
  const ageMin = _.get(ageMinOptions.find(option => option.value === order.ageMin), 'label');
  const ageMax = _.get(ageMaxOptions.find(option => option.value === order.ageMax), 'label');
  return {
    finalReportAdvertiserName: order.finalReportAdvertiserName,
    finalReportTargetType: i18n.t<string>(`order.finalReport.targetType.${order.finalReportTargetType}`),
    finalReportTargetValue: numberWithCommas(order.finalReportTargetValue),
    finalReportAge: `${ageMin} ~ ${ageMax}`,
    finalReportGender: _.get(genderOptions.find(option => option.value === +order.gender), 'label')
  };
};

const getSettleTips = (order: Order | undefined, hasAliveOrNotStartCampaign: boolean): string => {
  if (!order) {
    return '';
  }
  if (order.state === State.SETTLE) {
    return i18n.t<string>('orderDetail.labels.settleWait');
  }

  if (order.state === State.SETTLED) {
    return i18n.t<string>('orderDetail.labels.settledNoNeedSettle');
  }

  if (hasAliveOrNotStartCampaign) {
    return i18n.t<string>('orderDetail.labels.hasRunningCampaignCannotSettle');
  }

  return '';
};

const getCanSettle = (order: Order | undefined, hasAliveOrNotStartCampaign: boolean): boolean => {
  if (!order) {
    return false;
  }
  if (order.state === State.SETTLED) {
    return false;
  }

  if (order.state === State.SETTLE) {
    return false;
  }
  return !hasAliveOrNotStartCampaign;
};

const getReportLink = (order: Order | undefined, type: ReportType): string => {
  if (!order) {
    return '/reports/performance';
  }
  const from = encodeURIComponent(moment(order.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
  const to = encodeURIComponent(moment(order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
  return `/reports/performance?type=${type}&dimension=adsOrderId&from=${from}&to=${to}&adsOrderId=${order.id}`;
};

const showGoVideoReportButton = (order: Order | undefined, addonFeatureManager): boolean => {
  if (!order) {
    return false;
  }

  return addonFeatureManager.isAnyFeatureEnable(
    ADDONFEATURE.CREATIVES.VIDEO,
    ADDONFEATURE.CREATIVES.THIRDPARTY,
    ADDONFEATURE.CREATIVES.COMBO,
    ADDONFEATURE.CREATIVES.EDIMAX,
    ADDONFEATURE.CREATIVES.PIC_LONG,
    ADDONFEATURE.CREATIVES.PIC_SHORT
  );
};

const showApproveButton = (order?: Order): boolean => {
  if (!order) {
    return false;
  }

  return order.state === State.NOT_APPROVE || order.state === State.CHANGE_PENDING;
};

const showRejectButton = (order?: Order): boolean => {
  if (!order) {
    return false;
  }

  return order.state === State.NOT_APPROVE || order.state === State.CHANGE_PENDING;
};

const canEditOrder = (actor: Actor | null, order?: Order): boolean => {
  if (!order || !actor) {
    return false;
  }

  return order.state !== State.SETTLE &&
    actor.permissions.indexOf(Permission.ORDER_WRITE) > -1 &&
    order.id >= 2000000;
};

const showSettleButton = (order, addonFeatureManager): boolean => {
  const permissionAllowOrderSettlement = addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.AGENCY_ALLOW_ORDER_SETTLEMENT);
  const stateAllowSettlement = !!order &&
    order.state !== State.CHANGE_PENDING &&
    order.state !== State.NOT_APPROVE &&
    order.state !== State.REJECT;
  return permissionAllowOrderSettlement && stateAllowSettlement;
};

const showUnsettleButton = (order, addonFeatureManager): boolean => {
  const permissionAllowOrderSettlement = addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.AGENCY_ALLOW_ORDER_SETTLEMENT);
  const stateAllowSettlement = !!order &&
    order.state === State.SETTLE;
  return permissionAllowOrderSettlement && stateAllowSettlement;
};

const validateBudgetWithRemainingStoredValue = async (order: Order | undefined, addonFeatureManager: AddonFeatureManager, storedValueManager: StoredValueManager): Promise<string | undefined> => {
  if (!order) {
    return;
  }
  if (addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.STORED_VALUE) && showApproveButton(order)) {
    const remainingStoredValue = await storedValueManager.getRemainingStoredValue(order.agencyId);
    const budgetToCompare = !_.isNil(order.changeBudget) ? order.changeBudget - order.budget : order.budget;
    if (!_.isNil(remainingStoredValue) && budgetToCompare > remainingStoredValue) {
      return i18n.t<string>('orderDetail.errors.budgetExceedStoredValueError', { amount: formatPriceWithCurrency(order.currency, remainingStoredValue) });
    }
  }
};
