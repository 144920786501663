import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { FormControl, OverlayTrigger, Popover } from 'react-bootstrap';
import styles from './dateRangePicker.module.scss';
import classnames from 'classnames/bind';

export type DateRange = {
  label: string,
  dateRange: [Date, Date]
};

export type DateRangePickerProps = {
  startDate: string;
  endDate: string;
  minDate?: string;
  maxDate?: string;
  format?: string;
  className?: string;
  popOverClassName?: string;
  showTimePicker?: boolean;
  timeInterval?: 30 | 60;
  startDatePickerDisabled?: boolean;
  endDatePickerDisabled?: boolean;
  parseDate?: (date: string) => Date;
  onChange: (startDate: string | undefined, endDate: string | undefined) => void;
  formatDate?: (date: Date, locale?: string) => string;
  shortcuts?: any;
  onPopOverClosed?: () => void;
  hasError?: boolean;
};

export const defaultFormat = 'YYYY-MM-DD HH:mm:ss';
const ignoreTimeZoneFormat = 'YYYY-MM-DD HH:mm:ss';

export class DateRangePicker extends React.Component<DateRangePickerProps> {
  static defaultProps = {
    format: defaultFormat,
    onChange: _.noop,
    showTimePicker: true,
    timeInterval: 60,
    startDatePickerDisabled: false,
    endDatePickerDisabled: false,
    hasError: false
  };
  injectEndTimes: Date[];
  cx: any;
  constructor (props) {
    super(props);
    this.cx = classnames.bind(styles);
    this.injectEndTimes = _.flatten(Array.from(Array(24), (_, index) => {
      const optionArray: Date[] = [];
      const endOfHour = new Date();
      endOfHour.setHours(index);
      endOfHour.setMinutes(59);
      optionArray.push(endOfHour);
      if (props.timeInterval === 30) {
        const endOfHalfHour = new Date();
        endOfHalfHour.setHours(index);
        endOfHalfHour.setMinutes(29);
        optionArray.push(endOfHalfHour);
      }
      return optionArray;
    }));
  }

  parseDate = (s: string) => {
    if (this.props.parseDate) {
      return this.props.parseDate(s);
    }

    const ignoreTimeZoneDate = moment(moment(s, this.props.format).format(ignoreTimeZoneFormat));
    return new Date(
      ignoreTimeZoneDate.year(),
      ignoreTimeZoneDate.month(),
      ignoreTimeZoneDate.date(),
      ignoreTimeZoneDate.hour(),
      ignoreTimeZoneDate.minute(),
      ignoreTimeZoneDate.second()
    );
  }

  formatDate = (date: Date) => {
    if (this.props.formatDate) {
      return this.props.formatDate(date);
    }
    const clientTimeZone = moment.tz.guess();
    return moment.tz(date, clientTimeZone).format(this.props.format);
  }

  formatDateInternal = (date: Date) => {
    const clientTimeZone = moment.tz.guess();
    const format = this.props.showTimePicker ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
    return moment.tz(date, clientTimeZone).format(format);
  }

  handleChange = (startDate: Date, endDate: Date) => {
    let finalEndDate = endDate;
    if (endDate.getTime() < startDate.getTime()) {
      finalEndDate = new Date(startDate.getTime());
      finalEndDate.setMinutes(59);
    }
    this.props.onChange(
      this.formatDate(startDate),
      this.formatDate(finalEndDate)
    );
  }

  onStartDateChange = (date: Date) => {
    const updateStartMoment = moment(date).startOf('minute');
    let updateEndDate = this.parseDate(this.props.endDate);
    if (updateStartMoment.isAfter(moment(updateEndDate))) {
      updateEndDate = this.parseDate(moment(date).endOf('day').format(ignoreTimeZoneFormat));
    }
    this.handleChange(updateStartMoment.toDate(), updateEndDate);
  }

  onEndDateChange = (date: Date) => {
    const updateEndMoment = this.props.showTimePicker ?
      moment(date).endOf('minute') :
      moment(date).endOf('day');
    this.handleChange(this.parseDate(this.props.startDate), updateEndMoment.toDate());
  }

  renderShortCut = () => {
    return this.props.shortcuts ?
      this.props.shortcuts.map(shortcut => {
        return (
          <div
            className={styles.shortcutItem}
            key={shortcut.label}
            onClick={this.handleChange.bind(this, shortcut.dateRange[0], shortcut.dateRange[1])}
          >
            {shortcut.label}
          </div>
        );
      }) :
      undefined;
  }

  onClose = () => {
    this.props.onPopOverClosed && this.props.onPopOverClosed();
  }

  filterStartTime = time => {
    if (this.props.startDatePickerDisabled) {
      return false;
    }
    const selectedDate = this.parseDate(this.props.startDate);
    return this.filterTime(selectedDate, time);
  }

  filterEndTime = time => {
    if (this.props.endDatePickerDisabled) {
      return false;
    }
    const selectedDate = this.parseDate(this.props.endDate);
    return this.filterTime(selectedDate, time);
  }

  filterTime = (selectedDate, time) => {
    const minTime = this.props.minDate ? this.parseDate(this.props.minDate) : undefined;
    const maxTime = this.props.maxDate ? this.parseDate(this.props.maxDate) : undefined;
    const timeDate = new Date(time);
    selectedDate.setHours(timeDate.getHours());
    selectedDate.setMinutes(timeDate.getMinutes());
    const biggerThanMin = minTime ? minTime.getTime() <= selectedDate.getTime() : true;
    const smallerThanMax = maxTime ? maxTime.getTime() >= selectedDate.getTime() : true;
    return biggerThanMin && smallerThanMax;
  }

  render () {
    const {
      startDate: startDateString,
      endDate: endDateString,
      showTimePicker,
      timeInterval,
      minDate,
      maxDate,
      popOverClassName,
      className,
      hasError,
      startDatePickerDisabled,
      endDatePickerDisabled
    } = this.props;

    const startDate = this.parseDate(startDateString);
    const endDate = this.parseDate(endDateString);

    if (startDatePickerDisabled && endDatePickerDisabled) {
      return (
        <FormControl
          disabled
          type='text'
          style={{ minWidth: showTimePicker ? '260px' : '185px' }}
          value={`${this.formatDateInternal(this.parseDate(startDateString))} ~ ${this.formatDateInternal(this.parseDate(endDateString))}`}
        />
      );
    }
    const startTimePickerProps = showTimePicker ? {
      timeFormat: 'HH:mm',
      timeIntervals: timeInterval,
      showTimeSelect: true,
      filterTime: this.filterStartTime
    } : undefined;
    const endTimePickerProps = showTimePicker ? {
      timeFormat: 'HH:mm',
      injectTimes: this.injectEndTimes,
      timeIntervals: 60 * 24,
      showTimeSelect: true,
      filterTime: this.filterEndTime
    } : undefined;
    const basicDatePickerProps = {
      startDate,
      endDate,
      disabledKeyboardNavigation: true,
      showMonthDropdown: true,
      showYearDropdown: true,
      inline: true
    };
    const startDateMinMax = this.props.startDatePickerDisabled ? {
      minDate: startDate,
      maxDate: moment(startDate).subtract(1, 'day').toDate()
    } : {
      minDate: minDate ? this.parseDate(minDate) : undefined,
      maxDate: maxDate ? this.parseDate(maxDate) : undefined
    };
    const endDateMinMax = this.props.endDatePickerDisabled ? {
      minDate: endDate,
      maxDate: moment(endDate).subtract(1, 'day').toDate()
    } : {
      minDate: startDate,
      maxDate: maxDate ? this.parseDate(maxDate) : undefined
    };
    const inputClass = this.cx('datePickerInput', className, {
      error: hasError
    });
    return (
      <OverlayTrigger
        placement='bottom'
        trigger='click'
        rootClose
        onExited={this.onClose}
        overlay={
          <Popover id='popover-basic' className={popOverClassName}>
            <Popover.Content className={styles.datePickerContainer}>
              <div className={styles.shortcuts}>
                {this.renderShortCut()}
              </div>
              <DatePicker
                calendarClassName='start-date'
                selected={startDate}
                onChange={this.onStartDateChange}
                selectsStart
                {...basicDatePickerProps}
                {...startDateMinMax}
                {...startTimePickerProps}
              />
              <DatePicker
                calendarClassName='end-date'
                selected={endDate}
                onChange={this.onEndDateChange}
                selectsEnd
                {...basicDatePickerProps}
                {...endDateMinMax}
                {...endTimePickerProps}
              />
            </Popover.Content>
          </Popover>
        }
      >
        <span className={`form-control ${inputClass}`}>
          {`${this.formatDateInternal(this.parseDate(startDateString))} ~ ${this.formatDateInternal(this.parseDate(endDateString))}`}
        </span>
      </OverlayTrigger>
    );
  }
}
