import { OrderListModel, DefaultOrderListModel } from 'components/OrderList/OrderListModel';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { AddonFeatureManager } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';

export interface OrderHomeModel {
  readonly advertiserId?: number;
  readonly advertisers: Array<SelectOptions>;
  readonly orderListModel: OrderListModel;
  readonly event: UpdateEventListener<OrderHomeModel>;
  readonly state: OrderHomeState;
  setAdvertiserId (advertiserId: number | undefined): void;
}

export type OrderHomeProps = {
  readonly model: OrderHomeModel
};

export type OrderHomeState = {
  readonly advertiserId?: number;
};

export class DefaultOrderHomeModel implements OrderHomeModel {

  advertiserId?: number;
  advertisers: Array<SelectOptions>;
  orderListModel: OrderListModel;
  event: FireableUpdateEventListener<OrderHomeModel>;

  constructor (
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager
  ) {
    this.advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    this.advertisers = advertisers;
    this.orderListModel = new DefaultOrderListModel(this.advertiserId, addonFeatureManager);
    const advertiserMap = {};
    this.advertisers.forEach(advertiser => {
      advertiserMap[advertiser.value] = advertiser.label;
    });
    this.orderListModel.setAdvertisers(advertiserMap);
    this.event = new FireableUpdateEventListener<OrderHomeModel>();
  }

  get state () {
    return {
      advertiserId: this.advertiserId
    };
  }

  setAdvertiserId (advertiserId: number | undefined) {
    if (this.advertiserId === advertiserId) {
      return;
    }

    this.advertiserId = advertiserId;
    this.orderListModel.changeAdvertiser(this.advertiserId);
    this.event.fireEvent(this);
  }
}
