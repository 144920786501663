import { FormikField } from 'components/common/form/field/FormikField';
import { Page } from 'components/Page/Page';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { RoleNames } from 'core';
import { hasRoles } from 'core/permission/PermissionDSL';
import i18n from 'i18n';
import styles from './productGroupForm.module.scss';

export const OtherSection: React.FC<{ agencyOptions: SelectOptions[] }> = ({
  agencyOptions
}) => {
  return (
    <Page.Section title={i18n.t<string>('productGroupForm.titles.other')} inlineTitle withShadow={false}>
      <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
        <FormikField.Select
          labelColSm={2}
          inputColSm={10}
          label={i18n.t<string>('productGroupForm.labels.agencyIds')}
          name='agencyIds'
          simpleValue
          isMulti
          options={agencyOptions}
          closeMenuOnSelect={false}
          className={styles.otherField}
        />
      </PermissionChecker>
      <FormikField.TextArea
        labelColSm={2}
        inputColSm={10}
        label={i18n.t<string>('productGroupForm.labels.description')}
        name='description'
        className={styles.otherField}
      />
    </Page.Section>
  );
};
