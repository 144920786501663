import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import styles from './productSegmentList.module.scss';
import formatters from './listFormatters';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { numberWithCommas } from 'utils/StringUtil';
import i18n from 'i18n';
import moment from 'moment';
import { DismissFunction } from 'components/AlertDialog';
import { useCoreContext } from 'contexts/coreContext';
import { debounce, defaultTo, get, partial } from 'lodash';
import { ProductSegmentRecord } from 'core/product/ProductSegment';
import { CampaignState } from 'core/rtbCampaign/RtbCampaign';

export enum ProductSegmentListColumns {
  ID = 'segmentId',
  ADVERTISER = 'advertiserName',
  VISITORS = 'visitors',
  EXEC_STATUS = 'execStatus',
  UPDATED_TIME = 'lastUpdatedTime',
  EDITBTNS = 'editBtns'
}

export enum SegmentUsageType {
  NO_USAGE,
  RUNNING_CAMPAIGN,
  NOT_RUNNING_CAMPAIGN
}

const defaultRdpManager: RdpManager = new DefaultRdpManager();

export const useProductSegmentListModel = (
  retailers: SelectOptions[],
  rdpManager: RdpManager = defaultRdpManager
) => {

  const coreContext = useCoreContext();
  const agencyId = defaultTo(coreContext?.accountManager.localeMeta?.agencyId, 1);
  const [productSegments, setProductSegments] = useState<ProductSegmentRecord[]>([]);
  const [pagination, setPagination] = useState<{
    page: number,
    size: number,
    totalCount: number
  }>({
    page: 1,
    size: 10,
    totalCount: 0
  });
  const [retailerId, setRetailerId] = useState<number | string | undefined>();
  const [advertiserId, setAdvertiserId] = useState<number | string | undefined>();
  const [searchString, setSearchString] = useState<string>('');
  const [showActive, setShowActive] = useState<boolean>(true);
  const [modalData, setModalData] = useState<{
    usageType: SegmentUsageType,
    detail: string,
    confirmBtnData: {
      title: string,
      callback: DismissFunction,
      disabled?: boolean
    }
  } | undefined>();
  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    if (retailers.length === 0) {
      return;
    }
    setRetailerId(retailers[0].value);
  }, [retailers]);

  const fetchProductSegmentsData = useCallback(async (page: number | undefined = 1) => {
    if (retailerId) {
      const pageable = { page, sizePerPage: 10 };
      callAPIs([
        () => rdpManager.getProductSegments(agencyId, retailerId, pageable, searchString, showActive, advertiserId)
      ], productSegmentsWithPagination => {
        setProductSegments(productSegmentsWithPagination.productSegments);
        setPagination(productSegmentsWithPagination.pagination);
      });
      return;
    }
    setProductSegments([]);
    setPagination({
      page: 1,
      size: 10,
      totalCount: 0
    });
  }, [advertiserId, agencyId, callAPIs, rdpManager, retailerId, searchString, showActive]);

  useEffect(() => {
    fetchProductSegmentsData();
  }, [fetchProductSegmentsData]);

  const debounceSetSearchString = useMemo(() => {
    return debounce(setSearchString, 1000);
  }, []);

  const handleOnSearch = (searchString: string): void => {
    if (searchString === '') {
      debounceSetSearchString.cancel();
      setSearchString(searchString);
      return;
    }
    debounceSetSearchString(searchString);
  };

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `productSegmentList.headers.${columnName}`, false),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const archiveProductSegment = useCallback((segmentId: string) => {
    if (!retailerId) {
      return;
    }
    callAPIs([() => rdpManager.deleteProductSegment(retailerId.toString(), segmentId)], () => {
      fetchProductSegmentsData(pagination.page);
      setModalData(undefined);
    });
  }, [callAPIs, fetchProductSegmentsData, pagination.page, rdpManager, retailerId]);

  const onArchiveBtnClick = useCallback((productSegmentRecord: ProductSegmentRecord) => {
    const modalData = getArchiveModalData(productSegmentRecord, archiveProductSegment);
    setModalData(modalData);
  }, [archiveProductSegment]);

  const handleTableChange = useCallback((type, props) => {
    if (type === 'sort') {
      return;
    }
    fetchProductSegmentsData(props.page);
  }, [fetchProductSegmentsData]);

  const retailer = retailers.find(option => option.value === retailerId);
  const retailName = get(retailer, 'label', get(retailers, '0.label', '')).toLowerCase();
  const columns = [
    renderColumn({
      ...columnDefinition(ProductSegmentListColumns.ID),
      formatExtraData: {
        retail: retailName
      }
    }, formatters.nameFormatter),
    renderColumn(columnDefinition(ProductSegmentListColumns.ADVERTISER)),
    renderColumn(columnDefinition(ProductSegmentListColumns.VISITORS), numberWithCommas),
    renderColumn(columnDefinition(ProductSegmentListColumns.EXEC_STATUS), formatters.execStatusFormatter),
    renderColumn(columnDefinition(ProductSegmentListColumns.UPDATED_TIME), value => moment(value).format('YYYY-MM-DD HH:mm:ss')),
    renderColumn({
      ...columnDefinition(ProductSegmentListColumns.EDITBTNS),
      text: '',
      sort: false,
      formatExtraData: {
        retail: retailName,
        onArchiveBtnClick
      }
    }, formatters.floatingEditBtnsFormatter)
  ];

  return {
    loading,
    columns,
    modalData,
    showActive,
    pagination,
    retailerId,
    advertiserId,
    searchString,
    productSegments,
    setShowActive,
    setModalData,
    setRetailerId,
    setAdvertiserId,
    handleOnSearch,
    handleTableChange
  };
};

const getArchiveModalData = (productSegmentRecord: ProductSegmentRecord, archiveProductSegment) => {
  const campaigns = productSegmentRecord.campaigns;
  if (!campaigns || campaigns.length === 0) {
    return {
      usageType: SegmentUsageType.NO_USAGE,
      detail: '',
      confirmBtnData: {
        title: i18n.t<string>('productSegmentList.buttons.archive'),
        callback: partial(archiveProductSegment, productSegmentRecord.segmentId)
      }
    };
  }
  const now = moment();
  const detailList = campaigns.map(campaign => {
    let state;
    if (campaign.state === CampaignState.DEACTIVATE) {
      state = 'paused';
    } else if (moment(campaign.startDate).isAfter(now)) {
      state = 'scheduled';
    } else if (moment(campaign.endDate).isBefore(now)) {
      state = 'finished';
    } else {
      state = 'running';
    }
    return `${campaign.name} (${i18n.t<string>(`productSegment.usageType.${state}`)})`;
  }, {});

  return {
    usageType: SegmentUsageType.RUNNING_CAMPAIGN,
    detail: detailList.join(','),
    confirmBtnData: {
      title: i18n.t<string>('productSegmentList.buttons.archive'),
      disabled: true,
      callback: () => {
        // This is intentional
      }
    }
  };
};
