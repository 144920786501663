import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styles from './stateTableCell.module.scss';
import { Status } from 'components/Status/Status';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const StateTableCell: React.FC<{
  stateData: {
    des: string,
    color: string,
    extraInfo?: string
  },
  renderHintButton?: Function,
  renderHintData?: Function
}> = ({
  stateData,
  renderHintButton,
  renderHintData
}) => {
  const [showHint, setShowHint] = useState(false);

  const handleHintDetail = () => {
    setShowHint(!showHint);
  };

  const renderHintDetail = () => {
    return (
      <div className={styles.hintDetailBox}>
        <div className={styles.toggleDetail}>
          <Button variant='link' className={styles.closeBtn} onClick={handleHintDetail}>
            <FontAwesomeIcon icon={faTimes} size='sm' />
          </Button>
        </div>
        <div className={styles.hintDetail}>
          {renderHintData && renderHintData()}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.tableCell}>
      <Status
        label={stateData.des}
        color={stateData.color}
        renderExtraInfo={stateData.extraInfo ? () => stateData.extraInfo : undefined}
      />
      {renderHintButton && renderHintButton(handleHintDetail)}
      {showHint && renderHintData && renderHintDetail()}
    </div>
  );
};
