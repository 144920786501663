import { SummaryData, SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { PmpFormType, PmpType } from 'core/pmp/Pmp';
import { camelCase, compact, defaultTo, isEmpty, omit, omitBy } from 'lodash';
import i18n from 'i18n';
import { useCoreContext } from 'contexts/coreContext';
import { getDayOfWeekLabelByValue } from 'components/Dayparts/Dayparts';
import moment from 'moment';

export function getPmpSummaryData (
  pmp: PmpFormType | undefined,
  spaceOptions: SelectOptions[],
  currency: string,
  errors?: { [key: string]: string | JSX.Element }
): SummaryData[] {

  if (!pmp) return [];

  const dateFormat = pmp.pmpType === PmpType.BUYOUT ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';

  const agencyI18nKey = `agency.name.${pmp.adAgencyIds[0]}`;
  const agencySummary = i18n.exists(agencyI18nKey)
    ? i18n.t<string>(agencyI18nKey)
    : i18n.t<string>('pmp.labels.noAgency');
  const spacesSummary = pmp.spaces
    .map(space => {
      const option = spaceOptions.find(option => option.value === space);
      return option ? option.label : space;
    })
    .join('\n');

  const dayPartValue = omitBy(omit(pmp.dayPart, 'enabled'), isEmpty);
  const dayPartSummary = dayPartValue
    ? Object.keys(dayPartValue)
        .map(day => {
          const value = dayPartValue[day];
          return `${getDayOfWeekLabelByValue(
            parseInt(day, 10)
          )},${i18n.t<string>('daypart.labels.hourUnit')}: ${
            Array.isArray(value) ? value.join(', ') : value
          }`;
        })
        .join('\r\n')
    : undefined;

  const getColor = (key: string) => (errors && errors[key] ? SummaryTitleColor.RED : undefined);
  return compact([
    {
      label: i18n.t<string>('pmp.labels.name'),
      value: pmp.name
    },
    {
      label: i18n.t<string>('pmp.labels.pmpType'),
      value: i18n.t<string>(`pmp.pmpTypes.${camelCase(pmp.pmpType)}`)
    },
    {
      label: i18n.t<string>('pmp.labels.duration'),
      value: `${moment(pmp.startTime).format(dateFormat)} ~ ${moment(pmp.endTime).format(dateFormat)}`,
      valueColor: getColor('dayRange'),
      hint: errors?.dayRange,
      hintColor: getColor('dayRange')
    },
    dayPartSummary ? {
      label: i18n.t<string>('pmp.labels.dayparts'),
      value: dayPartSummary
    } : undefined,
    {
      label: i18n.t<string>('pmp.labels.spaces'),
      value: spacesSummary
    },
    {
      label: i18n.t<string>('pmp.labels.adType'),
      value: i18n.t<string>(`pmp.adType.${camelCase(pmp.adType)}`)
    },
    {
      label: i18n.t<string>('pmp.labels.budget'),
      value: `${currency} ${pmp.budget}`,
      valueColor: getColor('budget'),
      hint: errors?.budget,
      hintColor: getColor('budget')
    },
    {
      label: i18n.t<string>('pmp.labels.agency'),
      value: agencySummary
    }
  ]);
}

export const usePmpSummaryModel = (
  pmp: PmpFormType | undefined,
  spaceOptions: SelectOptions[],
  errors?: { [key: string]: string }
): SummaryData[] => {
  const core = useCoreContext();
  const currency = defaultTo(core?.accountManager?.localeMeta?.currency, 'NTD');

  if (!pmp) return [];

  return getPmpSummaryData(pmp, spaceOptions, currency, errors);
};
