import { AgencyDetail, Language, Currency, TimeZone, AgencyRebate, Actor } from 'core';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import {
  AgencyManager,
  DefaultAgencyManager
} from 'core/agency/AgencyManager';
import { multiply100 } from 'utils/Math';
import { AgencyMemberTabModel, DefaultAgencyMemberTabModel } from './AgencyMemberTabModel';
import { AdLogoType } from 'core/adLogo/AdLogo';
import { round } from 'lodash';
import { AgencyAuditLogModel } from 'containers/AuditLog/AgencyAuditLogModel';

const defaultAgency: AgencyDetail & AgencyRebate = {
  currentRate: 0,
  bidPriceBase: {
    DISPLAY: 1,
    COMBO: 17,
    VIDEO: 90,
    THIRD_PARTY: 112.5,
    THIRD_PARTY_BOTTOM: 112.5,
    THIRD_PARTY_RECTANGLE: 112.5
  },
  addonFeatures: {
    channel: {
      openRTB: true,
      retail_media: true,
      ediMax: true,
      facebook: true,
      message: true
    },
    order: {
      tenmax: false,
      gojek: false,
      showOrderExternalType: false
    },
    creatives: {
      option_1: false,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_12: false,
      option_13: false,
      option_20: false,
      option_21: false
    },
    company: {
      agencyProfit: false,
      rebate: false,
      allow: false,
      provideReportToWorkflow: false,
      singleOrderBudgetConstraint: false,
      orderBudgetMaximum: false,
      useAgencyProfitOnRebateReport: false,
      showOutComeOnRebateReport: false,
      creditLimit: false,
      allowOrderSettlement: false,
      fixedExchangeRate: false,
      storedValue: false,
      advAdminInfoRestriction: false,
      directlyApproveCreative: false
    },
    campaign: {
      bid_weight_setting: false,
      orderAgcProfitSetting: false,
      deliveryAmountConstraint: false,
      bidPriceConstraint: false,
      bidPriceBaseVal: false,
      bidPriceCPCMin: false,
      bidPriceCPMMin: false,
      bidPriceCPVMin: false,
      enableOptimizedPrice: false,
      revenueSharing: false,
      budgetDominate: false,
      roadblock: false,
      videoAdViewObjective: false,
      thirdparty_tracking: false,
      retargeting_tracker: false
    },
    conversiontracking: {
      TrackingList: false
    },
    report: {
      viewableCtr: false,
      campaign_dashboard: false,
      booking_calendar: false,
      yahooAd: false,
      closingReport: false,
      restrictFinalReport: false,
      campaignDashboard: false
    },
    monitor: {
      campaignBehindProgress: false,
      systemProfitMonitor: false
    },
    campaignLimitation: {
      country: false,
      spaceType: false,
      device: false,
      target_audience: false,
      adexchange: false,
      asiamax_space: false,
      domain: false,
      space_category: false,
      tenmax_audience: false,
      product_segment: false,
      city: false,
      gender: false,
      age: false,
      carrier: false,
      enablePMPDeal: false,
      keyword: false,
      unicornkeywords: false,
      content_language: false,
      content_category: false
    }, creativeLimitation: {
      country: false,
      spaceType: false,
      device: false,
      target_audience: false,
      adexchange: false,
      asiamax_space: false,
      domain: false,
      space_category: false,
      tenmax_audience: false,
      city: false,
      gender: false,
      age: false,
      carrier: false,
      enablePMPDeal: false,
      keyword: false,
      unicornkeywords: false,
      content_language: false,
      content_category: false
    }
  },
  addonProps: {
    creditLimit: 500000,
    fixedExchangeRate: 30,
    orderBudgetMaximum: 500000,
    sysProfitMonitorPercent: 10,
    maxOrderProfit: 50,
    outdoorAdImpresMultiply: 1
  },
  logoUrl: '',
  agcDataPercent: 0,
  agcPercent: 15,
  agencyId: 0,
  comComment: '',
  companyName: '',
  currency: Currency.NTD,
  defaultCountry: [],
  defaultCountryOp: 'Preferred',
  defaultSpace: [],
  defaultSpaceOp: 'exc',
  interstitial: 0,
  oem: false,
  language: Language.ZHTW,
  limitAdx: [],
  limitAdxOp: 'inc',
  limitPublisher: [],
  limitPublisherCountry: [],
  noLimitSelfBuyStatus: true,
  priority: 1,
  segmentCountry: [],
  segmentLabel: [],
  selfBuy: false,
  sysDataPercent: 15,
  sysPercent: 25,
  sysPercentIn: 15,
  sysPercentOut: 0,
  targetBudgetMinimum: 100,
  campaignBudgetMinimum: 100,
  taxPercent: 0,
  timeZone: TimeZone.TAIPEI,
  adLogo: {
    type: AdLogoType.DEFAULT
  },
  logoData: {}
};

export interface AgencyDetailModel {
  readonly agencyId?: number;
  readonly operator?: Actor | null;
  readonly defaultAgency: AgencyDetail & AgencyRebate;
  readonly title: string;
  readonly currentAccountId: number;
  state: AgencyDetailState;
  isSystemViewOnly: boolean;
  event: UpdateEventListener<AgencyDetailModel>;
  init (): Promise<void>;
  getAgencyMemberTabModel (): AgencyMemberTabModel;
  showTab (tabName: string | null, e): void;
  getAuditLogModel (): AgencyAuditLogModel | undefined;
}

export interface AgencyDetailProps {
  readonly model: AgencyDetailModel;
}

export type AgencyDetailState = {
  isLoading: boolean;
  dataTabName: string;
};

export class DefaultAgencyDetailModel implements AgencyDetailModel {
  agencyDetail: AgencyDetail & AgencyRebate;
  modelEvent: FireableUpdateEventListener<AgencyDetailModel>;
  isLoading: boolean;
  isSystemViewOnly: boolean;
  agencyManager: AgencyManager;
  agencyId: number;
  operator: Actor | null;
  agencyMemberTabModel?: AgencyMemberTabModel;
  dataTabName: string = 'basic';
  auditLogModel?: AgencyAuditLogModel;

  constructor (
    agencyId: number,
    operator: Actor | null,
    public currentAccountId: number,
    agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    this.agencyId = agencyId;
    this.operator = operator;
    this.agencyManager = agencyManager;
    this.modelEvent = new FireableUpdateEventListener<AgencyDetailModel>();
    this.isLoading = true;
    this.isSystemViewOnly = operator ? operator.role === 'ROOT' : false;
    this.agencyDetail = defaultAgency;
  }

  async init (): Promise<void> {
    try {
      const { detail, rebate } = await this.agencyManager.fetchAgency(this.agencyId);
      const percentFields = this.convertToPercent({ ...detail, ...rebate });
      this.agencyDetail = { ...detail, ...percentFields, effectDate: rebate.effectDate };
      await this.getAuditLogModel()?.init(1, true);
      this.updateState(false);
    } catch (e) {}
  }

  showTab = (tabName, e) => {
    e && e.stopPropagation();
    if (tabName === null) {
      return;
    }
    this.dataTabName = tabName;
    this.updateState(false);
  }

  convertToPercent ({
    sysPercentIn,
    sysPercentOut,
    sysPercent,
    sysDataPercent,
    agcPercent,
    agcDataPercent,
    taxPercent,
    addonProps,
    currentRate,
    futureRate
  }: AgencyDetail & AgencyRebate) {
    const { maxOrderProfit, sysProfitMonitorPercent } = addonProps;
    const multiply = (value) => round(multiply100(value), 2);
    return {
      sysPercentIn: multiply(sysPercentIn),
      sysPercentOut: multiply(sysPercentOut),
      sysPercent: multiply(sysPercent),
      sysDataPercent: multiply(sysDataPercent),
      agcPercent: multiply(agcPercent),
      agcDataPercent: multiply(agcDataPercent),
      taxPercent: multiply(taxPercent),
      currentRate: multiply(currentRate),
      futureRate: futureRate ? multiply(futureRate) : futureRate,
      addonProps: {
        ...addonProps,
        maxOrderProfit: multiply(maxOrderProfit),
        sysProfitMonitorPercent: multiply(sysProfitMonitorPercent)
      }
    };
  }

  get defaultAgency (): AgencyDetail & AgencyRebate {
    return this.agencyDetail;
  }

  get title (): string {
    return 'agency.form.titles.edit';
  }

  get state (): AgencyDetailState {
    return {
      isLoading: this.isLoading,
      dataTabName: this.dataTabName
    };
  }

  get event (): UpdateEventListener<AgencyDetailModel> {
    return this.modelEvent;
  }

  updateState (
    isLoading: boolean
  ) {
    this.isLoading = isLoading;
    this.modelEvent.fireEvent(this);
  }

  getAgencyMemberTabModel (): AgencyMemberTabModel {
    if (this.agencyMemberTabModel) {
      return this.agencyMemberTabModel;
    }
    this.agencyMemberTabModel = new DefaultAgencyMemberTabModel(this.agencyId, this.agencyManager, this.operator, this.currentAccountId);
    return this.agencyMemberTabModel;
  }

  getAuditLogModel (): AgencyAuditLogModel | undefined {
    // TODO: temparary only Admin enable to see audit log
    const isAdmin = this.isSystemViewOnly;
    if (!isAdmin) {
      return undefined;
    }

    if (
      this.auditLogModel &&
      this.agencyId === this.auditLogModel.agencyId
    ) {
      return this.auditLogModel;
    }

    this.auditLogModel = new AgencyAuditLogModel(isAdmin, this.agencyId);
    return this.auditLogModel;
  }
}
