import { PMaxCore } from 'core';
import { SegmentHomeModel, DefaultSegmentHomeModel, SysAdminSegmentHomeModel } from './SegmentHome/SegmentHomeModel';
import { DefaultAdvertiserManager, AdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { SegmentFormModel, CreateSegmentFormModel, EditSegmentFormModel } from './SegmentForm/SegmentFormModel';
import { SegmentDetailModel, DefaultSegmentDetailModel } from './SegmentDetail/SegmentDetailModel';
import { isSystemAdmin } from 'helper/ActorHelper';

export interface SegmentsModel {
  readonly event: UpdateEventListener<SegmentsModel>;
  readonly state: SegmentsState;
  init (): Promise<void>;
  getSegmentHomeModel (): SegmentHomeModel;
  getCreateSegmentFormModel (): SegmentFormModel;
  getEditSegmentFormModel (segmentId: number): SegmentFormModel;
  getSegmentDetailModel (segmentId: number): SegmentDetailModel;
  onUnmount (handler: any): void;
}

export type SegmentsProps = {
  readonly model: SegmentsModel;
};

export type SegmentsState = {
  readonly loading: boolean;
};

export class DefaultSegmentsModel implements SegmentsModel {

  loading: boolean;
  core: PMaxCore;
  segmentHomeModel?: SegmentHomeModel;
  createSegmentFormModel?: CreateSegmentFormModel;
  editSegmentFormModel?: EditSegmentFormModel;
  segmentDetailModel?: SegmentDetailModel;
  advertiserManager: AdvertiserManager;
  advertisers: Array<SelectOptions>;
  event: FireableUpdateEventListener<SegmentsModel>;

  constructor (
    core: PMaxCore,
    advertiserManager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.core = core;
    this.loading = true;
    this.event = new FireableUpdateEventListener<SegmentsModel>();
    this.advertiserManager = advertiserManager;
    this.advertisers = [];
  }

  get state () {
    return {
      loading: this.loading
    };
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      this.advertisers = await this.advertiserManager.getAdvertiserOptions();
    } catch (error) {}
    this.updateState(false);
  }

  getSegmentHomeModel () {
    if (isSystemAdmin(this.core.authenticationManager.actor)) {
      if (this.segmentHomeModel) {
        return this.segmentHomeModel;
      }
      this.segmentHomeModel = new SysAdminSegmentHomeModel();
    } else {
      const currentAdvertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
      if (this.segmentHomeModel &&
        this.segmentHomeModel.state.advertisers === this.advertisers &&
        this.segmentHomeModel.state.advertiserId === currentAdvertiserId) {
        return this.segmentHomeModel;
      }
      this.segmentHomeModel = new DefaultSegmentHomeModel(this.advertisers);
    }
    return this.segmentHomeModel;
  }

  getCreateSegmentFormModel (): SegmentFormModel {
    if (this.createSegmentFormModel &&
      this.createSegmentFormModel.advertisers === this.advertisers) {
      return this.createSegmentFormModel;
    }
    this.createSegmentFormModel = new CreateSegmentFormModel(this.advertisers);
    return this.createSegmentFormModel;
  }

  getEditSegmentFormModel (segmentId: number): SegmentFormModel {
    if (this.editSegmentFormModel &&
      this.editSegmentFormModel.segmentId === segmentId &&
      this.editSegmentFormModel.advertisers === this.advertisers) {
      return this.editSegmentFormModel;
    }
    this.editSegmentFormModel = new EditSegmentFormModel(segmentId, this.advertisers);
    return this.editSegmentFormModel;
  }

  getSegmentDetailModel (segmentId: number): SegmentDetailModel {
    if (this.segmentDetailModel &&
      this.segmentDetailModel.segmentId === segmentId) {
      return this.segmentDetailModel;
    }
    this.segmentDetailModel = new DefaultSegmentDetailModel(segmentId);
    return this.segmentDetailModel;
  }

  onUnmount (handler) {
    handler && this.event.remove(handler);
    this.loading = true;
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
