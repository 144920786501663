import _ from 'lodash';
import React, { Fragment, ReactElement } from 'react';
import i18n from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ColumnDescription, ColumnFormatter, HeaderFormatter, SortOrder } from 'react-bootstrap-table-next';
import { NameWithIdColumn } from './NameWithIdColumn';
import { SelectableColumn } from './SelectableColumn';
import { SelectableHeader } from './SelectableHeader';
import { LabelValueColumn } from './LabelValueColumn';
import { ContentWithEyeColumn } from './ContentWithEyeColumn';
import { Trans } from 'react-i18next';
import { Status } from 'components/Status/Status';
import { Link } from 'react-router-dom';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import classNames from 'classnames/bind';
import styles from './tableColumn.module.scss';

const cx = classNames.bind(styles);

export type SortDescriptor = [{

  readonly order: SortOrder;
  readonly dataField: any;
}];

export type ColumnDefinition = Omit<ColumnDescription, 'formatter' | 'headerFormatter'>;

function translatedHeader (column: ColumnDescription, columnIndex: number, { sortElement }) {
  const header = i18n.t<string>(column.text);
  return (
    <Fragment>
      {header}
      {sortElement}
    </Fragment>
  );
}

export function renderColumn<R extends object = any, C= any, E= any> (column: ColumnDefinition, formatter: ColumnFormatter<R, E, C> | undefined = undefined, headerFormatter: HeaderFormatter<R> | undefined = translatedHeader): ColumnDescription {
  const columnProps: ColumnDescription<R> = {
    ...column,
    formatter: formatter,
    headerFormatter
  };
  return _.omitBy(columnProps, _.isNil) as ColumnDescription;
}

export function sortableColumn (dataField: string, text: string, sort: boolean = true): ColumnDefinition {
  return {
    sort,
    text,
    dataField
  };
}

export function checkedFormatter (cell: boolean) {
  return (
    <Fragment>
      {cell ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
    </Fragment>
  );
}

export function withStyles (column: ColumnDefinition, styles: {[key: string]: string}) {
  return {
    ...column,
    classes: () => styles[column.dataField],
    headerClasses: () => styles[column.dataField]
  };
}

export function selectableHeaderFormatter (column: ColumnDefinition, _2, { sortElement }) {
  const formatExtraData = column.formatExtraData;
  if (!formatExtraData) {
    return (
      <div>
        {column.text}
        {sortElement}
      </div>
    );
  }
  const selectedRows = _.defaultTo(formatExtraData.selectedRows, []);
  return (
    <SelectableHeader
      allSelected={formatExtraData.allSelected}
      selectedCount={selectedRows.length}
      onSelectPage={formatExtraData.onSelectPage}
    >
      {column.text}
      {sortElement}
    </SelectableHeader>
  );
}

export function headerWithIconFormatter (column: ColumnDefinition, _1, { sortElement }) {
  const dataField = column.dataField;
  const formatExtraData = column.formatExtraData;
  if (!formatExtraData) {
    return (
      <div>
        {column.text}
        {sortElement}
      </div>
    );
  }

  return (
    <div className={cx(styles.tableHeaderHint, formatExtraData.className)}>
      {column.text}
      <IconWithTooltip
        key={dataField}
        icon={formatExtraData.icon ? formatExtraData.icon : faQuestionCircle}
        tooltipProps={{
          id: dataField,
          tooltip: formatExtraData.hint
        }}
      />
      {sortElement}
    </div>
  );
}

export function selectableColumnFormatter<T extends { id: string | number}> (
  data: any,
  record: T,
  rowIndex: number,
  formatExtraData: {
    selectedRows: (string | number)[],
    onSelect: (id: string | number) => void,
    columnFormatter: (data: any, record: T, rowIndex: number, extraData: any) => ReactElement
  }
): ReactElement {
  return (
    <SelectableColumn
      id={record.id}
      isSelected={formatExtraData.selectedRows.includes(record.id)}
      onSelect={formatExtraData.onSelect}
    >
      {formatExtraData.columnFormatter(data, record, rowIndex, formatExtraData)}
    </SelectableColumn>
  );
}

export function getNameIdColumn<T extends { id: string | number, name: string}> (column: ColumnDefinition, getClickUrl?: (record: T) => string | undefined, clickable?: (record: T) => boolean) {
  return renderColumn({
    ...column,
    formatExtraData: { getClickUrl, clickable }
  }, function nameWithIdFormatter<T extends { id: string | number, name: string}> (
    _1,
    record: T,
    rowIndex: number,
    formatExtraData?: {
      getClickUrl?: (record: T) => string | undefined,
      clickable?: (record: T) => boolean
    }
  ): ReactElement {

    const clickUrl = formatExtraData!.getClickUrl ? formatExtraData!.getClickUrl(record) : undefined;
    const clickable = formatExtraData!.clickable ? formatExtraData!.clickable(record) : true;
    return (
      <NameWithIdColumn
        id={record.id}
        name={record.name}
        clickUrl={clickUrl}
        clickable={clickable}
      />
    );
  });
}

export function getStringArrayColumn (column: ColumnDefinition, valueDecorator?: (value: string | number) => string) {
  return renderColumn(column, (strings: string[]): ReactElement => {
    if (!strings) return <div/>;
    const renderStrings = () => (
      strings.map((str, index) => (
        <div key={index}>
          {valueDecorator ? valueDecorator(str) : str}
        </div>
      ))
    );
    return (
      <div>
        {renderStrings()}
      </div>
    );
  });
}

export function getCheckedColumn (column: ColumnDefinition) {
  return renderColumn(column, checkedFormatter);
}

export function getLabelValueColumn (column: ColumnDefinition, getOptions: (record: any) => SelectOptions[]) {
  return renderColumn({
    ...column,
    formatExtraData: { getOptions }
  }, (
    _1,
    record: any,
    _3,
    formatExtraData?: {
      getOptions: (record: any) => SelectOptions[]
    }
  ): ReactElement => {
    return (
      <LabelValueColumn options={formatExtraData!.getOptions(record)} />
    );
  });
}

export function getLinkColumn (column: ColumnDefinition, getClickUrl?: (record: any) => string | undefined) {
  return renderColumn({
    ...column,
    formatExtraData: { getClickUrl }
  }, function linkFormatter (
    field: string | number,
    record: any,
    _2,
    formatExtraData?: {
      getClickUrl: (record: any) => string | undefined
    }
  ): ReactElement {
    if (!formatExtraData) {
      return <>{field}</>;
    }

    const clickUrl = formatExtraData.getClickUrl(record);
    if (!clickUrl) {
      return <>{field}</>;
    }

    return (
      <Link to={clickUrl}>
        {field}
      </Link>
    );
  });
}

export function getDateRangeColumn (column: ColumnDefinition, getDateRange: (record: any) => { startDate: string, endDate: string }) {
  return renderColumn({
    ...column,
    formatExtraData: { getDateRange }
  }, (
    _1,
    record: any,
    _3,
    formatExtraData?: {
      getDateRange: (record: any) => { startDate: string, endDate: string }
    }
  ): ReactElement => {
    const { startDate, endDate } = formatExtraData!.getDateRange(record);
    return (
      <div style={{ width: 'max-content' }}>
        <Trans i18nKey='common.duration'>
          {{ start: startDate }}<br/>to<br/>{{ end: endDate }}
        </Trans>
      </div>
    );
  });
}

export function getStatusDesColumn (column: ColumnDefinition, getStatusDesData: (record: any) => { des: string, color: string }) {
  return renderColumn({
    ...column,
    formatExtraData: { getStatusDesData }
  }, (
    _1,
    record: any,
    _3,
    formatExtraData?: {
      getStatusDesData: (record: any) => { des: string, color: string }
    }
  ): ReactElement => {
    const { des, color } = formatExtraData!.getStatusDesData(record);
    return (
      <Status
        label={des}
        color={color}
      />
    );
  });
}

export function getContentWithEyeColumnn<T extends { id: string | number, name: string}> (
  column: ColumnDefinition,
  eyeDescription: string,
  onClickBtn: (record: T) => void,
  contentData?: {
    component: any;
    props: any;
  }
) {
  return renderColumn({
    ...column,
    formatExtraData: {
      eyeDescription,
      onClickBtn,
      contentData
    }
  }, (
    _1,
    record: T,
    _3,
    formatExtraData?: {
      eyeDescription: string,
      onClickBtn: (record: T) => void,
      contentData?: {
        component: any;
        props: any;
      }
    }
  ): ReactElement => {
    const tooltipProps = {
      id: `faEye-tooltip-${record.id}`,
      tooltip: formatExtraData!.eyeDescription
    };
    return (
      <ContentWithEyeColumn
        onClickBtn={_.partial(formatExtraData!.onClickBtn, record)}
        tooltipProps={tooltipProps}
        contentData={formatExtraData!.contentData}
      />
    );
  });
}

export function getComponentColumn (column: ColumnDefinition, component: React.ElementType, getProps: (record: any) => any) {
  return renderColumn({
    ...column,
    formatExtraData: {
      component,
      getProps
    }
  }, (
    _1,
    record,
    _3,
    formatExtraData?: {
      component: any;
      getProps: any;
    }
  ): ReactElement => {
    if (!formatExtraData) {
      return <div/>;
    }

    const Component = formatExtraData.component;
    return (
      <Component {...formatExtraData.getProps(record)} />
    );
  });
}
