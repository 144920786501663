import _ from 'lodash';
import { CreativeType } from 'core/creative/Creative';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { retailCreativeConfig } from 'core/creative/RetailCreativeConfig';
import i18n from 'i18n';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { CallToAction } from 'enum/CallToAction';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCallAPI } from 'hooks/useCallAPI';

const callToActionOptions: SelectOptions[] = createSelectOptionsFromEnum(CallToAction, 'callToAction.');
const defaultRdpManager = new DefaultRdpManager();

export const useRetailOptionsHook = (
  advertiserId: string | number,
  flowPageModel: CreativeSetupFlowPageModel,
  creativeType: CreativeType,
  rdpManager: RdpManager = defaultRdpManager
) => {

  const [retailerOptions, setRetailerOptions] = useState<SelectOptions[]>([]);
  const [productSetOptions, setProductSetOptions] = useState<SelectOptions[]>([]);
  const layoutOptions = useMemo(() => retailCreativeConfig[creativeType].map(layout => ({
    label: i18n.t(`adneon.layouts.${_.camelCase(layout)}`),
    value: layout
  })), [creativeType]);
  const { loading, callAPIs } = useCallAPI();

  const getDefaultValueOfOptions = (options) => {
    return options && options.length > 0 ? options[0].value : undefined;
  };

  const fetchProductSets = useCallback(async (retailer) => {
    const cacheKey = `${advertiserId}_${retailer}`;
    let productSetOptions = flowPageModel.getCache(cacheKey);
    if (productSetOptions) {
      setProductSetOptions(productSetOptions);
      return;
    }
    callAPIs(
      [rdpManager.getRetailProductSets.bind(rdpManager, retailer, advertiserId)],
      (productSets) => {
        productSetOptions = productSets.map(productSet => ({
          label: productSet.name,
          value: productSet.id
        }));
        flowPageModel.addCache(cacheKey, [...productSetOptions]);
        setProductSetOptions(productSetOptions);
      }
    );
  }, [flowPageModel, rdpManager, advertiserId, callAPIs]);

  const fetchAll = useCallback(async () => {
    let retailerOptions = flowPageModel.getCache('retailerOptions');
    if (retailerOptions) {
      setRetailerOptions(retailerOptions);
      const retailer = getDefaultValueOfOptions(retailerOptions);
      const cacheKey = `${advertiserId}_${retailer}`;
      let productSetOptions = flowPageModel.getCache(cacheKey);
      if (productSetOptions) {
        setProductSetOptions(productSetOptions);
        return;
      }
    }
    callAPIs(
      [rdpManager.getRetails.bind(rdpManager)],
      (retailers) => {
        retailerOptions = retailers.map(retailer => ({
          label: retailer.name,
          value: retailer.id
        }));
        flowPageModel.addCache('retailerOptions', [...retailerOptions]);
        setRetailerOptions(retailerOptions);
        const retailer = getDefaultValueOfOptions(retailerOptions);
        fetchProductSets(retailer);
      }
    );

  }, [flowPageModel, rdpManager, advertiserId, callAPIs, fetchProductSets]);

  const onRetailerChange = useCallback(async (retailer) => {
    fetchProductSets(retailer);
  }, [fetchProductSets]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  return {
    loading,
    defaultRetailer: getDefaultValueOfOptions(retailerOptions),
    retailerOptions,
    productSetOptions,
    layoutOptions,
    callToActionOptions,
    onRetailerChange
  };
};
