import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { CompanyRole, AdvertiserRole } from 'core/companyMember/CompanyRole';
import _ from 'lodash';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import * as SelectOptionsUtils from 'utils/SelectOptionsUtils';
import * as ValidateUtils from 'utils/ValidateUtils';
import { toast } from 'react-toastify';
import i18n from 'i18next';

export interface FormikSubmitModel {
  readonly isSubmit: 'FORMIK_SUBMIT' | 'PRE_SUBMIT' | 'POST_SUBMIT';
}

export type AdvertiserMemberFormState = {
  readonly loading: boolean;
  readonly error: Error | null;
  readonly message: string | null;
};
export interface AdvertiserMemberFormModel {
  readonly companyRole: CompanyRole;
  validate (values: CompanyRole): any;
  submit (values: CompanyRole): Promise<void>;
  submitAndValide (): void;
  init (): Promise<void>;
  event: UpdateEventListener<AdvertiserMemberFormModel>;
  formikEvent: UpdateEventListener<FormikSubmitModel>;
  readonly state: AdvertiserMemberFormState;
  readonly roleOptions: SelectOptions[];
  readonly emailEditable: boolean;
}

export type AdvertiserMemberFormProps = {
  model: AdvertiserMemberFormModel
};

const defaultValues: CompanyRole = {
  email: '',
  role: AdvertiserRole.ROLE_ADV_ADMIN
};

abstract class DefaultAdvertiserMemberFormModel implements AdvertiserMemberFormModel {
  manager: AdvertiserManager;
  accountId?: number;
  advertiserId: number;
  modelCompanyRole: CompanyRole;
  loading: boolean;
  modelEvent: FireableUpdateEventListener<AdvertiserMemberFormModel>;
  modelFormikEvent: FireableUpdateEventListener<FormikSubmitModel>;
  error: Error | null;
  message: string | null;
  submitted: boolean;

  constructor (advertiserId: number, accountId?: number, manager: AdvertiserManager = new DefaultAdvertiserManager()) {
    this.error = null;
    this.message = null;
    this.loading = false;
    this.manager = manager;
    this.accountId = accountId;
    this.advertiserId = advertiserId;
    this.loading = false;
    this.submitted = false;
    this.modelCompanyRole = defaultValues;
    this.modelEvent = new FireableUpdateEventListener<AdvertiserMemberFormModel>();
    this.modelFormikEvent = new FireableUpdateEventListener<FormikSubmitModel>();
  }

  abstract validate (values: CompanyRole);
  async submit (values: CompanyRole) {
    try {
      this.preSubmit();
      await this.submitting(values);
      this.postSubmit();
    } catch (e) {
      this.updateState(false);
      (e instanceof Error) && toast.error(e.message);
    }
  }

  submitAndValide () {
    this.modelFormikEvent.fireEvent({ isSubmit: 'FORMIK_SUBMIT' });
  }

  preSubmit () {
    this.modelFormikEvent.fireEvent({ isSubmit: 'PRE_SUBMIT' });
    this.updateState(true);
  }

  abstract submitting (values: CompanyRole);

  postSubmit () {
    this.updateState(false);
    this.modelFormikEvent.fireEvent({ isSubmit: 'POST_SUBMIT' });
  }
  abstract get companyRole ();
  abstract get emailEditable ();
  abstract init (): Promise<void>;
  get event (): UpdateEventListener<AdvertiserMemberFormModel> {
    return this.modelEvent;
  }

  get formikEvent (): UpdateEventListener<FormikSubmitModel> {
    return this.modelFormikEvent;
  }

  get state (): AdvertiserMemberFormState {
    return {
      error: this.error,
      message: this.message,
      loading: this.loading
    };
  }

  get roleOptions (): SelectOptions[] {
    return SelectOptionsUtils.createSelectOptionsFromEnum(
      AdvertiserRole, 'advertiserMemberForm.labels.'
    );
  }

  updateState (loading: boolean, error: Error | null = null) {
    this.loading = loading;
    this.modelEvent.fireEvent(this);
  }
}

export class CreateAdvertiserMemberFormModel extends DefaultAdvertiserMemberFormModel {
  constructor (advertiserId, manager: AdvertiserManager = new DefaultAdvertiserManager()) {
    super(advertiserId, undefined, manager);
  }

  get emailEditable (): boolean {
    return true;
  }

  validate (companyRole: CompanyRole) {
    const errors: any = {};
    errors.email = ValidateUtils.validateEmail(companyRole.email);
    errors.role = ValidateUtils.validateEmpty(companyRole.role);
    return _.pickBy(errors, _.identity);
  }
  async submitting (companyRole: CompanyRole) {
    try {
      await this.manager.createAdvertiserMember(this.advertiserId, companyRole);
      toast.success(i18n.t<string>('advertiserMemberList.labels.createAccountMemberSuccess'));
    } catch (e) {
      throw e;
    }
  }
  get companyRole (): CompanyRole {
    return defaultValues;
  }
  async init (): Promise<void> {
    // This is intentional
  }
}

export class EditAdvertiserMemberFormModel extends DefaultAdvertiserMemberFormModel {
  constructor (advertiserId, accountId: number, manager: AdvertiserManager = new DefaultAdvertiserManager()) {
    super(advertiserId, accountId, manager);
  }

  get emailEditable (): boolean {
    return false;
  }

  model (): AdvertiserMemberFormModel {
    return this;
  }

  validate (companyRole: CompanyRole): any {
    const errors: any = {};
    errors.email = ValidateUtils.validateEmail(companyRole.email);
    errors.role = ValidateUtils.validateEmpty(companyRole.role);
    return _.pickBy(errors, _.identity);
  }

  get companyRole (): CompanyRole {
    return this.modelCompanyRole;
  }

  async init () {
    this.updateState(true);
    try {
      const companyRole = await this.manager.getAdvertiserMember(this.advertiserId, this.accountId as number);
      this.modelCompanyRole = {
        email: companyRole.account.email,
        role: AdvertiserRole[companyRole.roleName]
      };
      this.updateState(false);
    } catch (error) {
      // use redirect to reload form
    }
  }

  async submitting (companyRole: CompanyRole) {
    try {
      await this.manager.updateAdvertiserMember(this.advertiserId, companyRole);
      toast.success(i18n.t<string>('advertiserMemberList.labels.editAccountMemberSuccess'));
    } catch (error) {
      throw error;
    }
  }
}
