import _ from 'lodash';
import client from './RestClient';
import { ProductGroupCreateDTO, ProductGroupListDTO, ProductGroupReadDTO, ProductGroupType, ProductGroupUpdateDTO } from 'core/limitation/ProductGroup';
import { AxiosInstance } from 'axios';

export interface ProductGroupWebService {
  createProductGroup (productGroup: ProductGroupCreateDTO): Promise<void>;
  updateProductGroup (productGroup: ProductGroupUpdateDTO): Promise<void>;
  getProductGroup (): Promise<ProductGroupListDTO[]>;
  getProductGroupById (groupId: number): Promise<ProductGroupReadDTO>;
  getCampaignByGroupId (groupId: number);
  deleteProductGroupById (groupId: number): Promise<void>;
}

const limitationGroupUrl = '/v2/limitation-groups';
export class RestfulProductGroupWebService implements ProductGroupWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async createProductGroup (productGroup: ProductGroupCreateDTO): Promise<void> {
    return client.post(limitationGroupUrl, {
      ...productGroup,
      state: 1
    });
  }
  async updateProductGroup (productGroup: ProductGroupUpdateDTO): Promise<void> {
    return client.put(`${limitationGroupUrl}/${productGroup.id}`, {
      ...productGroup,
      state: 1
    });
  }
  async getProductGroup (): Promise<ProductGroupListDTO[]> {
    const response = await client.get(`${limitationGroupUrl}`);
    return response.data.records.map(this.wrapProductGroupListDTO);
  }
  async getProductGroupById (groupId: number): Promise<ProductGroupReadDTO> {
    const response = await client.get(`${limitationGroupUrl}/${groupId}`);
    return this.wrapProductGroup(response.data);
  }
  async getCampaignByGroupId (groupId: number) {
    const response = await client.get(`${limitationGroupUrl}/${groupId}/manage`);
    return _.get(response, 'data.inUsedCampaign', []).map(data => ({ value: data.id, label: data.name }));
  }

  async deleteProductGroupById (groupId: number): Promise<void> {
    return client.delete(`${limitationGroupUrl}/${groupId}`);
  }

  wrapProductGroup (json: any): ProductGroupReadDTO {
    const groupType: ProductGroupType = _.get(json, 'groupType');
    const isSpace = groupType === ProductGroupType.ADSPACE;
    const parseGroupValues = (groupValues: { limitationName: string, limitationValue: string }[] | null, isCustom: boolean) => {
      if (!groupValues) {
        return [];
      }
      return _.uniqBy<SelectOptions>(groupValues.map(groupValue => {
        const value = groupValue.limitationValue.replace(/^\\/, '');
        return {
          label: isSpace ?
            `${value},${groupValue.limitationName}` :
            groupValue.limitationName,
          value,
          extra: isCustom ? 'isCustom' : undefined
        };
      }), 'value');
    };

    return {
      id: _.get(json, 'id'),
      groupName: _.get(json, 'groupName'),
      state: _.get(json, 'state'),
      groupType,
      description: _.get(json, 'description'),
      groupValues: parseGroupValues(_.get(json, 'groupValues', []), false),
      customGroupValues: parseGroupValues(_.get(json, 'customGroupValues', []), true),
      agencyIds: _.get(json, 'agencyIds'),
      adFormat: _.get(json, 'adFormat'),
      channel: _.get(json, 'channel'),
      attributes: _.get(json, 'attributes'),
      autoUpdate: _.get(json, 'autoUpdate')
    };
  }

  wrapProductGroupListDTO (json: any): ProductGroupListDTO {
    return {
      id: _.get(json, 'id'),
      groupName: _.get(json, 'groupName'),
      groupType: _.get(json, 'groupType'),
      description: _.get(json, 'description'),
      state: _.get(json, 'state'),
      agencys: _.get(json, 'agencys'),
      autoUpdate: _.get(json, 'autoUpdate')
    };
  }

}
