import React, { useCallback, useMemo, useState } from 'react';
import styles from './salesChannelReconciliationReport.module.scss';
import i18n from 'i18n';
import moment from 'moment';
import { DateRangePickerField } from 'components/common/form/field/DateRangePickerField';
import { CustomField } from 'components/common/form/field/CustomField';
import { Button } from 'react-bootstrap';
import { DefaultReportManager } from 'core/report/ReportManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Trans } from 'react-i18next';
import { MonthPicker } from 'components/common/MonthPicker/MonthPicker';
import { ReportGran } from 'core/report/ReportData';
import ToggleButtons from 'components/common/ToggleButtons/ToggleButtons';
import { Page } from 'components/Page/Page';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { SelectField } from 'components/common/form/field/SelectField';

const defaultReportManager = new DefaultReportManager();

export const SalesChannelReconciliationReport: React.FC = () => {

  const allowedSalesChannels = useMemo(() => [L1ObjectChannel.PIC], []);
  const [salesChannel, setSalesChannel] = useState<L1ObjectChannel>(allowedSalesChannels[0]);
  const [gran, setGran] = useState<string | number>(ReportGran.MONTH);
  const [from, setFrom] = useState<string>(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState<string>(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
  const { loading, callAPIs } = useCallAPI();

  const salesChannelOptions = useMemo(() => {
    return allowedSalesChannels.map(salesChannel => ({
      label: i18n.t<string>(`l1Object.labels.channel_${salesChannel.toLowerCase()}`),
      value: salesChannel
    }));
  }, [allowedSalesChannels]);

  const reportGranOptions = useMemo(() => {
    return Object.values(ReportGran)
      .filter(reportGran => reportGran !== ReportGran.HOUR)
      .map(reportGran => {
        return {
          label: i18n.t<string>(`report.labels.${reportGran}`),
          value: reportGran
        };
      });
  }, []);

  const renderMonthPicker = useCallback(() => {
    const handleMonthChange = (date: string) => {
      const from = moment(date).startOf('month').format('YYYY-MM-DD');
      const to = moment(date).endOf('month').format('YYYY-MM-DD');
      setFrom(from);
      setTo(to);
    };
    return (
      <MonthPicker
        date={from}
        onChange={handleMonthChange}
      />
    );
  }, [from]);

  const renderDateRangePicker = useCallback(() => {
    const renderScheduling = () => {
      const endDateMoment = moment(to);
      const startDateMoment = moment(from);
      const days = endDateMoment.diff(startDateMoment, 'days');
      if (days > 0) {
        return (
          <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
            Total <span className='text-dark'><>{{ days: days + 1 }} days</></span>
          </Trans>
        );
      }
    };

    const handleDateChange = (from: string | undefined, to: string | undefined) => {
      from && setFrom(moment(from).startOf('day').format('YYYY-MM-DD'));
      to && setTo(moment(to).endOf('day').format('YYYY-MM-DD'));
    };

    return (
      <DateRangePickerField
        startDate={from}
        endDate={to}
        maxDate={moment().endOf('day').subtract(1, 'day').format('YYYY-MM-DD')}
        format={'YYYY-MM-DD'}
        onChange={handleDateChange}
        showTimePicker={false}
        hint={renderScheduling()}
        fieldContentWidth={184}
      />
    );
  }, [from, to]);

  const reportGranDatePickerElementMap: { [key: string | number]: JSX.Element } = useMemo(() => {
    return {
      [ReportGran.MONTH]: renderMonthPicker(),
      [ReportGran.DAY]: renderDateRangePicker()
    };
  }, [renderMonthPicker, renderDateRangePicker]);

  const downloadReport = useCallback(() => {
    callAPIs([() => defaultReportManager.downloadSalesChannelReconciliationReport(salesChannel, from, to)]);
  }, [salesChannel, from, to, callAPIs]);

  return (
    <Page title={i18n.t<string>('report.titles.salesChannelReconciliation')}>
      {loading && <LoadingIndicator/>}
      <Page.Section>
        <CustomField label={i18n.t<string>('report.labels.reportGran')} name='reportGran'>
          <div className={styles.reportGran}>
            <ToggleButtons
              name='reportGran'
              value={gran}
              options={reportGranOptions}
              onChange={setGran}
            />
            {reportGranDatePickerElementMap[gran]}
          </div>
        </CustomField>
        <SelectField
          label={i18n.t<string>('report.labels.salesChannel')}
          name='salesChannel'
          options={salesChannelOptions}
          value={salesChannel}
          simpleValue
          onChange={setSalesChannel}
        />
        <CustomField formGroupClassName={styles.reportSearchBtnContainer} label='' name='reportDownloadButton'>
          <Button variant='primary' onClick={downloadReport} size='sm'>
            {i18n.t<string>('report.labels.download')}
          </Button>
        </CustomField>
      </Page.Section>
    </Page>
  );
};
