import display from 'assets/campaign/adType-display.png';
import display2x from 'assets/campaign/adType-display@2x.png';
import video from 'assets/campaign/adType-video.png';
import video2x from 'assets/campaign/adType-video@2x.png';
import outdoor from 'assets/campaign/adType-outdoor.png';
import outdoor2x from 'assets/campaign/adType-outdoor@2x.png';
import thirdParty from 'assets/campaign/adType-thirdParty.png';
import thirdParty2x from 'assets/campaign/adType-thirdParty@2x.png';
import { AdType, AD_TYPE_MAP_CREATIVE_TYPE } from 'core/rtbCampaign/RtbCampaign';
import { DefaultCampaignAdTypeButtonModel, CampaignAdTypeButtonModel } from 'components/CampaignAdTypeButton/CampaignAdTypeButtonModel';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { AddonFeatureType } from 'core/agency/AddonFeature';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import i18n from 'i18next';
import _ from 'lodash';
import { CreativeType } from 'core/creative/Creative';
import { GROUP_CHANNEL_CAMPAIGN_TYPE_MAP, L1ObjectObjective } from 'core/l1Object/L1Object';
import { OrderType } from 'core/order/Order';
import { PmpAdType } from 'core/pmp/Pmp';

export const ORDER_TYPE_MAP_CAMPAIGN_TYPE = {
  [OrderType.GOJEK]: {
    [AdType.ONE_FOR_ALL_DISPLAY]: [CreativeType.ONE_FOR_ALL_DISPLAY, CreativeType.IMAGE]
  },
  [OrderType.TENMAX]: AD_TYPE_MAP_CREATIVE_TYPE
};

export interface ChooseAdTypeStepModel {
  readonly event: UpdateEventListener<ChooseAdTypeStepModel>;
  readonly state: ChooseAdTypeStepState;
  readonly flowModel: RtbCampaignSetupFlowPageModel;
  readonly getAdTypeButtonModels: () => CampaignAdTypeButtonModel[];
  readonly showChangeAdTypeAlertModal: (confirmFuc) => void;
  onAdTypeChange: (adType: AdType) => void;
  backToL1ObjectDetail: () => void;
}

export type ChooseAdTypeStepProps = {
  readonly model: ChooseAdTypeStepModel;
};

export type ChooseAdTypeStepState = {
  readonly changeAdTypeAlertModal?: any;
};

export class DefaultChooseAdTypeStepModel implements ChooseAdTypeStepModel {
  event: FireableUpdateEventListener<ChooseAdTypeStepModel>;
  adType?: AdType;
  changeAdTypeAlertModal?: any;

  constructor (
    public flowModel: RtbCampaignSetupFlowPageModel,
    private goNext: () => void
  ) {
    this.adType = flowModel.state.campaign.basic.adType;
    this.event = new FireableUpdateEventListener<ChooseAdTypeStepModel>();
  }

  get state () {
    return {
      changeAdTypeAlertModal: this.changeAdTypeAlertModal
    };
  }

  get adTypeOptions () {
    const addonFeatureManager = this.flowModel.addonFeatureManager;
    const objective = _.get(this.flowModel.l1Object, 'objective');
    const channel = _.get(this.flowModel.l1Object, 'channel');
    const orderType = _.get(this.flowModel.order, 'orderType');
    const objectiveDependancy = {
      [AdType.PILOT_TV]: [L1ObjectObjective.AWARENESS],
      [AdType.CTV]: [L1ObjectObjective.AWARENESS]
    };
    const allowedAdType = Object.keys(AD_TYPE_MAP_CREATIVE_TYPE).reduce((allowAdType, adType) => {
      const allowCreativeTypes = AD_TYPE_MAP_CREATIVE_TYPE[adType];
      const allowOrderTypeAdTypes = ORDER_TYPE_MAP_CAMPAIGN_TYPE[orderType];
      const hasPermission = allowCreativeTypes.some((creativeType) => {
        if (creativeType === CreativeType.ONE_FOR_ALL) {
          return false;
        }
        if (!allowOrderTypeAdTypes[adType] || !allowOrderTypeAdTypes[adType].includes(creativeType)) {
          return false;
        }
        return addonFeatureManager.isFeatureEnable(`option_${creativeType}` as AddonFeatureType);
      });
      const objectiveCompatible = !objectiveDependancy[adType] || objectiveDependancy[adType].includes(objective);
      const channelCompatible = !(channel in GROUP_CHANNEL_CAMPAIGN_TYPE_MAP) || GROUP_CHANNEL_CAMPAIGN_TYPE_MAP[channel].includes(adType);
      if (hasPermission && objectiveCompatible && channelCompatible) {
        allowAdType.push(AdType[adType]);
      }
      return allowAdType;
    }, [] as Array<AdType>);

    if (
      allowedAdType.includes(AdType.PIC_SHORT) &&
      this.flowModel.pmpListOfOrder.some(
        pmp => pmp.adType === PmpAdType.PIC_SHORT
      )
    ) {
      allowedAdType.push(AdType.PMP_PIC_SHORT);
    }

    if (
      allowedAdType.includes(AdType.PIC_LONG) &&
      this.flowModel.pmpListOfOrder.some(
        pmp => pmp.adType === PmpAdType.PIC_LONG
      )
    ) {
      allowedAdType.push(AdType.PMP_PIC_LONG);
    }
    return allowedAdType;
  }

  getAdTypeButtonModels = () => {
    return this.adTypeOptions.map((adTypeOption) => {
      return new DefaultCampaignAdTypeButtonModel(
        this.getAdTypeButtonData(adTypeOption),
        adTypeOption === this.adType,
        _.partial(this.onAdTypeButtonClick, adTypeOption)
      );
    });
  }

  onAdTypeButtonClick = (adType: AdType) => {
    const campaign = this.flowModel.state.campaign;
    const currentAdType = _.get(campaign, 'basic.adType');
    if (currentAdType !== undefined && adType !== currentAdType) {
      this.showChangeAdTypeAlertModal(() => {
        this.onAdTypeChange(adType);
      });
    } else {
      this.onAdTypeChange(adType);
    }
  }

  onAdTypeChange (adType: AdType) {
    this.adType = adType;
    this.flowModel.onAdTypeChange(adType);
    this.goNext();
  }

  getAdTypeButtonData (adTypeOption: AdType) {
    switch (adTypeOption) {
      case AdType.DISPLAY:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeDisplayTitle',
          description: 'campaign.buttons.adTypeDisplayDesc'
        };
      case AdType.EDIMAX:
        return {
          icon: outdoor,
          icon2x: outdoor2x,
          title: 'campaign.buttons.adTypeEdimaxTitle',
          description: 'campaign.buttons.adTypeEdimaxDesc'
        };
      case AdType.PIC_SHORT:
        return {
          icon: outdoor,
          icon2x: outdoor2x,
          title: 'campaign.buttons.adTypePicShortTitle',
          description: 'campaign.buttons.adTypePicShortDesc'
        };
      case AdType.PIC_LONG:
        return {
          icon: outdoor,
          icon2x: outdoor2x,
          title: 'campaign.buttons.adTypePicLongTitle',
          description: 'campaign.buttons.adTypePicLongDesc'
        };
      case AdType.PMP_PIC_SHORT:
        return {
          icon: outdoor,
          icon2x: outdoor2x,
          title: 'campaign.buttons.adTypePmpPicShortTitle',
          description: 'campaign.buttons.adTypePmpPicShortDesc'
        };
      case AdType.PMP_PIC_LONG:
        return {
          icon: outdoor,
          icon2x: outdoor2x,
          title: 'campaign.buttons.adTypePmpPicLongTitle',
          description: 'campaign.buttons.adTypePmpPicLongDesc'
        };
      case AdType.THIRD_PARTY:
        return {
          icon: thirdParty,
          icon2x: thirdParty2x,
          title: 'campaign.buttons.adTypeThirdPartyTitle',
          description: 'campaign.buttons.adTypeThirdPartyDesc'
        };
      case AdType.THIRD_PARTY_BOTTOM:
        return {
          icon: thirdParty,
          icon2x: thirdParty2x,
          title: 'campaign.buttons.adTypeThirdPartyBottomTitle',
          description: 'campaign.buttons.adTypeThirdPartyBottomDesc'
        };
      case AdType.THIRD_PARTY_RECTANGLE:
        return {
          icon: thirdParty,
          icon2x: thirdParty2x,
          title: 'campaign.buttons.adTypeThirdPartyRectangleTitle',
          description: 'campaign.buttons.adTypeThirdPartyRectangleDesc'
        };
      case AdType.VIDEO:
        return {
          icon: video,
          icon2x: video2x,
          title: 'campaign.buttons.adTypeVideoTitle',
          description: 'campaign.buttons.adTypeVideoDesc'
        };
      case AdType.COMBO:
        return {
          icon: video,
          icon2x: video2x,
          title: 'campaign.buttons.adTypeComboTitle',
          description: 'campaign.buttons.adTypeComboDesc'
        };
      case AdType.ONE_FOR_ALL_DISPLAY:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeOneForAllDisplayTitle',
          description: 'One For All Display | RTB Image'
        };
      case AdType.PILOT_TV:
        return {
          icon: video,
          icon2x: video2x,
          title: 'campaign.buttons.adTypePilotTvTitle',
          description: 'campaign.buttons.adTypePilotTvDesc'
        };
      case AdType.RETAIL:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeRetailTitle',
          description: 'campaign.buttons.adTypeRetailDesc'
        };
      case AdType.CTV:
        return {
          icon: video,
          icon2x: video2x,
          title: 'campaign.buttons.adTypeCTVTitle',
          description: 'campaign.buttons.adTypeCTVDesc'
        };
      default:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeDisplayTitle',
          description: 'Image / html 5 / Google Native / Dynamic / Product'
        };
    }
  }

  showChangeAdTypeAlertModal = (confirmFuc) => {
    this.changeAdTypeAlertModal = {
      title: i18n.t<string>('common.warning'),
      message: i18n.t<string>('campaign.descriptions.adTypeChangeAlert'),
      primaryBtn: {
        title: i18n.t<string>('common.buttons.ok'),
        callback: () => {
          confirmFuc();
          this.hideModal();
        }
      },
      secondaryBtn: {
        title: i18n.t<string>('common.buttons.cancel'),
        callback: this.hideModal
      }
    };
    this.updateState();
  }

  hideModal = () => {
    this.changeAdTypeAlertModal = undefined;
    this.updateState();
  }

  backToL1ObjectDetail = () => {
    this.flowModel.setFinishedRedirectPath(`/orders/${this.flowModel.order.orderNumber}/campaign-groups/${this.flowModel.l1Object.l1ObjectId}`);
  }

  updateState () {
    this.event.fireEvent(this);
  }
}
