import React, { Fragment, useMemo, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import i18n from 'i18n';
import _ from 'lodash';
import styles from './productSegmentForm.module.scss';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { Formik, FormikProps, Form } from 'formik';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { ProductSegmentFormData, ProductSegmentFormModelData, AlertModalData } from './ProductSegmentFormModel';
import { FormikField } from 'components/common/form/field/FormikField';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { RouteComponentProps } from 'react-router';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'components/common/Modal/Modal';
import {
  EventRule,
  PRODUCT_SEGMENT_OPERATOR,
  EVENT_TYPES
} from 'core/product/ProductSegment';
import {
  CombinedEventTypes,
  DURATION_DAYS,
  MAX_EXCLUSION_RULES_LENGTH,
  MAX_INCLUSION_RULES_LENGTH
} from 'core/product/ProductSegmentConfig';
import { RuleForm } from './RuleForm';
import { BasicSettings } from './BasicSettings';
import { ErrorHandler } from 'components/common/form/FormErrorHandler/FormErrorHandler';
import { CustomField } from 'components/common/form/field/CustomField';
import { LabelField } from 'components/common/form/field/LabelField';

export type ProductSegmentFormProps = {
  advertisers: SelectOptions[];
  retailers: SelectOptions[];
  useModel: () => ProductSegmentFormModelData
};

export const ProductSegmentForm: React.FC<RouteComponentProps<any> & ProductSegmentFormProps> = ({
  history,
  advertisers,
  retailers,
  useModel
}) => {

  const {
    title,
    loading,
    formType,
    breadcrumbs,
    redirectPath,
    productSegment,
    productSetOptions,
    alertModalData,
    updateAlertModalData,
    globalEventRuleId,
    submit,
    validate,
    addEventRule,
    onRetailIdChange,
    onAdvertiserChange,
    initSubConditions
  } = useModel();

  const mainDomRef = useRef(null);

  const inclusionOptions: SelectOptions[] = useMemo(
    () => createSelectOptions([ PRODUCT_SEGMENT_OPERATOR.OR, PRODUCT_SEGMENT_OPERATOR.AND], 'productSegmentForm.labels.inclusion.'),
    []
  );

  const cancelSubmit = () => history.goBack();

  const renderAlertModalData = (alertModalData: AlertModalData) => {
    const { title, message, dismiss, onConfirm, onCancel } = alertModalData;
    return (
      <Modal
        title={title}
        dismiss={dismiss}
        primaryButton={{
          title: i18n.t<string>('common.buttons.confirm'),
          callback: onConfirm
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: onCancel
        }}
      >
        {i18n.t<string>(message)}
      </Modal>
    );
  };

  const renderInclusionRules = (formProps: FormikProps<ProductSegmentFormData>) => {
    const inclusionRules: EventRule[] = _.get(formProps, 'values.inclusion.rules', []);
    const operator: string = _.get(formProps, 'values.operator');
    const handleCloseRule = (ruleId: number) => {
      const newEventRules: {[rules: string]: EventRule[]} = {
        rules: inclusionRules.filter(eventRule => eventRule.ruleId !== ruleId)
      };
      formProps.setFieldValue('inclusion', newEventRules);
    };
    return (
      <Fragment>
        {_.map(inclusionRules, (inclusionRule, ruleIndex) => {
          const ruleId = _.get(inclusionRule, 'ruleId');
          return (
            <Fragment key={`inclusionRule-${ruleId}`}>
              {ruleId !== _.get(inclusionRules, '[0].ruleId') &&
                <CustomField name='combinationText' label=''>
                  <div className={styles.combinationText}>
                    {i18n.t<string>(`productSegmentForm.labels.combinationText.${operator}`)}
                  </div>
                </CustomField>
              }
              <CustomField name='inclusionRuleBlock' label=''>
                <div className={styles.eventRuleContainer}>
                  <div className={styles.ruleCloseBtn}>
                    {inclusionRules.length > 1 &&
                      <button
                        type='button'
                        className='btn'
                        onClick={_.partial(handleCloseRule, ruleId)}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          size='lg'
                        />
                      </button>
                    }
                  </div>
                  <div className={styles.eventRule}>
                    <RuleForm
                      ruleIndex={ruleIndex}
                      combination='inclusion'
                      eventRule={inclusionRule}
                      eventRules={inclusionRules}
                      productSetOptions={productSetOptions}
                      initSubConditions={initSubConditions}
                      setValues={formProps.setValues}
                      setFieldValue={formProps.setFieldValue}
                    />
                  </div>
                </div>
              </CustomField>
            </Fragment>
          );
        })}
      </Fragment>
    );
  };

  const renderExclusionRules = (formProps: FormikProps<ProductSegmentFormData>) => {
    const exclusionRules: EventRule[] = _.get(formProps, 'values.exclusion.rules', []);

    const handleCloseRule = () => {
      formProps.setFieldValue('exclusion', undefined);
    };

    return (
      <Fragment>
        {_.map(exclusionRules, (exclusionRule, ruleIndex) => {
          const ruleId = _.get(exclusionRule, 'ruleId');
          return (
            <Fragment key={`exclusionRule-${ruleId}`} >
              <CustomField name='exclusionRuleBlock' label=''>
                <div className={styles.eventRuleContainer}>
                  <div className={styles.ruleCloseBtn}>
                      <button
                        type='button'
                        className='btn'
                        onClick={_.partial(handleCloseRule)}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          size='lg'
                        />
                      </button>
                  </div>
                  <div className={styles.eventRule}>
                    <RuleForm
                      ruleIndex={ruleIndex}
                      combination='exclusion'
                      eventRule={exclusionRule}
                      eventRules={exclusionRules}
                      productSetOptions={productSetOptions}
                      initSubConditions={initSubConditions}
                      setValues={formProps.setValues}
                      setFieldValue={formProps.setFieldValue}
                    />
                  </div>
                </div>
              </CustomField>
            </Fragment>
          );
        })}
      </Fragment>
    );
  };

  const renderForm = (formProps: FormikProps<ProductSegmentFormData>) => {
    // console.log('[ProductSegmentForm]: formProps.values', formProps.values);
    const productSegmentFormData: ProductSegmentFormData = formProps.values;
    const advertiser: number | string | undefined = _.get(productSegmentFormData, 'advertiser');
    const retailId: string = _.get(productSegmentFormData, 'retailId', '');
    const inclusionRules: EventRule[] = _.get(productSegmentFormData, 'inclusion.rules', []);
    const exclusionRules: EventRule[] = _.get(productSegmentFormData, 'exclusion.rules', []);
    const operator: string = _.get(productSegmentFormData, 'operator', '');

    const handleEventRuleButton = (combination: 'inclusion' | 'exclusion') => {
      const eventRules: EventRule[] = _.get(formProps, `values.${combination}.rules`, []);
      const eventType: EVENT_TYPES = combination === 'inclusion'
       ? Object.values(EVENT_TYPES).filter(eventType => !_.includes(CombinedEventTypes, eventType))[0]
       : EVENT_TYPES.ALL_PRODUCT_EVENTS;
      const newEventRules: {[rules: string]: EventRule[]} = {
        rules: [
          ...eventRules,
          {
            ruleId: globalEventRuleId + 1,
            durationDays: DURATION_DAYS[0],
            eventType,
            filterSet: initSubConditions(eventType)
          }
        ]
      };
      formProps.setFieldValue(combination, newEventRules);
      addEventRule();
    };

    return (
      <>
        <ErrorHandler
          parentRef={mainDomRef}
          isSubmitting={formProps.isSubmitting}
          submitCount={formProps.submitCount}
        />
        <Form>
          <fieldset>
            <legend>
              <span>{i18n.t<string>('productSegmentForm.labels.basic')}</span>
            </legend>
            <div className={styles.fieldContent}>
              <BasicSettings
                retailId={retailId}
                advertiser={advertiser}
                formType={formType}
                retailers={retailers}
                advertisers={advertisers}
                setFieldValue={formProps.setFieldValue}
                onRetailIdChange={onRetailIdChange}
                onAdvertiserChange={onAdvertiserChange}
                updateAlertModalData={updateAlertModalData}
              />
              <FormikField.Select
                label={i18n.t<string>('productSegmentForm.labels.combination.include')}
                componentWidthFitParent={true}
                preText={i18n.t<string>('productSegmentForm.labels.combination.preText')}
                postText={i18n.t<string>('productSegmentForm.labels.combination.postText')}
                fieldContentWidth={150}
                name='operator'
                simpleValue
                options={inclusionOptions}
              />
              {operator && renderInclusionRules(formProps)}
              {operator && inclusionRules.length < MAX_INCLUSION_RULES_LENGTH && !inclusionRules.some(eventRule => _.includes(CombinedEventTypes, eventRule.eventType)) &&
                <CustomField name='expandButtonText' label=''>
                  <button
                    type='button'
                    className='btn btn-secondary btn-sm'
                    onClick={_.partial(handleEventRuleButton, 'inclusion')}
                  >
                    {i18n.t<string>(`productSegmentForm.buttons.expand.${operator}`)}
                  </button>
                </CustomField>
              }
              <CustomField name='exclusion' label={i18n.t<string>('productSegmentForm.labels.combination.exclude')}>
                {exclusionRules.length < MAX_EXCLUSION_RULES_LENGTH ?
                  <button
                    type='button'
                    className='btn btn-secondary btn-sm'
                    onClick={_.partial(handleEventRuleButton, 'exclusion')}
                  >
                    {i18n.t<string>('productSegmentForm.buttons.combination.exclude')}
                  </button> :
                  <LabelField value={i18n.t<string>('productSegmentForm.labels.combination.excludeText')}/>
                }
              </CustomField>
              {renderExclusionRules(formProps)}
            </div>
            <div className={styles.buttonArea}>
              <button
                type='submit'
                className='btn btn-primary btn-sm'
              >
                {i18n.t<string>('common.buttons.submit')}
              </button>
              <button
                type='button'
                className='btn btn-secondary btn-sm'
                onClick={cancelSubmit}
              >
                {i18n.t<string>('common.buttons.cancel')}
              </button>
            </div>
          </fieldset>
        </Form>
      </>
    );
  };

  return (
    <div className={styles.productSegmentFormContainer} ref={mainDomRef}>
      {loading && <LoadingIndicator />}
      <NavigationPrompt when={!redirectPath}/>
      {redirectPath && <Redirect to={redirectPath} />}
      {alertModalData && renderAlertModalData(alertModalData)}
      <div className={styles.titleArea}>
        <TitleWithBreadcrumbs
          title={title}
          routes={breadcrumbs}
        />
      <div className={styles.titleBottomLine} />
      </div>
      <Formik
        initialValues={productSegment}
        enableReinitialize={true}
        onSubmit={submit}
        validate={validate}
        validateOnBlur={false}
      >
        {renderForm}
      </Formik>
    </div>
  );
};
