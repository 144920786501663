import { AdLogo } from 'core/adLogo/AdLogo';
import { Currency } from 'core/agency/Agency';

export type Order = {
  id: number;
  projectName: string;
  orderNumber: string;
  agencyId: number;
  advertiserId: number;
  orderType: OrderType;
  budget: number;
  originBudget?: number;
  changeBudget?: number;
  orderMargin: number;
  sysMargin: number;
  changeOrderMargin?: number;
  externalId?: string;
  externalType?: string;
  state: State;
  spent: number;
  creator?: string;
  creatorEmail?: string;
  expectedSpent: number,
  actualSpent: number,
  comments?: string,
  startDate: string;
  endDate: string;
  createDate: string;
  createDateTime?: string;
  campaignConstraint: CampaignConstraint,
  budgetBalance: number;
  currency: Currency;
  timezone: string;
  impres: number;
  viewable: number;
  clicks: number;
  deepWarning?: boolean;
  warning?: boolean;
  danger?: boolean;
  modifyReason?: string;
  rtbCampaignRunningStatus?: any;
  needAlert?: boolean;
  adLogo: AdLogo;
  campaignBidPrice: {
    type: string,
    autoBidCap: BidPriceFloor,
    bidFloor: BidPriceFloor
  }[],
  campaignMinStartDate?: string;
  campaignMaxEndDate?: string;
  monitor?: boolean,
  orderPrice: number,
  creativeDuration: number,
  dayPart: any,
  finalReportProjectName?: string,
  finalReportAdvertiserName?: string,
  finalReportProjectType?: GojekFinalReportProjectType,
  finalReportReceivers?: string[],
  finalReportSendOutDate?: string,
  finalReportTargetType?: FinalReportTargetType,
  finalReportTargetValue?: string | number,
  ageMin?: string | number,
  ageMax?: string | number,
  gender?: FinalReportGender,
  adView: number,
  picMargin?: number
};

export enum FinalReportTargetType {
  IMPRESSION = 'impression',
  CLICK = 'click',
  CONVERSION = 'conversion',
  VIEW = 'view'
}

export enum FinalReportGender {
  ALL = -1,
  MALE = 1,
  FEMALE
}

export enum GojekFinalReportProjectType {
  GOFOOD = 'GoFood',
  GOMART = 'GoMart',
  GOMED = 'GoMed',
  GOSURE = 'GoSure',
  GOINVESTASI = 'GoInvestasi',
  OTHERS = 'Others'
}

export enum State {
  NOT_APPROVE = 1,
  REJECT = 2,
  APPROVE_NOT_START = 3,
  APPROVE_START = 4,
  APPROVE = 5,
  STOP = 6,
  DELETE = 7,
  INVALID = 8,
  CHANGE_PENDING = 9,
  SETTLE = 10,
  SETTLED = 11
}

export type CampaignConstraint = {
  bidPriceCPCMin: number | null,
  bidPriceCPVMin: number | null,
  bidPriceCPMMin: number | null,
  bidPricevCPMMin: number | null,
  budgetMinimum: number,
  campaignBudgetMinimum: number
};

export type BidPriceFloor = {
  cpc?: number,
  cpv?: number,
  cpm?: number,
  vcpm?: number,
  dcpm?: number
};

export enum OrderDelivery {
  NOTSTART = 'notStart',
  PROGRESS = 'progress',
  FINISHED = 'finished'
}

export enum OrderCreationInfo {
  CREATOR = 'creator',
  CREATEDATETIME = 'createDateTime',
  CREATOREMAIL = 'creatorEmail'
}

export enum OrderType {
  TENMAX = 'TENMAX',
  GOJEK = 'GOJEK'
}
