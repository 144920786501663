import { DEFAULT_INVENTORY, LimitationInventorySettings } from './limitationSettingsType';
import { defaultItemSetting, ItemType } from 'components/CampaignLimitation/SelectItemComponent';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import i18n from 'i18n';

export const getMessageLimitationInventorySettings = (
  segmentsOptions: SelectOptions[]
): LimitationInventorySettings[] => {

  const settings: LimitationInventorySettings[] = segmentsOptions.map(segment => ({
    name: segment.label,
    title: `limitation.labels.${segment.label}`,
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.autoByGroupSetting,
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchGojekSegment',
    cb: () => {
      return segment.options?.map(option => {
        if (segment.label === 'custom_segment') {
          return option;
        }
        return {
          ...option,
          label: i18n.t<string>(`limitation.labels.${option.label}`)
        };
      });
    },
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred']
  }));

  return [
    {
      name: DEFAULT_INVENTORY
    },
    ...settings
  ];
};
