import {
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { AbstractPmpFormModel } from './PmpFormModel';
import { Pmp, PmpAdType } from 'core/pmp/Pmp';

abstract class AbstractPmpPICShortFormModel extends AbstractPmpFormModel {

  constructor (
    mode: 'create' | 'edit',
    pmpListOfOrder: Pmp[],
    ...args: RtbCampaignBasicFormModelConstructorParams
  ) {
    super(mode, ...args);
    this.pmpListOfOrder = pmpListOfOrder.filter(pmp => pmp.adType === PmpAdType.PIC_SHORT);
  }

  override get campaignAdType (): AdType {
    return AdType.PMP_PIC_SHORT;
  }

  override get isOutdoorType (): boolean {
    return true;
  }
}

export class EditPmpPICShortFormModel extends AbstractPmpPICShortFormModel {

  constructor (pmpListOfOrder: Pmp[], ...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', pmpListOfOrder, ...args);
  }

  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreatePmpPICShortFormModel extends AbstractPmpPICShortFormModel {

  constructor (pmpListOfOrder: Pmp[], ...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', pmpListOfOrder, ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
