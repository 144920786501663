import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import { RtbCampaignBasicFormModel } from 'containers/RtbCampaigns/RtbCampaignSetupFlow/RtbCampaignForm/RtbCampaignBasicFormModel';
import { EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import _ from 'lodash';

export interface RtbCampaignSetupStepModel {
  readonly flowModel: RtbCampaignSetupFlowPageModel;
  readonly activeTab: number;
  validateCampaignBasic (campaign): any;
  setFormikProps (formikProps): void;
  getRtbCampaignBasicFormModel (): RtbCampaignBasicFormModel | undefined;
  getLimitationModel (): EditLimitationModel;
  onUnmount (eventHandler?: number): void;
  goNext: () => void;
  goLast: () => void;
  goSubStep: (subStepIndex: number) => void;
}

export type RtbCampaignSetupStepProps = {
  readonly model: RtbCampaignSetupStepModel;
};

export enum CampaignSetupTab {
  BASIC,
  LIMITATION,
  TRACKING
}

export class DefaultRtbCampaignSetupStepModel implements RtbCampaignSetupStepModel {

  flowModel: RtbCampaignSetupFlowPageModel;
  formikProps: any;
  goNext: () => void;

  constructor (
    flowModel: RtbCampaignSetupFlowPageModel,
    public activeTab: number,
    public goSubStep: (subStepIndex: number) => void,
    goNext: () => void,
    public goLast: () => void,
    registerValidateMethod: (validateMethod) => void
  ) {
    this.flowModel = flowModel;
    this.goNext = () => {
      const limitationModel = this.flowModel.getLimitationModel();
      limitationModel.validateRequireIncludeLimitation(true);
      if (limitationModel.state.showRequireIncludeLimitationInfo) {
        goSubStep(CampaignSetupTab.LIMITATION);
        return;
      }
      goNext();
    };
    registerValidateMethod(this.validate);
  }

  getRtbCampaignBasicFormModel (): RtbCampaignBasicFormModel | undefined {
    return this.flowModel.getRtbCampaignBasicFormModel();
  }

  getLimitationModel (): EditLimitationModel {
    return this.flowModel.getLimitationModel();
  }

  setFormikProps (formikProps) {
    this.formikProps = formikProps;
  }

  validate = async () => {
    if (!this.formikProps) {
      return [];
    }
    const campaignBasic = this.formikProps.values;
    const basicErrors = this.validateCampaignBasic(campaignBasic);
    this.setErrorsToFormik(basicErrors);
    const basicErrorNames = Object.keys(basicErrors);
    const limitationErrors = await this.flowModel.getLimitationModel().validate();
    const limitationErrorNames = Object.keys(limitationErrors);
    this.goErrorSubStep(basicErrorNames, limitationErrorNames);
    return _.concat(basicErrorNames, limitationErrorNames);
  }

  setErrorsToFormik (errors) {
    this.formikProps && this.formikProps.setErrors(errors);
    let touched = {};
    this.generateFormikTouchedObj(errors, touched);
    this.formikProps && this.formikProps.setTouched(touched);
  }

  validateCampaignBasic (campaignBasic) {
    const basicFormModel = this.flowModel.getRtbCampaignBasicFormModel();
    return basicFormModel ? basicFormModel.validate(campaignBasic, this.flowModel.order, this.flowModel.localeMeta) : {};
  }

  goErrorSubStep (basicErrorNames, limitationErrorNames) {
    const errors = [...basicErrorNames];
    const basicPageErrors = _.remove(errors, (errorName) => {
      return errorName !== 'videoProgressTrackingCode' && errorName !== 'conversionTracking';
    });
    const trackingPageErrors = errors;
    const jumpToTrackingCode =
      basicPageErrors.length === 0 &&
      limitationErrorNames.length === 0 &&
      trackingPageErrors.length > 0;
    if (jumpToTrackingCode) {
      this.goSubStep(CampaignSetupTab.TRACKING);
    } else if (basicPageErrors.length > 0) {
      this.goSubStep(CampaignSetupTab.BASIC);
    } else if (limitationErrorNames.length > 0) {
      this.goSubStep(CampaignSetupTab.LIMITATION);
    }
  }

  generateFormikTouchedObj (errors, touched) {
    if (!errors) {
      return;
    }
    let keys = Object.keys(errors);
    keys.forEach(key => {
      let value = errors[key];
      if (typeof value === 'object') {
        touched[key] = {};
        this.generateFormikTouchedObj(errors[key], touched[key]);
      } else {
        touched[key] = true;
      }
    });
  }

  onUnmount () {
    if (!this.formikProps || !this.flowModel.state.campaign) {
      return;
    }
    const limitationModel = this.flowModel.getLimitationModel();
    this.flowModel.setCampaign({
      basic: { ...this.formikProps.values },
      limitations: limitationModel.limitationValue
    }, true);
  }
}
