import { useCallback, useEffect, useMemo, useState } from 'react';
import { Pmp, PmpAdminStatus, PmpStatus, PmpsWithPagination } from 'core/pmp/Pmp';
import _ from 'lodash';
import { Pageable } from 'ws/Pageable';
import { useCoreContext } from 'contexts/coreContext';
import { useCallAPI } from 'hooks/useCallAPI';
import { DefaultPmpManager } from 'core/pmp/PmpManager';
import { PmpDurationError, usePmpDurationValidator } from './PmpDurationValidator';
import i18n from 'i18n';
import { getDateRangeColumn, getLabelValueColumn, getNameIdColumn, getStatusDesColumn, getStringArrayColumn, headerWithIconFormatter, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

export enum PmpListColumns {
  NAME = 'name',
  STATUS = 'adminStatus',
  AGENCYID = 'adAgencyIds',
  SCHEDULE = 'startTime',
  BUDGET = 'budget',
  AD_TYPE = 'adType',
  SPACES = 'spaces'
}

const pmpManager = new DefaultPmpManager();

export const usePmpListModel = (
  spaceOptions: SelectOptions[],
  agencyOptions: SelectOptions[],
  singleSelect?: boolean,
  defaultSelectedPmps: number[] = [],
  pmpList?: Pmp[],
  editable: boolean = true,
  showStatus: boolean = true,
  showAgency: boolean = true
) => {

  const fetchPmpsFromServer = !pmpList;
  const core = useCoreContext();
  const [pmps, setPmps] = useState<Pmp[]>(_.defaultTo(pmpList, []));
  const [searchString, setSearchString] = useState<string>('');
  const [selectedPmps, setSelectedPmps] = useState<number[]>(defaultSelectedPmps);
  const [pageable, setPageable] = useState<Pageable & { totalCount: number}>({
    page: 1,
    sizePerPage: 10,
    sort: 'id',
    direction: 'desc',
    totalCount: pmpList ? pmpList.length : 0
  });
  const [pmpDurationError, setPmpDurationError] = useState<PmpDurationError & Pmp | undefined>();
  const currency = _.get(core, 'accountManager.localeMeta.currency', 'NTD');
  const {
    loading,
    callAPIs
  } = useCallAPI();
  const {
    loading: loadingDurationValidator,
    fetchPmpWithinDuration,
    // validatePmpDuration,
    clearCacheWithin
  } = usePmpDurationValidator();

  const fetchInvalidPmpWithinDuration = useCallback((pmps: Pmp[]) => {
    const inactivePmps: Pmp[] = pmps.filter(pmp => pmp.status === PmpStatus.INACTIVE);
    inactivePmps.forEach(pmp => fetchPmpWithinDuration(pmp.startTime, pmp.endTime));
  }, [fetchPmpWithinDuration]);

  const fetchPmps = useCallback((page: number = 1) => {
    callAPIs([() => pmpManager.getPmpList({ page, sort: pageable.sort, direction: pageable.direction, sizePerPage: pageable.sizePerPage }, searchString)], (pmpsWithPagination: PmpsWithPagination) => {
      setPmps(pmpsWithPagination.pmps);
      setPageable(prev => ({
        ...prev,
        page: pmpsWithPagination.pagination.page,
        sizePerPage: pmpsWithPagination.pagination.size,
        totalCount: pmpsWithPagination.pagination.totalCount
      }));
      fetchInvalidPmpWithinDuration(pmpsWithPagination.pmps);
    });
  }, [pageable.sort, pageable.direction, pageable.sizePerPage, searchString, fetchInvalidPmpWithinDuration, callAPIs]);

  const getPmpStatusFilterString = useCallback((status: PmpAdminStatus) => {
    return i18n.t<string>(`pmp.adminStatus.${_.camelCase(PmpAdminStatus[status])}`);
  }, []);

  const getPmpStatusColor = useCallback((status: PmpAdminStatus) => {
    switch (status) {
      case PmpAdminStatus.BINDING:
        return 'primary';
      case PmpAdminStatus.STOP:
        return 'black';
      case PmpAdminStatus.EXPIRED:
        return 'danger';
      case PmpAdminStatus.FINISH:
      case PmpAdminStatus.NOT_BINDING:
        return 'light';
      default:
        return '';
    }
  }, []);

  const getPmpStatusDesData = useCallback((status: PmpAdminStatus) => {
    return {
      des: getPmpStatusFilterString(status),
      color: getPmpStatusColor(status)
    };
  }, [getPmpStatusFilterString, getPmpStatusColor]);

  const handleTableChange = useCallback((type, props) => {
    if (type === 'sort') {
      (props.sortField !== pageable.sort || props.sortOrder !== pageable.direction) &&
        setPageable(prev => ({
          ...prev,
          page: 1,
          sort: props.sortField,
          direction: props.sortOrder
        }));
      return;
    }
    fetchPmps(props.page);
  }, [pageable.sort, pageable.direction, fetchPmps]);

  const onHandleSelect = useCallback((id: string | number, select: boolean) => {
    if (singleSelect) {
      setSelectedPmps(select ? [+id] : []);
      return;
    }
    setSelectedPmps(prev => select ? _.uniq([...prev, +id]) : prev.filter(p => p.toString() !== id.toString()));
  }, [singleSelect]);

  const onHandleSelectPage = useCallback((select: boolean) => {
    const allId = pmps.map(pmp => pmp.id);
    const offset = (pageable.page - 1) * pageable.sizePerPage;
    const end = offset + pageable.sizePerPage;
    const targetId = allId.slice(offset, end);
    setSelectedPmps(prev => select ?
      _.uniq([...prev, ...targetId]) :
      prev.filter(p => !targetId.includes(p))
    );
  }, [pmps, pageable.page, pageable.sizePerPage]);

  const onHandleSearch = useCallback((searchString: string) => {
    setSearchString(searchString);
  }, []);

  // const activatePmp = useCallback(() => {
  //   const pmp = pmps.find(pmp => selectedPmps.includes(pmp.id));
  //   if (!pmp) {
  //     return;
  //   }
  //   const pmpDurationError = validatePmpDuration(pmp);
  //   if (pmpDurationError) {
  //     setPmpDurationError({
  //       ...pmpDurationError,
  //       ...pmp
  //     });
  //   } else {
  //     callAPIs([() => pmpManager.updatePmpState(selectedPmps, 'activate')], () => {
  //       const pmp = pmps.find(pmp => selectedPmps.includes(pmp.id));
  //       // clear duration cache if pmp status is changed
  //       pmp && clearCacheWithin(pmp.startTime, pmp.endTime);
  //       fetchPmps();
  //       setSelectedPmps([]);
  //     });
  //   }
  // }, [pmps, selectedPmps, clearCacheWithin, validatePmpDuration, callAPIs, fetchPmps]);

  const deactivePmp = useCallback(() => {
    callAPIs([() => pmpManager.updatePmpState(selectedPmps, 'deactivate')], () => {
      const pmp = pmps.find(pmp => selectedPmps.includes(pmp.id));
      toast.success(i18n.t<string>('pmpList.labels.deactivateSuccess'));
      // clear duration cache if pmp status is changed
      pmp && clearCacheWithin(pmp.startTime, pmp.endTime);
      fetchPmps();
      setSelectedPmps([]);
    });
  }, [pmps, selectedPmps, clearCacheWithin, callAPIs, fetchPmps]);

  const cancelActivate = useCallback(() => {
    setPmpDurationError(undefined);
  }, []);

  const statusColumn = useMemo(() => getStatusDesColumn(
    sortableColumn(PmpListColumns.STATUS, i18n.t<string>('pmpList.headers.status'), true),
    (pmp: Pmp) => getPmpStatusDesData(pmp.adminStatus)
  ), [getPmpStatusDesData]);

  const columns = useMemo(() => (_.compact([
    getNameIdColumn(
      sortableColumn(PmpListColumns.NAME, i18n.t<string>('pmpList.headers.name'), true),
      (pmp: Pmp) => `/pmp/${pmp.id}/edit`,
      (pmp: Pmp) => editable && !pmp.campaignId && pmp.status !== PmpStatus.EXPIRED
    ),
    showStatus ? {
      ...statusColumn,
      formatExtraData: {
        ...statusColumn.formatExtraData,
        hint: i18n.t<string>('pmpList.hints.pmpStatus'),
        icon: faInfoCircle
      },
      headerFormatter: headerWithIconFormatter
    } : undefined,
    getDateRangeColumn(sortableColumn(PmpListColumns.SCHEDULE, i18n.t<string>('pmpList.headers.schedule'), true), (pmp: Pmp) => ({ startDate: pmp.startTime, endDate: pmp.endTime })),
    getLabelValueColumn(
      sortableColumn(PmpListColumns.BUDGET, i18n.t<string>('pmpList.headers.budget'), true),
      (pmp: Pmp) => (_.compact([
        { label: i18n.t<string>('pmp.labels.pmpType'), value: i18n.t<string>(`pmp.pmpTypes.${_.camelCase(pmp.pmpType)}`) },
        { label: i18n.t<string>('pmp.labels.budget'), value: `${currency} ${pmp.budget}` },
        undefined
      ]))
    ),
    renderColumn(sortableColumn(PmpListColumns.AD_TYPE, i18n.t<string>('pmpList.headers.adType'), true), (value: string) => i18n.t<string>(`pmp.adType.${_.camelCase(value)}`)),
    getStringArrayColumn(sortableColumn(PmpListColumns.SPACES, i18n.t<string>('pmpList.headers.space'), true), (value: string | number) => spaceOptions.find(option => option.value.toString() === value.toString())?.label || value.toString()),
    showAgency ? getStringArrayColumn(sortableColumn(PmpListColumns.AGENCYID, i18n.t<string>('pmpList.headers.adAgencyId'), true), (value: string | number) => agencyOptions.find(option => option.value.toString() === value.toString())?.label || value.toString()) : undefined
  ])), [editable, showStatus, showAgency, currency, spaceOptions, statusColumn, agencyOptions]);

  const filterdPmps = useMemo(() => {
    return pmps.filter(pmp => {
      const nameMatch = pmp.name.includes(searchString);
      return (nameMatch);
    });
  }, [pmps, searchString]);

  const batchOperations = useMemo(() => ([{
    operation: {
      text: i18n.t<string>('pmpList.buttons.deactivate'),
      enable: selectedPmps.length > 0,
      hint: '',
      onClick: deactivePmp
    }
  }]), [deactivePmp, selectedPmps]);

  useEffect(() => {
    fetchPmpsFromServer && fetchPmps();
  }, [fetchPmpsFromServer, fetchPmps]);

  return {
    loading: loading || loadingDurationValidator,
    columns,
    pageable,
    filterdPmps,
    selectedPmps,
    searchString,
    batchOperations,
    pmpDurationError,
    fetchPmps,
    cancelActivate,
    onHandleSearch,
    onHandleSelect,
    onHandleSelectPage,
    handleTableChange
  };
};
