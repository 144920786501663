import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useCoreContext } from 'contexts/coreContext';
import { DefaultAgencyManager } from 'core';
import { DefaultProductGroupManager } from 'core/limitation/ProductGroupManager';
import { isSystemAdmin } from 'helper/ActorHelper';
import { useCallAPI } from 'hooks/useCallAPI';
import { compact } from 'lodash';
import { useEffect, useRef } from 'react';

const agencyManager = new DefaultAgencyManager();
const productGroupManager = new DefaultProductGroupManager();

export const FetchAgency: React.FC<{
  adFormat?: string,
  channel?: string,
  onAgencyOptionsFetched: (options: SelectOptions[]) => void
}> = ({
  adFormat,
  channel,
  onAgencyOptionsFetched
}) => {

  const cacheOptions = useRef<{[key: string]: SelectOptions[]}>({});

  const core = useCoreContext();
  const isSysAdmin = core ? isSystemAdmin(core.authenticationManager.actor) : false;

  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    const adFormatChannel = `${adFormat}-${channel}`;
    if (adFormatChannel in cacheOptions.current) {
      onAgencyOptionsFetched(cacheOptions.current[adFormatChannel]);
      return;
    }

    if (!isSysAdmin) {
      return;
    }

    if (adFormat && channel) {
      let channelAddonfeatures: string[] = [];
      let creativeAddonfeatures: string[] = [];
      let channelAddon = productGroupManager.getRequiredGroupChannelAddonFeaturesOfAdFormatAndChannel(adFormat, channel);
      if (channelAddon) {
        channelAddonfeatures = [`channel.${channelAddon}`];
      }
      let creativeAddons = productGroupManager.getRequiredCreativesAddonFeaturesOfAdFormatAndChannel(adFormat, channel);
      if (creativeAddons) {
        creativeAddonfeatures = [...creativeAddons.map(addon => `creatives.${addon}`)];
      }

      callAPIs(
        compact([
          channelAddonfeatures.length > 0
            ? () => agencyManager.getAgenciesOptions(channelAddonfeatures.join(','))
            : () => agencyManager.getAgenciesOptions()
          ,
          creativeAddonfeatures.length > 0
            ? () => agencyManager.getAgenciesOptions(creativeAddonfeatures.join(','), undefined, false)
            : () => agencyManager.getAgenciesOptions()
        ]),
        (
          channelEnabledAgencies: SelectOptions[],
          creativeTypeEnabledAgencies: SelectOptions[]
        ) => {
          const agencyOptions = channelEnabledAgencies.filter(
            agency =>
              !!creativeTypeEnabledAgencies.find(
                creativeTypeEnabledAgency =>
                  creativeTypeEnabledAgency.value === agency.value
              )
          );
          cacheOptions.current[adFormatChannel] = agencyOptions;
          onAgencyOptionsFetched(agencyOptions);
        }
      );

      return;
    }

    callAPIs([
      () => agencyManager.getAgenciesOptions()
    ], (agencyOptions: SelectOptions[]) => {
      cacheOptions.current[adFormatChannel] = agencyOptions;
      onAgencyOptionsFetched(agencyOptions);
    });
  }, [adFormat, channel, isSysAdmin, callAPIs, onAgencyOptionsFetched]);

  return loading ? <LoadingIndicator /> : <div />;
};
