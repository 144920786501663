import client from './RestClient';
import { FbAdSetFormData } from 'containers/FbAdSets/FbAdSetSetupFlowPage/FbAdSetSetupFlowPageModel';
import { ADSET_DEFAULT_AGE_MAX, ADSET_DEFAULT_AGE_MIN, FbAdSet, FbOptimizationGoal, FbPacingType } from 'core/fbAdSet/FbAdSet';
import { L1Object } from 'core/l1Object/L1Object';
import _, { defaultTo } from 'lodash';
import { getInitDaypart } from 'components/Dayparts/Dayparts';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import moment from 'moment';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { AxiosInstance } from 'axios';

export interface FbAdSetWebService {
  getAdSet (adSetId: number | string): Promise<FbAdSet>;
  getAdSets (l1ObjectId: number | string): Promise<FbAdSet[]>;
  createAdSet (payload: any): Promise<void>;
  editAdSet (orderId: string | number, l1Object: L1Object, fbAdSetId: string | number, fbAdSetFormData: FbAdSetFormData): Promise<void>;
  deleteAdSets (adSetIds: (number | string)[]): Promise<void>;
  updateAdSetState (adSetsData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  getMinBudget (adSetId: number | string): Promise<number>;
  getAdSetOptions (from?: string, to?: string): Promise<SelectOptions[]>;
}

const optimizationGoalMap = {
  [FbOptimizationGoal.REACH]: L2ObjectOptimizationGoal.REACH,
  [FbOptimizationGoal.THRUPLAY]: L2ObjectOptimizationGoal.VIDEO_VIEWS,
  [FbOptimizationGoal.IMPRESSIONS]: L2ObjectOptimizationGoal.IMPRESSIONS,
  [FbOptimizationGoal.LINK_CLICKS]: L2ObjectOptimizationGoal.CLICKS,
  [FbOptimizationGoal.LANDING_PAGE_VIEWS]: L2ObjectOptimizationGoal.LANDING_PAGE_VIEWS,
  [FbOptimizationGoal.OFFSITE_CONVERSIONS]: L2ObjectOptimizationGoal.SALES
};

const wrapAdSetSchedule = (adsetSchedules: any) => {
  const adsetSchedule = !Array.isArray(adsetSchedules) ? JSON.parse(adsetSchedules) : adsetSchedules;
  const durationKeys = _.uniqWith(_.map(adsetSchedule, schedule => _.omit(schedule, ['days', 'timezone_type'])), _.isEqual);
  if (adsetSchedule.length !== durationKeys.length) {
    return getInitDaypart();
  }
  let dayPart = {};
  _.forEach(adsetSchedule, (schedule) => {
    const { start_minute, end_minute, days } = schedule;
    const hours = Array.from({ length: (end_minute - start_minute) / 60 }, (_, index) => index + start_minute / 60);
    _.forEach(days, (day) => {
      const dayKey = day === 0 ? (day + 7).toString() : (day).toString();
      dayPart[dayKey] = dayPart[dayKey] ? [...dayPart[dayKey], ...hours] : hours;
    });
  });
  return {
    ...dayPart,
    enabled: true
  };
};

export const wrapAdSet = (json) => {
  const {
    draft,
    adset_schedule,
    ...parsedJSON
  } = json;
  const hasAdSetScheduling = (!_.isEmpty(adset_schedule) && adset_schedule !== '[]');
  const removeTimezone = (time) => (
    moment(time.replace('T', ' ').split('+')[0]).format('YYYY-MM-DD HH:mm:ss')
  );

  const pmax3OptimizationGoal = optimizationGoalMap[json.optimization_goal];

  return {
    ...parsedJSON,
    optimization_goal: pmax3OptimizationGoal ? pmax3OptimizationGoal : json.optimization_goal,
    targeting: wrapTargetingFromServer(json.targeting),
    lifetime_min_spend_target: json.l2ObjectLifetimeMinSpendTarget,
    lifetime_spend_cap: json.l2ObjectSpendCap,
    lifetime_budget: json.l2ObjectLifetimeBudget,
    daily_budget: json.l2ObjectDailyBudget,
    bid_amount: json.l2ObjectBidAmount,
    start_time: json.start_time ?
      removeTimezone(json.start_time) :
      json.start_time,
    end_time: json.end_time ?
      removeTimezone(json.end_time) :
      json.end_time,
    frequency_control_specs: json.frequency_control_specs ?
      json.frequency_control_specs[0] : undefined,
    destination_type: json.destination_type,
    dayPart: hasAdSetScheduling ? wrapAdSetSchedule(adset_schedule) : undefined,
    isDraft: draft,
    pacing_type: hasAdSetScheduling ? [FbPacingType.STANDARD] : json.pacing_type
  };
};

export const wrapFbDayparting = (fbAdSetFormData): string => {
  const dayPart: { [day: string]: number[] } | undefined = _.omit(fbAdSetFormData.dayPart, ['enabled']);
  let mapping = {};
  _.forEach(Object.keys(dayPart), (dayKey) => {
    // Partition into minimal blocks of consecutive hours, and store as mapping key
    const originDays = defaultTo(dayPart[dayKey], []);
    let day: number[] = originDays.sort((hour1, hour2) => hour1 > hour2 ? 1 : -1);
    let blockStart = defaultTo(day[0], 0);
    let blockEnd = blockStart;
    _.forEach(day, (hour: number, index: number) => {
      if (!_.isNil(day[index + 1]) && day[index + 1] === hour + 1) {
        blockEnd = day[index + 1];
      } else {
        let durationKey = JSON.stringify({
          start_minute: blockStart * 60,
          end_minute: (blockEnd + 1) * 60
        });
        let mappingValue = defaultTo(mapping[durationKey], []);
        mappingValue = [...mappingValue, parseFloat(dayKey) % 7];
        mapping[durationKey] = mappingValue;
        blockStart = day[index + 1];
        blockEnd = day[index + 1];
      }
    });
  });
  // Restruct mapping into list of valid mapping structures
  let adsetSchedules = _.map(Object.keys(mapping), (durationKey) => ({
    ...JSON.parse(durationKey),
    days: mapping[durationKey],
    timezone_type: 'USER'
  }));
  if (_.get(fbAdSetFormData.dayPart, 'enabled') !== '1' && adsetSchedules.length === 1) {
    const schedule = _.omit(adsetSchedules[0], ['days']);
    adsetSchedules = [
      { ...schedule, days: [0,6] },
      { ...schedule, days: Array.from({ length: 5 }, (_, index) => index + 1) }
    ];
  }
  return JSON.stringify(adsetSchedules);
};

const wrapTargetingFromServer = (json: any) => {
  const { geo_locations, excluded_geo_locations, publisher_platforms, custom_audiences, excluded_custom_audiences, user_device, genders, age_min, age_max, ...others } = json;
  const fbDeviceTAMap = {
    iPhone: 'Smartphone',
    iPad: 'Tablet',
    Android_Smartphone: 'Smartphone',
    Android_Tablet: 'Tablet'
  };
  const deviceTypes = user_device ? _.uniq(user_device.map(device => fbDeviceTAMap[device])) : [];
  const getGeoLocationValue = (locationTA) => {
    const countries = _.get(locationTA, 'countries', []);
    const cities = _.get(locationTA, 'cities', []);
    const regions = _.get(locationTA, 'regions', []);
    return _.concat(
      countries.map(country => ({ label: country, value: country, extra: 'country' })),
      cities.map(city => ({ label: city.name, value: city.key, extra: 'city' })),
      regions.map(region => ({ label: region.name, value: region.key, extra: 'region' }))
    );
  };

  const geoLocationValue = getGeoLocationValue(geo_locations);
  const include = _.compact([
    publisher_platforms ? {
      type: 'publisher_platforms',
      value: createSelectOptions(
        publisher_platforms,
        'targeting.fb.publisher_platforms.',
        value => value.toLowerCase()
      )
    } : undefined,
    geoLocationValue.length > 0 ? {
      type: 'geo_locations',
      value: geoLocationValue
    } : undefined,
    custom_audiences && custom_audiences.length > 0 ? {
      type: 'segment',
      value: createSelectOptions(custom_audiences.map(audience => audience.id))
    } : undefined,
    deviceTypes.length > 0 ? {
      type: 'user_device',
      value: deviceTypes.map(deviceType => ({
        label: deviceType,
        value: deviceType
      }))
    } : undefined,
    genders && genders.length > 0 ? {
      type: 'genders',
      value: genders[0]
    } : undefined,
    {
      type: 'age_min',
      value: age_min ? age_min : ADSET_DEFAULT_AGE_MIN
    },
    {
      type: 'age_max',
      value: age_max ? age_max : ADSET_DEFAULT_AGE_MAX
    },
    ...Object.keys(others).map(key => ({
      type: key,
      value: Array.isArray(json[key]) ? json[key].map(value => ({ label: value, value })) : json[key]
    }))
  ]);

  const excludedGeoLocationValue = getGeoLocationValue(excluded_geo_locations);
  const exclude = _.compact([
    excludedGeoLocationValue.length > 0 ? {
      type: 'geo_locations',
      value: excludedGeoLocationValue
    } : undefined,
    excluded_custom_audiences && excluded_custom_audiences.length > 0 ? {
      type: 'segment',
      value: createSelectOptions(excluded_custom_audiences.map(audience => audience.id))
    } : undefined
  ]);

  return {
    include,
    exclude
  };
};

export class RestfulFbAdSetWebService implements FbAdSetWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getAdSet (adSetId: number | string): Promise<FbAdSet> {
    const response = await this.restClient.get(`/v2/fb/campaigns/${adSetId}`);
    return wrapAdSet(response.data);
  }

  async getAdSets (l1ObjectId: number | string): Promise<FbAdSet[]> {
    const response = await this.restClient.get(`/v2/fb/campaigns/l1Object/${l1ObjectId}`);
    return _.get(response, 'data', []).map(data => wrapAdSet(data));
  }

  async createAdSet (payload: any): Promise<void> {
    return this.restClient.post('/v2/fb/campaigns', payload);
  }

  async editAdSet (orderId: string | number, l1Object: L1Object, adSetId: number | string, fbAdSetFormData: FbAdSetFormData): Promise<void> {
    return this.restClient.put('/v2/fb/campaigns', {
      ..._.omit(fbAdSetFormData, ['lifetime_budget', 'bid_amount', 'hasSpendLimits', 'lifetime_min_spend_target', 'lifetime_spend_cap']),
      id: adSetId,
      orderId,
      l1ObjectId: l1Object.l1ObjectId,
      campaign_id: _.get(l1Object, 'fb.id'),
      l2ObjectLifetimeBudget: fbAdSetFormData.lifetime_budget,
      l2ObjectBidAmount: fbAdSetFormData.bid_amount,
      bid_strategy: l1Object.autoOptimise ? null : fbAdSetFormData.bid_strategy,
      l2ObjectLifetimeMinSpendTarget: fbAdSetFormData.lifetime_min_spend_target === '' ? 0 : fbAdSetFormData.lifetime_min_spend_target,
      l2ObjectSpendCap: fbAdSetFormData.lifetime_spend_cap === '' ? 0 : fbAdSetFormData.lifetime_spend_cap,
      destination_type: fbAdSetFormData.destination_type ? fbAdSetFormData.destination_type : '',
      adset_schedule: fbAdSetFormData.dayPart ? wrapFbDayparting(fbAdSetFormData) : '[]',
      pacing_type: l1Object.autoOptimise
       ? undefined
       : (fbAdSetFormData.dayPart
         ? [FbPacingType.DAY_PARTING]
         : fbAdSetFormData.pacing_type ? [fbAdSetFormData.pacing_type] : [])
    });
  }

  async deleteAdSets (adSetIds: (number | string)[]): Promise<void> {
    return this.restClient.delete(`/v2/fb/campaigns?adSetIds=${adSetIds.join(',')}`);
  }

  async updateAdSetState (adSetsData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.restClient.put(`/v2/fb/campaigns/${state}`, adSetsData);
  }

  async getMinBudget (adSetId: number | string): Promise<number> {
    const response = await this.restClient.get(`/v2/fb/campaigns/${adSetId}/min-budget`);
    return response.data;
  }

  async getAdSetOptions (from?: string, to?: string): Promise<SelectOptions[]> {
    const path = '/v2/fb/campaigns/options';
    const params = { from, to };
    const paramsString = Object.keys(params)
      .filter(key => params[key] !== undefined)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    const response = await this.restClient.get(`${path}?${paramsString}`);
    return response.data.records;
  }
}
