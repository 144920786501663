import { AxiosInstance } from 'axios';
import { MessageSegmentFormData, MessageSegmentListRecord } from 'core/messageSegment/MessageSegment';
import { defaultTo, get, omit } from 'lodash';
import client from './RestClient';

export type AudienceCountPayload = {
  op: 'inc',
  limits: SelectOptions[],
  type: string
}[];

export type MessageSegmentCount = {
  num_emails: number,
  num_gids: number,
  num_phones: number
};

export interface MessageSegmentWebService {
  getMessageSegments (agencyId?: number | string): Promise<MessageSegmentListRecord[]>;
  createMessageSegment (segment: MessageSegmentFormData): Promise<void>;
  getPICCoreSegments (): Promise<any[]>;
  getMessageSegment (segmentId: number | string): Promise<MessageSegmentFormData>;
  updateMessageSegment (segmentId: number | string, segment: MessageSegmentFormData): Promise<void>;
  getSegmentCount (payload: AudienceCountPayload): Promise<MessageSegmentCount>;
  getSegmentCountBySQL (brief: string): Promise<MessageSegmentCount>;
}

export class RestfulMessageSegmentWebService implements MessageSegmentWebService {

  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getMessageSegments (agencyId?: number | string): Promise<MessageSegmentListRecord[]> {
    const path = agencyId ? `/v2/go-segments?agencyId=${agencyId}` : '/v2/go-segments';
    const response = await this.restClient.get(path);
    return get(response, 'data.records', []).map(wrapMessageSegmentListRecord);
  }

  async createMessageSegment (segment: MessageSegmentFormData): Promise<void> {
    return this.restClient.post('/v2/go-segments', {
      advertiserId: segment.advertiserId,
      name: segment.name,
      description: segment.description,
      type: 'MANUAL',
      audienceBrief: segment.audienceBrief
    });
  }

  async getPICCoreSegments (): Promise<SelectOptions[]> {
    const response = await this.restClient.get('/v2/go-segments/pic-core-segments');
    return wrapCoreSegments(response.data);
  }

  async getMessageSegment (segmentId: string | number): Promise<MessageSegmentFormData> {
    const response = await this.restClient.get(`/v2/go-segments/${segmentId}`);
    return wrapMessageSegment(response.data);
  }

  async updateMessageSegment (segmentId: string | number, segment: MessageSegmentFormData): Promise<void> {
    return this.restClient.patch(`/v2/go-segments/${segmentId}`, {
      name: segment.name,
      description: segment.description
    });
  }

  async getSegmentCount (payload: AudienceCountPayload): Promise<MessageSegmentCount> {
    const response = await this.restClient.post('/v2/go-segments/audience-summary', {
      limitation: payload
    });
    return response.data;
  }

  async getSegmentCountBySQL (brief: string): Promise<MessageSegmentCount> {
    const response = await this.restClient.get(`/v2/go-segments/audience-info-by-brief?brief=${brief}`);
    return response.data;
  }
}

function wrapMessageSegment (json): MessageSegmentFormData {
  return {
    ...omit(json, ['segmentId', 'dmpSegmentId', 'type']),
    id: get(json, 'segmentId'),
    name: get(json, 'name'),
    description: get(json, 'description'),
    advertiserId: get(json, 'advertiserId')
  };
}

function wrapMessageSegmentListRecord (json: any): MessageSegmentListRecord {
  return {
    id: get(json, 'segment_id'),
    name: get(json, 'name'),
    description: get(json, 'description'),
    advertiserId: get(json, 'advertiserId'),
    createdAt: get(json, 'created_at')
  };
}

function wrapCoreSegments (json: any): SelectOptions[] {
  const resultsMap = {};
  json.forEach((segment: {
    segment_id: string,
    name: string,
    product_focus: string
  }) => {
    const focus = defaultTo(segment.product_focus, 'unknown');
    const segmentsOfFocus = defaultTo(resultsMap[focus], []);
    segmentsOfFocus.push({
      label: segment.name,
      value: segment.segment_id
    });
    resultsMap[focus] = segmentsOfFocus;
  });

  return Object.keys(resultsMap).map(focus => ({
    label: focus,
    value: focus,
    options: resultsMap[focus].sort((a, b) => a.label.localeCompare(b.label))
  })).sort((a, b) => a.label.localeCompare(b.label));
}
