import React, { ReactElement } from 'react';
import { FormikField } from 'components/common/form/field/FormikField';
import i18n from 'i18n';
import styles from '../l1ObjectForm.module.scss';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { RtbCampaignListBasic } from 'core/rtbCampaign/RtbCampaign';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { CustomField } from 'components/common/form/field/CustomField';

export const MessageCampaginGroupForm: React.FC<{
  formType: string,
  currency: string,
  campaigns: RtbCampaignListBasic[],
  campaignBudgets: any[],
  objectiveOptions: SelectOptions[]
  onEditCampaignBudgetsBtn: () => void,
  getBudgetTips: () => ReactElement | string | undefined
}> = ({
  formType,
  currency,
  campaigns,
  campaignBudgets,
  objectiveOptions,
  onEditCampaignBudgetsBtn,
  getBudgetTips
}) => {
  const objectiveFormatter = value => value ? i18n.t<string>(`l1Object.labels.objective.${value.toLowerCase()}`) : '';
  const renderCampaignBudget = () => {
    return campaigns.map(campaign => {
      const campaignBudget = campaignBudgets.find(campaignBudget => campaign.id && campaignBudget.id.toString() === campaign.id.toString());
      return (
        <div key={campaign.id}>
          {`${campaign.name}: ${formatPriceWithCurrency(currency, campaignBudget ? +campaignBudget.budget : 0)}`}
        </div>
      );
    });
  };
  return (
    <>
      <FormikField.InputGroup
        label={i18n.t<string>('l1Object.labels.budget')}
        name='budget'
        type='number'
        prefix={currency}
        hint={getBudgetTips()}
      />
      {
        campaignBudgets &&
          <CustomField name='campaignBudgets' label=''>
            <div className={styles.adSetBudgetContainer}>
              <div className={styles.adSetBudget}>
                {renderCampaignBudget()}
              </div>
              <IconWithTooltip
                icon={faPencilAlt}
                onClick={onEditCampaignBudgetsBtn}
                tooltipProps={{
                  id: `adsetBudgetsEditTip`,
                  tooltip: i18n.t<string>('l1Object.hints.editRtbCampaignBudgets')
                }}
              />
            </div>
          </CustomField>
      }
      {
        formType === 'create' ?
          <FormikField.Select
            label={i18n.t<string>('l1Object.labels.objectiveLabel')}
            name='objective'
            simpleValue
            options={objectiveOptions}
            className={styles.selectField}
          /> :
          <FormikField.Label
            label={i18n.t<string>('l1Object.labels.objectiveLabel')}
            name='objective'
            formatter={objectiveFormatter}
          />
      }
    </>
  );
};
