import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import styles from './doctorTable.module.scss';
import _ from 'lodash';
import i18n from 'i18n';
import { renderColumn } from 'components/TableColumn/TableColumn';

enum TABLE_COLUMN {
  DOMAIN = 'domain',
  SPACE_COUNT = 'spaceCount'
}

export enum DOCTER_TABLE_TAB {
  FILTERED = 'filtered',
  REMAIN = 'remain'
}

export interface DoctorTableModel {
  readonly doctorTableData: any;
  readonly state: DoctorTableState;
  readonly event: UpdateEventListener<DoctorTableModel>;
  setTab (tabName: string | null): void;
  getColumns (): any[];
  handleOnSearch (searchString: string): void;
  updateDoctorTableData (doctorTableData: any[]): void;
  getFilters (): {[key: string]: {
    options: SelectOptions[],
    value?: string | number,
    onSelect: (target: string) => void;
  }};
}

export type DoctorTableProps = {
  readonly model: DoctorTableModel;
};

export type DoctorTableState = {
  readonly loading: boolean;
  readonly selectedTab: string;
  readonly tableData: any;
  readonly searchString: string;
  readonly selectedFilterType: string;
};

export class DefaultDoctorTableModel implements DoctorTableModel {
  event: FireableUpdateEventListener<DoctorTableModel> = new FireableUpdateEventListener<DoctorTableModel>();
  loading: boolean = false;
  filteredDoctorTableData?: any[];
  selectedTab: string = 'filtered';
  selectedFilterType: string = 'all';
  doctorTableData: any[];
  searchString: string = '';

  constructor (
    doctorTableData: any[]
  ) {
    this.doctorTableData = [];
    this.updateDoctorTableData(doctorTableData);
  }

  updateDoctorTableData (doctorTableData: any[]) {
    this.doctorTableData = doctorTableData.map(data => ({
      remainSpaceChannels: this.collectDomain(data.remainSpaceChannels, 'remain'),
      filteredReasons: this.collectDomain(data.filteredReasons, 'filtered'),
      type: data.type
    }));
    this.updateFilteredDoctorTableData(false);
  }

  collectDomain (array, tabName) {
    const domainMap: {[key: string]: any} = {};
    array.forEach(data => {
      const domain = data.domain;
      if (domain in domainMap) {
        domainMap[domain].spaces.push(data);
      } else {
        domainMap[domain] = {
          key: `${tabName}-${domain}`,
          domain,
          spaces: [data]
        };
      }
    });
    return Object.values(domainMap)
      .map(domainData => ({
        ...domainData,
        spaceCount: domainData.spaces.length
      }));
  }

  get state (): DoctorTableState {
    return {
      loading: this.loading,
      selectedTab: this.selectedTab,
      tableData: this.filteredDoctorTableData,
      searchString: this.searchString,
      selectedFilterType: this.selectedFilterType
    };
  }

  getColumns () {
    return Object.values(TABLE_COLUMN).map(column => renderColumn(this.getDoctorTableColumnDefinition(column)));
  }

  getFilterTypes (): SelectOptions[] {
    const typeOptions = this.doctorTableData.map(data => ({
      label: i18n.t<string>(`doctorTable.labels.${_.camelCase(data.type)}`),
      value: data.type
    }));
    return typeOptions;
  }

  getDoctorTableDomains (): SelectOptions[] {
    return [];
  }

  getDoctorTableSpaces (): SelectOptions[] {
    return [];
  }

  getFilters () {
    return {
      filterCondition: {
        options: [{ label: i18n.t<string>('common.placeholder.all'), value: 'all' }, ...this.getFilterTypes()],
        value: this.selectedFilterType,
        onSelect: this.selectFilterType
      }
    };
  }

  getDoctorTableColumnDefinition (columnName) {
    const columnClassGetter = () => {
      return styles[columnName];
    };

    return {
      sort: true,
      text: `doctorTable.headers.${columnName}`,
      dataField: columnName,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }

  handleOnSearch = (searchString: string): void => {
    this.searchString = searchString;
    this.updateFilteredDoctorTableData();
  }

  setTab = (tabName: string | null): void => {
    if (tabName === null) {
      return;
    }
    this.selectedTab = tabName;
    this.updateFilteredDoctorTableData();
  }

  selectFilterType = (type) => {
    this.selectedFilterType = type;
    this.updateFilteredDoctorTableData();
  }

  updateFilteredDoctorTableData (updateState: boolean | undefined = true) {
    const typeData = this.doctorTableData ?
      this.doctorTableData.filter(data => data.type === this.selectedFilterType || this.selectedFilterType === 'all') :
      [];

    if (this.selectedTab === DOCTER_TABLE_TAB.FILTERED) {
      this.filteredDoctorTableData = _.flatten(typeData.map(data => data.filteredReasons));
    } else {
      this.filteredDoctorTableData = typeData[typeData.length - 1] ? typeData[typeData.length - 1].remainSpaceChannels : [];
    }

    if (!_.isEmpty(this.searchString) && this.filteredDoctorTableData) {
      this.filteredDoctorTableData =
        this.filteredDoctorTableData.filter(data =>
          (data.domain && data.domain.includes(this.searchString)) ||
          data.spaces.find(space => space.spaceName && space.spaceName.includes(this.searchString))
        );
    }

    if (updateState) {
      this.updateState(false);
    }
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
