
export type ProductGroupCreateDTO = {
  groupName: string;
  groupType: ProductGroupType;
  description: string;
  adFormat?: string;
  channel?: string;
  groupValues: {
    limitationName: string,
    limitationValue: string
  }[];
  customGroupValues: {
    limitationName: string,
    limitationValue: string
  }[];
  agencyIds: number[];
  attributes?: {[key: string]: string[]};
  autoUpdate: boolean;
};

export type ProductGroupUpdateDTO = {
  id: number
} & ProductGroupCreateDTO;

export type ProductGroupReadDTO = Omit<ProductGroupUpdateDTO, 'groupValues' | 'customGroupValues'> & {
  state: number;
  groupValues: SelectOptions[],
  customGroupValues: SelectOptions[]
};

export type ProductGroupListDTO = Pick<ProductGroupReadDTO, 'id' | 'groupName' | 'groupType' | 'description' | 'state' | 'autoUpdate'> & {
  agencys: string[]
};

export const GeneralChannel = {
  OpenRTB: 'OpenRTB'
} as const;

export const OutdoorChannel = {
  PIC: 'PIC',
  EdiMax: 'EdiMax'
} as const;

export type OpenRtbSpaceChannel = typeof GeneralChannel;
export type OutdoorSpaceChannel = typeof OutdoorChannel;
export type SpaceChannel = keyof(OutdoorSpaceChannel & OpenRtbSpaceChannel);

type BaseProductGroupFormValue = {
  groupName: string;
  description: string;
  groupValues: SelectOptions[];
  customGroupValues: SelectOptions[];
  agencyIds: number[];
};
export type DomainProductGroupFormValue = BaseProductGroupFormValue & {
  groupType: ProductGroupType.DOMAIN;
  attributes?: never
};
export type SpaceProductGroupFormValue = BaseProductGroupFormValue & {
  groupType: ProductGroupType.ADSPACE;
  channel: SpaceChannel | '';
  adFormat: string;
  attributes: {[key: string]: string[]}
};
export type ProductGroupFormValue = DomainProductGroupFormValue | SpaceProductGroupFormValue;

export enum ProductGroupType {
  DOMAIN = 'DOMAIN',
  ADSPACE = 'ADSPACE'
}

export const AUTO_UPDATE_VALUE = 'autoUpdate';
export const ALL_SELECT_VALUE = 'all';
