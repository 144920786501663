import React, { useRef } from 'react';
import { Modal } from 'components/common/Modal/Modal';
import i18n from 'i18n';
import { PRODUCT_SET_CREATE_TYPE, useProductSetCreateModalModel } from './ProductSetCreateModalModel';
import { Form } from 'react-bootstrap';
import styles from './productSetCreateModal.module.scss';
import { Formik, FormikProps } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { CustomField } from 'components/common/form/field/CustomField';

export const ProductSetCreateModal: React.FC<{
  retailId: string,
  selectedProducts: SelectOptions[];
  onClose: () => void;
}> = ({
  retailId,
  selectedProducts,
  onClose
}) => {

  const {
    loading,
    formModel,
    createType,
    submitAlertModalData,
    submit,
    setCreateType
  } = useProductSetCreateModalModel(retailId, selectedProducts, onClose);

  const formRef = useRef<FormikProps<any>>(null);

  const onSubmit = async () => {
    formRef.current && formRef.current.submitForm();
  };

  const renderSubmitAlertModal = () => {
    const campaignInfo = submitAlertModalData.usageData.map(data => {
      const creativeInfo = data.creatives.map(creative => (
        <li key={creative.id}>{`${creative.name} (ID: ${creative.id})`}</li>
      ));
      return (
        <div key={data.id}>
          <div>{`${data.name} (ID: ${data.id})`}</div>
          <ul>
            {creativeInfo}
          </ul>
        </div>
      );
    });
    return (
      <Modal
        title={submitAlertModalData.title}
        primaryButton={{
          title: i18n.t<string>('common.buttons.submit'),
          callback: submitAlertModalData.submit
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: submitAlertModalData.cancel
        }}
        dismiss={submitAlertModalData.cancel}
        animation={false}
      >
        <div>
          <div className={styles.modalHint}>{i18n.t<string>('productSet.labels.changingProductSetAlert')}</div>
          {i18n.t<string>('productSetCreateModal.labels.usageDataTitle')}
          <div className={styles.campaignUsageInfo}>
            {campaignInfo}
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <Modal
      fullScreen={true}
      title={i18n.t<string>('productSetCreateModal.labels.title')}
      primaryButton={{
        title: formModel.submitBtnLabel,
        callback: onSubmit
      }}
      secondaryButton={{
        title: i18n.t<string>('common.buttons.cancel'),
        callback: onClose
      }}
      dismiss={onClose}
      animation={false}
    >
      {
        <div className={styles.content}>
          {submitAlertModalData && renderSubmitAlertModal()}
          {loading && <LoadingIndicator/>}
          <Formik
            innerRef={formRef}
            initialValues={formModel.initValue}
            enableReinitialize
            onSubmit={submit}
            validate={formModel.validate}
            validateOnBlur={false}
          >
            {
              (formikProps) => {
                const onCreateTypeChange = (e) => {
                  setCreateType(e.target.value);
                  formikProps.setFieldValue('advertiser', undefined);
                };
                const fieldsConfig = formModel.getFormFieldsConfig(formikProps);
                const renderFields = () =>
                  fieldsConfig.map(config => {
                    const Component = config.fieldComponent;
                    return <Component key={`${createType}-${config.fieldProps.name}`} {...config.fieldProps}/>;
                  });
                const renderProducts = () =>
                  selectedProducts.map(product => {
                    return <li key={product.value}>{product.label}</li>;
                  });
                return (
                  <>
                    <CustomField name='createType' label=''>
                      <div className={styles.radioRow}>
                        <Form.Check
                          type={'radio'}
                          label={i18n.t<string>('productSetCreateModal.labels.new')}
                          value={PRODUCT_SET_CREATE_TYPE.NEW}
                          name={'createType'}
                          id={'newProductSet'}
                          onChange={onCreateTypeChange}
                          checked={createType === PRODUCT_SET_CREATE_TYPE.NEW}
                        />
                        <Form.Check
                          type={'radio'}
                          label={i18n.t<string>('productSetCreateModal.labels.addTo')}
                          value={PRODUCT_SET_CREATE_TYPE.ADD_TO_EXIST}
                          name={'createType'}
                          id={'addToProductSet'}
                          onChange={onCreateTypeChange}
                          checked={createType === PRODUCT_SET_CREATE_TYPE.ADD_TO_EXIST}
                        />
                      </div>
                    </CustomField>
                    {renderFields()}
                    <CustomField name='products' label={i18n.t<string>('productSetCreateModal.labels.products')}>
                      <ul>
                        {renderProducts()}
                      </ul>
                    </CustomField>
                  </>
                );
              }
            }
          </Formik>
        </div>
      }
    </Modal>
  );
};
