
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Pmp, PmpFormType, PmpType, PmpAdType } from 'core/pmp/Pmp';
import i18n from 'i18n';
import moment from 'moment';
import { SummaryData } from 'components/SummaryDetail/SummaryDetail';
import { useParams } from 'react-router-dom';
import { DefaultPmpManager } from 'core/pmp/PmpManager';
import { usePmpSummaryModel } from './PmpSummaryModel';
import { toast } from 'react-toastify';

const pmpManager = new DefaultPmpManager();

export type PmpSetupFlowModel = {
  pmp?: PmpFormType,
  title: string,
  loading: boolean,
  summaryData: SummaryData[],
  redirectPath?: string,
  defaultPmp?: Pmp,
  readonly: boolean,
  setPmp: (pmp: PmpFormType) => void,
  handleSubmit: (pmp: PmpFormType) => void
};

export const useCreatePmpSetupFlowPageModel = (
  spaceOptions: SelectOptions[]
): PmpSetupFlowModel => {

  const { loading, callAPIs } = useCallAPI();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();

  const initPmp: PmpFormType = useMemo(() => ({
    name: '',
    adAgencyIds: [],
    startTime: moment().startOf('day').add(2, 'days').format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment().startOf('day').add(2, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    pmpType: PmpType.BUYOUT,
    budget: 1,
    spaces: [],
    adType: PmpAdType.PIC_SHORT
  }), []);

  const [pmp, setPmp] = useState<PmpFormType | undefined>(initPmp);
  const summaryData = usePmpSummaryModel(
    pmp,
    spaceOptions
  );

  const handleSubmit = useCallback((pmp: PmpFormType) => {
    callAPIs([
      () => pmpManager.createPmp(pmp)
    ], () => {
      setRedirectPath('/pmp');
      toast.success(i18n.t<string>('pmpSetupFlowPage.labels.createSuccess'));
    });
  }, [callAPIs]);

  return {
    pmp,
    title: i18n.t<string>('pmpSetupFlowPage.titles.create'),
    loading: loading,
    redirectPath,
    summaryData,
    readonly: false,
    setPmp,
    handleSubmit
  };
};

export const useEditPmpSetupFlowPageModel = (
  spaceOptions: SelectOptions[],
  agencyOptions: SelectOptions[]
): PmpSetupFlowModel => {

  const { loading, callAPIs } = useCallAPI();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const { pmpId } = useParams<{ pmpId: string }>();
  const [pmp, setPmp] = useState<PmpFormType | undefined>();
  const [defaultPmp, setDefaultPmp] = useState<Pmp>();

  useEffect(() => {
    callAPIs([
      () => pmpManager.getPmp(+pmpId)
    ], pmp => {
      setPmp({
        ...pmp,
        spaces: pmp.spaces.filter(space => spaceOptions.some(option => option.value === space))
      });
      setDefaultPmp(pmp);
    });
  }, [pmpId, spaceOptions, callAPIs]);

  const handleSubmit = useCallback((pmp: PmpFormType) => {
    if (!defaultPmp) return;
    callAPIs([
      () => pmpManager.updatePmp({
        ...defaultPmp,
        ...pmp,
        id: +pmpId
      })
    ], () => {
      setRedirectPath(`/pmp`);
      toast.success(i18n.t<string>('pmpSetupFlowPage.labels.updateSuccess'));
    });
  }, [pmpId, defaultPmp, callAPIs]);

  const readonly = useMemo(() => {
    if (!pmp) {
      return true;
    }

    if (moment(pmp.startTime).diff(moment().startOf('day'), 'days') < 2) {
      return true;
    }

    return false;
  }, [pmp]);

  const summaryData = usePmpSummaryModel(
    pmp,
    spaceOptions
  );

  return {
    pmp,
    title: i18n.t<string>('pmpSetupFlowPage.titles.edit'),
    loading,
    summaryData,
    redirectPath,
    readonly,
    setPmp,
    handleSubmit
  };
};
