import { Link } from 'react-router-dom';
import styles from './nameWithIdColumn.module.scss';
import classNames from 'classnames/bind';

export type NameWithIdColumnProps = {
  id: string | number;
  name: string;
  clickUrl?: string;
  clickable?: boolean;
};

const cx = classNames.bind(styles);

export const NameWithIdColumn = ({
  id,
  name,
  clickUrl,
  clickable
}: NameWithIdColumnProps) => {

  const nameClass = cx('name', {
    active: clickable,
    link: !!clickUrl
  });
  return (
    <div className={styles.info}>
      {
        (clickUrl && clickable) ?
          <Link to={clickUrl} className={nameClass}>
            {name}
          </Link> :
          <span className={nameClass}>{name}</span>
      }
      <div className={styles.id}>
        {'ID: ' + id}
      </div>
    </div>
  );
};
