import { AuditLogManager, DefaultAuditLogManager } from 'core/auditLog/AuditLogManager';
import { Pagination } from 'core/pagination/Pagination';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';
import styles from './auditLog.module.scss';
import _ from 'lodash';
import i18n from 'i18n';
import { ColumnDescription, ExpandRowProps } from 'react-bootstrap-table-next';

export interface AuditLogModel<T> extends SortableList {
  readonly state: AuditLogState<T>;
  readonly event: UpdateEventListener<AuditLogModel<T>>;
  init (page?: number, needRefetchFields?: boolean): Promise<void>;
  getColumns (): ColumnDescription[];
  getExpandRenderer (): ExpandRowProps<any, number>;
  sortAuditLog (field, direction);
  getFilters (): {[key: string]: {
    options: SelectOptions[],
    value?: string | number,
    onSelect: (campaignTarget: string | number) => void;
  }};
}

export type AuditLogProps<T> = {
  readonly model: AuditLogModel<T>;
};

export type AuditLogState<T> = {
  readonly auditLogData?: T[];
  readonly pagination: Pagination;
  readonly loading: boolean;
};

export abstract class AbstractAuditLogModel<T> extends AbstractSortableList implements AuditLogModel<T> {
  event: FireableUpdateEventListener<AuditLogModel<T>> = new FireableUpdateEventListener<AuditLogModel<T>>();
  loading: boolean = true;
  auditLogData?: T[];
  filteredAuditLogData?: T[];
  pagination: Pagination = {
    page: 1,
    size: 10,
    totalCount: 0
  };
  auditLogFields: string[] = [];
  selectedAuditLogField?: string;

  constructor (protected auditLogManager: AuditLogManager = new DefaultAuditLogManager()) {
    super('ctime', 'desc');
  }

  get state (): AuditLogState<T> {
    return {
      auditLogData: this.filteredAuditLogData,
      loading: this.loading,
      pagination: this.pagination
    };
  }

  async init (page: number = 1, needRefetchFields: boolean = false) {
    this.updateState(true);
    if (needRefetchFields) {
      await this.getAuditLogFields();
    }
    await this.initAuditLog(page);
    this.updateState(false);
  }

  abstract initAuditLog (page?: number);

  abstract getColumns ();

  abstract getExpandRenderer ();

  abstract getSubFilters ();

  abstract getAuditLogFields ();

  abstract getIgnoreFields (): string[];

  getSearchString () {
    if (!_.isEmpty(this.selectedAuditLogField)) {
      return `msgKeys=${this.selectedAuditLogField}`;
    }
  }

  getFilters () {
    const filters = this.getSubFilters();
    let generalOptions: SelectOptions[] = [];
    let limitationOptions: {[key: string]: SelectOptions[]} = {
      inc: [],
      exc: [],
      Preferred: [],
      NonPreferred: []
    };
    this.auditLogFields
      .filter(field => !this.getIgnoreFields().includes(field))
      .forEach(field => {
        const i18nKeys = field.split('.');
        if (i18nKeys.length === 2) {
          const label = i18n.t<string>(`limitation.labels.${i18nKeys[0]}`) + `(${i18n.t<string>(`limitation.${i18nKeys[1]}`)})`;
          limitationOptions[i18nKeys[1]] && limitationOptions[i18nKeys[1]].push({
            label: label,
            value: field
          });
        } else {
          const label = i18n.t<string>(`auditLog.labels.${field}`);
          generalOptions.push({
            label: label,
            value: field
          });
        }
      });
    const auditLogFieldOptions = generalOptions.concat(...Object.values(limitationOptions));
    auditLogFieldOptions.unshift({
      label: i18n.t<string>('common.placeholder.all'),
      value: ''
    });
    filters['fieldFilter'] = {
      options: auditLogFieldOptions,
      value: this.selectedAuditLogField,
      onSelect: this.setAuditLogField
    };
    return filters;
  }

  getAuditLogColumnDefinition (columnName) {
    const columnClassGetter = () => {
      return styles[columnName];
    };

    return {
      sort: true,
      text: `auditLog.headers.${columnName}`,
      dataField: columnName,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }

  sortAuditLog = (field, direction) => {
    if (this.sortField !== field || this.sortOrder !== direction) {
      this.handleSort(field, direction);
      this.init();
    }
  }

  setAuditLogField = (field: string) => {
    this.selectedAuditLogField = field;
    this.init();
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
