import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { State, Order, GojekFinalReportProjectType, OrderType, FinalReportTargetType, FinalReportGender } from 'core/order/Order';
import { OrderManager, DefaultOrderManager } from 'core/order/OrderManager';
import moment from 'moment';
import { LocaleMeta, AddonFeatureManager } from 'core';
import i18n from 'i18next';
import _ from 'lodash';
import { FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { CreateOrderFormContent } from './CreateOrderFormContent';
import { EditOrderFormContent } from './EditOrderFormContent';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { validateMinimum, validateMinMax, validateEmpty, validateMax } from 'utils/ValidateUtils';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { renderCreditHint, renderErrors } from './OrderFormHintRenderFuntions';
import { getFieldErrors } from 'utils/FormikUtils';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { ROUTE_PATH } from 'enum/RoutePath';
import { RTBCAMPAIGN_DEFAULT_AGE_MAX, RTBCAMPAIGN_DEFAULT_AGE_MIN } from 'core/rtbCampaign/RtbCampaign';
import { DefaultStoredValueManager, StoredValueManager } from 'core/storedValue/StoredValueManager';

export type OrderFormState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly needCheckModifyReason: boolean;
  readonly showBudgetInputField: boolean,
  readonly showMarginInputField: boolean,
};

export interface OrderFormModel {
  readonly state: OrderFormState;
  readonly event: UpdateEventListener<OrderFormModel>;
  readonly order?: Order;
  readonly advertiserList: SelectOptions[];
  readonly localeMeta?: LocaleMeta;
  readonly formikProps?: FormikProps<Order>;
  readonly title: string;
  readonly totalDays: number;
  readonly dateRangeMinDate: string;
  readonly dateRangeMaxDate: string;
  readonly contentComponent: any;
  readonly canEditMargin: boolean;
  readonly canEditMonitor: boolean;
  readonly orderTypes: SelectOptions[];
  readonly orderExternalTypes: SelectOptions[];
  readonly showOrderExternalTypes: boolean;
  readonly showEditButton: boolean;
  readonly isOrderHasApproved: boolean;
  readonly isOrderStarted: boolean;
  readonly budgetTip: any;
  readonly isOutdoorOrder: boolean;
  readonly breadcrumbs: any[];
  readonly showFinalReportSection: boolean;
  init (): Promise<void>;
  validate (order: Partial<Order>): any;
  setFormikProps (props: FormikProps<Order>): void;
  submit (values: Order): void;
  setNeedCheckModifyReason (needCheck: boolean): void;
  onUnmount (eventHandler): void;
  triggerBudgetInputField? ();
  triggerMarginInputField? ();
  onAdvertiserChange (newAdvertiser);
  onOrderTypeChange (newOrderType);
  isFinalReportProjectNameExist (finalReportProjectName: string): boolean;
}

const NOT_NEED_BUDGET_MAX = false;

export type OrderFormProps = {
  readonly model: OrderFormModel;
};

abstract class DefaultOrderFormModel implements OrderFormModel {
  event: FireableUpdateEventListener<OrderFormModel>;
  loading: boolean;
  order: any;
  manager: OrderManager;
  advertiserList: SelectOptions[];
  formikProps?: FormikProps<Order>;
  redirectPath?: string;
  needCheckModifyReason: boolean;
  canEditMargin: boolean;
  localeMeta: LocaleMeta;
  hasOrderBudgetMaximun: boolean;
  orderTypes: SelectOptions[];
  orderExternalTypes: SelectOptions[];
  addonFeatureManager: AddonFeatureManager;
  showBudgetInputField: boolean;
  showMarginInputField: boolean;
  existedProjectNames: string[] = [];
  orderType?: OrderType;
  finalReportConfig?: FinalReportConfig;
  showFinalReportSection: boolean;
  remainingStoredValue?: number;

  constructor (
    canEditMargin: boolean,
    hasOrderBudgetMaximun: boolean,
    manager: OrderManager,
    localeMeta: LocaleMeta,
    advertisers: SelectOptions[],
    addonFeatureManager: AddonFeatureManager,
    private storedValueManager: StoredValueManager = new DefaultStoredValueManager()
  ) {
    this.event = new FireableUpdateEventListener<OrderFormModel>();
    this.loading = true;
    this.manager = manager;
    this.advertiserList = advertisers ? advertisers : [];
    this.needCheckModifyReason = false;
    this.canEditMargin = canEditMargin;
    this.localeMeta = localeMeta;
    this.hasOrderBudgetMaximun = hasOrderBudgetMaximun;
    this.orderTypes = [];
    this.orderExternalTypes = [];
    this.addonFeatureManager = addonFeatureManager;
    this.showBudgetInputField = false;
    this.showMarginInputField = false;
    this.showFinalReportSection = !this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_RESTRICT_FINALREPORT);
  }

  setFormikProps (props: FormikProps<Order>) {
    this.formikProps = props;
  }

  abstract get budgetTip ();

  abstract get contentComponent ();

  abstract get title ();

  abstract get breadcrumbs ();

  get isOutdoorOrder () {
    // hide outdoor bk function
    // return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.OUTDOOR_AGENCY);
    return false;
  }

  get dateRangeMinDate () {
    return moment().startOf('day').format('YYYY-MM-DD');
  }

  get dateRangeMaxDate () {
    return moment().startOf('day').add(10, 'years').format('YYYY-MM-DD');
  }

  get showOrderExternalTypes () {
    const orderType = _.get(this.formikProps, 'values.orderType', '');
    return orderType === OrderType.TENMAX && this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.ADSORDER.SHOW_ORDER_EXTERNAL_TYPE);
  }

  get canEditMonitor () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.MONITOR.MONITOR_CAMPAIGN_BEHIND_PROGRESS);
  }

  get totalDays () {
    if (!this.formikProps) {
      return 0;
    }
    const formProps = this.formikProps;
    return moment(formProps.values.endDate).diff(moment(formProps.values.startDate), 'days') + 1;
  }

  abstract init (): Promise<void>;

  abstract validateBudget (order: Partial<Order>, currency: string, budgetMin: number);

  abstract validate (order?: Partial<Order>): any;

  async getOrderExternalTypeList (): Promise<void> {
    try {
      const allOrderExternalType = await this.manager.getOrderExternalTypes();
      this.orderExternalTypes = allOrderExternalType.filter(orderExternalTypes => orderExternalTypes.active !== false);
    } catch (e) {}
  }

  async getRemainingStoredValue () {
    if (this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.STORED_VALUE) && this.localeMeta.agencyId) {
      this.remainingStoredValue = await this.storedValueManager.getRemainingStoredValue(this.localeMeta.agencyId);
    }
  }

  onAdvertiserChange = (newAdvertiser) => {
    if (!this.formikProps) {
      return;
    }
    const advertiser = this.advertiserList.find(advertiser => advertiser.value === newAdvertiser);
    this.formikProps.setFieldValue('finalReportAdvertiserName', advertiser ? advertiser.label : '');
  }

  onOrderTypeChange = (newOrderType) => {
    if (!this.formikProps) {
      return;
    }
    const advertiserId = this.formikProps.values.advertiserId;
    const advertiser = this.advertiserList.find(advertiser => advertiser.value === advertiserId);
    // TODO: Modify code structure
    const basicValues = _.omit(
      this.formikProps.values,
      ['finalReportProjectName', 'finalReportAdvertiserName', 'finalReportProjectType', 'finalReportReceivers', 'finalReportSendOutDate', 'finalReportTargetImpressions']
    ) as Order;
    switch (newOrderType) {
      case OrderType.GOJEK:
        this.finalReportConfig = new GojekFinalReportConfig();
        break;
      case OrderType.TENMAX:
        this.finalReportConfig = new TenmaxFinalReportConfig();
        break;
      default:
        break;
    }
    this.orderType = newOrderType;
    const defaultFinalReportConfigs = this.finalReportConfig ? this.finalReportConfig.getDefault(advertiser) : {};
    this.formikProps.setValues({
      ...basicValues,
      ...defaultFinalReportConfigs,
      finalReportTargetValue: this.showFinalReportSection ? '' : 0,
      orderType: newOrderType
    });
  }

  onUnmount = (eventHandler) => {
    this.event.remove(eventHandler);
    this.redirectPath = undefined;
    this.showBudgetInputField = false;
    this.showMarginInputField = false;
  }

  abstract submit (values: Order): void;

  get state (): OrderFormState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      needCheckModifyReason: this.needCheckModifyReason,
      showBudgetInputField: this.showBudgetInputField,
      showMarginInputField: this.showMarginInputField
    };
  }

  get showEditButton (): boolean {
    const hideEditBtn = this.order ? _.includes([State.NOT_APPROVE, State.REJECT], this.order.state) || !!this.order.modifyReason : true;
    return !hideEditBtn;
  }

  get isOrderHasApproved (): boolean {
    const notApproved = this.order ? _.includes([State.NOT_APPROVE, State.REJECT], this.order.state) : true;
    return !notApproved;
  }

  get isOrderStarted (): boolean {
    if (!this.order) {
      return false;
    }
    return moment().startOf('day').isAfter(moment(this.order.startDate));
  }

  abstract setNeedCheckModifyReason (needCheck: boolean);

  abstract isFinalReportProjectNameExist (finalReportProjectName: string);

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }

}

export class CreateOrderFormModel extends DefaultOrderFormModel {

  constructor (canEditMargin: boolean,
    hasOrderBudgetMaximun: boolean,
    advertisers: SelectOptions[],
    localeMeta: LocaleMeta,
    addonFeature: AddonFeatureManager,
    manager: OrderManager = new DefaultOrderManager()) {
    super(canEditMargin, hasOrderBudgetMaximun, manager, localeMeta, advertisers, addonFeature);
  }

  get breadcrumbs () {
    return [
      { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
      { path: '/orders/new', breadcrumb: this.title }
    ];
  }

  get budgetTip (): any {
    const credit = this.localeMeta.credit;
    const hasCreditLimit = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.AGENCY_CREDIT_LIMIT);
    if (hasCreditLimit && this.formikProps) {
      const hasBudetError = !!getFieldErrors(this.formikProps, 'budget');
      if (!hasBudetError && credit < this.formikProps.values.budget) {
        return renderCreditHint(credit);
      }
    }
    return '';
  }

  async init (): Promise<void> {
    this.updateState(true);
    this.orderTypes = createSelectOptionsFromEnum(OrderType, 'orderType.enums.').filter(orderType => this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.ADSORDER[orderType.value]));
    await this.getOrderExternalTypeList();
    await this.getRemainingStoredValue();
    try {
      this.existedProjectNames = await this.manager.getExistedProjectNames();
    } catch (e) {}
    const advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    this.order = {
      advertiserId,
      orderType: '',
      budget: 0,
      orderMargin: _.round(this.localeMeta.agcPercent * 100, 2),
      state: State.NOT_APPROVE,
      startDate: moment().startOf('day').format('YYYY-MM-DD'),
      endDate: moment().endOf('day').format('YYYY-MM-DD'),
      currency: this.localeMeta.currency,
      orderPrice: 0,
      creativeDuration: 0,
      dayPart: {},
      monitor: this.canEditMonitor ? true : false
    };
    if (this.isOutdoorOrder) {
      const dayPartHourRange = Array.from({ length: 24 }, (_, index) => index);
      this.order.orderPrice = 100;
      this.order.creativeDuration = 30;
      this.order.dayPart = {
        '1': [...dayPartHourRange],
        '2': [...dayPartHourRange],
        '3': [...dayPartHourRange],
        '4': [...dayPartHourRange],
        '5': [...dayPartHourRange],
        '6': [...dayPartHourRange],
        '7': [...dayPartHourRange]
      };
    }
    this.updateState(false);
  }

  submit = async (values: Order) => {
    const finalReportConfigs = this.finalReportConfig ?
      this.finalReportConfig.get(values) :
      {};
    const newOrder = {
      ...values,
      orderMargin: _.round(values.orderMargin / 100, 4),
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
      ...finalReportConfigs
    };
    this.updateState(true);
    try {
      const order = await this.manager.createOrder(newOrder);
      this.updateState(false, `/orders/${order.orderNumber}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  validateOrderDateRange = (endDate?: string) => {
    const error = validateEmpty(endDate);
    if (error) {
      return error;
    }

    if (moment(endDate).isBefore(moment().startOf('day'))) {
      return i18n.t<string>('orderForm.errors.endDateError');
    }
  }

  validateBudget = (order: Partial<Order>, currency: string, budgetMin: number = 1) => {
    const budget = _.defaultTo(order.budget, 0);
    if (!_.isNil(this.remainingStoredValue) || this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.STORED_VALUE)) {
      const remainingStoredValue = _.defaultTo(this.remainingStoredValue, 0);
      const error = validateMax(budget, remainingStoredValue, currency, 'orderForm.errors.budgetExceedStoredValueError');
      if (error) {
        return error;
      }
    }
    const maxOrderBudget = this.hasOrderBudgetMaximun && this.localeMeta.maxOrderBudget;
    const budgetMax = _.min(_.xor([maxOrderBudget, NOT_NEED_BUDGET_MAX], [NOT_NEED_BUDGET_MAX]));
    return _.isUndefined(budgetMax) ? validateMinimum(budget, budgetMin) : validateMinMax(budget, budgetMin, budgetMax);
  }

  validate = (order: Partial<Order>) => {
    const marginMin = 0;
    const marginMax = this.localeMeta.maxOrderProfit * 100;
    const finalReportErrors = this.finalReportConfig ? this.finalReportConfig.validate(order) : {};
    return _.omitBy(
      {
        advertiserId: validateEmpty(order.advertiserId),
        projectName: validateEmpty(order.projectName),
        orderType: !_.isEmpty(this.orderTypes) ? validateEmpty(order.orderType) : i18n.t<string>('orderForm.errors.orderTypeEmptyError'),
        budget: this.validateBudget(order, this.order.currency),
        orderMargin: this.canEditMargin ? validateMinMax(order.orderMargin, marginMin, marginMax) : undefined,
        orderPrice: this.isOutdoorOrder ? validateEmpty(order.orderPrice) : undefined,
        creativeDuration: this.isOutdoorOrder ? validateEmpty(order.creativeDuration) : undefined,
        dateRange: this.validateOrderDateRange(order.endDate),
        ...finalReportErrors
      }, _.isEmpty);
  }

  get contentComponent () {
    return CreateOrderFormContent;
  }

  get title () {
    return i18n.t<string>('orderForm.labels.createTitle');
  }

  setNeedCheckModifyReason (needCheck) {
    this.needCheckModifyReason = false;
  }

  isFinalReportProjectNameExist (finalReportProjectName) {
    return this.existedProjectNames.includes(finalReportProjectName);
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}

export class EditOrderFormModel extends DefaultOrderFormModel {

  orderNumber: string;
  originOrder?: Order = undefined;

  constructor (canEditMargin: boolean,
    hasOrderBudgetMaximun: boolean,
    localeMeta: LocaleMeta,
    advertisers: SelectOptions[],
    orderNumber: string,
    addonFeature: AddonFeatureManager,
    manager: OrderManager = new DefaultOrderManager()) {
    super(canEditMargin, hasOrderBudgetMaximun, manager, localeMeta, advertisers, addonFeature);
    this.orderNumber = orderNumber;
  }

  async getOrderExternalTypeList (): Promise<void> {
    try {
      const allOrderExternalType = await this.manager.getOrderExternalTypes();
      this.orderExternalTypes = allOrderExternalType.filter(orderExternalTypes => orderExternalTypes.active !== false || orderExternalTypes.value === this.order.externalType);
    } catch (e) {}
  }

  get breadcrumbs () {
    return [
      { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(this.order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t<string>('common.labels.edit'),
          label: _.get(this.order, 'projectName'),
          matchParam: 'orderNumber'
        }
      }
    ];
  }

  get budgetTip (): any {
    const hasCreditLimit = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.AGENCY_CREDIT_LIMIT);
    if (hasCreditLimit && this.formikProps) {
      const credit = this.formikProps.values.state !== State.NOT_APPROVE ?
        this.localeMeta.credit + this.order.budget :
        this.localeMeta.credit;
      const hasBudetError = !!getFieldErrors(this.formikProps, 'budget');
      if (!hasBudetError && credit < this.formikProps.values.budget) {
        return renderCreditHint(credit);
      }
    }
    return '';
  }

  get contentComponent () {
    return EditOrderFormContent;
  }

  get title () {
    return i18n.t<string>('orderForm.labels.editTitle');
  }

  setNeedCheckModifyReason (needCheck) {
    this.needCheckModifyReason = needCheck;
    this.updateState(false);
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      this.existedProjectNames = await this.manager.getExistedProjectNames();
      const order = await this.manager.getOrder(this.orderNumber);
      this.originOrder = { ...order };
      const advertiserOption = this.advertiserList.find(advertiserOption => advertiserOption.value.toString() === order.advertiserId.toString());
      this.order = {
        ...order,
        advertiserName: advertiserOption ? advertiserOption.label : '',
        orderMargin: _.round(order.orderMargin * 100, 2),
        startDate: moment(order.startDate).format('YYYY-MM-DD'),
        endDate: moment(order.endDate).format('YYYY-MM-DD')
      };
      this.finalReportConfig = order.orderType === OrderType.TENMAX ? new TenmaxFinalReportConfig() : new GojekFinalReportConfig();
      await this.getOrderExternalTypeList();
      await this.getRemainingStoredValue();
      this.updateState(false, this.order && this.order.id >= 2000000 ? undefined : `/orders/${this.order.orderNumber}/edit/${ROUTE_PATH.ERROR403}`);
    } catch (e) {
      this.updateState(false);
    }
  }

  validateBudget = (order: Partial<Order>, currency: string, budgetMin: number = 1) => {
    const budget = _.defaultTo(order.budget, 0);
    const defaultBudget = _.get(this.originOrder, 'budget', 0);
    if (defaultBudget === budget) {
      return;
    }

    if (!_.isNil(this.remainingStoredValue)) {
      const max = order.state && [State.NOT_APPROVE, State.REJECT].includes(order.state) ?
        this.remainingStoredValue :
        this.remainingStoredValue + defaultBudget;
      const error = validateMax(budget, max, currency, 'orderForm.errors.budgetExceedStoredValueError');
      if (error) {
        return error;
      }
    }
    const maxOrderBudget = this.hasOrderBudgetMaximun && this.localeMeta.maxOrderBudget;
    const budgetMax = _.min(_.xor([maxOrderBudget, NOT_NEED_BUDGET_MAX], [NOT_NEED_BUDGET_MAX]));
    return _.isUndefined(budgetMax) ? validateMinimum(budget, budgetMin) : validateMinMax(budget, budgetMin, budgetMax);
  }

  submit = async (values: Order) => {
    const finalReportConfig = this.finalReportConfig ? this.finalReportConfig.get(values) : {};
    const orderUpdateData = _.omit({
      ...values,
      orderMargin: _.round(values.orderMargin / 100, 4),
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
      ...finalReportConfig
    }, ['advertiserName']);
    this.updateState(true);
    try {
      const order = await this.manager.updateOrder(orderUpdateData);
      this.updateState(false, `/orders/${order.orderNumber}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  needCheckBudget (order: Partial<Order>) {
    // budget field is a input if edit button is hide
    const hideEditBtn = _.includes([State.NOT_APPROVE, State.REJECT], order.state) || !!order.modifyReason;
    return hideEditBtn || this.state.showBudgetInputField;
  }

  needCheckMargin (order: Partial<Order>) {
    // margin field is a input if edit button is hide
    const hideEditBtn = _.includes([State.NOT_APPROVE, State.REJECT], order.state) || !!order.modifyReason;
    return hideEditBtn || this.state.showMarginInputField;
  }

  validateOrderDateRange = (startDate, endDate) => {
    if (!this.order) {
      return;
    }
    const errors: any[] = [];
    if (this.order.campaignMinStartDate && moment(startDate).isAfter(moment(this.order.campaignMinStartDate))) {
      errors.push(i18n.t<string>('orderForm.labels.campaignMinDateError'));
    }
    if (this.order.campaignMaxEndDate && moment(endDate).isBefore(moment(this.order.campaignMaxEndDate))) {
      errors.push(i18n.t<string>('orderForm.labels.campaignMaxDateError'));
    }
    if (this.order.endDate !== endDate) {
      if (moment(endDate).isBefore(moment().startOf('day'))) {
        return i18n.t<string>('orderForm.errors.endDateError');
      }
    }
    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validate = (order: Partial<Order>) => {
    const marginMin = 0;
    const marginMax = this.localeMeta.maxOrderProfit * 100;
    const budgetMin = this.order.budget - this.order.budgetBalance;
    let errors = _.omitBy(
      {
        projectName: validateEmpty(order.projectName),
        budget: this.needCheckBudget(order) ?
          this.validateBudget(order, this.order.currency, budgetMin) :
          undefined,
        orderMargin: this.needCheckMargin(order) && this.canEditMargin ? validateMinMax(order.orderMargin, marginMin, marginMax) : undefined,
        modifyReason: this.needCheckModifyReason ? validateEmpty(order.modifyReason) : undefined,
        dateRange: this.validateOrderDateRange(order.startDate, order.endDate),
        orderPrice: this.isOutdoorOrder ? validateEmpty(order.orderPrice) : undefined,
        creativeDuration: this.isOutdoorOrder ? validateEmpty(order.creativeDuration) : undefined
      }, (value) => value === undefined);

    const finalReportErrors = this.finalReportConfig ? this.finalReportConfig.validate(order) : {};
    return _.omitBy({
      ...errors,
      ...finalReportErrors
    }, _.isUndefined);
  }

  triggerBudgetInputField () {
    this.showBudgetInputField = !this.showBudgetInputField;
    this.updateState(false);
  }

  triggerMarginInputField () {
    this.showMarginInputField = !this.showMarginInputField;
    this.updateState(false);
  }

  canActorViewOrder (actor) {
    if (!this.order) {
      return false;
    }

    return !(actor &&
      this.order.agencyId !== actor.agencyId);
  }

  isFinalReportProjectNameExist (finalReportProjectName) {
    if (this.originOrder && this.originOrder.finalReportProjectName === finalReportProjectName) {
      return false;
    }
    return this.existedProjectNames.includes(finalReportProjectName);
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}

interface FinalReportConfig {
  get: (order: Order) => any;
  getDefault: (advertiser?: SelectOptions) => any;
  validate: (order: Partial<Order>) => any;
}

class GojekFinalReportConfig implements FinalReportConfig {

  get (order: Order) {
    return {
      finalReportAdvertiserName: order.finalReportAdvertiserName,
      finalReportProjectType: order.finalReportProjectType,
      finalReportReceivers: order.finalReportReceivers,
      finalReportTargetType: order.finalReportTargetType,
      finalReportTargetValue: order.finalReportTargetValue ? +order.finalReportTargetValue : 0,
      finalReportSendOutDate: order.finalReportSendOutDate ? moment(order.finalReportSendOutDate).format('YYYY-MM-DD') : undefined,
      finalReportProjectName: order.finalReportProjectType === GojekFinalReportProjectType.OTHERS ? 'Others' : order.finalReportProjectName
    };
  }

  getDefault (advertiser?: SelectOptions) {
    return {
      finalReportProjectName: '',
      finalReportProjectType: GojekFinalReportProjectType.OTHERS,
      finalReportReceivers: [],
      finalReportSendOutDate: moment().startOf('day').add(2, 'day').format('YYYY-MM-DD'),
      finalReportAdvertiserName: advertiser ? advertiser.label : '',
      finalReportTargetType: FinalReportTargetType.IMPRESSION,
      finalReportTargetValue: ''
    };
  }

  validate (order: Partial<Order>) {
    const finalReportProjectType = order.finalReportProjectType;
    return {
      finalReportProjectName: finalReportProjectType === GojekFinalReportProjectType.OTHERS ?
        undefined :
        this.validateFinalReportProjectName(order.finalReportProjectName),
      finalReportProjectType: validateEmpty(finalReportProjectType),
      finalReportAdvertiserName: validateEmpty(order.finalReportAdvertiserName),
      finalReportTargetValue: validateEmpty(order.finalReportTargetValue)
    };
  }

  private validateFinalReportProjectName (value) {
    let error = validateEmpty(value);
    if (error) {
      return error;
    }

    const validPorjectNameRegExp = /((^[a-z0-9]|[A-Z0-9])[a-z0-9]+)+$/g;
    const matches = value.match(validPorjectNameRegExp);
    if (!matches || matches[0].length !== value.length) {
      return i18n.t<string>('orderForm.errors.projectNamePatternError');
    }
  }
}

class TenmaxFinalReportConfig implements FinalReportConfig {

  get (order: Order) {
    return {
      finalReportAdvertiserName: order.finalReportAdvertiserName,
      finalReportTargetType: order.finalReportTargetType,
      finalReportTargetValue: order.finalReportTargetValue ? +order.finalReportTargetValue : 0,
      ageMin: order.ageMin,
      ageMax: order.ageMax,
      gender: order.gender
    };
  }

  getDefault (advertiser?: SelectOptions) {
    return {
      finalReportAdvertiserName: advertiser ? advertiser.label : '',
      finalReportTargetType: FinalReportTargetType.IMPRESSION,
      finalReportTargetValue: '',
      ageMin: RTBCAMPAIGN_DEFAULT_AGE_MIN,
      ageMax: RTBCAMPAIGN_DEFAULT_AGE_MAX,
      gender: FinalReportGender.ALL
    };
  }

  validate (order: Partial<Order>) {
    return {
      finalReportAdvertiserName: validateEmpty(order.finalReportAdvertiserName),
      finalReportTargetValue: validateEmpty(order.finalReportTargetValue)
    };
  }
}
