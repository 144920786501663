import { DefaultAgencyManager } from 'core';
import { DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { OutdoorChannel } from 'core/limitation/ProductGroup';
import { useCallAPI } from 'hooks/useCallAPI';
import { useEffect, useState } from 'react';

const agencyManager = new DefaultAgencyManager();
const adRequestSourceManager = new DefaultAdRequestSourceManager();

export const useGetPmpOptions = () => {
  const [spaceOptions, setSpaceOptions] = useState<SelectOptions[]>([]);
  const [agencyOptions, setAgencyOptions] = useState<SelectOptions[]>([]);

  const { callAPIs } = useCallAPI();
  const [optionInited, setOptionInited] = useState(false);

  useEffect(() => {
    callAPIs([
      () => agencyManager.getAgenciesOptions('channel.pic'),
      () => adRequestSourceManager.getSpacesWithConditions({}, 'Outdoor', OutdoorChannel.PIC)
    ], (agencyOptions: SelectOptions[], spaceOptions: SelectOptions[]) => {
      setSpaceOptions(spaceOptions);
      setAgencyOptions(agencyOptions);
      setOptionInited(true);
    });
  }, [callAPIs]);

  return {
    optionInited,
    spaceOptions,
    agencyOptions
  };
};
