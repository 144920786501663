import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Pmp, PmpStatus } from 'core/pmp/Pmp';
import { DefaultPmpManager } from 'core/pmp/PmpManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';

const pmpManager = new DefaultPmpManager();

const PmpListContext = createContext<Pmp[] | null>(null);

export const PmpListProvider: React.FC<PropsWithChildren<{ orderId: number }>> = ({
  orderId,
  children
}) => {

  const [pmpListOfOrder, setPmpListOfOrder] = useState<Pmp[]>();

  const { callAPIs } = useCallAPI();

  useEffect(() => {
    callAPIs(
      [() => pmpManager.getPmpsOfOrder(orderId, [PmpStatus.ACTIVE])],
      (pmps: Pmp[]) => {
        setPmpListOfOrder(pmps);
      }
    );
  }, [orderId, callAPIs]);

  if (!pmpListOfOrder) {
    return <LoadingIndicator />;
  }

  return (
    <PmpListContext.Provider value={pmpListOfOrder}>
      {children}
    </PmpListContext.Provider>
  );
};
