import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import _ from 'lodash';
import { DefaultDoctorManager, DoctorManager } from 'core/doctor/DoctorManager';
import { FILTER_TYPE } from 'core/doctor/DoctorData';
import { toast } from 'react-toastify';
import { DefaultDoctorTableModel, DoctorTableModel } from 'containers/DoctorTable/DoctorTableModel';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import i18n from 'i18n';

export type DoctorsState = {
  readonly doctorTableData: any[];
  readonly loading: boolean;
  readonly campaignId?: string;
  readonly creativeId?: string | number;
  readonly creativeOptions?: SelectOptions[];
};
export interface DoctorsModel {
  readonly state: DoctorsState;
  readonly event: UpdateEventListener<DoctorsModel>;
  getTableModel (): DoctorTableModel | undefined;
  check (): Promise<void>;
  setCampaign (event);
  setCreative (creativeId: string | number);
}

export type DoctorsProps = {
  readonly model: DoctorsModel;
};

export class DefaultDoctorsModel implements DoctorsModel {
  event: FireableUpdateEventListener<DoctorsModel> = new FireableUpdateEventListener<DoctorsModel>();
  loading: boolean = false;
  doctorTableData: any[] = [];
  campaignId?: string;
  creativeId?: string | number;
  currentSupportFilter: FILTER_TYPE[] = [FILTER_TYPE.CATEGORY, FILTER_TYPE.INTERSTITIAL];
  tableModel?: DoctorTableModel;
  creativeOptions?: SelectOptions[];

  constructor (
    private doctorManager: DoctorManager = new DefaultDoctorManager(),
    private creativeManager: CreativeManager = new DefaultCreativeManager()
  ) {}

  get state (): DoctorsState {
    return {
      doctorTableData: this.doctorTableData,
      loading: this.loading,
      campaignId: this.campaignId,
      creativeId: this.creativeId,
      creativeOptions: this.creativeOptions
    };
  }

  getTableModel (): DoctorTableModel | undefined {
    if (this.doctorTableData.length === 0) {
      return;
    }
    if (this.tableModel) {
      this.tableModel.updateDoctorTableData(this.doctorTableData);
      return this.tableModel;
    }

    this.tableModel = new DefaultDoctorTableModel(this.doctorTableData);
    return this.tableModel;
  }

  getCreativeOptions = async () => {
    if (!this.campaignId) {
      return;
    }
    this.updateState(true);
    try {
      const creativeData = await this.creativeManager.getCreativesByCampaignId(this.campaignId, false);
      this.creativeOptions = creativeData.map(data => ({
        label: data.name,
        value: data.id
      }));
      if (this.creativeOptions.length > 0) {
        this.creativeId = this.creativeOptions[0].value;
      } else {
        toast.error(i18n.t<string>('doctors.labels.noCreative'));
      }
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  check = async () => {
    if (!this.campaignId || !this.creativeId) {
      return;
    }
    this.updateState(true);
    try {
      const doctorRecords = await this.doctorManager.getDoctorRecords(this.campaignId, this.creativeId);
      this.doctorTableData = [];
      const allSpaceChannels = [...doctorRecords.allSpaceChannels];
      const filterReasons = [...doctorRecords.filterReasons];
      this.currentSupportFilter.forEach(filterType => {
        const filteredReasons = _.remove(filterReasons, reason => reason.type === filterType);
        _.remove(
          allSpaceChannels,
          spaceChannel => filteredReasons
            .find(reason => reason.spaceId === spaceChannel.spaceId) !== undefined
        );
        const remainSpaceChannels = [...allSpaceChannels];
        this.doctorTableData.push({
          filteredReasons,
          remainSpaceChannels,
          type: filterType
        });
      });
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  setCampaign = (event) => {
    this.campaignId = event.target.value;
    this.getCreativeOptions();
  }

  setCreative = (creativeId) => {
    this.creativeId = creativeId;
    this.updateState(false);
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
