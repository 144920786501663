import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import _ from 'lodash';
import styles from './toggleButtons.module.scss';
import classnames from 'classnames/bind';

type ToggleButtonsProps = {
  type?: any;
  value: string | number;
  name: string;
  options: SelectOptions[];
  onChange: (value: string | number) => void;
  className?: string;
};

const cx = classnames.bind(styles);

const ToggleButtons: React.FunctionComponent<ToggleButtonsProps> = ({
  type,
  value,
  name,
  options,
  onChange: handleChange,
  className: parentClassName
}) => {
  const className = cx(parentClassName, 'toggleButtons');
  return (
    <ToggleButtonGroup
      type={type}
      value={value}
      onChange={handleChange}
      name={name}
      className={className}
    >
      {options &&
        options.map(({ label, value }: SelectOptions) => (
          <ToggleButton variant='secondary' key={value} value={value}>
            {label}
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
};

ToggleButtons.defaultProps = {
  type: 'radio',
  options: [],
  onChange: _.noop
};

export default ToggleButtons;
