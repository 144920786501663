import React from 'react';
import i18next from 'i18n';
import styles from './addon.module.scss';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import { AddonDependency } from 'core/agency/AddonFeature';
import { CreativeType } from 'core/creative/Creative';
import _, { round } from 'lodash';
import { multiply100 } from 'utils/Math';

type AddonProps = {
  agencyDetail: {};
};

function addonProps (agencyDetail, addonGroup, addonItem): any {
  if (addonItem === 'singleOrderBudgetConstraint') {
    return i18next.t<string>('agency.addonItem.company.orderBudgetMaximum') + ' ' + agencyDetail['property']['addonProps']['orderBudgetMaximum'];
  }
  if (addonItem === 'creditLimit') {
    return i18next.t<string>('agency.addonItem.company.creditLimitMaximum') + ' ' + agencyDetail['property']['addonProps']['creditLimit'];
  }
  if (addonItem === 'fixedExchangeRate') {
    return i18next.t<string>('agency.addonItem.company.ExchangeRate') + ' ' + agencyDetail['property']['addonProps']['fixedExchangeRate'];
  }
  if (addonItem === 'systemProfitMonitor') {
    return i18next.t<string>('agency.addonItem.monitor.percentLabel') + ' ' + agencyDetail['property']['addonProps']['sysProfitMonitorPercent'];
  }
  if (addonItem === 'outdoorAdImpresMultiply') {
    return i18next.t<string>('agency.addonItem.report.outdoorImpresMultiple') + ' ' + agencyDetail['property']['addonProps']['outdoorAdImpresMultiply'];
  }
  if (addonItem === 'outdoorAgency') {
    return i18next.t<string>('agency.addonItem.company.outdoorMachineCount') + ' ' + agencyDetail['property']['addonProps']['outdoorMachineCount'];
  }
  if (addonItem === 'agencySegment') {
    return i18next.t<string>('limitation.labels.agencySegment', { name: agencyDetail['property']['addonProps']['agencySegmentLimitationName'] });
  }
  if (addonItem === 'pic' && agencyDetail['property']['addonProps']['picMargin']) {
    return i18next.t<string>('agency.addonItem.channel.picMargin') + ' ' + round(multiply100(agencyDetail['property']['addonProps']['picMargin']), 2) + '%';
  }
}

function isAddonAllFalse (addonGroup, excludeList) {
  let result = true;
  if (!addonGroup) {
    return result;
  }
  Object.keys(addonGroup).forEach(function (addonItem) {
    if (addonGroup[addonItem] === true &&
      !excludeList.includes(addonItem)
    ) {
      result = false;
    }
  });

  return result;
}

const Addon: React.FC<AddonProps> = ({
  agencyDetail
}) => {
  const keylist = ['channel', 'creatives', 'company', 'campaign', 'campaignLimitation'];
  const excludeList = [
    'bidPriceBaseVal',
    'bidPriceCPCMin',
    'bidPriceCPMMin',
    'bidPriceCPVMin',
    'bidPricevCPMMin',
    'bidPriceConstraint',
    'booking_calendar',
    'campaignDashboard',
    'habits',
    'option_6',
    'orderBudgetMaximum',
    'incentive',
    'landingPage',
    'percentLabel',
    'campaignUnderBidFloor',
    'deliveryAmountConstraint'
  ];

  const addonData = keylist.map((addonGroup) => {
    if (isAddonAllFalse(agencyDetail['addonFeatures'][addonGroup], excludeList)) {
      return {
        sectionTitle: i18next.t<string>('agency.addonGroup.' + addonGroup),
        sectionData: []
      };
    } else {
      return {
        sectionTitle: i18next.t<string>('agency.addonGroup.' + addonGroup),
        sectionData: _.compact(Object.keys(agencyDetail['addonFeatures'][addonGroup])
          .filter((addonItem) =>
            agencyDetail['addonFeatures'][addonGroup][addonItem] === true &&
            !excludeList.includes(addonItem) &&
            (AddonDependency[addonItem] === undefined || agencyDetail['addonFeatures'][addonGroup][AddonDependency[addonItem]] === true))
          .map(addonItem => {
            let i18nkey = addonItem;
            if (_.startsWith(addonItem, 'option_')) {
              const creativeTypeKey = CreativeType[+(addonItem.replace('option_', ''))];
              if (!creativeTypeKey) {
                return undefined;
              }
              i18nkey = `option_${_.camelCase(creativeTypeKey)}`;
            }
            return {
              label: i18next.t<string>('agency.addonItem.' + addonGroup + '.' + i18nkey),
              value: addonProps(agencyDetail, addonGroup, addonItem)
            };
          })
        )
      };
    }
  });
  return (
    <div className={styles.container}>
      {
        addonData.map((addonGroup) => {
          return (
            <InfoDisplaySection key={addonGroup.sectionTitle} data={addonGroup.sectionData} title={addonGroup.sectionTitle} />
          );
        })
      }
    </div>
  );
};

export default Addon;
