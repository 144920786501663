import { Dayparts } from 'core/dayparts/Dayparts';
import { Pagination } from 'core/pagination/Pagination';

export type PmpFormType = {
  name: string;
  startTime: string;
  endTime: string;
  spaces: string[];
  adType: PmpAdType;
  pmpType: PmpType;
  budget: number;
  adAgencyIds: number[];
  dayPart?: Dayparts;
};

export type Pmp = {
  id: number;
  status: PmpStatus;
  campaignId?: number;
  adminStatus: PmpAdminStatus;
  dealId: string;
} & PmpFormType;

export type PmpsWithPagination = {
  pmps: Pmp[];
  pagination: Pagination;
};

export enum PmpAdType {
  PIC_SHORT = 'PIC_SHORT',
  PIC_LONG = 'PIC_LONG'
}

export enum PmpType {
  BUYOUT = 'BUYOUT',
  SPONSORSHIP = 'SPONSORSHIP'
}

export enum PmpStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  EXPIRED = 4 // Align Server Common Status
}

export enum PmpAdminStatus {
  NOT_BINDING = 1,
  BINDING = 2,
  STOP = 3,
  FINISH = 4,
  EXPIRED = 5
}
