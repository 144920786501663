import { PMaxCore } from 'core';
import { OrderFormModel, CreateOrderFormModel, EditOrderFormModel } from './OrderForm/OrderFormModel';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { OrderHomeModel, DefaultOrderHomeModel } from './OrderHome/OrderHomeModel';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';

export interface OrdersModel {
  readonly core: PMaxCore;
  readonly state: OrdersState;
  readonly event: UpdateEventListener<OrdersModel>;
  init (): Promise<void>;
  getCreateOrderFormModel (): OrderFormModel | undefined;
  getEditOrderFormModel (orderNumber): OrderFormModel | undefined;
  getOrderDetailProps (orderNumber): any;
  getOrderHomeModel (): OrderHomeModel | undefined;
  onUnmount (eventHandler?: number): void;
}

export type OrdersProps = {
  readonly model: OrdersModel;
};

export type OrdersState = {
  loading: boolean;
};

export class DefaultOrdersModel implements OrdersModel {

  loading: boolean;
  core: PMaxCore;
  createOrderFormModel?: CreateOrderFormModel;
  editOrderFormModel?: EditOrderFormModel;
  orderHomeModel?: DefaultOrderHomeModel;
  event: FireableUpdateEventListener<OrdersModel>;
  advertiserManager: AdvertiserManager;
  advertisers?: Array<SelectOptions>;

  constructor (
    core: PMaxCore,
    advertiserManager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.core = core;
    this.loading = true;
    this.event = new FireableUpdateEventListener<OrdersModel>();
    this.advertiserManager = advertiserManager;
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      this.advertisers = await this.advertiserManager.getAdvertiserOptions();
      const advertiserMap = {};
      this.advertisers.forEach(advertiser => {
        advertiserMap[advertiser.value] = advertiser.label;
      });
    } catch (error) {}
    this.updateState(false);
  }

  get state () {
    return {
      loading: this.loading
    };
  }

  getCreateOrderFormModel (): OrderFormModel | undefined {
    if (!this.core.accountManager.localeMeta || !this.advertisers) {
      return undefined;
    }

    if (this.createOrderFormModel &&
      this.createOrderFormModel.advertiserList === this.advertisers &&
      this.createOrderFormModel.localeMeta.credit === this.core.accountManager.localeMeta.credit
    ) {
      return this.createOrderFormModel;
    }
    const canEditAgcMargin = this.core.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING);
    const hasOrderMaximum = this.core.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.SINGLE_ORDER_BUDGET_CONSTRAINT);
    this.createOrderFormModel = new CreateOrderFormModel(
      canEditAgcMargin,
      hasOrderMaximum,
      this.advertisers,
      this.core.accountManager.localeMeta,
      this.core.addonFeatureManager);
    return this.createOrderFormModel;
  }

  getEditOrderFormModel (orderNumber): OrderFormModel | undefined {
    if (!this.core.accountManager.localeMeta || !this.advertisers) {
      return undefined;
    }

    if (this.editOrderFormModel &&
      this.editOrderFormModel.orderNumber === orderNumber &&
      this.editOrderFormModel.localeMeta.credit === this.core.accountManager.localeMeta.credit) {
      return this.editOrderFormModel;
    }

    const canEditAgcMargin = this.core.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING);
    const hasOrderMaximum = this.core.addonFeatureManager.isFeatureEnable(ADDONFEATURE.COMPANY.SINGLE_ORDER_BUDGET_CONSTRAINT);
    this.editOrderFormModel = new EditOrderFormModel(
      canEditAgcMargin,
      hasOrderMaximum,
      this.core.accountManager.localeMeta,
      this.advertisers,
      orderNumber,
      this.core.addonFeatureManager);
    return this.editOrderFormModel;
  }

  getOrderDetailProps (orderNumber): any {
    return {
      orderNumber,
      authenticationManager: this.core.authenticationManager,
      addonFeatureManager: this.core.addonFeatureManager,
      localMetaDataUpdater: this.core.accountManager.updateLocaleMeta.bind(this.core.accountManager)
    };
  }

  getOrderHomeModel (): OrderHomeModel | undefined {
    if (!this.advertisers) {
      return undefined;
    }

    const currentAdvertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    if (this.orderHomeModel &&
      this.orderHomeModel.advertisers === this.advertisers &&
      this.orderHomeModel.advertiserId === currentAdvertiserId) {
      return this.orderHomeModel;
    }

    this.orderHomeModel = new DefaultOrderHomeModel(this.advertisers, this.core.addonFeatureManager);
    return this.orderHomeModel;
  }

  onUnmount (eventHandler: number | undefined = undefined): void {
    this.loading = true;
    if (eventHandler !== undefined) {
      this.event.remove(eventHandler);
    }
    this.createOrderFormModel = undefined;
    this.editOrderFormModel = undefined;
    this.orderHomeModel = undefined;
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
