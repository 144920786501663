import React, { Fragment, useCallback, useState } from 'react';
import styles from './filterItemComponent.module.scss';
import classnames from 'classnames/bind';
import { Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import { SpaceAttribute } from './DeviceDataContext';

const cx = classnames.bind(styles);

export const FilterItemComponent: React.FunctionComponent<{
  attributeType: string;
  title: string;
  options: SpaceAttribute[];
  filterSpaceAttributeMap: {
    [spaceAttribute: string]: SpaceAttribute
  };
  onFilterCallback: (attributeType: string, spaceAttribute: SpaceAttribute) => void;
}> = ({
  attributeType,
  title,
  options,
  filterSpaceAttributeMap,
  onFilterCallback
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [itemToggleOpenMap, setItemToggleOpenMap] = useState<{
    [category: string]: boolean;
  }>({});

  const toggleDropdown = useCallback((
    isOpen: boolean,
    _e: React.SyntheticEvent<Dropdown>,
    metaData: {
      source: 'select' | 'click' | 'rootClose' | 'keydown';
    }) => {
    if (metaData.source === 'select') {
      setIsDropdownOpen(isOpen);
    }
    setIsDropdownOpen(prev => !prev);
  }, []);

  const itemToggleOpenFunc = useCallback((category: string) => {
    setItemToggleOpenMap(prev => {
      return {
        ...prev,
        [category]: !prev[category]
      };
    });
  }, []);

  const itemClickFunc = useCallback((data: SelectOptions) => {
    onFilterCallback(attributeType, data);
  }, [onFilterCallback, attributeType]);

  const renderOptionContent = useCallback((data: SelectOptions) => (
    <Fragment>
      {data.label}
    </Fragment>
  ), []);

  const renderOptions = useCallback((data: SelectOptions) => {
    if (!data.options) {
      return [];
    }
    return _.map(data.options, (option: SelectOptions) => {
      const dropdownItemClass = cx('dropdownItem', {
        isSelected: filterSpaceAttributeMap[option.value]
      });
      return (
        <Dropdown.Item
          key={option.value}
          className={dropdownItemClass}
          onClick={_.partial(itemClickFunc, option)}
        >
          {renderOptionContent(option)}
        </Dropdown.Item>
      );
    });
  }, [renderOptionContent, itemClickFunc, filterSpaceAttributeMap]);

  const dropdownToggleClass = cx('dropdownToggle', {
    open: isDropdownOpen,
    isSelected: !_.isEmpty(filterSpaceAttributeMap)
  });

  return (
    <Dropdown
      show={isDropdownOpen}
      onToggle={_.partial(toggleDropdown)}
    >
      <Dropdown.Toggle
        as='div'
        id={`${attributeType}-LimitationFilterItemDropdown`}
        className={dropdownToggleClass}
      >
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu className={styles.dropdownMenu}>
        {options.map((data: SpaceAttribute) => {
          const itemToggleOpen = itemToggleOpenMap[data.value.toString()];
          const filterItemClass = cx('filterItem', {
            open: itemToggleOpen
          });
          const itemContainerClass = cx('itemContainer', {
            isSelected: filterSpaceAttributeMap[data.value]
          });
          const dropdownItemClass = cx('dropdownItem', {
            isSelected: filterSpaceAttributeMap[data.value]
          });
          if (data.options && data.options.length > 0) {
            return (
              <div
                key={data.value}
                className={filterItemClass}
              >
                <div className={itemContainerClass}>
                  <div
                    className={styles.arrowContainer}
                    onClick={_.partial(itemToggleOpenFunc, data.value.toString())}
                  >
                    <div className={styles.arrow} />
                  </div>
                  <Dropdown.Item
                    className={dropdownItemClass}
                    onClick={_.partial(itemClickFunc, data)}
                  >
                    {renderOptionContent(data)}
                  </Dropdown.Item>
                </div>
                <div className={styles.options}>
                  {renderOptions(data)}
                </div>
              </div>
            );
          } else {
            return (
              <div
                key={data.value}
                className={filterItemClass}
                onClick={_.partial(itemClickFunc, data)}
              >
                <Dropdown.Item className={dropdownItemClass}>
                  {renderOptionContent(data)}
                </Dropdown.Item>
              </div>
            );
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
