import React from 'react';
import { find } from 'lodash';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';

interface LabelProps {
  options?: SelectOptions[];
  className?: string;
  value?: string;
  formatter?: (value: string) => string;
}

export interface LabelFieldProps extends LabelProps, Omit<FieldLayoutProps, 'name'> {}
export interface FormikLabelProps extends FormikFieldProps, LabelProps {}
export interface FormikLabelWithFieldLayoutProps extends FormikFieldProps, Omit<FieldLayoutProps, 'name'>, LabelProps {}
export type FormikLabelFieldProps = ({ withFieldLayout?: true } & FormikLabelWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikLabelProps);

const LabelContent = ({
  value,
  options,
  className,
  formatter
}) => {
  const labelClassName = classnames(className, ['col-form-label', 'label']);

  let label;
  if (options) {
    const optionsValue = find(options, { value });
    if (optionsValue && optionsValue.label) {
      label = <Form.Label className={labelClassName}>{formatter ? formatter(optionsValue.label) : optionsValue.label}</Form.Label>;
    }
  }
  label = <Form.Label className={labelClassName}>{formatter ? formatter(value) : value}</Form.Label>;
  return label;
};
const MemorizedLabelContent = React.memo(LabelContent);

export const LabelField = withFieldLayout<LabelFieldProps>(LabelContent);
const FormikLabel = withFormikField<FormikLabelProps>(MemorizedLabelContent);
const FormikLabelWithFieldLayout = withFormikField<FormikLabelWithFieldLayoutProps>(withFieldLayout(LabelContent));

export const FormikLabelField: React.FC<FormikLabelFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikLabel {...props} />;
  } else {
    return <FormikLabelWithFieldLayout {...props} />;
  }
};
