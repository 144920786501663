import { FormikField } from 'components/common/form/field/FormikField';
import { Page } from 'components/Page/Page';
import { AUTO_UPDATE_VALUE, ProductGroupFormValue } from 'core/limitation/ProductGroup';
import { DefaultProductGroupManager } from 'core/limitation/ProductGroupManager';
import { useFormikContext } from 'formik';
import i18n from 'i18n';
import { useCallback } from 'react';

const productGroupManager = new DefaultProductGroupManager();
export const GeneralSection: React.FC<{
  productGroupTypeOptions: SelectOptions[]
}> = ({
  productGroupTypeOptions
}) => {

  const { values, setValues } = useFormikContext<ProductGroupFormValue>();
  const isAutoUpdate = values.groupValues.find(option => option.value === AUTO_UPDATE_VALUE);

  const onGroupTypeOnChange = useCallback((groupType) => {
    const initProductGroup = productGroupManager.getInitPropductGroup(groupType);
    setValues({
      ...initProductGroup,
      groupName: values.groupName,
      agencyIds: values.agencyIds
    });
  }, [values.agencyIds, values.groupName, setValues]);

  return (
    <Page.Section title={i18n.t<string>('productGroupForm.title.generalSection')} inlineTitle withShadow={false}>
      <FormikField.Input
        labelColSm={2}
        inputColSm={10}
        label={i18n.t<string>('productGroupForm.labels.groupName')}
        name='groupName'
        postText={isAutoUpdate ? i18n.t<string>('productGroupForm.hints.autoUpdate') : undefined}
      />
      <FormikField.Select
        labelColSm={2}
        inputColSm={10}
        label={i18n.t<string>('productGroupForm.labels.groupType')}
        name='groupType'
        simpleValue
        options={productGroupTypeOptions}
        onChange={onGroupTypeOnChange}
      />
    </Page.Section>
  );
};
