import { DefaultPmpManager } from 'core/pmp/PmpManager';
import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel
} from './RtbCampaignBasicFormModel';
import { DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { DefaultAgencyManager } from 'core';
import { OutdoorChannel } from 'core/limitation/ProductGroup';
import { Pmp } from 'core/pmp/Pmp';
import { RtbCampaign, RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';

export type PmpCampaign = {
  basic: Partial<RtbCampaignBasic>;
  limitations: RtbCampaign['limitations'];
};

export interface PmpFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
  readonly pmpSpaceOptions: SelectOptions[];
  readonly pmpListOfOrder: Pmp[];
  readonly pmp?: Pmp;
  setPmp (pmp: Pmp): void;
}

export abstract class AbstractPmpFormModel extends DefaultRtbCampaignBasicFormModel
  implements PmpFormModel {

  pmpManager = new DefaultPmpManager();
  agencyManager = new DefaultAgencyManager();
  adRequestSourceManager = new DefaultAdRequestSourceManager();
  pmpSpaceOptions: SelectOptions[] = [];
  pmpListOfOrder: Pmp[] = [];
  pmp?: Pmp;

  override get canCreateWithCreative (): boolean {
    return true;
  }

  override async init (): Promise<void> {
    try {
      this.pmpSpaceOptions = await this.adRequestSourceManager.getSpacesWithConditions(
        {},
        'Outdoor',
        OutdoorChannel.PIC
      );
      await this.agencyManager.getAgencyName(this.order.agencyId);
    } catch (e) {
      console.error(e);
    }
  }

  override get isPmpType (): boolean {
    return true;
  }

  setPmp (pmp: Pmp): void {
    this.pmp = pmp;
  }
}
