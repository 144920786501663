import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { AdType } from './RtbCampaign';
import { get } from 'lodash';

export type CreativeBindData = {
  id: string;
  startDate: string;
  endDate: string;
  name: string;
  adType?: AdType;
  channel: L1ObjectChannel;
  retailer: string;
  draftId?: number;
  billingEvent: string;
  isPmp?: boolean;
};

export function createCreativeBindDataByCampaign (campaign): CreativeBindData {
  return {
    id: campaign.id,
    startDate: campaign.startDate,
    endDate: campaign.endDate,
    name: campaign.name,
    adType: campaign.adType,
    channel: L1ObjectChannel.RTB,
    draftId: campaign.draftId,
    retailer: campaign.retailType,
    billingEvent: 'UNSPECIFIED',
    isPmp: get(campaign, 'additionalInfo.pmp') !== undefined
  } as CreativeBindData;
}

export function createCreativeBindDataByFbAdSet (fbAdSet): CreativeBindData {
  return {
    id: fbAdSet.id,
    startDate: fbAdSet.start_time,
    endDate: fbAdSet.end_time,
    name: fbAdSet.name,
    adType: undefined,
    channel: L1ObjectChannel.FB,
    draftId: fbAdSet.draftId,
    billingEvent: fbAdSet.billing_event
  } as CreativeBindData;
}
