import React, { useState } from 'react';
import { L1Object } from 'core/l1Object/L1Object';
import { DefaultL1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Formik } from 'formik';
import { FormikField } from 'components/common/form/field/FormikField';
import i18n from 'i18n';
import styles from './draftSetupTarget.module.scss';
import { get, isEmpty, omitBy, partial } from 'lodash';
import { validateInteger, validateMinimum } from 'utils/ValidateUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { DraftL1ObjectOption } from './DraftL1ObjectOption';

const defaultL1ObjectManager = new DefaultL1ObjectManager();
type DraftTarget = {
  id: number,
  order?: {
    name: string,
    id: number
  } | null,
  l1Object?: {
    name: string,
    id: number
  } | null,
  numberOfCopies: number,
  includeBinding: boolean,
  hasError: boolean
};

export const DraftSetupTarget: React.FC<{
  draftTarget: DraftTarget,
  orderOptions: SelectOptions[],
  l1ObjectsOptionsCache: {[key: number]: SelectOptions[]}
  selectedL1Objects: number[]
  l1ObjectsFilter: (l1Objects: L1Object[]) => L1Object[],
  onTargetChange: (data: DraftTarget) => void,
  onRemove?: () => void
}> = ({
  draftTarget,
  orderOptions,
  l1ObjectsOptionsCache,
  selectedL1Objects,
  l1ObjectsFilter,
  onTargetChange,
  onRemove
}) => {

  const { loading, callAPIs } = useCallAPI();
  const orderId = get(draftTarget, 'order.value');
  const addDisabledPropsToOptions = (l1ObjectOptions: SelectOptions[]) => {
    return l1ObjectOptions.map(option => ({
      ...option,
      disabled: selectedL1Objects.includes(+option.value)
    }));
  };
  const cachedL1ObjectOptions = addDisabledPropsToOptions(orderId && l1ObjectsOptionsCache[orderId] ? l1ObjectsOptionsCache[orderId] : []);
  const [l1ObjectOptions, setL1ObjectOptions] = useState<(SelectOptions & { disabled: boolean })[]>(cachedL1ObjectOptions);

  const submit = () => {
    // This is intentional
  };

  const validate = values => {
    const numberOfCopies = values.numberOfCopies;
    return omitBy({
      numberOfCopies: validateInteger(numberOfCopies) || validateMinimum(numberOfCopies, 1)
    }, isEmpty);
  };

  const handleFieldChange = (fieldName, value) => {
    const newTarget = {
      ...draftTarget,
      [fieldName]: value
    };
    newTarget.l1Object = fieldName === 'order' ? null : newTarget.l1Object;
    const errors = validate(newTarget);
    onTargetChange({
      ...newTarget,
      hasError: !isEmpty(errors)
    });
  };

  const fetchL1ObjectOptions = (orderId) => {
    const l1ObjectOptions = l1ObjectsOptionsCache[orderId];
    if (l1ObjectOptions) {
      setL1ObjectOptions(addDisabledPropsToOptions(l1ObjectOptions));
      return;
    }
    callAPIs([() => defaultL1ObjectManager.getL1Objects(orderId)], (l1Objects: L1Object[]) => {
      const l1ObjectOptions = l1ObjectsFilter(l1Objects).map(l1Object => ({
        label: l1Object.name,
        value: l1Object.l1ObjectId
      }));
      l1ObjectsOptionsCache[orderId] = l1ObjectOptions;
      setL1ObjectOptions(addDisabledPropsToOptions(l1ObjectOptions));
    });
  };

  return (
    <Formik
      initialValues={draftTarget}
      enableReinitialize={false}
      onSubmit={submit}
      validate={validate}
      validateOnBlur={false}
    >
      {({ setFieldValue, values }) => {
        const onOrderChange = (orderOption) => {
          handleFieldChange('order', orderOption);
          setFieldValue('l1Object', null);
          fetchL1ObjectOptions(orderOption.value);
        };
        return (
          <div className={styles.draftTargetForm}>
            {loading && <LoadingIndicator/>}
            { onRemove &&
              <FontAwesomeIcon
                className={styles.closeBtn}
                icon={faTimes}
                onClick={onRemove}
              />
            }
            <FormikField.Select
              label={i18n.t<string>('draftCreateModal.labels.order')}
              options={orderOptions}
              name='order'
              onChange={onOrderChange}
            />
            <FormikField.Select
              label={i18n.t<string>('draftCreateModal.labels.l1Object')}
              options={l1ObjectOptions}
              optionComponent={DraftL1ObjectOption}
              name='l1Object'
              onChange={partial(handleFieldChange, 'l1Object')}
              hint={i18n.t<string>('draftCreateModal.hints.l1ObjectNeedSameChannel')}
            />
            <FormikField.Input
              label={i18n.t<string>('draftCreateModal.labels.numberOfCopies')}
              name='numberOfCopies'
              type='number'
              min={1}
              onChange={partial(handleFieldChange, 'numberOfCopies')}
            />
            <FormikField.Checkbox
              label={''}
              id={`includeBinding-${draftTarget.id}`}
              checkboxLabel={i18n.t<string>('draftCreateModal.labels.includeBinding')}
              name={'includeBinding'}
              onChange={partial(handleFieldChange, 'includeBinding')}
              postfix={(
                <IconWithTooltip
                  className={styles.bindHint}
                  icon={faInfoCircle}
                  tooltipProps={{
                    id: `draftCreateModalBindingHint-${draftTarget.id}`,
                    tooltip: i18n.t<string>('draftCreateModal.hints.cannotBindHint')
                  }}
                />
              )}
            />
          </div>
        );
      }}
    </Formik>
  );
};
