import { FormikField } from 'components/common/form/field/FormikField';
import React, { SetStateAction, useCallback } from 'react';
import i18n from 'i18n';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { FinalReportTargetType, Order } from 'core/order/Order';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'components/common/Select/Select';
import { ageMaxOptions, ageMinOptions, genderOptions } from 'core/limitation/l2ObjectTAOptions';
import styles from './orderForm.module.scss';

const finalReportTargetTypeOptions: SelectOptions[] = createSelectOptionsFromEnum(FinalReportTargetType, 'order.finalReport.targetType.');

export const TenmaxFinalReportSection: React.FC<{
  ageMin: string | number,
  ageMax: string | number,
  gender: number,
  onFieldsChange: (values: SetStateAction<Order>) => void
}> = ({
  ageMin,
  ageMax,
  gender,
  onFieldsChange
}) => {

  const onAgeMinChange = useCallback(newAgeMin => {
    onFieldsChange(prev => ({
      ...prev,
      ageMax: newAgeMin > ageMax ? newAgeMin : prev.ageMax,
      ageMin: newAgeMin
    }));
  }, [ageMax, onFieldsChange]);

  const onAgeMaxChange = useCallback(newAgeMax => {
    onFieldsChange(prev => ({
      ...prev,
      ageMin: newAgeMax < ageMin ? newAgeMax : prev.ageMin,
      ageMax: newAgeMax
    }));
  }, [ageMin, onFieldsChange]);

  const onGendersChange = useCallback(gender => {
    onFieldsChange(prev => ({
      ...prev,
      gender
    }));
  }, [onFieldsChange]);

  const renderAgeAndGender = () => {
    return (
      <>
        <Form.Group as={Row} controlId={'age'}>
          <Col sm={3}>
            <Form.Label column>{i18n.t<string>('limitation.labels.age')}</Form.Label>
          </Col>
          <Col sm={9}>
            <div className={styles.ageSelector}>
              <Select
                className={styles.ageMin}
                options={ageMinOptions}
                name='ageMin'
                simpleValue
                value={ageMin}
                onChange={onAgeMinChange}
              />
              ~
              <Select
                className={styles.ageMax}
                options={ageMaxOptions}
                name='ageMax'
                simpleValue
                value={ageMax}
                onChange={onAgeMaxChange}
              />
            </div>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId={'gender'}>
          <Col sm={3}>
            <Form.Label column>{i18n.t<string>('limitation.labels.gender')}</Form.Label>
          </Col>
          <Col sm={9}>
            <Select
              name='gender'
              options={genderOptions}
              simpleValue
              value={gender}
              onChange={onGendersChange}
            />
           </Col>
        </Form.Group>
      </>
    );
  };

  return (
    <>
      <FormikField.Input
        label={i18n.t<string>('orderForm.labels.finalReport.advertiserName')}
        name='finalReportAdvertiserName'
      />
      <FormikField.Select
        label={i18n.t<string>('orderForm.labels.finalReport.targetType')}
        name='finalReportTargetType'
        simpleValue
        options={finalReportTargetTypeOptions}
      />
      <FormikField.Input
        label={i18n.t<string>('orderForm.labels.finalReport.targetValue')}
        name='finalReportTargetValue'
        type='number'
      />
      {renderAgeAndGender()}
    </>
  );
};
