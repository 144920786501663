import _ from 'lodash';
import client from './RestClient';
import { ConversionFormDTO, ConversionListDTO } from 'core/conversion/Conversion';
import { AxiosInstance } from 'axios';

export interface ConversionWebService {
  getConversionOptions (advertiserId: number): Promise<Array<SelectOptions>>;
  getConversions (agencyId?: number): Promise<Array<ConversionListDTO>>;
  createConversion (conversion: ConversionFormDTO): Promise<void>;
  updateConversion (conversion: ConversionFormDTO): Promise<void>;
  getConversionCode (conversionId: number): Promise<string>;
}

function wrapConversionOption (json: any): SelectOptions {
  return {
    value: _.get(json, 'value'),
    label: _.get(json, 'label')
  };
}

function wrapConversionListDTO (json: any): ConversionListDTO {
  return {
    id: _.get(json, 'id'),
    dmpConversionTrackingId: _.get(json, 'dmpConversionTrackingId'),
    advertiserId: _.get(json, 'advertiserId'),
    type: _.get(json, 'type'),
    name: _.get(json, 'name')
  };
}

export class RestfulConversionWebService implements ConversionWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getConversionOptions (advertiserId: number): Promise<Array<SelectOptions>> {
    const response = await this.restClient.get(`/v2/conversion-trackers/options?advertiser_id=${advertiserId}`);
    return _.get(response, 'data.records', []).map(json => { return wrapConversionOption(json); });
  }

  async getConversions (agencyId?: number): Promise<Array<ConversionListDTO>> {
    const path = agencyId === undefined ? '/v2/conversion-trackers' : `/v2/conversion-trackers?agencyId=${agencyId}`;
    const response = await this.restClient.get(path);
    return _.get(response, 'data.records', []).map(json => { return wrapConversionListDTO(json); });
  }

  async createConversion (conversion: ConversionFormDTO): Promise<void> {
    return this.restClient.post('/v2/conversion-trackers', {
      advertiserId: conversion.advertiserId,
      name: conversion.name,
      type: conversion.type
    });
  }

  async updateConversion (conversion: ConversionFormDTO): Promise<void> {
    return this.restClient.put(`/v2/conversion-trackers/${conversion.id}`, {
      advertiserId: conversion.advertiserId,
      name: conversion.name,
      type: conversion.type
    });
  }

  async getConversionCode (conversionId: number): Promise<string> {
    const result = await this.restClient.get(`/v2/conversion-trackers/${conversionId}/code`);
    return _.get(result.data, 'code', '');
  }
}
