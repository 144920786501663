import { FormikField } from 'components/common/form/field/FormikField';
import { AggregationField, AGGREGATION_TYPES, EventRule, EVENT_TYPES, FilterField, FILTER_FIELDS, PRODUCT_SEGMENT_OPERATOR } from 'core/product/ProductSegment';
import { AggregationTypeOperatorsConfig, CombinedEventTypes, DURATION_DAYS, EventTypeConfig, MAX_FILTER_FIELDS_LENGTH, OperatorInitValuesMapConfig } from 'core/product/ProductSegmentConfig';
import _ from 'lodash';
import React, { FC, Fragment, useCallback, useMemo } from 'react';
import i18n from 'i18n';
import { createSelectOptions, createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import styles from './productSegmentForm.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ProductSegmentFilterField } from './FilterField';
import { CustomField } from 'components/common/form/field/CustomField';

export const RuleForm: FC<{
  ruleIndex: number,
  combination: 'inclusion' | 'exclusion',
  eventRule: EventRule,
  eventRules: EventRule[],
  productSetOptions: SelectOptions[],
  initSubConditions: (eventType: EVENT_TYPES | string) => {
    operator: PRODUCT_SEGMENT_OPERATOR;
    filters: FilterField[]
  } | undefined,
  setValues: (updateFunc: (prev: any) => any) => void,
  setFieldValue: (field: string, value: any) => void
}> = ({
  ruleIndex,
  combination,
  eventRule,
  eventRules,
  productSetOptions,
  initSubConditions,
  setValues,
  setFieldValue
}) => {

  const durationDayOptions = useMemo(() => createSelectOptions(DURATION_DAYS), []);
  const eventType: string = _.get(eventRule, 'eventType', '');
  const filters: FilterField[] = _.get(eventRule, 'filterSet.filters', []);
  const filterFields: FILTER_FIELDS[] = _.get(EventTypeConfig, `${eventType}.filterFields`, []);
  const aggregationTypes: {
    type: AGGREGATION_TYPES,
    field?: FILTER_FIELDS
  }[] = _.get(EventTypeConfig, `${eventType}.aggregationTypes`, []);

  const aggregation: AggregationField | undefined = _.get(eventRule, 'aggregation', undefined);
  const aggregationType = _.get(aggregation, 'type', AGGREGATION_TYPES.COUNT);
  const aggregationField = _.get(aggregation, 'field', undefined);
  const allEventTypeOptions: SelectOptions[] = useMemo(() => createSelectOptionsFromEnum(EVENT_TYPES, 'productSegmentForm.labels.eventRule.eventType.'), []);
  const eventTypeOptions = useMemo(() => {
    if (eventRules.some(eventRule => !_.includes(CombinedEventTypes, eventRule.eventType)) && eventRules.length > 1) {
      return allEventTypeOptions.filter(option => !_.includes(CombinedEventTypes, option.value));
    }
    return allEventTypeOptions;
  }, [allEventTypeOptions, eventRules]);

  const hasAggregation = !_.isNil(aggregation);
  const handleGenerateAggregation = useCallback(() => {
    const aggregationType: AGGREGATION_TYPES | undefined = _.get(aggregationTypes, '[0].type');
    const operator: PRODUCT_SEGMENT_OPERATOR | undefined = _.get(AggregationTypeOperatorsConfig, `${aggregationType}[0]`);
    if (!aggregationType || !operator) return;
    const newAggregation: AggregationField = {
      type: aggregationType,
      operator,
      value: _.get(OperatorInitValuesMapConfig, `${operator}`, [''])
    };
    if (hasAggregation) {
      setFieldValue(`${combination}.rules[${ruleIndex}.aggregation]`, undefined);
    } else {
      setFieldValue(`${combination}.rules[${ruleIndex}.aggregation]`, newAggregation);
    }
  }, [hasAggregation, aggregationTypes, combination, ruleIndex, setFieldValue]);

  const handleGenerateFilterField = useCallback(() => {
    const newFilterField = {
      field: '',
      operator: '',
      value: ['']
    };
    const newFilterSet = {
      ..._.get(eventRule, 'filterSet', {}),
      operator: PRODUCT_SEGMENT_OPERATOR.AND,
      filters: [
        ...filters,
        newFilterField
      ]
    };
    setValues(prev => {
      const newValue = _.cloneDeep(prev);
      _.set(newValue, `${combination}.rules[${ruleIndex}].filterSet`, newFilterSet);
      _.set(newValue, `${combination}.rules[${ruleIndex}].selectedFilterField`, undefined);
      return newValue;
    });
  }, [eventRule, combination, ruleIndex, setValues, filters]);

  const onChangeEventType = useCallback((selectedEventType: EVENT_TYPES) => {
    const defaultFilterSet = initSubConditions(selectedEventType);
    const newEventRule = {
      ruleId: eventRule.ruleId,
      durationDays: eventRule.durationDays,
      eventType: selectedEventType,
      filterSet: defaultFilterSet
    };
    setFieldValue(`${combination}.rules[${ruleIndex}]`, newEventRule);
  }, [eventRule.ruleId, eventRule.durationDays, setFieldValue, combination, ruleIndex, initSubConditions]);

  const aggregationTypesOptions: SelectOptions[] = useMemo(() => _.map(aggregationTypes, aggregation => ({
    label: i18n.t<string>(`productSegmentForm.labels.aggregationType.${Object.values(aggregation).join('.')}`),
    value: aggregation.type
  })), [aggregationTypes]);
  const aggregationTypeOperators: PRODUCT_SEGMENT_OPERATOR[] = useMemo(() => _.get(AggregationTypeOperatorsConfig, `${aggregation?.type}`, []), [aggregation]);
  const aggregationTypeOperatorOptions = useMemo(() => createSelectOptions(aggregationTypeOperators, 'productSegmentForm.labels.operator.', _.snakeCase), [aggregationTypeOperators]);
  const aggregationLabel: string = useMemo(() => {
    if (!_.isNil(aggregation)) {
      let values = Object.values(_.omitBy(_.omit(aggregation, ['operator', 'value']), _.isEmpty));
      return values.join('.');
    } else {
      return '';
    }
  }, [aggregation]);

  const aggregationI18nKey = `productSegmentForm.labels.aggregationType.postText.${aggregationLabel}`;

  const onChangeAggregationType = useCallback((selectedAggregationType: AGGREGATION_TYPES) => {
    const aggregationField: FILTER_FIELDS | undefined = _.filter(aggregationTypes, aggregation => aggregation.type === selectedAggregationType)[0].field;
    const operator: PRODUCT_SEGMENT_OPERATOR | undefined = _.get(AggregationTypeOperatorsConfig, `${selectedAggregationType}[0]`);
    if (!operator) {
      return;
    }
    const newAggregation: AggregationField = {
      type: selectedAggregationType,
      operator,
      value: _.get(OperatorInitValuesMapConfig, `${operator}`, ['']),
      field: aggregationField
    };
    setFieldValue(`${combination}.rules[${ruleIndex}.aggregation`, _.omitBy(newAggregation, _.isNil));
  }, [combination, ruleIndex, aggregationTypes, setFieldValue]);

  const onChangeOperator = useCallback((selectedOperator: PRODUCT_SEGMENT_OPERATOR) => {
    const newAggregation: AggregationField = {
      type: aggregationType,
      operator: selectedOperator,
      value: _.get(OperatorInitValuesMapConfig, `${selectedOperator}`, ['']),
      field: aggregationField
    };
    setFieldValue(`${combination}.rules[${ruleIndex}.aggregation`, _.omitBy(newAggregation, _.isNil));
  }, [combination, ruleIndex, aggregationType, aggregationField, setFieldValue]);

  const renderAggregation = (aggregation: AggregationField) => {
    return (
      <div className={styles.filterFieldRow}>
        <FormikField.Select
          name={`${combination}.rules[${ruleIndex}].aggregation.type`}
          simpleValue
          options={aggregationTypesOptions}
          componentWidthFitParent={true}
          fieldContentWidth={180}
          inputColSm={3}
          onChange={onChangeAggregationType}
        />
        <FormikField.Select
          name={`${combination}.rules[${ruleIndex}].aggregation.operator`}
          simpleValue
          options={aggregationTypeOperatorOptions}
          componentWidthFitParent={true}
          fieldContentWidth={100}
          inputColSm={3}
          onChange={onChangeOperator}
        />
        {_.map(aggregation.value, (_1, valueId) => {
          return (
            <FormikField.InputGroup
              key={`fieldValue-${valueId}`}
              name={`${combination}.rules[${ruleIndex}].aggregation.value[${valueId}]`}
              fieldContentWidth={280 / aggregation.value.length}
              inputColSm={3}
              postfix={i18n.exists(aggregationI18nKey) ? i18n.t<string>(aggregationI18nKey) : ''}
            />
          );
        })}
        <div className={styles.fieldCloseBtn}>
          <button
            type='button'
            className='btn'
            onClick={handleGenerateAggregation}
          >
            <FontAwesomeIcon
              icon={faTimes}
              size='lg'
            />
          </button>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <FormikField.Select
        label={i18n.t<string>('productSegmentForm.labels.eventRule.durationDays')}
        name={`${combination}.rules[${ruleIndex}].durationDays`}
        labelColSm={2}
        simpleValue
        componentWidthFitParent={true}
        fieldContentWidth={100}
        preText={i18n.t<string>('productSegmentForm.labels.eventRule.durationDaysPreText')}
        postText={i18n.t<string>('productSegmentForm.labels.eventRule.durationDaysPostText')}
        options={durationDayOptions}
      />
      <FormikField.Select
        label={i18n.t<string>('productSegmentForm.labels.eventRule.eventTypes')}
        name={`${combination}.rules[${ruleIndex}].eventType`}
        labelColSm={2}
        simpleValue
        componentWidthFitParent={true}
        fieldContentWidth={280}
        options={eventTypeOptions}
        onChange={onChangeEventType}
      />
      {filters.length > 0 &&
        _.map(filters, (filterField, fieldIndex) => {
          return (
            <ProductSegmentFilterField
              key={fieldIndex}
              ruleIndex={ruleIndex}
              combination={combination}
              filters={filters}
              filterField={filterField}
              fieldIndex={fieldIndex}
              productSetOptions={productSetOptions}
              filterFields={filterFields}
              setValues={setValues}
            />
          );
        })
      }
      {(filters.length < Math.min(filterFields.length, MAX_FILTER_FIELDS_LENGTH)) &&
        <CustomField
          name='generateFilterField-button'
          label={i18n.t<string>(`productSegmentForm.labels.common.${PRODUCT_SEGMENT_OPERATOR.AND}`)}
          labelColSm={2}
          formGroupClassName={styles.selectedFilterFieldFormGroup}
        >
          <div className={styles.filterFieldRow}>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={handleGenerateFilterField}
            >
              {i18n.t<string>('productSegmentForm.placeholder.filterField')}
            </button>
            <FormikField.Label
              isFlexibleContent={true}
              inputColSm={3}
              name={`${combination}.rules[${ruleIndex}].selectedFilterField`}
            />
          </div>
        </CustomField>
      }
      {aggregationTypes.length > 0 &&
        <CustomField
          name={`aggregationField-${ruleIndex}`}
          label={i18n.t<string>(`productSegmentForm.labels.common.${PRODUCT_SEGMENT_OPERATOR.AND}`)}
          labelColSm={2}
          formGroupClassName={styles.aggregationFieldFormGroup}
        >
          {!_.isNil(aggregation) ?
            renderAggregation(aggregation) :
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={handleGenerateAggregation}
            >
              {i18n.t<string>('productSegmentForm.buttons.aggregation')}
            </button>
          }
        </CustomField>
      }
    </Fragment>
  );
};
