import { validateMinMax } from 'utils/ValidateUtils';
import { BasicOutdoorFormModel, OutdoorFormProps } from './OutdoorFormModel';
import { OutdoorForm } from './OutdoorForm';
import i18n from 'i18n';
import { floor } from 'lodash';

export class PICFormModel extends BasicOutdoorFormModel<PICFormModel> {

  constructor (
    public minDuration: number,
    public maxDuration: number,
    forPmp: boolean = false
  ) {
    super(forPmp);
    this.validateDuration = this.validateDuration.bind(this);
  }

  get adFormat () {
    return 'PIC_OUTDOOR';
  }

  get useMediaDuration () {
    return true;
  }

  getFormContent (): (props: OutdoorFormProps<PICFormModel>) => JSX.Element {
    return OutdoorForm<PICFormModel>;
  }

  getInitTypeProperties () {
    return {
      ...super.getInitTypeProperties(),
      duration: this.maxDuration
    };
  }

  getDefaultDuration = () => {
    return this.maxDuration;
  }

  getVideoHints (width: number, height: number) {
    return [
      ...super.getVideoHints(width, height),
      i18n.t<string>('picFormModel.hints.videoDuration', { min: this.minDuration, max: this.maxDuration })
    ];
  }

  getAudioHints () {
    return [
      ...super.getAudioHints(),
      i18n.t<string>('picFormModel.hints.audioDuration', { min: this.minDuration, max: this.maxDuration })
    ];
  }

  validateVideo (targetWidth: number, targetHeight: number, fileData: MediaFieldData) {
    const errors = super.validateVideo(targetWidth, targetHeight, fileData);
    if (typeof errors === 'string') {
      return errors;
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const error = this.validateDuration(floor(fileData.duration));
    if (error) {
      return i18n.t<string>('picFormModel.hints.videoDuration', { min: this.minDuration, max: this.maxDuration });
    }

    return errors;
  }

  validateAudio (fileData: MediaFieldData) {
    const errors = super.validateAudio(fileData);
    if (typeof errors === 'string') {
      return errors;
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const error = this.validateDuration(floor(fileData.duration));
    if (error) {
      return i18n.t<string>('picFormModel.hints.audioDuration', { min: this.minDuration, max: this.maxDuration });
    }

    return errors;
  }

  validateDuration (value: number): string | undefined {
    return validateMinMax(value, this.minDuration, this.maxDuration);
  }
}
