import { StepPage } from 'containers/StepPage/StepPage';
import React, { useCallback, useMemo } from 'react';
import { noop } from 'lodash';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Redirect } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import i18n from 'i18n';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { Page } from 'components/Page/Page';
import SummaryDetail from 'components/SummaryDetail/SummaryDetail';
import { PmpFormType } from 'core/pmp/Pmp';
import { PmpSetupFlowModel } from './PmpSetupFlowPageModel';
import { PmpForm } from './PmpForm';

export const PmpSetupFlowPage: React.FC<{
  spaceOptions: SelectOptions[],
  agencyOptions: SelectOptions[],
  useModel: (spaceOptions: SelectOptions[], agencyOptions: SelectOptions[]) => PmpSetupFlowModel
}> = ({
  spaceOptions,
  agencyOptions,
  useModel
}) => {

  const {
    pmp,
    title,
    loading,
    redirectPath,
    summaryData,
    readonly,
    setPmp,
    handleSubmit
  } = useModel(spaceOptions, agencyOptions);

  const renderForm = useCallback(({
    goNext
  }) => {
    const onSubmit = (pmp: PmpFormType) => {
      setPmp(pmp);
      goNext();
    };
    return (
      <PmpForm
        readonly={readonly}
        pmpFormData={pmp!}
        onSubmit={onSubmit}
        spaceOptions={spaceOptions}
        agencyOptions={agencyOptions}
      />
    );
  }, [pmp, readonly, spaceOptions, agencyOptions, setPmp]);

  const renderSummary = useCallback(() => {
    return (
      <Page.Section title={i18n.t<string>('pmpSetupFlowPage.titles.summarySection')}>
        <SummaryDetail data={summaryData}/>
      </Page.Section>
    );
  }, [summaryData]);

  const renderSummparyBtns = useCallback(({
    goLast
  }) => {
    if (!pmp) {
      return <div/>;
    }
    const onSubmit = () => {
      handleSubmit(pmp);
    };
    return (
      <>
        <Button size='sm' onClick={onSubmit}>{i18n.t<string>('common.buttons.submit')}</Button>
        <Button variant='secondary' size='sm' onClick={goLast}>{i18n.t<string>('common.buttons.back')}</Button>
      </>
    );
  }, [pmp, handleSubmit]);

  const breadcrumbRoutes = useMemo(() => [
    { path: '/pmp', breadcrumb: i18n.t<string>('appMenus.comapnyManagement.items.pmp') },
    { path: '/pmp/new', breadcrumb: i18n.t<string>('pmpSetupFlowPage.titles.create') },
    { path: '/pmp/:pmpId', breadcrumb: DynamicBreadcrumb, props: { label: pmp ? pmp.name : '', matchParam: 'pmpId' } }
  ], [pmp]);

  if (!pmp) {
    return <LoadingIndicator/>;
  }

  return (
    <>
      {loading && <LoadingIndicator/>}
      {redirectPath && <Redirect to={redirectPath}/>}
      <StepPage
        cancel={noop}
        withSidebar={false}
        title={title}
        spaceBetweenTopAreaAndContent={false}
        breadcrumbsRoutes={breadcrumbRoutes}
      >
        <StepPage.Step
          label=''
          renderStepContent={renderForm}
        />
        <StepPage.Step
          label=''
          renderStepContent={renderSummary}
          renderBtns={renderSummparyBtns}
        />
      </StepPage>
    </>
  );
};
